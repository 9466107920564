import * as React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import PropTypes from "prop-types"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { Grid, Typography } from "@mui/material"

const BreadcrumbHerbarium = ({ pages }) => {
  // const location = useLocation()
  // console.log(location)
  return (
    <Grid container spacing={2} py={2} px={5}>
      <Grid item md={12} xs={12}>
        <Breadcrumbs
          separator={
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "black" }}
            >
              {">"}
            </Typography>
          }
          // separator=">"
        >
          <Link
            key={"home"}
            to={"/herbarium-home"}
            component={RouterLink}
            sx={{
              color: "#10AC8E",
              textDecorationColor: "#10AC8E",
              textDecoration: "none",
              // fontWeight: "medium",
            }}
          >
            Home
          </Link>
          {pages?.map((page) =>
            page?.path ? (
              <Link
                key={page?.title}
                to={page?.path}
                component={RouterLink}
                sx={{
                  color: "#10AC8E",
                  textDecorationColor: "#10AC8E",
                  textDecoration: "none",
                  // fontWeight: "medium",
                  // fontFamily: "Prompt",
                }}
              >
                {page?.title}
              </Link>
            ) : (
              <Typography key={page?.title}>{page?.title}</Typography>
            ),
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  )
}
BreadcrumbHerbarium.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
}
BreadcrumbHerbarium.defaultProps = {
  pages: [],
}
export default BreadcrumbHerbarium
