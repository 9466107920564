import React, { useState } from "react"
import "./contact-archive.css"
import Header from "../layouts/header-layout"
import { Helmet } from "react-helmet"
import Footer from "../layouts/footer-layout"
import { Box, Grid, Container, Button, TextField } from "@mui/material"
import { Email, LocationOn, Phone, Search } from "@mui/icons-material"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"
import ItemArchiveCardAnimal from "../components/item-archive-card-animal"
import ItemArchiveCardMicrobe from "../components/item-archive-card-microbe"
import ItemArchiveCardPlant from "../components/item-archive-card-plant"
import "./advanced-search-archive.css"

const AdvancedSearchArchive = () => {

  const requestPrivate = useAxiosPrivate()
  const [genericName, setGenericName] = useState("")
  const [specificEpithet, setSpecificEpithet] = useState("")
  const [vercularName, setVercularName] = useState("")
  const [searchDatas, setSearchDatas] = useState([])
  const [isHaveResults, setIsHaveResults] = useState(false)

  const microbeRegex = new RegExp("MICROBE", "i")
  const plantRegex = new RegExp("PLANT", "i")
  const animalRegex = new RegExp("ANIMAL", "i")

  const handleSearchItem = async (e) => {
    if (!genericName && !specificEpithet && !vercularName) return null
    try {
      const response = await requestPrivate.post(`/searchName`, {
        search: {
          genericName: genericName || "",
          specificEpithet: specificEpithet || "",
          vercularName: vercularName || "",
        },
      })

      if (response.status === 200) {
        setSearchDatas(response.data)
        setIsHaveResults(true)
      }
    } catch (error) {
      console.error("An error occurred:", error)
      alert("An error occurred while searching. Please try again later.")
    }
  }

  const handleReset = () => {
    setSearchDatas([])
    setGenericName("")
    setSpecificEpithet("")
    setVercularName("")
    setIsHaveResults(false)
  }


  return (
    <div className="advanced-search-container">
      <Helmet>
        <title>Advanced Search Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Contact Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <BreadcrumbLayout pages={[{ title: "Advanced Search" }]} />
      <Container
        sx={{
          maxWidth: "100%",
          margin: 5,
          marginBottom: "50px",
          marginRight: 0,
          marginLeft: 0,
          "@media(max-width: 767px)": {
            padding: 2,
          },
        }}
      >
        <Box
          sx={{
            bgcolor: "rgba(217, 217, 217, 0.03)",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            p: 4,
            mb: 4,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#144372",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: { xs: 0, sm: "20px", md: "20px" },
                    }}
                  >
                    <Search
                      sx={{
                        fontSize: "30px",
                        color: "#fff",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#144372",
                      width: { xs: "200px", sm: "260px", md: "260px" },
                      textAlign: { xs: "center", sm: "start", md: "start" },
                    }}
                  >
                    Advanced Search
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "3px",
                    backgroundColor: "#144372",
                    mt: 2,
                    display: { xs: "none", sm: "flex", md: "flex" },
                  }}
                ></Box>
              </Box>
            </Grid>
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="genericName"
                  label="Generic Name"
                  variant="outlined"
                  value={genericName}
                  onChange={(e) => setGenericName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="specificEpithet"
                  label="Specific Epithet"
                  variant="outlined"
                  value={specificEpithet}
                  onChange={(e) => setSpecificEpithet(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="vercularName"
                  label="ค้นหาชื่อภาษาไทย"
                  variant="outlined"
                  value={vercularName}
                  onChange={(e) => setVercularName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{ mt: 3, display: "flex", flexDirection: "row" }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "flex-start",
                    md: "flex-start",
                  },
                  mb: { xs: 1 },
                }}
              >
                <Button disabled>Total result : {searchDatas.length}</Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                // justifyContent={{}}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "flex-end",
                    md: "flex-end",
                  },
                }}
              >
                <Button variant="outlined" onClick={handleReset} sx={{ mr: 1 }}>
                  reset
                </Button>
                <Button variant="contained" onClick={handleSearchItem}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <div className="advanced-search-archive-card-container">
          {searchDatas?.length !== 0
            ? searchDatas?.map((row, index) => {
                const category = row?.category
                if (microbeRegex.test(category))
                  return (
                    <ItemArchiveCardMicrobe
                      key={index}
                      index={index}
                      id={row?._id}
                      photo={row?.photo}
                      genericName={row?.genericName}
                      specificEpithet={row?.specificEpithet}
                      sp={row?.sp}
                      subspecies={row?.subspecies}
                      typeOfIsolate={row?.typeOfIsolate}
                      strainDesignation={row?.strainDesignation}
                      rootClassName="item-archive-card-root-class-name"
                    />
                  )
                if (plantRegex.test(category))
                  return (
                    <ItemArchiveCardPlant
                      key={index}
                      index={index}
                      id={row?._id}
                      photo={row?.photo}
                      genericName={row?.genericName}
                      specificEpithet={row?.specificEpithet}
                      authorName1={row?.authorName1}
                      authorName2={row?.authorName2}
                      commonName={row?.commonName}
                      vercularName={row?.vercularName}
                      rootClassName="item-archive-card-root-class-name"
                    />
                  )
                if (animalRegex.test(category))
                  return (
                    <ItemArchiveCardAnimal
                      key={index}
                      index={index}
                      id={row?._id}
                      photo={row?.photo}
                      genericName={row?.genericName}
                      specificEpithet={row?.specificEpithet}
                      authorName1={row?.authorName1}
                      commonName={row?.commonName}
                      vercularName={row?.vercularName}
                      rootClassName="item-archive-card-root-class-name"
                    />
                  )
              })
            : isHaveResults && <Box>No results</Box>}
        </div>
      </Container>

      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default AdvancedSearchArchive
