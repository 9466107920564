import React, { useCallback, useState } from "react"
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LockIcon from '@mui/icons-material/Lock';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Photo from './photo/image 26.png'
import HerbariumAppBar from '../../layouts/header-herbarium';
import FooterHerbarium from '../../layouts/new-footerherbarium';

import styles from './contact.module.css'

import { request } from "../../axios-config";
import { useAuth } from "../../contexts/auth-context"
import { Link, useHistory } from "react-router-dom"

const defaultTheme = createTheme();

export default function HerbariumLogin() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const { setAuth } = useAuth()
    const history = useHistory()

    const handleSubmit = useCallback(async (event) => {
        try {
            event.preventDefault();
            const response = await request.post(
                "/login",
                {
                    username,
                    password,
                },
                {
                    withCredentials: true,
                },
            )
            if (response.status === 200) {
                setAuth((prev) => {
                    return {
                        ...prev,
                        roles: response.data.roles,
                        accessToken: response.data.accessToken,
                    }
                })
                localStorage.setItem("Me", {roles: response.data.roles,})
                history.push("/adminmenu")
                // window.open("/adminmenu", "_blank")
            }
        } catch (err) {
            alert("Username or password incorrect")
        }
    }, [username, password])

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <HerbariumAppBar />
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Photo} width="120" height="120" />

                    <Box noValidate sx={{ mt: 2, mb: 8, }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} >
                                <TextField
                                    fullWidth
                                    name="Username"
                                    id="username-Archive-login"
                                    label="username"
                                    color="success" focused

                                    autoComplete="none"
                                    value={username ? username : ""}
                                    onChange={(e) => {
                                        setUsername(e.target.value)
                                    }}

                                    InputProps={{
                                        autocomplete: "new-password",
                                        startAdornment: (
                                            <InputAdornment position="start"> <PermIdentityIcon /> </InputAdornment>
                                        ), style: { borderRadius: '20px', },
                                    }}

                                    sx={{ input: { color: '#138670' } }} />

                            </Grid>
                            
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="password-Archive-login"
                                    label="password"
                                    type={showPassword ? 'text' : 'password'}
                                    color="success" focused

                                    value={password ? password : ""}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}

                                    InputProps={{
                                        autocomplete: "new-password",
                                        startAdornment: (
                                            <InputAdornment position="start"> <LockIcon /> </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                // onMouseDown={handleMouseDownPassword}
                                               
                                            >
                                                <IconButton  
                                                    onClick={handleClickShowPassword}  
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: { borderRadius: '20px', },
                                    }}
                                    sx={{ input: { color: '#138670' } }} />
                            </Grid>
                        </Grid>
                     
                        <div>
                            <Button
                                
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    bgcolor: '#11AC8E',
                                    fontSize: '20px',
                                    height: '50px',
                                    borderRadius: '20px',
                                    '&:hover': {
                                        bgcolor: '#138670',
                                    },
                                }}
                                // onClick={handleSubmit}

                            >
                                LOGIN
                            </Button>
                        </div>
                    </form>
                    </Box>
                </Box>
            </Container>
            <FooterHerbarium />
        </ThemeProvider >
    );
}