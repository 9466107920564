import React, { useState, ChangeEvent, Fragment, useEffect } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-archive-items.css"
import { request } from "../axios-config"
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    InputAdornment,
    Typography, FormControl, Input, Stack
} from "@mui/material"

import {
    Create as CreateIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    Delete,
} from "@mui/icons-material"

import BreadcrumbLayout from "../components/breadcrumb-layout"
import { useHistory } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"

const AddAnimal = (props) => {
    const [me, setMe] = useState()
    const requestPrivate = useAxiosPrivate()
    const history = useHistory()

    const [item, setItem] = useState({})
    const [filePhotos, setFilePhotos] = useState([])
    const [fileTxt, setFileTxt] = useState(null)
    const [fileName, setFileName] = useState('')
    const [references, setReferences] = useState([
        { link: "", year: "", type: "", title: "" },
    ])
    const [lecturerForAdd, setlecturerForAdd] = useState([""])
    const [lecturers, setLecturers] = useState([])
    const [isFileSelected, setIsFileSelected] = useState(false);

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        const getLecturers = async () => {
            try {
                const response = await request.get("lecturer", {
                    signal: controller.signal,
                })
                isMounted && setLecturers(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getLecturers()
        return () => {
            isMounted = false
            controller.abort()
        }
    }, [])

    const AddItem = async () => {
        try {
            if (!references[0]?.title) {
                alert("References is required");
            } else if(!lecturerForAdd[0]){
                alert("Researcher is required");
            } else {
                const responseUrlPhoto = await request.post("/upload", {
                    photo: filePhotos
                });

                if(fileTxt){
                    const responseFileTxt = await request.post("/uploadfiles", {
                        text: fileTxt,
                    });    
                
                
                    const response = await requestPrivate.post("/item", {
                        ...item,
                        photo: responseUrlPhoto?.data?.urls,
                        references: references,
                        lecturer: lecturerForAdd,
                        molecularData: responseFileTxt?.data?.url || null,
                    });

                    if (response.status === 200) {
                        alert("Success");
                        history.push("/animalview");
                    }
                }else{
                    const response = await requestPrivate.post("/item", {
                        ...item,
                        photo: responseUrlPhoto?.data?.urls,
                        references: references,
                        lecturer: lecturerForAdd,
                        molecularData: null,
                    });

                    if (response.status === 200) {
                        alert("Success");
                        history.push("/animalview");
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            setIsFileSelected(true);
            setFileTxt(selectedFile);
            setFileName(selectedFile.name)
            
            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target.result;
                setFileTxt(contents);
            };
            reader.readAsText(selectedFile);
        } else {
            setIsFileSelected(false);
            setFileTxt('');
        }
    };

    // const selectPhotoFile = (e) => {
    //     new Promise((resolve, reject) => {
    //         e.preventDefault()
    //         const file = e.currentTarget.files[0]
    //         if (file.size > 2000000) {
    //             alert("กรุณาใส่รูปที่มีขนาดไม่เกิน 2 Mb");
    //             e.target.value = null; // clear the input field
    //             return;
    //         } else {
    //             setFilePhoto({ data: file })
    //             const reader = new FileReader()
    //             reader.readAsDataURL(file)
    //             reader.onload = () => {
    //                 resolve(reader.result)
    //                 setFilePhoto({ base64: reader.result })
    //             }
    //             reader.onerror = (error) => reject(error)
    //         }

    //     })
    // }

    const selectPhotoFiles = (e) => {
        if (filePhotos.length >= 4) {
            alert(`Up to 4 photos can be uploaded.`)
            e.target.value = null
            return
        }

        new Promise((resolve, reject) => {
            e.preventDefault()
            const files = Array.from(e.currentTarget.files)
            if (files?.length + filePhotos?.length > 4) {
                alert(`Up to 4 photos can be uploaded.`)
                e.target.value = null
                return
            }

            const invalidFiles = files.filter((file) => file.size > 2000000)
            if (invalidFiles.length > 0) {
                const fileNames = invalidFiles.map((file) => file.name).join(", ")
                alert(
                    `Please select images with a size not exceeding 2 MB: ${fileNames}`,
                )
                e.target.value = null // clear the input field
                return
            }

            const filePromises = files.map(
                (file) =>
                    new Promise((resolve) => {
                        const reader = new FileReader()
                        reader.readAsDataURL(file)
                        reader.onload = () => {
                            resolve(reader.result)
                        }
                    }),
            )

            Promise.all(filePromises)
                .then((results) => {
                    const base64Files = files.map((file, index) => ({
                        data: file,
                        base64: results[index],
                    }))
                    setFilePhotos([...base64Files, ...filePhotos])
                    resolve(results)
                })
                .catch((error) => reject(error))
        })
    }

    const handleDeletePhoto = (index) => {
        const newPhotos = [...filePhotos]
        newPhotos.splice(index, 1)
        setFilePhotos(newPhotos)
    }

    const ClearData = () => {
        setItem({
            ...item,
            kingdom: "",
            category: "",
            phylum: "",
            aclass: "",
            order: "",
            family: "",
            genericName: "",
            specificEpithet: "",
            authorName1: "",
            sp: "",
            subspecies: "",
            commonName: "",
            vercularName: "",
            locality: "",
            ecologyAndHabitat: "",
            specimenRepository: "",
            molecularData: "",
            utilization: "",
        })
        setReferences([{ link: "", year: "", type: "", title: "" }])
    }

    const handleDeleteLecturer = (index) => {
        const newSelectedLecturers = [...lecturerForAdd]
        newSelectedLecturers.splice(index, 1)
        setlecturerForAdd(newSelectedLecturers)
    }

    const handleDeleteReference = (index) => {
        const newReferences = [...references]
        newReferences.splice(index, 1)
        setReferences(newReferences)
    }

    const handleLecturerChange = (e, index) => {
        const newValue = e.target.value
        setlecturerForAdd((prevSelectedLecturers) => {
            const newSelectedLecturers = [...prevSelectedLecturers]
            newSelectedLecturers[index] = newValue
            return newSelectedLecturers
        })
    }

    const isLecturerSelected = (lecturerValue, lecturerId) => {
        if (lecturerValue === lecturerId) return false
        return lecturerForAdd.some((selectedId) => selectedId === lecturerId)
    }


    return (
        <>
            <Helmet>
                <title>AddArchiveItems - Sci PSU Archive</title>
                <meta
                    name="description"
                    content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                />
                <meta property="og:title" content="AddArchiveItems - Sci PSU Archive" />
                <meta
                    property="og:description"
                    content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                />
            </Helmet>
            <Header rootClassName="header-root-class-name1" auth={true} ></Header>
            <BreadcrumbLayout
                pages={[
                    { title: "Admin Menu", path: `/adminmenu` },
                    { title: "Manage Archive", path: `/archiveview` },
                    { title: "Animal Lists", path: `/animalview` },
                    { title: "Add Animal Item" },
                ]}
            />
            <Container sx={{ mt: 5 }} maxWidth="xl">

                <Grid>
                    <Typography variant="h3">Add Animal Item</Typography>
                </Grid>

                <Grid container spacing={2}>
                    <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        alignItems={"center"}
                        direction={"column"}
                        spacing={1}
                        sx={{ mb: "40px", mt: "5px" }}
                    >
                        <PhotoAction
                            filePhotos={filePhotos}
                            handleDeletePhoto={handleDeletePhoto}
                            selectPhotoFiles={selectPhotoFiles}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Kingdom <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="kingdom"
                                size="small"
                                fullWidth
                                value={item ? item?.kingdom : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        kingdom: e.target.value,
                                    })
                                }}
                                error={ item && item?.kingdom === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.kingdom === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Category <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="category"
                                required
                                size="small"
                                fullWidth
                                select
                                SelectProps={{
                                    MenuProps: {
                                        sx: {
                                            ".MuiList-root": {
                                                overflowY: "auto",
                                                bgColor: "green",
                                            },
                                        },
                                    },
                                }}
                
                                value={ item?.category ? item.category : null}
                                error={ item?.category === undefined || item?.category ? false : true}
                                InputProps={{
                                    endAdornment: (
                                        item && item.category === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px", marginRight: "14px", zIndex: -4}}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        category: e.target.value,
                                    })
                                }}
                            >
                                <MenuItem value={"ANIMAL_VERTEBRATE"}>Vertebrate</MenuItem>
                                <MenuItem value={"ANIMAL_INVERTEBRATE"}>Invertebrate</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Phylum</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="phylum"
                                size="small"
                                fullWidth
                                value={item ? item?.phylum : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        phylum: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Class</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="aclass"
                                size="small"
                                fullWidth
                                value={item ? item?.aclass : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        aclass: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Order</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="order"
                                size="small"
                                fullWidth
                                value={item ? item?.order : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        order: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Family <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="family"
                                size="small"
                                fullWidth
                                value={item ? item?.family : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        family: e.target.value,
                                    })
                                }}
                                error={ item && item?.family === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.family === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Genus <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="genericName"
                                size="small"
                                fullWidth
                                value={item ? item?.genericName : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        genericName: e.target.value,
                                    })
                                }}
                                error={ item && item?.genericName === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.genericName === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Specific Epithet</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="specificEpithet"
                                size="small"
                                fullWidth
                                value={item ? item?.specificEpithet : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        specificEpithet: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Author Name(s)</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="authorName1"
                                size="small"
                                fullWidth
                                value={item ? item?.authorName1 : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        authorName1: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">SP. (กรณีไม่ทราบสปีชีส์)</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="sp"
                                size="small"
                                fullWidth
                                value={item ? item?.sp : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        sp: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Subspecies</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="subspecies"
                                size="small"
                                fullWidth
                                value={item ? item?.subspecies : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        subspecies: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Common Name</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="commonName"
                                size="small"
                                fullWidth
                                value={item ? item?.commonName : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        commonName: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Local Name</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="vercularName"
                                size="small"
                                fullWidth
                                value={item ? item?.vercularName : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        vercularName: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Locality</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="locality"
                                size="small"
                                fullWidth
                                value={item ? item?.locality : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        locality: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Ecology & Habitat</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="ecologyAndHabitat"
                                size="small"
                                fullWidth
                                value={item ? item?.ecologyAndHabitat : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        ecologyAndHabitat: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Specimen Repository</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="specimenRepository"
                                size="small"
                                fullWidth
                                value={item ? item?.specimenRepository : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        specimenRepository: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Utilization</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="utilization"
                                size="small"
                                fullWidth
                                value={item ? item?.utilization : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        utilization: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Molecular Data</Typography>
                        </Grid>
                        {/* <Grid item xs={6} md={8}>
                            <input
                                type="file"
                                id="fileInput"
                                accept=".txt"
                                onChange={handleFileChange}
                                onInput={(e) => {
                                    const input = e.target;
                                    const file = input.files[0];
                                    if (file && file.type !== 'text/plain') {
                                        input.value = '';
                                        alert("กรุณาใส่ไฟล์ txt")
                                    }
                                }}
                            />
                        </Grid> */}
                        <Grid item xs={6} md={8}>
                            <FormControl>
                                <Input
                                    id="upload-file"
                                    type="file"
                                    inputProps={{
                                        accept: ".txt"
                                    }}
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                    onInput={(e) => {
                                        const input = e.target;
                                        const file = input.files[0];
                                        if (file && file.type !== 'text/plain') {
                                            input.value = '';
                                            alert("กรุณาใส่ไฟล์ txt")
                                        }
                                    }}
                                />
                                <label htmlFor="upload-file">
                                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                                        <Button variant="outlined" component="span">
                                            {fileName? fileName : 'เลือกไฟล์'}
                                        </Button>
                                    </Stack>
                                </label>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                ></Grid>

                <Grid mt={5}>
                    <Typography variant="h5">Researcher</Typography>
                    {lecturerForAdd.map((row, index) => {
                        const lecturerValue =
                            lecturerForAdd[index] !== undefined &&
                                lecturerForAdd[index] !== ""
                                ? lecturerForAdd[index]
                                : row
                        const isLastIndex = index === lecturerForAdd.length - 1
                        const isLastItem = lecturerForAdd.length === 1
                        return (
                            <Fragment key={index}>
                                <Grid>
                                    <Typography variant="subtitle1">{`Researcher ${index + 1
                                        }`}</Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={12}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={2}
                                    >
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2">Researcher</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <TextField
                                                id="lecturer"
                                                required
                                                size="small"
                                                fullWidth
                                                select
                                                value={lecturerValue}
                                                onChange={(e) => handleLecturerChange(e, index)}
                                            >
                                                {lecturers?.length !== 0 ? (
                                                    lecturers?.map((row, index) => (
                                                        <MenuItem
                                                            disabled={isLecturerSelected(
                                                                lecturerValue,
                                                                row?._id,
                                                            )}
                                                            key={index}
                                                            value={`${row?._id}`}
                                                        >{`${row?.title} ${row?.name} ${row?.surname} `}</MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem />
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={2}
                                            alignItems={"center"}
                                            direction={"row"}
                                            spacing={1}
                                        >
                                            {!isLastItem && (
                                                <IconButton onClick={() => handleDeleteLecturer(index)}>
                                                    <Delete />
                                                </IconButton>
                                            )}

                                            {isLastIndex && (
                                                <IconButton
                                                    color={"primary"}
                                                    onClick={() => {
                                                        let lecfoad = lecturerForAdd.slice()
                                                        lecfoad[index + 1] = ""
                                                        setlecturerForAdd(lecfoad)
                                                    }}
                                                >
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    })}
                </Grid>
                <Grid mt={5}>
                    <Typography variant="h5">References</Typography>
                    {references.map((row, index) => {
                        const isLastIndex = index === references.length - 1
                        const isLastItem = references.length === 1
                        return (
                            <Fragment key={index}>
                                <Grid>
                                    <Typography variant="subtitle1">{`Reference ${index + 1
                                        }`}</Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={2}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={1}
                                    >
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2">title</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <TextField
                                                id="title"
                                                size="small"
                                                fullWidth
                                                required
                                                value={
                                                    references[index]
                                                        ? references[index]?.title
                                                        : references[index]?.title
                                                }
                                                onChange={(e) => {
                                                    let res = references.slice()
                                                    let re = {
                                                        ...references[index],
                                                        title: e.target.value,
                                                    }
                                                    res[index] = re
                                                    setReferences(res)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={3}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={1}
                                    >
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2">link</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <TextField
                                                id="link"
                                                size="small"
                                                fullWidth
                                                required
                                                value={
                                                    references[index]
                                                        ? references[index]?.link
                                                        : references[index]?.link
                                                }
                                                onChange={(e) => {
                                                    let res = references.slice()
                                                    let re = {
                                                        ...references[index],
                                                        link: e.target.value,
                                                    }
                                                    res[index] = re
                                                    setReferences(res)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={2}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={1}
                                    >
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2">year</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <TextField
                                                id="year"
                                                size="small"
                                                fullWidth
                                                required
                                                value={
                                                    references[index]
                                                        ? references[index]?.year
                                                        : references[index]?.year
                                                }
                                                onChange={(e) => {
                                                    let res = references.slice()
                                                    let re = {
                                                        ...references[index],
                                                        year: e.target.value,
                                                    }
                                                    res[index] = re
                                                    setReferences(res)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={2}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={1}
                                    >
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2">type</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <TextField
                                                id="type"
                                                size="small"
                                                fullWidth
                                                required
                                                value={
                                                    references[index]
                                                        ? references[index]?.type
                                                        : references[index]?.type
                                                }
                                                onChange={(e) => {
                                                    let res = references.slice()
                                                    let re = {
                                                        ...references[index],
                                                        type: e.target.value,
                                                    }
                                                    res[index] = re
                                                    setReferences(res)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={2}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={1}
                                    >
                                        {!isLastItem && (
                                            <IconButton
                                                // color={"primary"}
                                                onClick={() => handleDeleteReference(index)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        )}

                                        {isLastIndex && (
                                            <IconButton
                                                color={"primary"}
                                                onClick={() => {
                                                    let res = references.slice()
                                                    res[index + 1] = {
                                                        title: "",
                                                        link: "",
                                                        year: "",
                                                        type: "",
                                                    }
                                                    setReferences(res)
                                                }}
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    })}
                </Grid>

                <Grid
                    mt={10}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                >
                    {/* {console.log()} */}
                    <Button
                        disabled={
                            item?.category === undefined || item?.category === "" ||
                            item?.family === undefined ||  item?.family === "" ||
                            item?.kingdom === undefined || item?.kingdom === "" ||
                            item?.genericName === undefined ||  item?.genericName === "" 
                        }
                        variant="contained"
                        onClick={AddItem}
                    >
                        submit
                    </Button>
                    <Button variant="text" onClick={ClearData}>
                        Clear
                    </Button>
                </Grid>
            </Container>
            <Footer rootClassName="footer-root-class-name"></Footer>
        </>
    )
}

export default AddAnimal
