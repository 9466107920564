import React, { useState } from "react"
import { Link } from "react-router-dom"

import PropTypes from 'prop-types'

import './item-archive-card.css'

const ItemGeoArchiveCard = (props) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const containerStyle = {
    backgroundImage:
      props.photo.length > 0
        ? `url("${props.photo[0]}")`
        : "url(/playground_assets/rectangle493235-fxf-400h.png)",
    backgroundSize: isHovered ? "170%" : "cover",
    backgroundPosition: isHovered ? "center left" : "center",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    transition:
      "transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, background-size 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, background-position 0.5s",
  }
  return (
    <div
      key={props?.index}
      className={`item-archive-card-testimonial-card ${props.rootClassName} `}
    >
      <Link
        to={`/detailgeo/${props?.id}`}
        className="item-archive-card-navlink"
      >
        <div
          className="item-archive-card-container"
          style={containerStyle}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <div className="item-archive-card-container1">
            <span className="item-archive-card-text" style={{textAlign: 'center'}}>{props.ItemName}</span>
            <span className="item-archive-card-text1">{props.commonName}</span>
            <span className="item-archive-card-text2">{props.thaicommonName}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

ItemGeoArchiveCard.defaultProps = {
  ItemName: '-',
  rootClassName: '',
  commonName: '-',
  thaicommonName: '-',
  index: 0,
  photo: ["/playground_assets/rectangle493235-fxf-400h.png"],
}

ItemGeoArchiveCard.propTypes = {
  ItemName: PropTypes.string,
  rootClassName: PropTypes.string,
  commonName: PropTypes.string,
  thaicommonName: PropTypes.string,
  index: PropTypes.number,
  photo: PropTypes.array,
}

export default ItemGeoArchiveCard
