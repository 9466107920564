import React from 'react'

import PropTypes from 'prop-types'


import './footer.css'
import FooterContent from '../components/footer-content'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <FooterContent
        rootClassName="footer-content-root-class-name"
        className=""
      ></FooterContent>
      <div className="footer-separator"></div>
      <span className="footer-text">{props.text}</span>
    </footer>
  )
}

Footer.defaultProps = {
  text: '© 2023 Faculty of Science, All Rights Reserved.',
  rootClassName: '',
}

Footer.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Footer
