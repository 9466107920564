import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card, CardContent, Typography, CardActionArea, CardMedia } from '@mui/material'

import PropTypes from 'prop-types'

import './researcher-card.css'

const AdvancesearchCard = (props) => {
    return (
        <Link to={`/herbarium-detail/${props?.id}`} className="">
            {/* <div
                className={`researcher-card-testimonial-card ${props?.rootClassName} `}
            >
                <div className="researcher-card-container" style={{ backgroundImage: `url("${props.photograph}")` }}>
                    <div className="researcher-card-container1">
                        <span className="researcher-card-text">{props?.family}</span>
                        <span className="researcher-card-text1">{props?.genus}</span>
                        <span className="researcher-card-text1">{props?.species}</span>
                        <span className="researcher-card-text1">{props?.author1}</span>
                    </div>
                </div>
            </div> */}
            <Card sx={{ maxWidth: 345, color: "blue" }}  >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="300"
                        image={props.photograph}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h4" color="blue" component="div" >
                            {props?.family}
                        </Typography>
                        <Typography variant="h6" color="red">
                            {props?.genus}
                        </Typography>
                        <Typography variant="subtitle1" color="black" component="div">
                            {props?.species}
                        </Typography>
                        <Typography variant="caption" color="black">
                            {props?.author1}
                        </Typography>
                        <Typography variant="caption" color="black">
                            {props?.intraspecificStatus}
                        </Typography>
                        <Typography variant="caption" color="black">
                            {props?.intraspecificName}
                        </Typography>
                        <Typography variant="caption" color="black">
                            {props?.author2}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    )
}

AdvancesearchCard.defaultProps = {
    family: '-',
    rootClassName: '',
    genus: '-',
    author1: '-',
    species: '_',
    photograph: '/playground_assets/rectangle493235-fxf-400h.png',
}

AdvancesearchCard.propTypes = {
    family: PropTypes.string,
    rootClassName: PropTypes.string,
    genus: PropTypes.string,
    author1: PropTypes.string,
    species: PropTypes.string,
    photograph: PropTypes.string,
}

export default AdvancesearchCard