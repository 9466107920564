import React, { useState, Fragment, useEffect } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-archive-items.css"
import { request } from "../axios-config"
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  InputAdornment
} from "@mui/material"
import {
  Create as CreateIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Delete,
} from "@mui/icons-material"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { useHistory } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"

const AddMicrobeItems = (props) => {
  const [me, setMe] = useState()
  const requestPrivate = useAxiosPrivate()
  const history = useHistory()

  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const [references, setReferences] = useState([
    { link: "", year: "", type: "", title: "" },
  ])
  const [lecturerForAdd, setlecturerForAdd] = useState([""])
  const [lecturers, setLecturers] = useState([])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getLecturers = async () => {
      try {
        const response = await request.get("lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturers(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getLecturers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const AddItem = async () => {
    try {
      if (!references[0]?.title) {
        alert("References is required")
      }else if(!lecturerForAdd[0]){
        alert("Researcher is required");
      }else {
        const responseUrlPhoto = await request.post("/upload", {
          photo: filePhotos,
        })
        const response = await requestPrivate.post("/item", {
          ...item,
          photo: responseUrlPhoto?.data?.urls,
          references: references,
          lecturer: lecturerForAdd,
        })
        if (response) {
          alert("success")
          history.push("/microbeview")
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const selectPhotoFiles = (e) => {
    if (filePhotos.length >= 4) {
      alert(`Up to 4 photos can be uploaded.`)
      e.target.value = null
      return
    }

    new Promise((resolve, reject) => {
      e.preventDefault()
      const files = Array.from(e.currentTarget.files)
      if (files?.length + filePhotos?.length > 4) {
        alert(`Up to 4 photos can be uploaded.`)
        e.target.value = null
        return
      }
   
      const invalidFiles = files.filter((file) => file.size > 2000000)
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((file) => file.name).join(", ")
        alert(
          `Please select images with a size not exceeding 2 MB: ${fileNames}`,
        )
        e.target.value = null // clear the input field
        return
      }

      const filePromises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          }),
      )

      Promise.all(filePromises)
        .then((results) => {
          const base64Files = files.map((file, index) => ({
            data: file,
            base64: results[index],
          }))
          setFilePhotos([...base64Files, ...filePhotos])
          resolve(results)
        })
        .catch((error) => reject(error))
    })
  }

  const handleDeletePhoto = (index) => {
    const newPhotos = [...filePhotos]
    newPhotos.splice(index, 1)
    setFilePhotos(newPhotos)
  }

  const ClearData = () => {
    setItem({
      ...item,
      family: "",
      category: "",
      genericName: "",
      specificEpithet: "",
      sp: "",
      subspecies: "",
      serovar: "",
      serotype: "",
      biotype: "",
      strainDesignation: "",
      specificApplications: "",
      typeOfIsolate: "",
      isolationSource: "",
      geographicalIsolation: "",
      optimumTemperature: "",
      bsl: "",
    })
    setReferences([{ link: "", year: "", type: "", title: "" }])
  }

  const handleDeleteLecturer = (index) => {
    const newSelectedLecturers = [...lecturerForAdd]
    newSelectedLecturers.splice(index, 1)
    setlecturerForAdd(newSelectedLecturers)
  }

  const handleDeleteReference = (index) => {
    const newReferences = [...references]
    newReferences.splice(index, 1)
    setReferences(newReferences)
  }

  const handleLecturerChange = (e, index) => {
    const newValue = e.target.value
    setlecturerForAdd((prevSelectedLecturers) => {
      const newSelectedLecturers = [...prevSelectedLecturers]
      newSelectedLecturers[index] = newValue
      return newSelectedLecturers
    })
  }

  const isLecturerSelected = (lecturerValue, lecturerId) => {
    if (lecturerValue === lecturerId) return false
    return lecturerForAdd.some((selectedId) => selectedId === lecturerId)
  }

  return (
    <>
      <Helmet>
        <title>AddMicrobeItems - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddMicrobeItems - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Archive", path: `/archiveview` },
          { title: "Microbe Lists", path: `/microbeview` },
          { title: "Add Microbe Item" },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid>
          <Typography variant="h3">Microbe Item</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"column"}
            spacing={1}
            sx={{ mb: "10px", mt: "5px" }}
          >
            <PhotoAction
              filePhotos={filePhotos}
              handleDeletePhoto={handleDeletePhoto}
              selectPhotoFiles={selectPhotoFiles}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Category <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="category"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        // height: "180px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                
                // value={item?.category ? item?.category : ""}
                value={ item?.category ? item.category : null}
                error={ item?.category === undefined || item?.category ? false : true}
                InputProps={{
                    endAdornment: (
                        item && item.category === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px", marginRight: "14px", zIndex: -4}}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    category: e.target.value,
                  })
                }}
              >
                <MenuItem value={"MICROBE_BACTERIA"}>Bacteria</MenuItem>
                <MenuItem value={"MICROBE_MOLD"}>Mold</MenuItem>
                <MenuItem value={"MICROBE_PROTOZOA"}>Protozoa</MenuItem>
                <MenuItem value={"MICROBE_VIRUS"}>Virus</MenuItem>
                <MenuItem value={""} disabled={true}></MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Family <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="family"
                size="small"
                fullWidth
                value={item ? item?.family : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    family: e.target.value,
                  })
                }}
                error={ item && item?.family === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.family === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Generic Name / Genus <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="genericName"
                size="small"
                fullWidth
                value={item ? item?.genericName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    genericName: e.target.value,
                  })
                }}
                error={ item && item?.genericName === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.genericName === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">
                Specific Epithet / Species <span style={{color: 'red'}}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="specificEpithet"
                size="small"
                fullWidth
                value={item ? item?.specificEpithet : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specificEpithet: e.target.value,
                  })
                }}
                error={ item && item?.specificEpithet === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.specificEpithet === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">sp./spp.</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="sp"
                size="small"
                fullWidth
                value={item ? item?.sp : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    sp: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Subspecies</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="subspecies"
                size="small"
                fullWidth
                value={item ? item?.subspecies : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    subspecies: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Serovar</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="serovar"
                size="small"
                fullWidth
                value={item ? item?.serovar : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    serovar: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Serotype</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="serotype"
                size="small"
                fullWidth
                value={item ? item?.serotype : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    serotype: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Biotype</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="biotype"
                size="small"
                fullWidth
                value={item ? item?.biotype : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    biotype: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Strain designation</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="vercularName"
                size="small"
                fullWidth
                value={item ? item?.strainDesignation : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    strainDesignation: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Specific applications</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="specificApplications"
                size="small"
                fullWidth
                value={item ? item?.specificApplications : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specificApplications: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Type of isolate <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="typeOfIsolate"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        // height: "100px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                
                // value={item?.typeOfIsolate ? item?.typeOfIsolate : ""}
                value={ item?.typeOfIsolate ? item.typeOfIsolate : null}
                error={ item?.typeOfIsolate === undefined || item?.typeOfIsolate ? false : true}
                InputProps={{
                    endAdornment: (
                        item && item.typeOfIsolate === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px", marginRight: "14px", zIndex: -4}}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    typeOfIsolate: e.target.value,
                  })
                }}
              >
                <MenuItem value={"Human"}>Human</MenuItem>
                <MenuItem value={"Environmental"}>Environmental</MenuItem>
                <MenuItem value={""} disabled={true}></MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Isolation source</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="isolationSource"
                size="small"
                fullWidth
                value={item ? item?.isolationSource : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    isolationSource: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">
                Geographical isolation
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="geographicalIsolation"
                size="small"
                fullWidth
                value={item ? item?.geographicalIsolation : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    geographicalIsolation: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Medium/Temperature</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="optimumTemperature"
                size="small"
                fullWidth
                value={item ? item?.optimumTemperature : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    optimumTemperature: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">BSL</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="bsl"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        // height: "100px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item?.bsl ? item?.bsl : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    bsl: e.target.value,
                  })
                }}
              >
                {/* <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Microbe
                </ListSubheader> */}
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
                <MenuItem value={""} disabled={true}></MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={4}
          alignItems={"center"}
          direction={"row"}
          spacing={1}
        ></Grid>

        <Grid mt={5}>
          <Typography variant="h5">Researcher</Typography>
          {lecturerForAdd.map((row, index) => {
            const lecturerValue =
              lecturerForAdd[index] !== undefined &&
                lecturerForAdd[index] !== ""
                ? lecturerForAdd[index]
                : row
            const isLastIndex = index === lecturerForAdd.length - 1
            const isLastItem = lecturerForAdd.length === 1
            return (
              <Fragment key={index}>
                <Grid>
                  <Typography variant="subtitle1">{`Researcher ${index + 1
                    }`}</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={2}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">Researcher</Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        id="lecturer"
                        required
                        size="small"
                        fullWidth
                        select
                        value={lecturerValue}
                        onChange={(e) => handleLecturerChange(e, index)}
                      >
                        {lecturers?.length !== 0 ? (
                          lecturers?.map((row, index) => (
                            <MenuItem
                              disabled={isLecturerSelected(
                                lecturerValue,
                                row?._id,
                              )}
                              key={index}
                              value={`${row?._id}`}
                            >{`${row?.title} ${row?.name} ${row?.surname} `}</MenuItem>
                          ))
                        ) : (
                          <MenuItem />
                        )}
                      </TextField>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      {!isLastItem && (
                        <IconButton onClick={() => handleDeleteLecturer(index)}>
                          <Delete />
                        </IconButton>
                      )}

                      {isLastIndex && (
                        <IconButton
                          color={"primary"}
                          onClick={() => {
                            let lecfoad = lecturerForAdd.slice()
                            lecfoad[index + 1] = ""
                            setlecturerForAdd(lecfoad)
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
        <Grid mt={5}>
          <Typography variant="h5">References</Typography>
          {references.map((row, index) => {
            const isLastIndex = index === references.length - 1
            const isLastItem = references.length === 1
            return (
              <Fragment key={index}>
                <Grid>
                  <Typography variant="subtitle1">{`Reference ${index + 1
                    }`}</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">title</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        multiline
                        id="title"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.title
                            : references[index]?.title
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            title: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">link</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        multiline
                        id="link"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.link
                            : references[index]?.link
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            link: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">year</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        id="year"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.year
                            : references[index]?.year
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            year: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">type</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        id="type"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.type
                            : references[index]?.type
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            type: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    {!isLastItem && (
                      <IconButton
                        // color={"primary"}
                        onClick={() => handleDeleteReference(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}

                    {isLastIndex && (
                      <IconButton
                        color={"primary"}
                        onClick={() => {
                          let res = references.slice()
                          res[index + 1] = {
                            title: "",
                            link: "",
                            year: "",
                            type: "",
                          }
                          setReferences(res)
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
        <Grid
          mt={10}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          {/* {console.log()} */}
          <Button
            disabled={
              item?.category === undefined || item?.category === "" ||
              item?.family === undefined || item?.family === "" ||
              item?.genericName === undefined || item?.genericName === "" ||
              item?.specificEpithet === undefined || item?.specificEpithet === "" ||
              item?.typeOfIsolate === undefined || item?.typeOfIsolate === "" 


            }
            variant="contained"
            onClick={AddItem}
          >
            submit
          </Button>
          <Button variant="text" onClick={ClearData}>
            Clear
          </Button>
        </Grid>
      </Container>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </>
  )
}

export default AddMicrobeItems
