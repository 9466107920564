import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import PropTypes from "prop-types"

import DesktopNav from "../components/desktop-nav"
import IconSocial from "../components/icon-social"
import MobileNav from "../components/mobile-nav"
import "./header.css"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  Container,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import useAxiosPrivate from "../hook/use-axios-private"
import Person from "@mui/icons-material/Person"
import Settings from "@mui/icons-material/Settings"
import Logout from "@mui/icons-material/Logout"
import ExpandLess from "@mui/icons-material/ExpandLess"
import PublicIcon from "@mui/icons-material/Public"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

const pages = [
  "Home",
  "Plant",
  "Animal",
  "Microbe",
  "Geoscience",
  "Herbarium",
  "Contact",
]

const styles = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
}

const Header = (props) => {
  const [meLogin, setMeLogin] = useState(localStorage.getItem("Me"))
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElSocial, setAnchorElSocial] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleOpenSocialMenu = (event) => {
    setAnchorElSocial(event.currentTarget)
  }

  const handleCloseSocialMenu = () => {
    setAnchorElSocial(null)
  }

  const requestPrivate = useAxiosPrivate()
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getMe = async () => {
      try {
        const response = await requestPrivate.get("/me", {
          signal: controller.signal,
        })
        isMounted && setMeLogin(response?.data)
      } catch (error) {
        if (props.auth === true) {
          localStorage.removeItem("Me")
          history.push("/login")
        }
      }
    }
    getMe()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const handleLogout = async () => {
    try {
      await requestPrivate.get("/logout")
      localStorage.removeItem("Me")
      history.push("/")
    } catch (e) {
      console.error(e)
    }
  }
  // console.log("meLogin", meLogin)

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xxl">
          <Toolbar sx={{ justifyContent: "center", flex: 1 }}>
            <Stack
              sx={{
                flexGrow: 1,
                objectFit: "cover",
                textDecoration: "none",
              }}
            >
              <Box component={Link} to={"/"}>
                <Box
                  component={"img"}
                  alt={props.image_alt}
                  src={props.image_src}
                  sx={{
                    padding: 1,
                    width: { xs: 150, md: 150 },
                    height: "auto",
                    objectFit: "cover",
                    textDecoration: "none",
                    display: { xs: "flex", md: "flex" },
                    ":hover": {
                      opacity: 0.6,
                      cursor: "pointer",
                    },
                  }}
                  className="header-image"
                />
              </Box>
            </Stack>
            <Box
              sx={{
                flexGrow: 10,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <div className="header-nav">
                <DesktopNav
                  text="Home"
                  text1="Plant"
                  text2="Animal"
                  text3="Microbe"
                  text4="Contact"
                  text5="Herbarium"
                  rootClassName="rootClassName8"
                  className=""
                ></DesktopNav>
              </div>
            </Box>

            <Box
              // container="true"
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: 2,
              }}
            >
              <IconSocial
                rootClassName="icon-social-root-class-name1"
                className=""
              ></IconSocial>

              <div className="relative inline-block text-left">
                {!meLogin ? (
                  <Button
                    component={Link}
                    to={"/login"}
                    variant="contained"
                    sx={{ ml: 2 }}
                  >
                    login
                  </Button>
                ) : (
                  <>
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        color="inherit"
                      >
                        {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                        <AccountCircleIcon sx={{ width: 32, height: 32 }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={styles}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                      keepMounted
                    >
                      <MenuItem component={Link} to={"/adminmenu"}>
                        <ListItemIcon>
                          <Person fontSize="small" />
                        </ListItemIcon>
                        Admin Menu
                      </MenuItem>
                      <Divider />

                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
              <Box
                sx={{
                  display: "none",
                  "@media (max-width: 479px)": {
                    // styles to apply for screen width <= 478px
                    display: "flex",
                    mr: 1,
                  },
                }}
              >
                <Tooltip title="Social">
                  <IconButton
                    onClick={handleOpenSocialMenu}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={anchorElSocial ? "social-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorElSocial ? "true" : undefined}
                    color="inherit"
                  >
                    <PublicIcon sx={{ width: 32, height: 32 }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorElSocial}
                  id="social-menu"
                  open={Boolean(anchorElSocial)}
                  onClose={handleCloseSocialMenu}
                  onClick={handleCloseSocialMenu}
                  PaperProps={styles}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                  keepMounted
                >
                  <MenuItem
                    component="a"
                    href="https://www.facebook.com/PsuSci"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Facebook
                  </MenuItem>
                  <Divider />

                  <MenuItem
                    component="a"
                    href="https://www.instagram.com/PsuSci/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Instagram
                  </MenuItem>
                  <Divider />

                  <MenuItem
                    component="a"
                    href="https://twitter.com/psu_sci"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Twitter
                  </MenuItem>
                  <Divider />

                  <MenuItem
                    component="a"
                    href="https://www.youtube.com/c/PSUSciofficial"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Youtube
                  </MenuItem>
                </Menu>
              </Box>
              <Box
                sx={{
                  // flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                }}
              >
                <Tooltip title="Menu">
                  <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    aria-expanded={anchorElNav ? "true" : undefined}
                    color="inherit"
                    sx={{
                      transition: "transform 0.3s",
                      transform: anchorElNav ? "rotate(180deg)" : "",
                    }}
                  >
                    {anchorElNav ? (
                      <ExpandLess sx={{ width: 32, height: 32 }} />
                    ) : (
                      <MenuIcon sx={{ width: 32, height: 32 }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  PaperProps={styles}
                >
                  {pages.map((page, index) => {
                    const path = () => {
                      if (page === "Home") return "/"
                      else if (page === "Plant") return "/plant"
                      else if (page === "Animal") return "/animal"
                      else if (page === "Microbe") return "/microbe"
                      else if (page === "Geoscience") return "/geo"
                      else if (page === "Herbarium") return "/herbarium-home"
                      else if (page === "Contact") return "/contact-archive"
                    }

                    return [
                      page !== "Herbarium" && (
                        <MenuItem
                          key={page}
                          onClick={handleCloseNavMenu}
                          component={Link}
                          to={path()}
                        >
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      ),
                      page === "Herbarium" && (
                        <MenuItem
                          key={page}
                          onClick={handleCloseNavMenu}
                          component={Link}
                          target="_blank"
                          to="/herbarium-home"
                        >
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      ),
                      // page === "Contact" && (
                      //   <a
                      //     href="https://www.sci.psu.ac.th/en/contact-en/"
                      //     target="_blank"
                      //     rel="noreferrer noopener"
                      //   >
                      //     <MenuItem key={page} onClick={handleCloseNavMenu}>
                      //       <Typography textAlign="center">{page}</Typography>
                      //     </MenuItem>
                      //   </a>
                      // ),
                      index !== pages?.length - 1 && (
                        <Divider key={`divider-${index}`} />
                      ),
                    ]
                  })}
                </Menu>
              </Box>
            </Box>

            {/* <div data-role="BurgerMenu" className="header-burger-menu">
            <svg viewBox="0 0 1024 1024" className="header-icon">
              <path
                d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
                className=""
              ></path>
            </svg>
          </div> */}
            {/* <div data-role="MobileMenu" className="header-mobile-menu">
            <div className="header-nav1">
              <div className="header-container">
                <Box
                  component={"img"}
                  alt={props.image_alt}
                  src={props.image_src}
                  className="header-image1"
                />
                <div
                  data-role="CloseMobileMenu"
                  className="header-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="header-icon2">
                    <path
                      d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                      className=""
                    ></path>
                  </svg>
                </div>
              </div>
              <MobileNav
                rootClassName="mobile-nav-root-class-name9"
                className=""
              ></MobileNav>
            </div>
            <IconSocial
              rootClassName="icon-social-root-class-name6"
              className=""
            ></IconSocial>
          </div> */}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

Header.defaultProps = {
  image_src: "/playground_assets/rectangle493235-fxf-700w.png",
  image_alt: "logo",
  image_src1: "/playground_assets/rectangle493235-fxf-700w.png",
  image_alt1: "image",
  rootClassName: "",
}

Header.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Header
