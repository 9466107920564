import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./archiveview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { Box } from "@mui/system"
import {
  Backdrop,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Delete, Search} from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"
import * as XLSX from "xlsx"

const Microbeview = (props) => {
  // const [me, setMe] = useState()
  const requestPrivate = useAxiosPrivate()
  const [loadingData, setLoadingData] = useState(false);

  // useEffect(() => {
  //   let isMounted = true
  //   const controller = new AbortController()

  //   const getMe = async () => {
  //     try {
  //       const response = await requestPrivate.get("/me", {
  //         signal: controller.signal,
  //       })
  //       isMounted && setMe(response?.data)
  //     } catch (error) {
  //       history.push("./login")
  //       console.error(error)
  //     }
  //   }
  //   getMe()
  //   return () => {
  //     isMounted = false
  //     controller.abort()
  //   }
  // }, [])
  // console.log(me)
  const history = useHistory()

  const [searchDatas, setSearchDatas] = useState([])
  const [allDatas, setAllDatas] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })

  const exportExcel = async () => {
    const MicrobeData = searchDatas
      .filter((options) =>
        options.category === "MICROBE_BACTERIA" ||
        options.category === "MICROBE_MOLD" ||
        options.category === "MICROBE_PROTOZOA" ||
        options.category === "MICROBE_VIRUS"
      )
      .map(async (row) => {
        const lecturerData = await fetchLecturerData(row?.lecturer);

        return {
          family: row?.family || "-",
          category: row?.category || "-",
          genericName: row?.genericName || "-",
          specificEpithet: row?.specificEpithet || "-",
          sp: row?.sp || "-",
          subspecies: row?.subspecies || "-",
          serovar: row?.serovar || "-",
          serotype: row?.serotype || "-",
          biotype: row?.biotype || "-",
          strainDesignation: row?.strainDesignation || "-",
          specificApplications: row?.specificApplications || "-",
          typeOfIsolate: row?.typeOfIsolate || "-",
          isolationSource: row?.isolationSource || "-",
          geographicalIsolation: row?.geographicalIsolation || "-",
          optimumTemperature: row?.optimumTemperature || "-",
          bsl: row?.bsl || "-",
          lecturer: lecturerData?.map((lecturer) => lecturer.title + " " + lecturer.name + " " + lecturer.surname).join(", ") || "-",
          link: row?.references?.[0].link || "-",
          year: row?.references?.[0].year || "-",
          type: row?.references?.[0].type || "-",
          title: row?.references?.[0].title || "-",
        }
      });

    const resolvedMicrobeData = await Promise.all(MicrobeData);

    const PlantSheet = XLSX.utils.json_to_sheet(resolvedMicrobeData);

    const PlantHeader = [
      "Family",
      "Category",
      "Genus",
      "Specific Epithet",
      "SP",
      "Subspecies",
      "Serovar",
      "Serotype",
      "Biotype",
      "Strain Designation",
      "Specific Applications",
      "Type of Isolate",
      "Isolation Source",
      "Geographical Isolation",
      "OptimumTemperature",
      "BSL",
      "Researcher",
      "Link",
      "Year",
      "Type",
      "Title",
    ];
    const aoa = [
      PlantHeader.map((val) => ({ v: val, s: { font: { bold: true } } })),
    ];
    XLSX.utils.sheet_add_aoa(PlantSheet, aoa);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, PlantSheet, "Microbe");
    XLSX.writeFile(workbook, `Microbe.xlsx`);
  };

  const fetchLecturerData = async (lecturerIds) => {
    const lecturerPromises = lecturerIds.map(async (lecturerId) => {
      try {
        const response = await request.get(`/lecturer/${lecturerId}`);
        return response?.data || {};
      } catch (error) {
        console.error(error);
        return {};
      }
    });

    return Promise.all(lecturerPromises);
  };


  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 50,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "photo",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.photo.length > 0 ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({
                open: true,
                img: params.row.photo[0],
              })
            }}
          >
            <Box
              component="img"
              sx={{ width: "40px", height: "40px" }}
              src={params.row.photo[0]}
            />
          </ButtonBase>
        ) : (
          <Typography>no photo</Typography>
        )
      },
    },
    {
      field: "family",
      headerName: "Family",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "genericName",
      headerName: "Generic Name",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "specificEpithet",
      headerName: "Specific Epithet",
      headerClassName: "super-app-theme--header",
      width: 150,
    },

    {
      field: "subspecies",
      headerName: "Subspecies",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "typeOfIsolate",
      headerName: "Type of isolate",
      headerClassName: "super-app-theme--header",
      width: 150,
    },

    {
      field: "category",
      headerName: "Category",
      headerClassName: "super-app-theme--header",
      width: 200,
    },

    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          onClick={() => {
            history.push(`/edit-microbe/${params?.row?._id}`)
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "จัดการ",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            RemoveArchive(params?.row?._id, params?.row?.photo)
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ]

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const search = async () => {
      try {
        setLoadingData(true)
        const response = await request.post(
            "/items",
            {
              filter: {
                category: "MICROBE",
              },
            },
            {
              signal: controller.signal,
            },
          )
        isMounted && setSearchDatas(response?.data)
        if (response) {
          setAllDatas(response?.data)
          setRefetch(false)
        }
        setLoadingData(false)
      } catch (error) {
        console.error(error)
      }
    }
    search()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])

  // what's API?
  const RemoveArchive = async (id, urls) => {
    try {
      const confirmed = confirm("Are you sure you want to delete this item?")
      if (confirmed) {
        const response = await requestPrivate.delete(`/itemremove/${id}`, {})
        if (response) {
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            await request.delete(`/deletephoto/${fileNames}`, {})
          }
          setRefetch(true)
          alert("success")
        }
      }
    } catch (error) {
      alert(`${error?.data?.message}`)
    }
  }

  const handleFormatTextSearch = async (e) => {

    const textInput = e.target.value
    const [genericName, specificEpithet] = textInput.split(" ")

    if (textInput === "") {
      setSearchDatas(allDatas)
    } else {
      const SearchItem = async () => {
        try {
          setLoadingData(true)
          const response = await requestPrivate.post(
            `/searchName`,
            {
              filter: {
                category: "MICROBE",
              },
              search: {
                genericName: genericName || "",
                specificEpithet: specificEpithet || "",
              },
            },
          )

          if (response.status === 200) {
            setSearchDatas(response.data)
          }
          setLoadingData(false)
        } catch (error) {
          console.error("An error occurred:", error)
          alert("An error occurred while searching. Please try again later.")
        }
      }
      SearchItem()
    }
  }

  return (
    <div className="archiveview-container">
      <Helmet>
        <title>Microbeview - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="microbeview - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name6" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Archive", path: `/archiveview` },
          { title: "Microbe Lists" },
        ]}
      />
      <div className="archiveview-archive-list">
        <h1>
          <span>Microbe Lists</span>
          <br></br>
        </h1>
        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
            marginBottom: "100px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              autoComplete="false"
              label={
                <Grid container direction={"row"}>
                  <Grid item sx={{mr: 0.5}}> <Search /></Grid>
                  
                  <Grid item>Search</Grid>
                </Grid>
              }
              id="Search"
              sx={{ width: "500px" }}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton onClick={()=>SearchItem()}>
              //         <Search />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
              onChange={handleFormatTextSearch}
            />
          </Grid>
          <Grid container>
            <Grid container justifyContent={"flex-end"} item xs={12} md={12} sx={{ mt:1 }}>
              <Button
                onClick={() => exportExcel()}
                variant="contained"
                sx={{ alignContent: "flex-end", mb: 2, mr: 2 }}
              >
                Excel
              </Button>
              <Button
                component={Link}
                to="/add-microbe"
                variant="contained"
                sx={{ alignContent: "flex-end", mb: 2 }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            rows={
              searchDatas
                ? searchDatas
                  .filter(
                    (e) =>
                      e.category === "MICROBE_BACTERIA" ||
                      e.category === "MICROBE_MOLD" ||
                      e.category === "MICROBE_PROTOZOA" ||
                      e.category === "MICROBE_VIRUS",
                  )
                  .map((e, index) => ({
                    ...e,
                    id: e._id,
                    index: index + 1,
                    family: e?.family || "-",
                    genericName: e?.genericName || "-",
                    specificEpithet: e?.specificEpithet || "-",
                    subspecies: e?.subspecies || "-",
                    typeOfIsolate: e?.typeOfIsolate || "-",
                    category: e?.category || "-",
                  }))
                : []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            loading={loadingData}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          <Box component="img" src={openFullImage?.img} />
        </Backdrop>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default Microbeview
