import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./archiveview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { Box } from "@mui/system"
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Modal,
  TextField,
  InputAdornment,
} from "@mui/material"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { DataGrid } from "@mui/x-data-grid"
import { Delete, Edit, Key } from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"

const AdminUserManagement = (props) => {
  const [me, setMe] = useState()
  const requestPrivate = useAxiosPrivate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getMe = async () => {
      try {
        const response = await requestPrivate.get("/me", {
          signal: controller.signal,
        })
        isMounted && setMe(response?.data)
      } catch (error) {
        history.push("./login")
        console.error(error)
      }
    }
    getMe()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  // console.log(me)
  const history = useHistory()

  const [searchDatas, setSearchDatas] = useState([])
  const [refetch, setRefetch] = useState(false)

  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 50,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "super-app-theme--header",
      width: 130,
    },
    {
      field: "username",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      width: 150,
    },

    {
      field: "firstname",
      headerName: "Firstname",
      headerClassName: "super-app-theme--header",
      width: 150,
    },

    {
      field: "lastname",
      headerName: "Lastname",
      headerClassName: "super-app-theme--header",
      width: 150,
    },

    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 230,
    },

    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "super-app-theme--header",
      width: 110,
    },

    {
      field: "edit data",
      headerName: "Edit Data",
      headerClassName: "super-app-theme--header",
      width: 105,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          onClick={() => {
            history.push(`/admin-edit-user/${params?.row?._id}`)
          }}
        >
          <Edit />
        </Button>
      ),
    },

    {
      field: "edit password",
      headerName: "Edit Password",
      headerClassName: "super-app-theme--header",
      width: 110,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            handleOpen(params?.row?._id, params?.row?.username)
            // ChangePassword(params?.row?._id, params?.row?.username)
          }}
        >
          <Key style={{ color: "#47b31a" }} />
        </IconButton>
      ),
    },

    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      width: 105,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            RemoveUser(params?.row?._id, params?.row?.username)
          }}
        >
          <Delete style={{ color: "#eb4141" }} />
        </IconButton>
      ),
    },
  ]

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const search = async () => {
      try {
        const response = await request.get("/getalluser", {
          signal: controller.signal,
        })
        isMounted && setSearchDatas(response?.data)
        if (response) {
          setRefetch(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    search()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])
  // console.log(searchDatas)

  // what's API?

  const RemoveUser = async (id, username) => {
    const text = `คุณต้องการลบ account ${username} ?`
    const confirmed = window.confirm(text)
    if (confirmed) {
      try {
        const response = await requestPrivate.delete(`/userremove/${id}`, {})
        if (response) {
          setRefetch(true)
          alert("success")
        }
      } catch (error) {
        alert(`${error?.data?.message}`)
      }
    }
  }

  const [dataModal, setDataModal] = useState({})
  const [datapassword, setDataPassword] = useState({})
  const [open, setOpen] = useState(false)
  const [showpassconfirm, setShowpassconfirm] = useState(false)

  const handleOpen = (id, username) => {
    setDataModal({
      ...dataModal,
      id: id,
      username: username,
    })
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setDataPassword({
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    })
  }

  const SavePassword = async () => {
    const data = {
      username: dataModal?.username,
      password: datapassword?.password,
      newPassword: datapassword?.newPassword,
    }
    // console.log(data)
    const response = await requestPrivate.put(
      `/updatePassword/${dataModal?.id}`,
      {
        ...data,
      },
    )
    const check = response?.data?.check
    if (check) {
      alert(`Change Password Username ${data?.username} Success`)
      handleClose()
    } else {
      alert("Password Incorrect")
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    // height: "auto",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    "@media (max-width: 655px)": {
      width: "80%",
      // height: "auto",
    },
  }

  return (
    <div className="archiveview-container">
      <Helmet>
        <title>Admin User Management - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="Admin User Management - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name6" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "User Lists" },
        ]}
      />
      <div className="archiveview-archive-list">
        <h1>
          <span>User Lists</span>
          <br></br>
        </h1>
        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h5" component="h1">
                Account : {dataModal?.username}
              </Typography>
              <Grid container rowGap={1} pt={2}>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  alignItems={"center"}
                  direction={"row"}
                  spacing={1}
                >
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">Password</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      id="password"
                      type="password"
                      required
                      size="small"
                      fullWidth
                      value={datapassword ? datapassword?.password : ""}
                      onChange={(e) => {
                        setDataPassword({
                          ...datapassword,
                          password: e.target.value,
                        })
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  alignItems={"center"}
                  direction={"row"}
                  spacing={1}
                >
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">New Password</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      id="new-password"
                      type="password"
                      required
                      size="small"
                      fullWidth
                      value={datapassword ? datapassword?.newPassword : ""}
                      onChange={(e) => {
                        setDataPassword({
                          ...datapassword,
                          newPassword: e.target.value,
                        })
                      }}
                    />
                  </Grid>
                </Grid>
                
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  alignItems={"center"}
                  direction={"row"}
                  spacing={1}
                >
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">
                      Confirm New Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      id="con-new-password"
                      type={showpassconfirm ? "text" : "password"}
                      required
                      size="small"
                      fullWidth
                      value={
                        datapassword ? datapassword?.confirmNewPassword : ""
                      }
                      onChange={(e) => {
                        setDataPassword({
                          ...datapassword,
                          confirmNewPassword: e.target.value,
                        })
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {datapassword?.newPassword &&
                              datapassword?.confirmNewPassword && (
                                <div>
                                  {datapassword?.newPassword ===
                                  datapassword?.confirmNewPassword ? (
                                    <Typography
                                      sx={{ fontSize: 10, color: "green" }}
                                    >
                                      {/* <CheckIcon
                                      sx={{
                                        width: 10,
                                        height: 10,
                                        marginRight: 0.3,
                                      }}
                                    /> */}
                                      รหัสผ่านตรงกัน
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{ fontSize: 10, color: "red" }}
                                    >
                                      {/* <ClearIcon
                                      sx={{
                                        width: 10,
                                        height: 10,
                                        marginRight: 0.3,
                                      }}
                                    /> */}
                                      รหัสผ่านไม่ตรงกัน
                                    </Typography>
                                  )}
                                </div>
                              )}
                            <IconButton
                              onClick={() => {
                                setShowpassconfirm(!showpassconfirm)
                              }}
                            >
                              {showpassconfirm ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  pt={2}
                  columnGap={1}
                  xs={12}
                  container
                  direction={"row"}
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  // spacing={2}
                >
                  <Button
                    variant="contained"
                    onClick={SavePassword}
                    disabled={
                      datapassword?.password === "" ||
                      datapassword?.newPassword === "" ||
                      datapassword?.confirmNewPassword === "" ||
                      datapassword?.newPassword !==
                        datapassword?.confirmNewPassword
                    }
                  >
                    Save Password
                  </Button>
                  <Button variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Grid container>
            <Grid container justifyContent={"flex-end"} item xs={12} md={12}>
              <Button
                component={Link}
                to="/admin-add-user"
                variant="contained"
                sx={{ alignContent: "flex-end", mb: 2 }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            rows={
              searchDatas
                ? searchDatas?.map((e, index) => ({
                    ...e,
                    id: e._id,
                    index: index + 1,
                  }))
                : []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default AdminUserManagement
