import React from 'react'

import PropTypes from 'prop-types'

import './detail-fields.css'

const DetailFields = (props) => {
  return (
    <div className={`detail-fields-container ${props.rootClassName} `}>
      <h1 className="detail-fields-text">{props.category}</h1>
      <input
        type="text"
        placeholder={props.Input}
        className="detail-fields-textinput input"
        onChange={props.onChange}
      />
    </div>
  )
}

DetailFields.defaultProps = {
  Input: 'name',
  rootClassName: '',
  category: 'Name',
}

DetailFields.propTypes = {
  Input: PropTypes.string,
  rootClassName: PropTypes.string,
  category: PropTypes.string,
}

export default DetailFields
