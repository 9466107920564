import React from "react"
import ReactDOM from "react-dom"
import App from "./app"
import { AuthProvider } from "./contexts/auth-context"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"
import { deepOrange, teal } from "@mui/material/colors"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import "./index.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#144372",
    },
    secondary: deepOrange,
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById("app"),
)
serviceWorkerRegistration.register()
