import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import ScientificNameheader from '../components/scientific-nameheader'
import DetailsCard from '../components/details-card'
import ResearcherCard from '../components/researcher-card'
import Footer from '../layouts/footer-layout'
import './detail.css'
import { useParams } from 'react-router-dom'
import { request } from '../axios-config'

import BreadcrumbLayout from "../components/breadcrumb-layout"

const Detail = (props) => {
  const params=useParams()
  const [itemDatas, setItemDatas] = useState({})
  const [namePath,setNamePath]=useState('')

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getItem = async () => {
      try {
        const response = await request.get(`/item/${params?.id}`, {
          signal: controller.signal
        })
        isMounted && setItemDatas(response?.data)
        const path = `${response?.data?.category}`.substring(0, `${response?.data?.category}`.indexOf("_"))
        const pathtoLower = path.toLocaleLowerCase()
        setNamePath(pathtoLower)
      } catch (error) {
        console.error(error)
      }
    }
    getItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  // console.log(itemDatas);
  // console.log(namePath);

  const [lecturerDatas, setLecturerDatas] = useState([])

  const nameCategories = (name) => {
      switch (name) {
          case 'PLANT_ANGIOSPERM': return ("ANGIOSPERM")
          case 'PLANT_GYMNOSPERM': return ("GYMNOSPERM")
          case 'PLANT_LYCOPHYTEANDFERNS': return ("LYCOPHYTEANDFERNS")
          case 'PLANT_BRYOPHYTES': return ("BRYOPHYTE")
          case 'PLANT_ALGAEANDPHYTOPLANKTON': return ("ALGAEANDPHYTOPLANKTON")
          case 'ANIMAL_VERTEBRATE': return ("VERTEBRATE")
          case 'ANIMAL_INVERTEBRATE': return ("INVERTEBRATE")
          case 'MICROBE_BACTERIA': return ("BACTERIA")
          case 'MICROBE_MOLD': return ("MOLD")
          case 'MICROBE_PROTOZOA': return ("PROTOZOA")
          case 'MICROBE_VIRUS': return ("VIRUS")
          case 'Geophysics': return ("GEOPHYSICS")
          case 'Geology': return ("GEOLOGY")
          
          default: return null
      }
  }


  return (
    <div className="detail-container">
      <Helmet>
        <title>Detail - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Detail - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name8"></Header>
      <BreadcrumbLayout
        pages={[
          { title: namePath, path: `/${namePath}` },
          { title: `${itemDatas?.scientificName}` },
        ]}
      />
      <div className="detail-research-content">
        <ScientificNameheader
        GenericName={itemDatas?.genericName}
        SpecificEpithet={itemDatas?.specificEpithet}
        AuthorName={itemDatas?.authorName1}
        ></ScientificNameheader>
        <div className="detail-separator"></div>
        <div className="detail-container1">
          <div className="detail-container2">
            <img
              alt="image"
              src={itemDatas?.photo}
              className="detail-image"
            />
            <a
              href={itemDatas?.references?.[0].link}
              target="_blank"
              rel="noreferrer noopener"
              className="detail-link button"
            >
              Reference Article
            </a>
          </div>
          <div className="detail-container3">
            <DetailsCard
            title={"Family"}
            description={itemDatas?.family}
            rootClassName="rootClassName1"></DetailsCard>
            <DetailsCard
            title={"Generic Name"}
            description={itemDatas?.genericName}
            rootClassName="rootClassName1"></DetailsCard>
            <DetailsCard
            title={"Specific Epithet"}
            description={itemDatas?.specificEpithet}
            rootClassName="rootClassName1"></DetailsCard>
            <DetailsCard
            title={"Author Name"}
            description={itemDatas?.authorName1}
            rootClassName="rootClassName1"></DetailsCard>
            <DetailsCard
            title={"Categories"}
            description={nameCategories(itemDatas?.category)}
            rootClassName="rootClassName1"></DetailsCard>
            <DetailsCard
            title={"Common Name"}
            description={itemDatas?.commonName}
            rootClassName="rootClassName4"></DetailsCard>
            <DetailsCard
            title={"Vernacular Name"}
            description={itemDatas?.vercularName}
            rootClassName="rootClassName3"></DetailsCard>
            <DetailsCard
            title={"Location"}
            description={itemDatas?.locality}
            rootClassName="rootClassName2"></DetailsCard>
            <DetailsCard
            title={"Habitat"}
            description={itemDatas?.ecologyAndHabitat}
            rootClassName="details-card-root-class-name5"></DetailsCard>
            {/* <DetailsCard
            title={"Propagation"}
            description={itemDatas?.scientificName?.full}
            rootClassName="details-card-root-class-name6"></DetailsCard> */}
            <DetailsCard
            title={"Optimum temperature"}
            description={itemDatas?.optimumTemperature}
            rootClassName="details-card-root-class-name7"></DetailsCard>
          </div>
        </div>
      </div>

      <div class="detail-research-content">
  <h2 class="mb-2 text-lg flex flex-wrap items-center justify-center font-semibold text-gray-900 ">More References</h2>
    <ul class="container mx-auto p-6 justify-center flex flex-wrap flex-1 items-stretch mb-6 text-blue-900 ">
          {itemDatas?.references?.map((row, index) => (
            <li>
            <div class="max-w-sm rounded overflow-hidden shadow-lg h-full">
              <div class="px-6 py-4">
                
                <div class="font-bold text-xl mb-2">{row?.title}</div>
                
                <p class="text-gray-700 text-base">
                  {row?.type}, {row?.year}
                </p>
              </div>
              
              <div class="px-6 pt-4 pb-2">
                
    {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span> */}
            
                
                <a href="#" class="mr-4 hover:underline flex flex-wrap items-center justify-center md:mr-6 ">
                  <a
                    href={row?.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="detail-link2 button"
                  >
                    Reference Article

                  </a>
                </a>
                
              </div>
              
            </div>
            
          </li> 
                ))}
          
          
        </ul>
        
      </div>
      
      <div className="detail-banner">
        <h2 className="detail-text">
          <br></br>
          <span>Affiliated Researcher</span>
          <br></br>
          <span></span>
          <br className="detail-text3"></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h2>
      </div>
      <div className="detail-researcher">
        <div className="detail-container4">
          {itemDatas?.lecturerData?.map((row) => (
            <ResearcherCard
              id={row?._id}
              photo={row.pictureUrl}
              name={row?.title+" "+row?.name+" "+row?.surname}
              division={row?.major}
              email={row?.email}
              rootClassName="researcher-card-root-class-name"
            />
          ))}
            
        </div>
        
      </div>
      <Footer rootClassName="footer-root-class-name7"></Footer>
    </div>
  )
}

export default Detail
