import React from 'react'

import PropTypes from 'prop-types'

import './status-item.css'
import { Style } from '@mui/icons-material'

// function colorStatus(name) {
//   switch (name) {
//     case 'AVAILABLE': return { color: 'green' };
//     case 'UNAVAILABLE': return { color: 'red'};
//     default:
//       return { color: 'black' };
//   }
// }



const StatusItem = (props) => {
  return (
    <div className={`status-item-container ${props.rootClassName} `}>
      <h1 className="">{props.head}</h1>
      <h1 className="status-item-status-item" style={{ color: props?.StatusItem === 'AVAILABLE' ? 'green' : 'red' }}>&nbsp;{props.StatusItem}</h1>
    </div>
  )
}

StatusItem.defaultProps = {
  head: 'Status:',
  StatusItem: '-',
  rootClassName: '',
}

StatusItem.propTypes = {
  head: PropTypes.string,
  StatusItem: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default StatusItem
