import React, { useEffect, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./borrowview.css"
import { request } from "../axios-config"
import {
  Backdrop,
  Box,
  ButtonBase,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Typography,
} from "@mui/material"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { DataGrid } from "@mui/x-data-grid"
import useAxiosPrivate from "../hook/use-axios-private"
import { useHistory } from "react-router-dom"
import { Delete } from "@mui/icons-material"

const Borrowview = (props) => {

  const requestPrivate = useAxiosPrivate()


  const [borrowList, setBorrowList] = useState()
  const [status, setStatus] = useState()
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })

  function stringAvatar(name) {
    switch (name) {
      case "ACCEPTED":
        return {
          sx: {
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
            color: "green",
          },
        }

        break
      case "DECLINED":
        return {
          sx: {
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
            color: "red",
          },
        }

        break
      case "INPROGRESS":
        return {
          sx: {
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
            color: "orange",
          },
        }

        break

      default:
        break
    }
  }
  const RemoveBorrowForm = async (id) => {
    try {
      const confirmed = confirm("Are you sure you want to delete this form?")
      if (confirmed) {
        const response = await requestPrivate.delete(`/deleteborrowform/${id}`, {})
        if (response) {
          setRefetch(true)
          alert("success")
        }
      }
    } catch (error) {
      alert(`${error?.data?.message}`)
    }
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 50,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "plantName.photograph",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "center",
      headerAlign: "center",

      renderCell: (params) => {
        return params?.row?.plantName.photograph?.length > 0 ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({
                open: true,
                img: params.row.plantName.photograph[0],
              })
            }}
          >
            <Box
              component="img"
              sx={{ width: "40px", height: "40px" }}
              src={params.row.plantName.photograph[0]}
            />
          </ButtonBase>
        ) : (
          <Typography>no photo</Typography>
        )
      },
    },
    {
      field: "plantName",
      headerName: "Plant Name",
      headerClassName: "super-app-theme--header",
      width: 350,
      renderCell: (params) =>
        params?.row?.plantName ? (
          <div className="scrollable-cell">
            <Typography>
              {`${params?.row?.plantName.genus} 
              ${params?.row?.plantName.species} 
              ${params?.row?.plantName.author1}`}
            </Typography>
          </div>
        ) : (
          <Typography>no Data</Typography>
        ),
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      headerClassName: "super-app-theme--header",
      width: 200,
    },

    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "plantId",
      headerName: "Plant ID",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: (params) =>
        params?.row?.plantName.plantId ? (
          <Typography>
            {`${params?.row?.plantName.plantId}`}
            {/* {console.log(params.row)} */}
          </Typography>
        ) : (
          <Typography>no Data</Typography>
        ),
    },
    {
      field: "herbariumNumber",
      headerName: "Herbarium Number",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params?.row?.plantName.herbariumNumber ? (
          <Typography>
            {`${params?.row?.plantName.herbariumNumber}`}
          </Typography>
        ) : (
          <Typography>no Data</Typography>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <FormControl
          sx={{
            // width: "100%",
            m: 2,
          }}
        >
          <InputLabel id="statusLabel"></InputLabel>
          <Select
            labelId="statusLabel"
            id="statusSelect"
            size="small"
            {...stringAvatar(`${params?.row?.status}`)}
            value={params?.row?.status ? params?.row?.status : ""}
            onChange={(e) => {
              setStatus({
                ...status,
                status: e.target.value,
              })
            }}
          >
            <MenuItem
              value="ACCEPTED"
              onClick={() => editStatus("ACCEPTED", params?.row?._id)}
            >
              ACCEPTED
            </MenuItem>

            <MenuItem
              value="DECLINED"
              onClick={() => editStatus("DECLINED", params?.row?._id)}
            >
              DECLINED
            </MenuItem>

            <MenuItem
              value="INPROGRESS"
              onClick={() => editStatus("INPROGRESS", params?.row?._id)}
            >
              INPROGRESS
            </MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: "delete",
      headerName: "ลบคำขอ",
      headerClassName: "super-app-theme--header",
      width: 80,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            RemoveBorrowForm(params?.row?._id)
          }}
        >
          <Delete />
        </IconButton>
      ),
    }
  ]

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getBorrowList = async () => {
      try {
        const response = await request.get("/borrowform", {
          signal: controller.signal,
        })
        isMounted && setBorrowList(response?.data)
        if (response) {
          setRefetch(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    getBorrowList()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])

  const editStatus = async (status, id) => {
    try {
      const response = await requestPrivate.put(`/updatestatus/${id}`, {
        status: status,
      })
      if (response) {
        setRefetch(true)
        alert("success")
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="borrowview-container">
      <Helmet>
        <title>Borrowview - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Borrowview - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name7" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Borrowed Lists" },
        ]}
      />
      <div className="borrowview-pricing">
        <h1>
          <span>Borrowed Lists</span>
          <br></br><br></br>
        </h1>

        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <DataGrid
            rows={
              borrowList
                ? borrowList?.map((e, index) => ({
                  ...e,
                  id: e._id,
                  index: index + 1,
                }))
                : []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          <Box component="img" src={openFullImage?.img} />
        </Backdrop>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  )
}

export default Borrowview
