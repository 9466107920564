
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import HerbariumAppBar from '../../layouts/header-herbarium'
import FooterHerbarium from '../../layouts/new-footerherbarium'
import ResearcherNameHeader from '../../components/researcher-name-header'
import StaffDetailCard from '../../components/herbarium-staff-card'

import './herbarium-staff.css'
import { Link, useParams, useLocation } from 'react-router-dom'
import { request } from '../../axios-config'
import BreadcrumbHerbarium from '../../components/breadcrumb-herbarium-layout'

const HerbariumCollectorDetail = (props) => {
    const params = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('herbarium');
    const [collectorData, setCollectorData] = useState({})

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController();
        const getItem = async () => {
            try {
                const response = await request.get(`/collector/${params?.id}`, {
                    signal: controller.signal
                })
                isMounted && setCollectorData(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getItem();
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    return (
        <div className='detail-font'>
            <div className="staff-details-container">
                <Helmet>
                    <title>Herbarium Staff Detail - Sci PSU Archive</title>
                    <meta
                        name="description"
                        content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                    />
                    <meta
                        property="og:title"
                        content="ResearcherDetails - Sci PSU Archive"
                    />
                    <meta
                        property="og:description"
                        content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                    />
                </Helmet>
                <HerbariumAppBar rootClassName="header-root-class-name18"></HerbariumAppBar>

                {collectorData?.title || collectorData?.collectorName || collectorData?.collectorSurname ?
                    <BreadcrumbHerbarium
                        pages={[
                            { title: "Search", path: "/herbarium-search" },
                            { title: "Detail Herbarium", path: `/herbarium-detail/${id}` },
                            { title: `${collectorData?.title + " " + collectorData?.collectorName + " " + collectorData?.collectorSurname}` },
                        ]}
                    />
                    :
                    <BreadcrumbHerbarium
                        pages={[
                            { title: "Search", path: "/herbarium-search" },
                            { title: "Detail Herbarium", path: `/herbarium-detail/${id}` },
                            { title: `${"-" + " " + "-" + " " + "-"}` },
                        ]}
                    />
                }

                <div className="researcher-details-reseacrher-content">
                    <span
                        style={{ color: '#16957C' }}
                    >
                        {collectorData?.title || collectorData?.collectorName || collectorData?.collectorSurname ?
                            <ResearcherNameHeader
                                ResearcherName={collectorData?.title + " " + collectorData?.collectorName + " " + collectorData?.collectorSurname}
                                rootClassName="staff-name-header-root-class-name">
                            </ResearcherNameHeader>
                            :
                            <ResearcherNameHeader
                                ResearcherName={"-" + " " + "-" + " " + "-"}
                                rootClassName="staff-name-header-root-class-name">
                            </ResearcherNameHeader>
                        }
                    </span>
                    <div className="staff-details-separator"></div>
                    <div className="staff-details-container1">

                        <div className="staff-details-container2">
                            {collectorData?.photo ?
                                < img
                                    alt="image"
                                    src={collectorData?.photo}
                                    className="staff-details-image"
                                />
                                :
                                <img
                                    src={
                                        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
                                    }
                                    alt="no photo"
                                    loading="lazy"
                                    style={{
                                        width: "250px",
                                        height: "250px",
                                    }}
                                />
                            }
                        </div>

                        <div className="staff-details-container3">

                            <StaffDetailCard
                                title="Title"
                                description={collectorData?.title || "-"}
                                rootClassName="rootClassName8" />

                            <StaffDetailCard
                                title="Name"
                                description={collectorData?.collectorName || "-"}
                                rootClassName="rootClassName8" />

                            <StaffDetailCard
                                title="Surname"
                                description={collectorData?.collectorSurname || "-"}
                                rootClassName="rootClassName8" />
                            <StaffDetailCard
                                title="Email"
                                description={collectorData?.email || "-"}
                                rootClassName="rootClassName8" />
                            <StaffDetailCard
                                title="Person"
                                description={collectorData?.person || "-"}
                                rootClassName="rootClassName8" />
                            <StaffDetailCard
                                title="Mobile Number"
                                description={collectorData?.phoneNumber || "-"}
                                rootClassName="rootClassName8" />

                        </div>
                    </div>
                </div>

            </div>
            <FooterHerbarium />
        </div>

    )

}

export default HerbariumCollectorDetail
