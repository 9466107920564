import React from 'react'

import PropTypes from 'prop-types'

import './icon-social.css'

const IconSocial = (props) => {
  return (
    <div className={`icon-social-icon-group ${props.rootClassName} `}>
      <div className="icon-social-container">
        <a
          href="https://www.facebook.com/PsuSci"
          target="_blank"
          rel="noreferrer noopener"
          className="icon-social-link"
        >
          <img
            alt={props.image_alt4}
            src={props.image_src4}
            className="icon-social-image"
          />
        </a>
        <a
          href="https://www.instagram.com/PsuSci/"
          target="_blank"
          rel="noreferrer noopener"
          className="icon-social-link1"
        >
          <img
            alt={props.image_alt3}
            src={props.image_src3}
            className="icon-social-image1"
          />
        </a>
        <a
          href="https://twitter.com/psu_sci"
          target="_blank"
          rel="noreferrer noopener"
          className="icon-social-link2"
        >
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="icon-social-image2"
          />
        </a>
        <a
          href="https://www.youtube.com/c/PSUSciofficial"
          target="_blank"
          rel="noreferrer noopener"
          className="icon-social-link3"
        >
          <img
            alt={props.image_alt2}
            src={props.image_src2}
            className="icon-social-image3"
          />
        </a>
      </div>
    </div>
  )
}

IconSocial.defaultProps = {
  image_src3: '/playground_assets/instagram_icon-200h.png',
  image_src1: '/playground_assets/rectangle662044-wql-200h.png',
  image_src2: '/playground_assets/rectangle642042-nbf8-200h.png',
  image_alt3: 'image',
  image_alt1: 'image',
  rootClassName: '',
  image_alt4: 'image',
  image_src4: '/playground_assets/2021_facebook_icon.svg',
  image_alt2: 'image',
}

IconSocial.propTypes = {
  image_src3: PropTypes.string,
  image_src1: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt3: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt4: PropTypes.string,
  image_src4: PropTypes.string,
  image_alt2: PropTypes.string,
}

export default IconSocial
