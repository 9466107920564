import React from "react"

import PropTypes from "prop-types"

import "./scientific-nameheader.css"
import { SpaceBar, SpaceBarOutlined } from "@mui/icons-material"

const ScientificNameheaderAnimal = (props) => {
  return (
    <div className="scientific-nameheader-container">
      <h1 className="scientific-nameheader-heading">
        {/* <span>Scientific Name : </span> */}
        <br></br>
      </h1>
      <h1 className="scientific-nameheader-text2">
        {props.GenericName ? props.GenericName + "\u00A0" : ""}
        {props.SpecificEpithet ? props.SpecificEpithet + "\u00A0" : ""}
      </h1>
      <h1 className="scientific-nameheader-text">
        {props.AuthorName || props.Sp}
      </h1>
    </div>
  )
}

ScientificNameheaderAnimal.defaultProps = {
  GenericName: "",
  SpecificEpithet: "",
  AuthorName: "",
  Sp: "",
}

ScientificNameheaderAnimal.propTypes = {
  GenericName: PropTypes.string,
  SpecificEpithet: PropTypes.string,
  AuthorName: PropTypes.string,
  Sp: PropTypes.string
}

export default ScientificNameheaderAnimal
