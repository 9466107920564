import React, { useEffect, useState, Fragment } from "react"
import { useHistory, NavLink, Link } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"
import MenuIcon from "@mui/icons-material/Menu"
import "./header-herbarium.css"
import {
  Toolbar,
  AppBar,
  Box,
  Button,
  IconButton,
  Container,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  ListItemIcon,
} from "@mui/material"
import {
  Logout,
  Login,
  Home,
  Person,
  Search,
  Call,
  ExpandLess,
  AccountCircle,
} from "@mui/icons-material"

const navItems = [
  { name: "Home", link: "/herbarium-home" },
  { name: "Staff", link: "/herbarium-staff" },
  { name: "Search", link: "/herbarium-search" },
  { name: "Contact", link: "/herbarium-contact" },
]

const styles = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
}

function HerbariumAppBar(props) {
  const history = useHistory()
  const requestPrivate = useAxiosPrivate()

  const [meLogin, setMeLogin] = useState(localStorage.getItem("Me"))
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElAdmin, setAnchorElAdmin] = useState(null)
  const open = Boolean(anchorElAdmin)

  const handleClick = (event) => {
    setAnchorElAdmin(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElAdmin(null)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getMe = async () => {
      try {
        const response = await requestPrivate.get("/me", {
          signal: controller.signal,
        })
        isMounted && setMeLogin(response?.data)
      } catch (error) {
        if (props.auth === true) {
          localStorage.removeItem("Me")
          history.push("/herbarium-login")
        }
      }
    }
    getMe()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const handleLogout = async () => {
    try {
      const logout = await requestPrivate.get("/logout")
      
      if (logout){
        localStorage.removeItem("Me")
        history.push("/herbarium-login")
      } 
    } catch (e) {
      console.error(e)
    }
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#FFFFFF",
        zIndex: 3,
        fontFamily: "Prompt",
        justifyContent: "center",
        height: 100,
      }}
    >
      <Container maxWidth="xl" sx={{ paddingLeft: 16 }}>
        <Toolbar>
          <img
            alt="logo"
            src="/playground_assets/herbarium/herbariumlogo-1.png"
            className="img-logo"
          />
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box className="nav-nav">
            {navItems?.map((item, i) => (
              <NavLink key={item.name + i} to={item.link} className="nav-link">
                {item.name}
              </NavLink>
            ))}
          </Box>
          <Box className="nav-admin">
            {!meLogin ? (
              <Button
                component={Link}
                to="/herbarium-login"
                variant="contained"
                sx={{
                  backgroundColor: "rgba(17, 172, 142)",
                  marginRight: "16px",
                  fontFamily: "Prompt",
                  // width: 100,
                  ":hover": {
                    backgroundColor: "rgb(0, 88, 70)",
                    color: "rgb(255, 255, 255)",
                  },
                }}
              >
                LOGIN
              </Button>
            ) : (
              <>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="large"
                    sx={{
                      ":hover": {
                        color: "rgb(0, 88, 70)",
                      },
                      mr: 0.5,
                      ml: -2
                    }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    color="inherit"
                  >
                    {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                    <AccountCircle
                      sx={{
                        width: 32,
                        height: 32,
                        color: "rgba(17, 172, 142)",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorElAdmin}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={styles}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                  keepMounted
                >
                  <MenuItem component={Link} to={"/adminmenu"} target="_blank">
                    <ListItemIcon>
                      <Person fontSize="small" />
                    </ListItemIcon>
                    Admin Menu
                  </MenuItem>
                  <Divider />

                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>

          <Box className="nav-mobile">
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              aria-controls="responsive-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              size="large"
              sx={{
                transition: "transform 0.3s",
                transform: anchorEl ? "rotate(180deg)" : "",
              }}
            >
              {anchorEl ? (
                <ExpandLess
                  sx={{ width: 32, height: 32, color: "rgba(17, 172, 142)" }}
                />
              ) : (
                <MenuIcon
                  sx={{ width: 32, height: 32, color: "rgba(17, 172, 142)" }}
                />
              )}
            </IconButton>
          </Box>
        </Toolbar>

        <Menu
          id="responsive-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          PaperProps={styles}
          keepMounted
        >
          {navItems.map((item, index) => {
            let icon
            const isLastItem = index === navItems.length - 1
            switch (item.name) {
              case "Home":
                icon = (
                  <Home
                    sx={{ mr: 2, color: "#5B5B5B" }}
                    key={`icon-${index}`}
                    fontSize="small"
                  />
                )
                break
              case "Staff":
                icon = (
                  <Person
                    sx={{ mr: 2, color: "#5B5B5B" }}
                    key={`icon-${index}`}
                    fontSize="small"
                  />
                )
                break
              case "Search":
                icon = (
                  <Search
                    sx={{ mr: 2, color: "#5B5B5B" }}
                    key={`icon-${index}`}
                    fontSize="small"
                  />
                )
                break
              case "Contact":
                icon = (
                  <Call
                    sx={{ mr: 2, color: "#5B5B5B" }}
                    key={`icon-${index}`}
                    fontSize="small"
                  />
                )
                break
              default:
                icon = null
                break
            }

            return [
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to={item.link}
                key={`menu-item-${index}`}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <Box sx={{ fontFamily: "Prompt", color: "#5B5B5B" }}>
                  {item.name}
                </Box>
              </MenuItem>,
              <>{!isLastItem && <Divider key={`divider-${index}`} />}</>,
            ]
          })}

          {/* {!meLogin?.firstname ? (
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/herbarium-login"
            >
              <Login sx={{ mr: 2, color: "#5B5B5B" }} />
              <Box sx={{ fontFamily: "Prompt", color: "#5B5B5B" }}>LOGIN</Box>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={handleLogout}
              component={Link}
              to="/herbarium-login"
            >
              <Logout sx={{ mr: 2, color: "#5B5B5B" }} />
              <Box sx={{ fontFamily: "Prompt", color: "#5B5B5B" }}>LOGOUT</Box>
            </MenuItem>
          )} */}
        </Menu>
      </Container>
    </AppBar>
  )
}

export default HerbariumAppBar
