import React from 'react'

import PropTypes from 'prop-types'

import './card-categories.css'

import { Link } from 'react-router-dom'
import { Box } from "@mui/material"
const CardCategories = (props) => {
      const nameCategories = (name) => {
      switch (name) {
          case 'PLANT_ANGIOSPERM': return ("ANGIOSPERM")
          case 'PLANT_GYMNOSPERM': return ("GYMNOSPERM")
          case 'PLANT_LYCOPHYTEANDFERNS': return ("LYCOPHYTEANDFERNS")
          case 'PLANT_BRYOPHYTES': return ("BRYOPHYTE")
          case 'PLANT_ALGAEANDPHYTOPLANKTON': return ("ALGAEANDPHYTOPLANKTON")
          case 'ANIMAL_VERTEBRATE': return ("VERTEBRATE")
          case 'ANIMAL_INVERTEBRATE': return ("INVERTEBRATE")
          case 'MICROBE_BACTERIA': return ("BACTERIA")
          case 'MICROBE_MOLD': return ("MOLD")
          case 'MICROBE_PROTOZOA': return ("PROTOZOA")
          case 'MICROBE_VIRUS': return ("VIRUS")
          case 'Geophysics': return ("GEOPHYSICS")
          case 'Geology': return ("GEOLOGY")
          
          default: return null
      }
  }
  const namePath = (name) => {
        const path = `${name}`.substring(0, `${name}`.indexOf("_"))
        const pathtoLower = path.toLocaleLowerCase()
      switch (name) {
          case 'PLANT_ANGIOSPERM': return (`${pathtoLower}`)
          case 'PLANT_GYMNOSPERM': return (`${pathtoLower}`)
          case 'PLANT_LYCOPHYTEANDFERNS': return (`${pathtoLower}`)
          case 'PLANT_BRYOPHYTES': return (`${pathtoLower}`)
          case 'PLANT_ALGAEANDPHYTOPLANKTON': return (`${pathtoLower}`)
          case 'ANIMAL_VERTEBRATE': return (`${pathtoLower}`)
          case 'ANIMAL_INVERTEBRATE': return (`${pathtoLower}`)
          case 'MICROBE_BACTERIA': return (`${pathtoLower}`)
          case 'MICROBE_MOLD': return (`${pathtoLower}`)
          case 'MICROBE_PROTOZOA': return (`${pathtoLower}`)
          case 'MICROBE_VIRUS': return (`${pathtoLower}`)
          case 'Geophysics': return ("geo")
          case 'Geology': return ("geo")
          
          default: return null
      }
  }
  return (
    <Box sx={{ margin: "auto", marginBottom: 0, maxWidth: "260px" }}>
      <Link to={`/${namePath(props.name)}`}>
        <div
          style={{
            backgroundImage: `url("/playground_assets/categoriespics/${props.name}.png")`,
          }}
          className={`card-categories-testimonial-card ${props.rootClassName} `}
        >
          <div className="card-categories-testimonial">
            <span className="card-categories-text">
              {nameCategories(props.name)}
            </span>
            <span className="card-categories-text1">{props.Samples}</span>
          </div>
        </div>
      </Link>
    </Box>
  )
}

CardCategories.defaultProps = {
  rootClassName: '',
  Samples: '- Samples',
  name: 'Categories',
}

CardCategories.propTypes = {
  rootClassName: PropTypes.string,
  Samples: PropTypes.string,
  name: PropTypes.string,
}

export default CardCategories
