import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import experimentalStyled from '@mui/material/styles';

import Location from './photo/location.png'
import TelePhone from './photo/telephone.png'
import Phone from './photo/phon.png'
import Internet from './photo/internet.png'
import Facebook from './photo/facebook.png'
import Email from './photo/email.png'
import Twitter from './photo/twitter.png'

import styles from './contact.module.css'

import HerbariumAppBar from '../../layouts/header-herbarium';
import FooterHerbarium from '../../layouts/new-footerherbarium';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',

});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#70CDBB',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',

}));


export default function ContactGrid() {
    return (
        <div>
            < Typography>
                <HerbariumAppBar />
                <Box sx={{
                    p: 5,
                    margin: 'auto',
                    maxWidth: '80%',
                    backgroundColor: '#70CDBB',
                    marginTop: 5,
                    borderRadius: '5px',

                }}
                >
                    <Grid container spacing={3}>
                        <Grid item >

                            <ButtonBase href="https://goo.gl/maps/BUaYLzjb6NJKk8Qr8"
                                sx={{
                                    width: '208px',
                                    height: '208px',
                                    borderRadius: '50px',
                                    '&:hover': {
                                        bgcolor: '#5AA496',
                                    },

                                }}>
                                <Img alt="complex" src={Location} />
                            </ButtonBase>
                            
                        </Grid>
                        <Grid item xs={12} sm container >
                            <Grid
                                item xs container
                                // direction="column"
                                spacing={2}
                                alignItems='center'
                            >

                                <Grid item xs>
                                    <div className={styles['text01']}>
                                        <span>
                                            <div>
                                                <span>Department of Biology</span>
                                                <br></br>
                                                <span> Faculty of Science, Prince of Songkla University, </span>
                                                <br></br>
                                                <span> Kanjanavanit Road, Hat Yai District, Songkhla Province 90110 </span>
                                            </div>
                                        </span>
                                    </div>
                                </Grid>

                                {/* <Grid item xs>
                                    <Link href="https://goo.gl/maps/BUaYLzjb6NJKk8Qr8">
                                        <div>
                                            <div className={styles['text02']}>
                                                <span> To The Location </span>
                                            </div>
                                        </div>
                                    </Link>
                                </Grid> */}

                            </Grid>
                        </Grid>
                    </Grid>
                </Box >

               
                    <Box marginTop={3} marginBottom={10}
                        sx={{ p: 3, margin: 'auto', maxWidth: '83%', }}
                    >

                        <Grid container
                            spacing={{ xs: 1, md: 3, }}
                            columns={{ xs: 1, sm: 1, md: 12 }
                            }>

                            {Array.from(Array(1)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Item>
                                        <div>
                                            <img src={Twitter} className={styles['icon']} />
                                            <div className={styles['text1']}  >
                                                <span>Twitter</span>
                                            </div>
                                        </div>
                                        <div className={styles['line']} />
                                        <div className={styles['text2']}  >
                                            <Link href="https://twitter.com/PSUBiology" underline="hover" color="none" >
                                                <span>@PSUBiology</span>
                                            </Link>
                                        </div>
                                    </Item>
                                </Grid>
                            ))}

                            {Array.from(Array(1)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>

                                    <Item>
                                        <div>
                                            <img src={Email} className={styles['icon']} />
                                            <div className={styles['text1']}  >
                                                <span> Email </span>
                                            </div>
                                        </div>
                                        <div className={styles['line']} />

                                        <div className={styles['text2']}>
                                            <span>
                                                <div>
                                                    <span>hathaikhwan.j@psu.ac.th</span>
                                                </div>
                                            </span>
                                        </div>
                                    </Item>

                                </Grid>
                            ))}

                            {Array.from(Array(1)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Item>
                                        <div>
                                            <img src={Facebook} className={styles['icon']} />
                                            <div className={styles['text1']}  >
                                                <span>Facebook</span>
                                            </div>
                                        </div>
                                        <div className={styles['line']} />
                                        <div className={styles['text2']}  >
                                            <Link href="https://www.facebook.com/PSUBiology/" underline="hover" color="none" >
                                                <span>@PSUBiology</span>
                                            </Link>
                                        </div>

                                    </Item>
                                </Grid>
                            ))}

                            {Array.from(Array(1)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Item>
                                        <div>
                                            <img src={Phone} className={styles['icon']} />
                                            <div className={styles['text1']}  >
                                                <span>Telephone</span>
                                            </div>
                                        </div>
                                        <div className={styles['line']} />
                                        <div className={styles['text2']}  >
                                            <span>074-28-8481</span>
                                        </div>

                                    </Item>
                                </Grid>

                            ))}{Array.from(Array(1)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Item>
                                        <div>
                                            <img src={Internet} className={styles['icon']} />
                                            <div className={styles['text1']}  >
                                                <span>Website</span>
                                            </div>
                                        </div>
                                        <div className={styles['line']} />
                                        <div className={styles['text2']}  >
                                            <Link href="http://www.biology.sci.psu.ac.th/" underline="hover" color="none" >
                                                <span>biology.sci.psu.ac.th</span>
                                            </Link>
                                        </div>
                                    </Item>
                                </Grid>

                            ))}{Array.from(Array(1)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <div>
                                        <Item>
                                            <div>
                                                <img src={TelePhone} className={styles['icon']} />
                                                <div className={styles['text1']}  >
                                                    <span>Phone</span>
                                                </div>
                                            </div>
                                            <div className={styles['line']} />
                                            <div className={styles['text2']}  >
                                                <span>074-55-8840</span>
                                            </div>
                                        </Item>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Box> 
                <FooterHerbarium />
            </Typography>
        </div>
    );
}
