import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Typography, Stack } from "@mui/material"
import PropTypes from 'prop-types'
import profile from '../views/herbarium/photo/profile.png'

import './herbarium-card.css'

const HerbariumCard = (props) => {
  const { photo } = props;

  return (
    <Box
      sx={{
        marginBottom: 0,
        maxWidth: "260px",
        margin: "auto",
      }}
    >
      <Link to={`/herbarium-staff-detail/${props?.id}`}>
        <div
          className={`herbarium-card-testimonial-card ${props.rootClassName} `}
        >
          {!photo &&
            <Stack justifyContent={"center"} alignContent={"center"} sx={{mt: 4}}>
              <img src={profile} alt="profile" style={{ width: "200px", height: "200px", opacity: 0.5 }} />
            </Stack>
          }
          <div
            className="researcher-card-container"
            style={{ backgroundImage: photo ? `url("${photo}")` : 'none' }}
          >
            <div className="herbarium-card-container1 font-change-container">
              <span className="herbarium-card-text">{props.title}</span>
              <span className="herbarium-card-text1">{props.name}</span>
              <span className="herbarium-card-text1">{props.surname}</span>
              <span className="herbarium-card-text2">{props.email}</span>
            </div>
          </div>
        </div>
      </Link>
    </Box>
  )
}

HerbariumCard.defaultProps = {
  email: '-',
  rootClassName: '',
  division: '-',
  name: '-',
}

HerbariumCard.propTypes = {
  email: PropTypes.string,
  rootClassName: PropTypes.string,
  division: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
}

export default HerbariumCard
