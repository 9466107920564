import * as React from "react"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Box, Stack } from "@mui/material"
import Container from "@mui/material/Container"
import Link from "@mui/material/Link"

import HerbariumAppBar from "../../layouts/header-herbarium"
import FooterHerbarium from "../../layouts/new-footerherbarium"
import styles from "./contact.module.css"

import Location from "./photo/location.png"
import TelePhone from "./photo/telephone.png"
import Phone from "./photo/phon.png"
import Internet from "./photo/internet.png"
import Facebook from "./photo/facebook.png"
import Email from "./photo/email.png"
import Twitter from "./photo/twitter.png"
import { ContactSupportRounded } from "@mui/icons-material"

const iframe = document.createElement("iframe")
iframe.src =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1980.0064328649523!2d100.4976311!3d7.0077674!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304d29a52b736cf3%3A0x91021d95971b4075!2z4Lit4Liy4LiE4Liy4Lij4LiK4Li14Lin4Lin4Li04LiX4Lii4Liy!5e0!3m2!1sth!2sth!4v1684915286682!5m2!1sth!2sth"
iframe.width = "600"
iframe.height = "450"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#70CDBB",
  ...theme.typography.body2,
  textAlign: "center",
}))

const stylesPaper = {
  height: 70,
  width: "100%",
  bgcolor: "#70CDBB",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  ":hover": {
    bgcolor: "#258f7a",
    cursor: "pointer",
    transform: "scale(1.04)",
    // transition: "transform 0.3s ease-in-out",
  },
}

export default function GridContactvII() {
  const [spacing, setSpacing] = React.useState(2)

  return (
    <div>
      <HerbariumAppBar />
      <Box
        sx={{
          p: 4,
          margin: "auto",
          maxWidth: "99%",
          backgroundColor: "#FFFFFF",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item container xs={12} columns={{ xs: 1, sm: 1, md: 12 }}>
            <Grid
              item
              container
              justifyContent="center"
              spacing={spacing}
              columnGap={1}
            >
              <Grid item xs={12} sm={12} md={7} lg={8}>
                <div className={styles["container"]}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Box
                              className={styles["box"]}
                              sx={{
                                borderRadius: "10px",
                              }}
                            >
                              <div className={styles["box-text"]}>Location</div>
                            </Box>
                          </Stack>

                          <Paper
                            sx={{
                              // height: 180,
                              width: "100%",
                              bgcolor: "#70CDBB",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              p: 1,
                            }}
                          >
                            {/* className={styles['text01v2']} */}
                            {/* <div className={styles['container']}> */}
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={{ p: 1 }}
                            >
                              <Stack spacing={1}>
                                <span
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "#FFFFFF",
                                    textAlign: "center"
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;PSU Herbarium, Department of Biology, 
                                  Faculty of Science, Prince of Songkla University.
                                  <br />
                                  15 Karnjanavanich Rd., Hat Yai.
                                 Songkhla 90110 THAILAND
                                </span>
                              </Stack>
                            </Stack>
                            {/* </div> */}
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          <Paper
                            sx={{
                              p: 1,
                              height: "100%",
                              width: "100%",
                              bgcolor: "#70CDBB",
                            }}
                          >
                            <div className={styles["container000"]}>
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d990.0040976741525!2d100.49839944858184!3d7.007352480719476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304d29a52b736cf3%3A0x91021d95971b4075!2z4Lit4Liy4LiE4Liy4Lij4LiK4Li14Lin4Lin4Li04LiX4Lii4Liy!5e0!3m2!1sth!2sth!4v1684987839335!5m2!1sth!2sth"
                                width="100%"
                                height="482"
                              ></iframe>
                            </div>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
              </Grid>

              <Grid item xs={12} sm={8} md={4} lg={3}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                    marginTop: 1,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          className={styles["box"]}
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div className={styles["box-text"]}>Twitter</div>
                        </Box>
                        <Link
                          href="https://twitter.com/PSUBiology"
                          target="_blank"
                          underline="hover"
                          color="none"
                        >
                          <Paper sx={stylesPaper}>
                            <div className={styles["container"]}>
                              <img src={Twitter} className={styles["iconv2"]} />
                              <div className={styles["text2v2"]}>
                                <span>@PSUBiology</span>
                              </div>
                            </div>
                          </Paper>
                        </Link>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          className={styles["box"]}
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div className={styles["box-text"]}>Facebook</div>
                        </Box>
                        <Link
                          href="https://www.facebook.com/PSUBiology/"
                          target="_blank"
                          underline="hover"
                          color="none"
                        >
                          <Paper sx={stylesPaper}>
                            <div className={styles["container"]}>
                              <img
                                src={Facebook}
                                className={styles["iconv2"]}
                              />
                              <div className={styles["text2v2"]}>
                                <span>@PSUBiology</span>
                              </div>
                            </div>
                          </Paper>
                        </Link>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          className={styles["box"]}
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div className={styles["box-text"]}>Email</div>
                        </Box>

                        <Paper
                          sx={stylesPaper}
                          onClick={() => {
                            window.open(`mailto:orawanya.s@psu.ac.th`)
                          }}
                        >
                          <div className={styles["container"]}>
                            <img src={Email} className={styles["iconv2"]} />
                            <div className={styles["text2v2"]}>
                              <span>orawanya.s@psu.ac.th</span>
                            </div>
                          </div>
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          className={styles["box"]}
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div className={styles["box-text"]}>Phone</div>
                        </Box>
                        <Paper
                          sx={stylesPaper}
                          onClick={() => {
                            window.open(`tel:074-28-8481`)
                          }}
                        >
                          <div className={styles["container"]}>
                            <img src={Phone} className={styles["iconv2"]} />
                            <div className={styles["text2v2"]}>
                              <span>074-28-8481</span>
                            </div>
                          </div>
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          className={styles["box"]}
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div className={styles["box-text"]}>Fax</div>
                        </Box>
                        <Paper
                          sx={{
                            height: 70,
                            width: "100%",
                            bgcolor: "#70CDBB",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <div className={styles["container"]}>
                            <img src={TelePhone} className={styles["iconv2"]} />
                            <div className={styles["text2v2"]}>
                              <span>074-55-8840</span>
                            </div>
                          </div>
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          className={styles["box"]}
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div className={styles["box-text"]}>Website</div>
                        </Box>
                        <Link
                          href="http://www.biology.sci.psu.ac.th/"
                          target="_blank"
                          underline="hover"
                          color="none"
                        >
                          <Paper sx={stylesPaper}>
                            <div className={styles["container"]}>
                              <img
                                src={Internet}
                                className={styles["iconv2"]}
                              />
                              <div className={styles["text2v2"]}>
                                <span>biology.sci.psu.ac.th</span>
                              </div>
                            </div>
                          </Paper>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FooterHerbarium />
    </div>
  )
}
