import React, { useState, useEffect } from "react"
import {
  Box,
  Stack,
  Grid,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  InputAdornment,
} from "@mui/material"
import HerbariumAppBar from "../../layouts/header-herbarium"
import FooterHerbarium from "../../layouts/new-footerherbarium"
import BreadcrumbHerbarium from "../../components/breadcrumb-herbarium-layout"
import PhotoDetailHerbarium from "../../components/photoDetailHerbarium"
import { request } from "../../axios-config"
import { useParams, Link } from "react-router-dom"
import { CheckCircleOutline, CancelOutlined } from "@mui/icons-material"

const fontStyles = {
  fontFamily: "Prompt",
}

const HerbariumReserve = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  const params = useParams()
  const [herbariumDetail, setHerbariumDetail] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isReservedDialogOpen, setIsReservedDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [dataForm, setDataForm] = useState({})
  const [isCheckEmail, setIsCheckEmail] = useState(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getHerbariumDetail = async () => {
      try {
        const response = await request.get(`/herbariumItem/${params?.id}`, {
          signal: controller.signal,
        })
        isMounted && setHerbariumDetail(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getHerbariumDetail()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params?.id])

  useEffect(() => {
    setDataForm({ ...dataForm, plant: params?.id })
  }, [params?.id])

  const handleConfirmClick = () => {
    setIsModalOpen(true)
  }

  const handleReservationConfirm = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await request.post("/borrowform", {
        ...dataForm,
      })
      if (response?.data?.code === "SUCCESS_BORROW_CREATE") {
        setIsError(false)

        setIsLoading(false)
        setIsModalOpen(false)
        setIsReservedDialogOpen(true)
      } else if (response?.data?.code === "ERROR_BORROW") {
        setIsError(true)

        setIsLoading(false)
        setIsModalOpen(false)
        setIsReservedDialogOpen(true)
      }
    } catch (e) {
      console.error(e)
      // alert(`${e?.data?.message}`)
    }
  }

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value
    console.log("enteredEmail", enteredEmail)

    if (!enteredEmail || !isValidEmail(enteredEmail)) {
      setIsCheckEmail(true)
    } else {
      setIsCheckEmail(false)
    }

    setDataForm({
      ...dataForm,
      email: enteredEmail,
    })
  }

  // console.log("herbariumDetail?.status", herbariumDetail?.status)
  return (
    <div>
      <HerbariumAppBar />
      <BreadcrumbHerbarium
        pages={[
          { title: "Search", path: `/herbarium-search` },
          {
            title: "Detail Herbarium",
            path: `/herbarium-detail/${params?.id}`,
          },
          { title: `Reserved` },
        ]}
      />
      <Box
        sx={{
          bgcolor: "background.paper",
          p: isMobile ? 2 : 10,
          paddingTop: isMobile ? 2 : 6,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{
            position: "relative",
            mb: 2,
            // "@media(max-width: 601px)": {
            //   padding: 2,
            // },
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <Typography
              sx={{
                fontSize: { xs: 28, sm: 36, md: 36 },
                fontWeight: "bold",
                color: "rgba(0,0,0,0.7)",
                display: "inline",
              }}
              textAlign={{ xs: "center" }}
              fontFamily={fontStyles}
            >
              {isMobile ? "Status" : "Status :"}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 28, sm: 36, md: 36 },
                fontWeight: "bold",
                color:
                  herbariumDetail?.status === "AVAILABLE"
                    ? "#10AC8E"
                    : "#FF3E60",
                display: "inline",
              }}
              textAlign={{ xs: "center" }}
              fontFamily={fontStyles}
            >
              {herbariumDetail?.status}
            </Typography>
            <Divider
              orientation="horizontal"
              sx={{
                flexGrow: 1,
                bgcolor:
                  herbariumDetail?.status === "AVAILABLE"
                    ? "#10AC8E"
                    : "#FF3E60",
                height: 2,
              }}
            />
          </Stack>
          <Divider
            orientation="horizontal"
            sx={{
              flexGrow: 1,
              bgcolor:
                herbariumDetail?.status === "AVAILABLE" ? "#10AC8E" : "#FF3E60",
              height: 2,
            }}
          />
        </Stack>
        <Stack
          sx={{
            p: 5,
            bgcolor: "rgba(255, 255, 255, 0.5)",
            borderRadius: 5,
            boxShadow: "4px 8px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid
            container
            direction="row"
            columnGap={5}
            rowGap={4}
            sx={{ justifyContent: "center" }}
          >
            <Grid
              item
              xs={12}
              md={3}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PhotoDetailHerbarium itemphoto={herbariumDetail?.photograph} />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid container direction="column" rowGap={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Prompt",
                        fontSize: 20,
                      }}
                    >
                      Scientific&nbsp;Name
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{ fontFamily: "Prompt" }}
                    >
                      <em>
                        {herbariumDetail?.genus} {herbariumDetail?.species}
                      </em>{" "}
                      {herbariumDetail?.author1}{" "}
                      {herbariumDetail?.intraspecificStatus}{" "}
                      <em>{herbariumDetail?.intraspecificName}</em>{" "}
                      {herbariumDetail?.author2}
                    </Typography>
                  </Stack>
                </Grid>
                <Divider />
                <Grid item xs={12} sm={12} md={12}>
                  <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Prompt",
                        fontSize: 20,
                      }}
                    >
                      Data for reserve
                    </Typography>
                    {/* <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                          <em>{herbariumDetail?.genus} {herbariumDetail?.species}</em> {herbariumDetail?.author1} {herbariumDetail?.intraspecificStatus} <em>{herbariumDetail?.intraspecificName}</em> {herbariumDetail?.author2}
                      </Typography> */}
                  </Stack>
                </Grid>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  columnGap={2}
                  rowGap={2}
                >
                  <TextField
                    id="name"
                    label="Firstname"
                    placeholder="Firstname"
                    fullWidth
                    variant="outlined"
                    // size="small"
                    fontFamily={fontStyles}
                    required
                    value={dataForm ? dataForm?.name : ""}
                    onChange={(e) => {
                      setDataForm({
                        ...dataForm,
                        name: e.target.value,
                      })
                    }}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    color="success"
                  />

                  <TextField
                    label="Lastname"
                    placeholder="Lastname"
                    fullWidth
                    variant="outlined"
                    // size="small"
                    fontFamily={fontStyles}
                    id="surname"
                    required
                    value={dataForm ? dataForm?.surname : ""}
                    onChange={(e) => {
                      setDataForm({
                        ...dataForm,
                        surname: e.target.value,
                      })
                    }}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    color="success"
                  />
                </Stack>

                <TextField
                  label="Email"
                  placeholder="Email"
                  fullWidth
                  variant="outlined"
                  // size="large"
                  fontFamily={fontStyles}
                  id="email"
                  required
                  type="email"
                  value={dataForm ? dataForm?.email : ""}
                  onChange={handleEmailChange}
                  color="success"
                  error={isCheckEmail}
                  InputProps={{
                    style: { borderRadius: "10px" },
                    endAdornment:
                      dataForm?.email === "" ? null : isCheckEmail ? (
                        <InputAdornment position="end">
                          <span style={{ color: "red", fontSize: "10px" }}>
                            Email format incorrect!
                          </span>
                        </InputAdornment>
                      ) : null,
                  }}
                />

                <Grid item xs={12} md={12}>
                  <Grid container direction={{ xs: "column", md: "column" }}>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#10AC8E" }}
                        sx={{
                          width: 200,
                          height: 50,
                          ...fontStyles,
                          boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
                        }}
                        onClick={handleConfirmClick}
                        fontFamily={fontStyles}
                        disabled={
                          dataForm?.name === undefined ||
                          dataForm?.surname === undefined ||
                          dataForm?.email === undefined ||
                          dataForm?.name === "" ||
                          dataForm?.surname === "" ||
                          isCheckEmail
                        }
                      >
                        CONFIRM
                      </Button>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 5,
                      }}
                    >
                      <Button
                        component={Link}
                        to={`/herbarium-detail/${params?.id}`}
                        variant="text"
                        sx={{ color: "#10AC8E", ...fontStyles }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {/* confirm ################################################################ */}
      <Dialog open={isModalOpen}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
            {isLoading ? (
              <CircularProgress sx={{ color: "#10AC8E" }} />
            ) : (
              "Are you sure you want to confirm the reservation?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3, display: isLoading ? "none" : "" }}>
          <Button
            onClick={() => setIsModalOpen(false)}
            sx={{ color: "#10AC8E" }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            sx={{ bgcolor: "#10AC8E", ":hover": { bgcolor: "#086F5B" } }}
            onClick={handleReservationConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirm succes ################################################################ */}
      <Dialog
        open={isReservedDialogOpen}
        // onClose={() => setIsReservedDialogOpen(false)}
      >
        <DialogTitle textAlign={"center"} sx={{ mt: 2 }}>
          {isError ? "Reservation Failed" : "Reservation Success"}
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {isError ? (
              <>
                <CancelOutlined
                  sx={{ fontSize: 60, marginBottom: 2, color: "#E41E1E" }}
                />
                <DialogContentText>
                  <p>Your reserve has not been confirmed.</p>
                </DialogContentText>
              </>
            ) : (
              <>
                <CheckCircleOutline
                  sx={{ fontSize: 60, marginBottom: 2, color: "#04CB00" }}
                />
                <DialogContentText>
                  <p>Your reservation has been successfully confirmed.</p>
                </DialogContentText>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, mt: -3 }}>
          <Button
            component={Link}
            to={!isError ? `/herbarium-detail/${params?.id}` : null}
            onClick={() => setIsReservedDialogOpen(false)}
            sx={{ color: "#10AC8E" }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <FooterHerbarium />
    </div>
  )
}

export default HerbariumReserve
