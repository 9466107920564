import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import HerbariumItemList from "../components/herbarium-item-list"
import Footer from "../layouts/footer-layout"
import HerbariumFooter from "../layouts/footer-herbarium"
import "./herbarium-main.css"
import { request } from "../axios-config"
import { Autocomplete, TextField, Button, Stack, Container, Pagination } from '@mui/material'
import AdvancesearchCard from '../components/advanceresearchCard'

import ItemArchiveCard from '../components/item-archive-card'

const itemsPerPage = 5;

const HerbariumMain = (props) => {
  const [herbariumItem, setHerbariumItem] = useState([])
  const [searchDatas, setSearchDatas] = useState([])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getHerbariumItem = async () => {
      try {
        const response = await request.get("/herbariumItem", {
          signal: controller.signal,
        })
        isMounted && setHerbariumItem(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getHerbariumItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  // console.log(searchDatas);

  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageData = herbariumItem.slice(startIndex, endIndex);

  return (
    <div className="herbarium-main-container">
      <Helmet>
        <title>HerbariumMain - Sci PSU Archive</title>
        <meta
          name="description"
          content="A herbarium is a critical resource for biodiversity, ecological, and evolutionary research studies."
        />
        <meta property="og:title" content="HerbariumMain - Sci PSU Archive" />
        <meta
          property="og:description"
          content="A herbarium is a critical resource for biodiversity, ecological, and evolutionary research studies."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name13"></Header>
      <div className="herbarium-main-hero">
        <div className="herbarium-main-container1">
          <h1 className="herbarium-main-text">
            <span>What is the Herbarium PSU</span>
            <br></br>
          </h1>
          <span className="herbarium-main-text3">
            For scientific research on biodiversity, ecology, and evolution, herbaria are a critical resource. These collections consist of dried and labeled plant specimens that are arranged for easy retrieval and long-term storage. Similar to a library, herbaria contain information, but in this case, it is in the form of pressed, dried, and annotated plant specimens. While most vascular plants are preserved as pressed and dried specimens, lichens, fungi, bryophytes, and some vascular plants are preserved in slightly different ways. Despite the variations, the purpose of a herbarium is to provide a valuable scientific resource that can be used for various research purposes.
          </span>
        </div>
      </div>

      <Container maxWidth="sm">
        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2} sx={{ mt: 2 }}>
          <Autocomplete
            disablePortal
            options={herbariumItem}
            getOptionLabel={(option) => option.family + " " + option.genus + " " + option.species + " " + option.author1 || ""}
            sx={{ width: "100%" }}
            renderInput={(params => <TextField {...params} label="Search here" />)}
            onChange={(e, value) => {
              // console.log('value', value)
              if (value !== null) {
                setSearchDatas([value])
              } else (
                setSearchDatas([])
              )
            }}
          />
          <Link to="/herbarium-advancesearch" className="herbarium-main-navlink">
            <Button variant="contained" sx={{ height: 55 }}>
              Advanced Search
            </Button>
          </Link>
        </Stack>
      </Container>

      <div className="plant-features">
        <div>
          {searchDatas.length !== 0 ? searchDatas?.map((HerbariumData, index) => (
            <HerbariumItemList
              key={index}
              path="herbarium-detail"
              Locality={HerbariumData?.location}
              DateDetermine={HerbariumData?.dateDetermine}
              genus={HerbariumData?.genus ? HerbariumData?.genus : ""}
              species={HerbariumData?.species ? HerbariumData?.species : ""}
              author1={HerbariumData?.author1 ? HerbariumData?.author1 : ""}
              intraspecificStatus={HerbariumData?.intraspecificStatus ? HerbariumData?.intraspecificStatus : ""}
              intraspecificName={HerbariumData?.intraspecificName ? HerbariumData?.intraspecificName : ""}
              author2={HerbariumData?.author2 ? HerbariumData?.author2 : ""}
              CollectorName={HerbariumData?.collector}
              id={HerbariumData?._id}
            />
          )) : pageData?.map((HerbariumData, index) => (
            <HerbariumItemList
              key={index}
              path="herbarium-detail"
              Locality={HerbariumData?.location}
              DateDetermine={HerbariumData?.dateDetermine}
              genus={HerbariumData?.genus ? HerbariumData?.genus : ""}
              species={HerbariumData?.species ? HerbariumData?.species : ""}
              author1={HerbariumData?.author1 ? HerbariumData?.author1 : ""}
              intraspecificStatus={HerbariumData?.intraspecificStatus ? HerbariumData?.intraspecificStatus : ""}
              intraspecificName={HerbariumData?.intraspecificName ? HerbariumData?.intraspecificName : ""}
              author2={HerbariumData?.author2 ? HerbariumData?.author2 : ""}
              CollectorName={HerbariumData?.collector}
              id={HerbariumData?._id}
            />
          ))}
        </div>
      </div>

      <Stack spacing={2}>
        <Pagination count={Math.ceil(herbariumItem.length / itemsPerPage)} shape="rounded" onChange={handleChange} />
      </Stack>

      <HerbariumFooter rootClassName="footer-root-class-name12"></HerbariumFooter>
    </div >
  )
}

export default HerbariumMain