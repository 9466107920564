import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import HerbariumAppBar from '../../layouts/header-herbarium'
import FooterHerbarium from '../../layouts/new-footerherbarium'
import ResearcherNameHeader from '../../components/researcher-name-header'
import StaffDetailCard from '../../components/herbarium-staff-card'

import './herbarium-staff.css'
import { Link, useParams } from 'react-router-dom'
import { request } from '../../axios-config'
import { Typography } from '@mui/material'
import BreadcrumbHerbarium from '../../components/breadcrumb-herbarium-layout'
import PhotoDetailHerbarium from '../../components/photoDetailHerbarium'

const HerbariumStaffDetail = (props) => {
    const params = useParams()
    const [collector, setCollector] = useState({})

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController();
        const getItem = async () => {
            try {
                const response = await request.get(`/collector/${params?.id}`, {
                    signal: controller.signal
                })
                isMounted && setCollector(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getItem();
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])


    return (
        <div className='detail-font'>
            <div className="staff-details-container">
                <Helmet>
                    <title>Herbarium Staff Detail - Sci PSU Archive</title>
                    <meta
                        name="description"
                        content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                    />
                    <meta
                        property="og:title"
                        content="ResearcherDetails - Sci PSU Archive"
                    />
                    <meta
                        property="og:description"
                        content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                    />
                </Helmet>
                <HerbariumAppBar rootClassName="header-root-class-name18"></HerbariumAppBar>
                <BreadcrumbHerbarium
                    pages={[
                        { title: "Staff", path: "/herbarium-staff" },
                        { title: `${collector?.title + " " + collector?.collectorName + " " + collector?.collectorSurname}` },
                    ]}
                />
                <div className="researcher-details-reseacrher-content">
                    <span
                        style={{ color: '#16957C' }}
                    >
                        <ResearcherNameHeader
                            ResearcherName={collector?.title + " " + collector?.collectorName + " " + collector?.collectorSurname}
                            rootClassName="staff-name-header-root-class-name"></ResearcherNameHeader> </span>
                    <div className="staff-details-separator"></div>
                    <div className="staff-details-container1">
                        <div className="staff-details-container2">
                            {/* {!collector?.photo ?
                                <Typography>
                                    NO IMAGE AVAILABLE
                                </Typography>
                                :
                                <img
                                    alt="image"
                                    src={collector?.photo}
                                    className="staff-details-image"
                                />
                            } */}
                            <PhotoDetailHerbarium
                                itemphoto={
                                    typeof collector?.photo === "string"
                                        ? [collector?.photo]
                                        : collector?.photo
                                }
                            />
                        </div>
                        <div className="staff-details-container3">

                            <StaffDetailCard
                                title="Title"
                                description={collector?.title}
                                rootClassName="rootClassName8" />

                            <StaffDetailCard
                                title="Name"
                                description={collector?.collectorName}
                                rootClassName="rootClassName8" />

                            <StaffDetailCard
                                title="Surname"
                                description={collector?.collectorSurname}
                                rootClassName="rootClassName8" />
                            <StaffDetailCard
                                title="Affiliation"
                                description={collector?.afflication}
                                rootClassName="rootClassName8" />
                                
                            <StaffDetailCard
                                title="Designation"
                                description={collector?.designation}
                                rootClassName="rootClassName8" />

                            <StaffDetailCard
                                title="Mobile Number"
                                description={collector?.phoneNumber}
                                rootClassName="rootClassName8" />
                            <StaffDetailCard
                                title="Email"
                                description={collector?.email}
                                rootClassName="rootClassName8" />

                        </div>
                    </div>
                </div>

            </div>
            <FooterHerbarium />
        </div>

    )

}

export default HerbariumStaffDetail
