import React, { useEffect, useState } from "react"

import { Helmet } from "react-helmet"
import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-herbarium.css"
import { request } from "../axios-config"
import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useHistory, useParams } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"

const AddHerbarium = (props) => {

  const requestPrivate = useAxiosPrivate()
  const params = useParams()
  const history = useHistory()
  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const [me, setMe] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getMe = async () => {
      try {
        const response = await requestPrivate.get("/me", {
          signal: controller.signal,
        })
        isMounted && setMe(response?.data)
      } catch (error) {
        history.push("/login")
        console.error(error)
      }
    }
    getMe()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const AddItem = async () => {
    try {
        const responseUrlPhoto = await request.post("/upload", {
          photo: filePhotos
        })

        const response = await requestPrivate.post("/herbariumItem", {
          ...item,
          photograph: responseUrlPhoto?.data?.urls,
          category: params?.category,
          createdBy: me?.id
        
        })

        if (response) {
          alert("success")
          history.push(`/herbariumview/${params?.category}`)
        }
      
    } catch (e) {
      console.error(e)
      alert(`${e?.message}`)
    }
  }

  const selectPhotoFiles = (e) => {
    if (filePhotos.length >= 4) {
      alert(`Up to 4 photos can be uploaded.`)
      e.target.value = null
      return
    }

    new Promise((resolve, reject) => {
      e.preventDefault()
      const files = Array.from(e.currentTarget.files)
      if (files?.length + filePhotos?.length > 4) {
        alert(`Up to 4 photos can be uploaded.`)
        e.target.value = null
        return
      }

      const invalidFiles = files.filter((file) => file.size > 2000000)
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((file) => file.name).join(", ")
        alert(`Please select images with a size not exceeding 2 MB: ${fileNames}`)
        e.target.value = null // clear the input field
        return
      }

      const filePromises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          }),
      )

      Promise.all(filePromises)
        .then((results) => {
          const base64Files = files.map((file, index) => ({
            data: file,
            base64: results[index],
          }))
          setFilePhotos([...base64Files, ...filePhotos])
          resolve(results)
        })
        .catch((error) => reject(error))
    })
  }

  const handleDeletePhoto = (index) => {
    const newPhotos = [...filePhotos]
    newPhotos.splice(index, 1)
    setFilePhotos(newPhotos)
  }

  // const selectPhotoFile = (e) =>
  //   new Promise((resolve, reject) => {
  //     e.preventDefault()
  //     const file = e.currentTarget.files[0]
  //     if (file.size > 2000000) {
  //       alert("กรุณาใส่รูปที่มีขนาดไม่เกิน 2 mb");
  //       e.target.value = null; // clear the input field
  //       return;
  //     } else {
  //       setFilePhoto({ data: file })
  //       const reader = new FileReader()
  //       reader.readAsDataURL(file)
  //       reader.onload = () => {
  //         resolve(reader.result)
  //         setFilePhoto({ base64: reader.result })
  //       }
  //       reader.onerror = (error) => reject(error)
  //     }
  //   })

  const ClearData = () => {
    setItem({
      ...item,
      photograph: "",
      genus: "",
      family: "",
      species: "",
      author1: "",
      intraspecificStatus: "",
      intraspecificName: "",
      author2: "",
      province: "",
      district: "",
      location: "",
      longtitude: "",
      latitude: "",
      altitude: "",
      remark: "",
      determineBy: "",
      dateDetermine: "",
      duplicate: "",
      status: "AVAILABLE",
      collectorNumber: "",
      commonName: "",
      herbariumNumber: "",
      collector: "",
      date: "",
    })
    setFilePhotos([])
  }

  return (
    <div className="add-herbarium-container">
      <Helmet>
        <title>AddHerbarium - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddHerbarium - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Herbarium", path: `/Herbariummenu` },
          { title: "Herbarium Lists", path: `/herbariumview/${params?.category}` },
          { title: `Add Herbarium Item` },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid container direction="row" alignItems="center" columnGap={2}>
          <Typography variant="h3">Herbarium Item </Typography>
          <span style={{ fontSize: '30px'}}>
            ({
              params?.category == "HERBAR_BRYOPHYTES" ? 
              "Bryophytes" : params?.category == "HERBAR_ALGAE" ? 
              "Algae" : params?.category == "HERBAR_VASCULAR_PLANTS" ?
              "Vascular plants" : "-"
            })
          </span>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"column"}
            spacing={1}
            sx={{ mb: "40px", mt: "5px" }}
          >
            <PhotoAction
              filePhotos={filePhotos}
              handleDeletePhoto={handleDeletePhoto}
              selectPhotoFiles={selectPhotoFiles}
            />
          </Grid>
        </Grid>

        

        <Grid container spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6">Scientific Name</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={10}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2">Family</Typography>
            </Grid>
            <Grid item xs={6} md={5}>
              <TextField
                multiline
                id="family"
                required
                size="small"
                fullWidth
                value={item ? item?.family : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    family: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Genus</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="genus"
                required
                size="small"
                fullWidth
                value={item ? item?.genus : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    genus: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Species</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="species"
                required
                size="small"
                fullWidth
                value={item ? item?.species : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    species: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author1</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="author1"
                required
                size="small"
                fullWidth
                value={item ? item?.author1 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    author1: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Infraspecific Status</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="intraspecificStatus"
                required
                size="small"
                fullWidth
                value={item ? item?.intraspecificStatus : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    intraspecificStatus: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Infraspecific Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="intraspecificName"
                required
                size="small"
                fullWidth
                value={item ? item?.intraspecificName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    intraspecificName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author2</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="author2"
                required
                size="small"
                fullWidth
                value={item ? item?.author2 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    author2: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Common Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="commonName"
                required
                size="small"
                fullWidth
                value={item ? item?.commonName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    commonName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
      
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6">Locality</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Province</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="province"
                required
                size="small"
                fullWidth
                value={item ? item?.province : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    province: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">District</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="district"
                required
                size="small"
                fullWidth
                value={item ? item?.district : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    district: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Location</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="location"
                required
                size="small"
                fullWidth
                value={item ? item?.location : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    location: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Longtitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="longtitude"
                required
                size="small"
                fullWidth
                value={item ? item?.longtitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    longtitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Latitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="latitude"
                required
                size="small"
                fullWidth
                value={item ? item?.latitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    latitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Altitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="altitude"
                required
                size="small"
                fullWidth
                value={item ? item?.altitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    altitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6">Details</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={2}>
              <Typography variant="subtitle2" alignItems={"center"} >Remark</Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <TextField
                multiline
                id="remark"
                required
                size="small"
                fullWidth
                value={item ? item?.remark : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    remark: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Determined By</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="determineBy"
                required
                size="small"
                fullWidth
                value={item ? item?.determineBy : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    determineBy: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Date Determine</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/YYYY"
                  views={["year", "month"]}
                  value={item.dateDetermine}
                  onChange={(newValue) =>
                    setItem({
                      ...item,
                      dateDetermine: newValue,
                    })
                  }
                  disableFuture
                  renderInput={(params) => (
                    <TextField
                      required
                      size="small"
                      sx={{}}
                      fullWidth
                      {...params}
                    />
                  )}
                  sx={{
                    width: "100%",
                    ".MuiOutlinedInput-input": {
                      height: "100%",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Duplicate</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="duplicate"
                required
                size="small"
                fullWidth
                value={item ? item?.duplicate : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    duplicate: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Collector</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="collector"
                required
                size="small"
                fullWidth
                value={item ? item?.collector : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    collector: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Collector Number</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="collectorNumber"
                required
                size="small"
                fullWidth
                value={item ? item?.collectorNumber : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    collectorNumber: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Date</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={item.date}
                  onChange={(newValue) =>
                    setItem({
                      ...item,
                      date: newValue,
                    })
                  }
                  disableFuture
                  renderInput={(params) => (
                    <TextField required size="small" fullWidth {...params} />
                  )}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Herbarium Number</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="herbariumNumber"
                required
                size="small"
                fullWidth
                value={item ? item?.herbariumNumber : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    herbariumNumber: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Status</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="category"
                required
                size="small"
                fullWidth
                select
                value={item ? item?.status : "AVAILABLE"}
                onChange={(e) => {
                  setItem({
                    ...item,
                    status: e.target.value,
                  })
                }}
              >
                <MenuItem value={"AVAILABLE"}>Available</MenuItem>
                <MenuItem value={"UNAVAILABLE"}>Unavailable</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          
          


          {/* <Grid container mt={5}>
            <Typography variant="h5">Collector</Typography>
            {lecturerForAdd.map((row, index) => {
              const lecturerValue =
                lecturerForAdd[index] !== undefined &&
                  lecturerForAdd[index] !== ""
                  ? lecturerForAdd[index]
                  : row
              const isLastIndex = index === lecturerForAdd.length - 1
              const isLastItem = lecturerForAdd.length === 1
              return (
                <Fragment key={index}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1">{`Collector ${index + 1
                        }`}</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={2}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">Collector</Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          id="lecturer"
                          required
                          size="small"
                          fullWidth
                          select
                          value={lecturerValue}
                          onChange={(e) => handleLecturerChange(e, index)}
                        >
                          {collectors?.length !== 0 ? (
                            collectors?.map((row, index) => (
                              <MenuItem
                                disabled={isLecturerSelected(
                                  lecturerValue,
                                  row?._id,
                                )}
                                key={index}
                                value={`${row?._id}`}
                              >{`${row?.shortname}`}</MenuItem>
                            ))
                          ) : (
                            <MenuItem />
                          )}
                        </TextField>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={2}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        {!isLastItem && (
                          <IconButton onClick={() => handleDeleteLecturer(index)}>
                            <Delete />
                          </IconButton>
                        )}

                        {isLastIndex && (
                          <IconButton
                            color={"primary"}
                            onClick={() => {
                              let lecfoad = lecturerForAdd.slice()
                              lecfoad[index + 1] = ""
                              setlecturerForAdd(lecfoad)
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )
            })}
          </Grid> */}
          <Grid
            item
            xs={12}
            direction={"row"}
            display={"flex"}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button variant="contained" onClick={AddItem}>
              submit
            </Button>
            <Button variant="text" onClick={ClearData}>
              Clear
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default AddHerbarium
