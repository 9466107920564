import React, { useState, useEffect } from 'react'
import { Box, Stack, Grid, Typography, TextField, Button, useMediaQuery, Divider } from '@mui/material'
import HerbariumAppBar from '../../layouts/header-herbarium'
import FooterHerbarium from '../../layouts/new-footerherbarium'
import BreadcrumbHerbarium from '../../components/breadcrumb-herbarium-layout'
import HerbariumItemList from '../../components/herbarium-item-list'
import { request } from '../../axios-config'
import '../herbarium-advancesearch.css'
import {Pagination} from "@mui/material"

const itemsPerPage = 20

const NewHerbariumAdvancesearch = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [herbariumData, setHerbariumData] = useState([]);
    const [searchDatas, setSearchDatas] = useState([]);
    const [showData, setShowData] = useState(false);
    const [dataFound, setDataFound] = useState(false);
    const [searchDetail, setSearchDetail] = useState([]);
    const [collectors, setCollectors] = useState()

    useEffect(() => {
        const getCollector = async () => {
            try {
                const response = await request.get(`/collector`)
                setCollectors(response?.data)
                return response?.data || {}
            } catch (error) {
                console.error(error)
                return {}
            }
        }
        getCollector()
    }, [])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getItem = async () => {
            try {
                const response = await request.get('/herbariumItem', {
                    signal: controller.signal,
                });
                if (isMounted) {
                    setHerbariumData(response?.data);
                    console.log(response?.data); // Add this line to check the retrieved data
                }
            } catch (error) {
                console.error(error);
            }
        };

        getItem();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const initialSearchParams = {
        family: '',
        genus: '',
        species: '',
        province: '',
        intraspecificStatus: '',
        intraspecificName: '',
        district: '',
        location: '',
    };

    const [searchParams, setSearchParams] = useState(initialSearchParams);

    const handleSearch = async (event) => {
        event.preventDefault();
        const filteredData = herbariumData.filter((data) =>
            Object.entries(searchParams).every(([key, value]) =>
                !value || data[key]?.toLowerCase().includes(value.toLowerCase())
            )
        );
        setShowData(true);
        setSearchDatas(filteredData);
        setDataFound(filteredData.length > 0);
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;

        if (name === 'family' || name === 'genus') {
            updatedValue = value.charAt(0).toUpperCase() + value.slice(1);
        }

        setSearchParams((prevParams) => ({
            ...prevParams,
            [name]: updatedValue,
        }));
    };

    const handleReset = () => {
        setSearchParams(initialSearchParams);
    };

    const [page, setPage] = useState(1)

    const handleChange = (event, value) => {
        setPage(value)
    }
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const pageData = searchDatas.slice(startIndex, endIndex)

    return (
        <div>
            <HerbariumAppBar />
            <BreadcrumbHerbarium
                pages={[
                    { title: 'Search' },
                ]}
            />
            <Box sx={{ bgcolor: 'background.paper', p: isMobile ? 2 : 10 }}>
                <Stack
                    sx={{
                        p: 5,
                        bgcolor: 'rgba(217, 217, 217, 0.25)',
                        borderRadius: 5,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{ position: 'relative', mb: 2 }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Stack direction="row" spacing={1}>
                            <Typography
                                sx={{ fontSize: { xs: 28, sm: 36, md: 36 }, fontWeight: 'bold', color: '#10AC8E' }}
                            >
                                Scientific
                            </Typography>
                            <Typography
                                sx={{ fontSize: { xs: 28, sm: 36, md: 36 }, fontWeight: 'bold', color: '#000000' }}
                            >
                                Name
                            </Typography>
                        </Stack>
                        <Divider
                            orientation="horizontal"
                            sx={{
                                flexGrow: 1,
                                bgcolor: '#10AC8E',
                                height: 2,
                            }}
                        />
                    </Stack>

                    <Grid container spacing={3} direction="column">
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                <TextField
                                    placeholder="Family"
                                    label="Family"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="family"
                                    value={searchParams.family}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    placeholder="Generic name"
                                    label="Generic name"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="genus"
                                    value={searchParams.genus}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    placeholder="Specific Epithet"
                                    label="Specific Epithet"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="species"
                                    value={searchParams.species}
                                    onChange={handleInputChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                <TextField
                                    placeholder="Infraspecific Status"
                                    label="Infraspecific Status"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="intraspecificStatus"
                                    value={searchParams.intraspecificStatus}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    placeholder="Infraspecific Name"
                                    label="Infraspecific Name"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="intraspecificName"
                                    value={searchParams.intraspecificName}
                                    onChange={handleInputChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                <TextField
                                    placeholder="province"
                                    label="province"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="province"
                                    value={searchParams.province}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    placeholder="district"
                                    label="district"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="district"
                                    value={searchParams.district}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    placeholder="location"
                                    label="location"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="location"
                                    value={searchParams.location}
                                    onChange={handleInputChange}
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Stack direction="row" justifyContent="space-between" sx={{ mt: 4 }}>
                        <Stack>
                            <Button variant="text" sx={{ color: '#666666' }} href="/herbarium-search">
                                Cancel
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="outlined"
                                style={{ color: '#10AC8E', borderColor: '#10AC8E' }}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                            <Button variant="contained" style={{ backgroundColor: '#10AC8E' }} onClick={handleSearch}>
                                Search
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>

                {showData && (
                    <Stack sx={{ p: 2 }}>
                        {searchDatas.length > 0 ? (
                            pageData.map((row, index) => {
                                const matchingCollector = collectors?.find((collector) => collector?._id === row.collector)
                                const collectorName = matchingCollector?.title + "\u00A0" + matchingCollector?.collectorName + "\u00A0" + matchingCollector?.collectorSurname || ""
                                return (
                                    <>
                                        <HerbariumItemList
                                            key={index}
                                            path="herbarium-detail"
                                            // Locality={row?.district + "," + row?.province+","+row?.location}
                                            Locality={`${row && row.province ? row.province : '-'}, ${row && row.district ? row.district : '-'}, ${row && row.location ? row.location : '-'}`}
                                            // DateDetermine={row?.collector + "," + row?.collectorNumber}
                                            DateDetermine={`${row && row.collector ? row.collector : ''}, ${row && row.collectorNumber ? row.collectorNumber : ''}`}
                                            genus={row?.genus ? row?.genus : ""}
                                            species={row?.species ? row?.species : ""}
                                            author1={row?.author1 ? row?.author1 : ""}
                                            intraspecificStatus={row?.intraspecificStatus ? row?.intraspecificStatus : ""}
                                            intraspecificName={row?.intraspecificName ? row?.intraspecificName : ""}
                                            author2={row?.author2 ? row?.author2 : ""}
                                            // CollectorName={collectorName}
                                            id={row?._id}
                                            color="#10AC8E"
                                        />
                                    </>
                                )
                            })
                        ) : (
                            <Stack justifyContent={"center"} alignItems={"center"} sx={{ p: 5 }}>
                                <Typography variant='h5'>
                                    Not Found
                                </Typography>
                            </Stack>
                        )}
                        <Pagination
                            count={Math.ceil(searchDatas.length / itemsPerPage)}
                            shape="rounded"
                            onChange={handleChange}
                        />
                    </Stack>
                )}
            </Box>
            <FooterHerbarium />
        </div>
    );
};

export default NewHerbariumAdvancesearch
