import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"
import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./archiveview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { Box } from "@mui/system"
import {
  Backdrop,
  ButtonBase,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
  Container,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import LaunchIcon from "@mui/icons-material/Launch"
import useAxiosPrivate from "../hook/use-axios-private"

const CountViewsOverall = (props) => {

  const [items, setItems] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })
  const [month, setMonth] = useState("")

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getItem = async () => {
      try {
        const response = await request.get("/getalltviews", {
          signal: controller.signal,
        })
        isMounted && setItems(response?.data)
        if (response) {
          setRefetch(true)
        }
      } catch (error) {
        console.log("error", error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])

  const getMonthOptions = () => {
    let maxLength = items.reduce((max, item) => {
      let length = item?.countviews?.viewsPerMonthList?.length || 0
      return length > max ? length : max
    }, 0)

    let mainItem = items.find(
      (item) => item?.countviews?.viewsPerMonthList?.length === maxLength,
    )

    if (mainItem) {
      let mainList = mainItem.countviews.viewsPerMonthList.map((list) => ({
        textMonth: list.dateMonthYear,
        value: `${list.dateMonthYear}`,
      }))

      return mainList
    }

    return []
  }

  const columns = [
    // {
    //   field: "index",
    //   headerName: "#",
    //   headerClassName: "super-app-theme--header",
    //   flex: 0.3,
    //   diisplay: "flex",
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      field: "photo",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 1,
      diisplay: "flex",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.photo && params?.row?.photo.length > 0 ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({
                open: true,
                img:
                  typeof params.row.photo === "string"
                    ? params.row.photo
                    : params.row.photo[0],
              })
            }}
          >
            <Box
              component="img"
              sx={{ width: "40px", height: "40px" }}
              src={
                typeof params.row.photo === "string"
                  ? params.row.photo
                  : params.row.photo[0]
              }
            />
          </ButtonBase>
        ) : (
          <Typography>no photo</Typography>
        )
      },
    },
    {
      field: "itemType",
      headerName: "Item type",
      headerClassName: "super-app-theme--header",
      width: 300,
      flex: 1,
      diisplay: "flex",
    },
    {
      field: "countviews",
      headerName: "Total views",
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: "right",
      width: 200,
      flex: 1,
      diisplay: "flex",
      renderCell: (params) => {
        return params?.row?.countviews
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
    },
    {
      field: "viewsPerMount",
      headerName: `Views ${month ? `of ${month}` : "per month"}`,
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: "right",
      width: 200,
      flex: 1,
      display: "flex",
      renderCell: (params) => {
        const { row } = params
        const { viewsPerMonthList } = row
        const filteredRows = viewsPerMonthList?.filter(
          (row) => row?.dateMonthYear === month,
        )
        const results = filteredRows?.[0]?.viewsPerMonth || 0
        const formattedRows = results
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        return formattedRows
      },
    },

    {
      field: "_id",
      headerName: "Link",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      diisplay: "flex",
      width: 200,
      renderCell: (params) => {
        let link = ""
        const microbeRegex = new RegExp("MICROBE", "i")
        const plantRegex = new RegExp("PLANT", "i")
        const animalRegex = new RegExp("ANIMAL", "i")
        const HerbarRegex = new RegExp("HERBAR", "i")

        if (microbeRegex.test(params?.row?.category))
          link = `/detailmicrobe/${params?.row?._id}`
        else if (HerbarRegex.test(params?.row?.category))
          link = `/herbarium-detail/${params?.row?._id}`
        else if (plantRegex.test(params?.row?.category))
          link = `/detailplant/${params?.row?._id}`
        else if (animalRegex.test(params?.row?.category))
          link = `/detailanimal/${params?.row?._id}`
        else if (params?.row?.type) link = `/detailgeo/${params?.row?._id}`

        return (
          <IconButton component="a" href={link} target="_blank">
            <LaunchIcon />
          </IconButton>
        )
      },
    },
  ]

  return (
    <div className="archiveview-container">
      <Helmet>
        <title>CountViews - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="CountViews - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name6" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Count Views" },
        ]}
      />
      <div className="archiveview-archive-list">
        <h1>
          <span>Count Views</span>
          <br></br>
        </h1>
        <Container sx={{ mt: 2 }}>
          <Autocomplete
            disablePortal
            options={getMonthOptions() || []}
            getOptionLabel={(option) => option?.textMonth || ""}
            renderInput={(params) => (
              <TextField {...params} label="Search month here" />
            )}
            onChange={(e, value) => {
              // console.log('value', value)
              setMonth(value?.textMonth)
            }}
          />
        </Container>
        <Box
          sx={{
            mt: 5,
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <DataGrid
            rows={
              items
                ? items
                    .map((e, index) => ({
                      ...e,
                      id: e._id,
                      index: index + 1,
                      itemType: e?.category
                        ? e?.category
                        : e?.status
                        ? "Herbarium"
                        : e?.type
                        ? "Geo"
                        : "-",
                      countviews: e?.countviews?.totalViews || "0",
                      viewsPerMonthList: e?.countviews?.viewsPerMonthList || [],
                      photo: e?.photo || e?.photograph,
                    }))
                    .sort((a, b) => b.countviews - a.countviews)
                : []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          <Box
            component="img"
            src={openFullImage?.img}
            sx={{ maxWidth: "80%", maxHeight: "80%" }}
          />
        </Backdrop>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default CountViewsOverall
