import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  IconButton
} from '@mui/material'
import { green, teal } from '@mui/material/colors'


const CardMenuLayout = (props) => {
  const {
    path, title, className, icon, description, ...others
  } = props
  const { pathname } = useLocation()
  return (
    <Card
      component={Link}
      to={path}
      sx={{
        maxWidth: '250px',
        maxHeight: '250px',
        minWidth: '250px',
        minHeight: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        ":hover": {
          background: 'rgba(0,150,136,0.2)',
        }
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton sx={{ width: '100px', height: '100px' }} aria-label="previous">
          {icon}
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', wordWrap: 'break-word', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <CardContent sx={{
          flex: '1 0 auto',
          display: 'flex',
          wordWrap: 'break-word',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography fontWeight={'bold'} component="div" variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}
CardMenuLayout.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  description: PropTypes.string
}
CardMenuLayout.defaultProps = {
  className: null,
  icon: null,
  setOpened: () => { }
}

export default CardMenuLayout