import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./lecturerview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Delete } from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"

const Lecturerview = (props) => {
  const history = useHistory()
  const requestPrivate = useAxiosPrivate()

  const [lecturerDatas, setLecturerDatas] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })

  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 50,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "photo",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params?.row?.pictureUrl ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({ open: true, img: params?.row?.pictureUrl })
            }}
          >
            <Avatar
              src={params?.row?.pictureUrl}
              sx={{ width: 40, height: 40 }}
            />
          </ButtonBase>
        ) : (
          // <Typography>no photo</Typography>
          <Avatar sx={{ width: 40, height: 40 }} />
        ),
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "super-app-theme--header",

      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "major",
      headerName: "Major",
      headerClassName: "super-app-theme--header",
      width: 250,
    },

    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 250,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          onClick={() => {
            history.push(`/edit-lecturer/${params?.row?._id}`)
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "จัดการ",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            RemoveLecturer(params?.row?._id)
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ]

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.get("/lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturerDatas(response?.data)
        if (response) {
          setRefetch(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])

  const RemoveLecturer = async (id) => {
    try {
      const confirmed = confirm("Are you sure you want to delete this researcher?")
      if (confirmed) {
        const response = await requestPrivate.delete(`/lecturerremove/${id}`, {})
      
        if (response) {
        setRefetch(true)
        alert("success")
      }}
    } catch (error) {
      alert(`${error?.data?.message}`)
    }
  }

  return (
    <div className="lecturerview-container">
      <Helmet>
        <title>Lecturerview - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Lecturerview - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name15" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Researcher Lists" },
        ]}
      />
      <div className="lecturerview-lecturer-list">
        <h1>
          <span>Researcher Lists</span>
          <br></br>
        </h1>
        {/* <Link to="/add-lecturer" className="lecturerview-navlink button">
          Add
        </Link> */}
        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <Grid container>
            <Grid container justifyContent={"flex-end"} item xs={12} md={12}>
              <Button
                component={Link}
                to="/add-lecturer"
                variant="contained"
                sx={{ alignContent: "flex-end", mb: 2 }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            rows={
              lecturerDatas
                ? lecturerDatas?.map((e, index) => ({
                    ...e,
                    id: e._id,
                    index: index + 1,
                    title: e?.title || "-",
                    name: e?.name || "-",
                    surname: e?.surname || "-",
                    major: e?.major || "-",
                    email: e?.email || "-"
                  }))
                : []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          {" "}
          <Box
            component="img"
            sx={{ width: "500px", height: "500px" }}
            src={openFullImage?.img}
          />
        </Backdrop>
        {/* <div className="lecturerview-container01">
          {lecturerDatas?.map((row) => (
            <div className="lecturerview-lecturer-list1">
              <div className="lecturerview-container02">
                <img
                  alt="image"
                  src={row?.pictureUrl}
                  className="lecturerview-image"
                />
                <div className="lecturerview-container03">
                  <span className="lecturerview-text03">{row?.title}</span>
                </div>
                <div className="lecturerview-container04">
                  <span className="lecturerview-text04">{row?.name}</span>
                </div>
                <div className="lecturerview-container05">
                  <span className="lecturerview-text05">{row?.surname}</span>
                </div>
                <div className="lecturerview-container06">
                  <span className="lecturerview-text06">{row?.major}</span>
                </div>
                <div className="lecturerview-container07">
                  <span className="lecturerview-text07">{row?.email}</span>
                </div>
                <div className="lecturerview-container08">
                  <button
                    className="lecturerview-button button"
                    onClick={() => {
                      history.push(`/edit-lecturer/${row?._id}`)
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="lecturerview-button1 button"
                    onClick={() => {
                      RemoveLecturer(row?._id)
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <Footer rootClassName="footer-root-class-name14"></Footer>
    </div>
  )
}

export default Lecturerview
