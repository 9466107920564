import React from "react"
import PropTypes from "prop-types"
import "./scientific-nameheader.css"

const ScientificNameheaderMicrobe = (props) => {
  return (
    <div className="scientific-nameheader-container">
      <h1 className="scientific-nameheader-heading">
        <br></br>
      </h1>
      <h1 className="scientific-nameheader-text2">
        {props.GenericName ? props.GenericName + "\u00A0" : ""}
        {props.SpecificEpithet ? props.SpecificEpithet + "\u00A0" : ""}
      </h1>
      <h1 className="scientific-nameheader-text">
        {props.Sp ? props.Sp + "\u00A0" : ""}
        {props.Subspecies ? props.Subspecies + "\u00A0" : ""}
      </h1>
      <h1 className="scientific-nameheader-text">{props.StrainDesignation}</h1>
    </div>
  )
}

ScientificNameheaderMicrobe.defaultProps = {
  GenericName: "",
  SpecificEpithet: "",
  Sp: "",
  Subspecies: "",
  StrainDesignation: "",
}

ScientificNameheaderMicrobe.propTypes = {
  GenericName: PropTypes.string,
  SpecificEpithet: PropTypes.string,
  Sp: PropTypes.string,
  Subspecies: PropTypes.string,
  StrainDesignation: PropTypes.string,
}

export default ScientificNameheaderMicrobe
