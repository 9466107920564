import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./archiveview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { Delete } from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"
import * as XLSX from "xlsx"

const GeoView = (props) => {
  const history = useHistory()
  const requestPrivate = useAxiosPrivate()

  const [GeoItem, setGeoItem] = useState()
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getGeoItem = async () => {
      try {
        const response = await request.get("/geoItem", {
          signal: controller.signal,
        })
        isMounted && setGeoItem(response?.data)
        if (response) {
          setRefetch(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    getGeoItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])

  const RemoveGeoItem = async (id, urls) => {
    try {
      const confirmed = confirm("Are you sure you want to delete this item?")
      if (confirmed) {
        const response = await requestPrivate.delete(`/geoItem/${id}`, {})
        if (response) {
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            await request.delete(`/deletephoto/${fileNames}`, {})
          }
          setRefetch(true)
          alert("success")
        }
      }
    } catch (error) {
      alert(`${error?.data?.message}`)
    }
  }

  const exportExcel = async () => {
    const GeoData = GeoItem.map(async (row) => {
      const lecturerData = await fetchLecturerData(row?.lecturer);
      return {
        province: row?.province || "-",
        district: row?.district || "-",
        description: row?.description || "-",
        date: new Date(row?.date).toLocaleString("en-US", {
          timeZone: "Asia/Bangkok",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }) || "-",
        location: row?.location || "-",
        longtitude: row?.longtitude || "-",
        latitude: row?.latitude || "-",
        altitude: row?.altitude || "-",
        type: row?.type || "-",
        dataGroup: row?.dataGroup,
        remark: row?.remark || "-",
        lecturer: lecturerData?.map((lecturer) => lecturer.title + " " + lecturer.name + " " + lecturer.surname).join(", ") || "-",
        link: row?.references?.[0].link || "-",
        year: row?.references?.[0].year || "-",
        type: row?.references?.[0].type || "-",
        title: row?.references?.[0].title || "-",
      }
    });

    const resolvedMicrobeData = await Promise.all(GeoData);
    const GeoSheet = XLSX.utils.json_to_sheet(resolvedMicrobeData);
    const GeoHeader = [
      "Province",
      "District",
      "Description",
      "Date",
      "Location",
      "Longtitude",
      "Latitude",
      "Altitude",
      "Type",
      "Data Group",
      "Remark",
      "Researcher",
      "Link",
      "Year",
      "Type",
      "Title",
    ];
    const aoa = [
      GeoHeader.map((val) => ({ v: val, s: { font: { bold: true } } })),
    ];
    XLSX.utils.sheet_add_aoa(GeoSheet, aoa);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, GeoSheet, "Geoscience");
    XLSX.writeFile(workbook, `Geoscience.xlsx`);
  };

  const fetchLecturerData = async (lecturerIds) => {
    const lecturerPromises = lecturerIds.map(async (lecturerId) => {
      try {
        const response = await request.get(`/lecturer/${lecturerId}`);
        return response?.data || {};
      } catch (error) {
        console.error(error);
        return {};
      }
    });

    return Promise.all(lecturerPromises);
  };



  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 50,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "photograph",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.photograph?.length > 0 ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({
                open: true,
                img: params.row.photograph[0],
              })
            }}
          >
            <Box
              component="img"
              sx={{ width: "40px", height: "40px" }}
              src={params.row.photograph[0]}
            />
          </ButtonBase>
        ) : (
          <Typography>no photo</Typography>
        )
      },
    },
    {
      field: "dataGroup",
      headerName: "Data Group",
      headerClassName: "super-app-theme--header",
      width: 180,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      width: 180,
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 180,
    },
    {
      field: "district",
      headerName: "District",
      headerClassName: "super-app-theme--header",
      width: 180,
    },
    {
      field: "province",
      headerName: "Province",
      headerClassName: "super-app-theme--header",
      width: 180,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            history.push(`/edit-geo-items/${params?.row._id}`)
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "จัดการ",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            RemoveGeoItem(params?.row?._id, params?.row?.photograph)
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ]
  return (
    <div className="archiveview-container">
      <Helmet>
        <title>Geoscience - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Geoview - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name14" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Geoscience Lists" },
        ]}
      />
      <div className="archiveview-archive-list">
        <h1>
          <span>Geoscience Lists</span>
          <br></br>
        </h1>

        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <Grid container>
            <Grid container justifyContent={"flex-end"} item xs={12} md={12}>
              <Button
                onClick={() => exportExcel()}
                variant="contained"
                sx={{ alignContent: "flex-end", mb: 2, mr: 2 }}
              >
                Excel
              </Button>
              <Button
                component={Link}
                to="/add-geo-archive-items"
                variant="contained"
                sx={{ alignContent: "flex-end", mb: 2 }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            rows={
              GeoItem
                ? GeoItem?.map((e, index) => ({
                  ...e,
                  id: e._id,
                  index: index + 1,
                  province: e.province || "-",
                  district: e.district || "-",
                  location: e.location || "-",
                  type: e.type || "-",
                  dataGroup: e.dataGroup || "-",
                }))
                : []
            }
            columns={columns}

            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          <Box component="img" src={openFullImage?.img} />
        </Backdrop>
      </div>
      <Footer rootClassName="footer-root-class-name13"></Footer>
    </div>
  )
}

export default GeoView