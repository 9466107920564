import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './item-archive-card.css'

const ItemArchiveCard = (props) => {
  return (
    <div
      key={props?.index}
      className={`item-archive-card-testimonial-card ${props.rootClassName} `}
    >
      <Link to={`/detail/${props?.id}`} className="item-archive-card-navlink">
        <div className="item-archive-card-container" style={{backgroundImage:`url("${props.photo}")`}}>
          <div className="item-archive-card-container1">
            <span className="item-archive-card-text">{props.ItemName}</span>
            <span className="item-archive-card-text1">{props.commonName}</span>
            <span className="item-archive-card-text2">{props.thaicommonName}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

ItemArchiveCard.defaultProps = {
  ItemName: '-',
  rootClassName: '',
  commonName: '-',
  thaicommonName: '-',
  index: 0,
  photo:'/playground_assets/rectangle493235-fxf-400h.png'
}

ItemArchiveCard.propTypes = {
  ItemName: PropTypes.string,
  rootClassName: PropTypes.string,
  commonName: PropTypes.string,
  thaicommonName: PropTypes.string,
  index: PropTypes.number,
  photo:PropTypes.string
}

export default ItemArchiveCard
