import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import ItemArchiveCard from '../components/item-archive-card'
import Footer from '../layouts/footer-layout'
import './geoscience.css'
import { request } from '../axios-config'
import { Autocomplete, TextField } from '@mui/material'
import ItemGeoArchiveCard from '../components/item-geo-archive-card'

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const itemsPerPage = 8;

const Geoscience = (props) => {
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const [geoDatas, setGeotDatas] = useState([])
  const [searchDatas, setSearchDatas] = useState([])

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageData = geoDatas.slice(startIndex, endIndex);

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.get(
          "/geoItem",
          {
            signal: controller.signal,
          },
        )
        isMounted && setGeotDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  // console.log(plantDatas);
  return (
    <div className="geoscience-container">
      <Helmet>
        <title>Geoscience - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Geoscience - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name9"></Header>
      <div className="geoscience-hero">
        <div className="geoscience-container1">
          <h1 className="geoscience-text">
            <span className="geoscience-text01">
              Geoscience Archive of Faculty of Science,
            </span>
            <br></br>
            <span>Prince of Songkla University</span>
            <br></br>
          </h1>
          <span className="geoscience-text05">
            <span className="geoscience-text06">
              The Faculty of Science at Prince of Songkla University has compiled a collection of geoscience samples, research data, and related information on this website. Our team of dedicated lecturers has gathered this information with the aim of making it readily available for academic and educational purposes. All of the data on this site is freely accessible, and we encourage researchers and educators to utilize it in their work. We strive to keep the contents of this website up to date with the latest findings from our faculty members, making this an invaluable resource for those seeking to advance scientific knowledge related to geoscience.
            </span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="geoscience-search-tab">
        <Autocomplete
        disablePortal
        options={geoDatas.length!==0?geoDatas.map((row)=>({...row,label:`${row?.dataGroup}`})):[]}
        getOptionLabel={(option) => option.dataGroup+" "+option.type || ""}
        sx={{ width: 500 }}
        renderInput={(params => <TextField {...params} label="Search here" />)}
        onChange={(e, value) => {
          // console.log('value', value)
          if (value !== null) {  
            setSearchDatas([value])
          } else (
            setSearchDatas([])
          )
        }}
        // onChange={(event, value) => console.log(value)} 
        />
      </div>
      <div className="geoscience-features">
        <div className="plant-container2">


          {searchDatas.length !== 0 ? searchDatas?.map((row, index) => (

            <ItemGeoArchiveCard
              key={index}
              index={index}
              id={row?._id}
              photo={row?.photograph}
              ItemName={row?.dataGroup + " " + row?.type}
              commonName={row?.province}
              thaicommonName={row?.description}
              rootClassName="item-archive-card-root-class-name"
            />
          ))
            : pageData?.map((row, index) => (
              <ItemGeoArchiveCard
                key={index}
                index={index}
                id={row?._id}
                photo={row?.photograph}
                ItemName={row?.dataGroup + " " + row?.type}
                commonName={row?.province}
                thaicommonName={row?.description}
                rootClassName="item-archive-card-root-class-name"
              />
            ))}
        </div>
      </div>

      <Stack spacing={2}>
        <Pagination
          count={Math.ceil(geoDatas.length / itemsPerPage)}
          shape="rounded"
          onChange={handleChange} />
      </Stack>

      <Footer rootClassName="footer-root-class-name8"></Footer>
    </div>
  )
}

export default Geoscience
