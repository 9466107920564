import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box } from "@mui/material"
import PropTypes from 'prop-types'

import './researcher-card.css'

const ResearcherCard = (props) => {
  
  return (
    <Box
      sx={{
        // flex: 1,
        // justifyContent: "center",
        // alignItems: "center",
        marginBottom: 0,
        maxWidth: "260px",
        margin: "auto",
        // padding: "auto",
      }}
    >
      <Link to={`/researcher-detail/${props?.id}`}>
        <div
          className={`researcher-card-testimonial-card ${props.rootClassName} `}
        >
          <div
            className="researcher-card-container"
            style={{ backgroundImage: `url("${props.photo}")` }}
          >
            <div className="researcher-card-container1">
              <span className="researcher-card-text">{props.name}</span>
              <span className="researcher-card-text1">{props.division}</span>
              <span className="researcher-card-text2">{props.email}</span>
            </div>
          </div>
        </div>
      </Link>
    </Box>
  )
}

ResearcherCard.defaultProps = {
  email: '-',
  rootClassName: '',
  division: '-',
  name: '-',
}

ResearcherCard.propTypes = {
  email: PropTypes.string,
  rootClassName: PropTypes.string,
  division: PropTypes.string,
  name: PropTypes.string,
}

export default ResearcherCard
