import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./herbariumview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { AddToQueue, ContactlessOutlined, Delete } from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"
import * as XLSX from "xlsx"
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import ScientificNameheader from "../components/scientific-nameheader"
import DetailsCard from "../components/details-card"
import exportMultipleLabel from "../lips/export-multi-label"
import exportDry from "../lips/export-dry"

const HerbariumPrint = () => {
  const history = useHistory()
  const requestPrivate = useAxiosPrivate()
  const params = useParams()
  const [status, setStatus] = useState()
  const [herbariumItem, setHerbariumItem] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })
  const [collectors, setCollectors] = useState()
  const [allCollectors, setAllCollectors] = useState()
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const cardRef = useRef(null);

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getHerbariumItem = async () => {
      try {
        setLoadingData(true)
        const response = await request.get(`/herbariumItemByCategory/${params?.category}`, {
          signal: controller.signal,
        })
        isMounted && setHerbariumItem(response?.data)
        if (response) {
          setRefetch(false)
        }
        setLoadingData(false)
      } catch (error) {
        console.error(error)
      }
    }
    getHerbariumItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])


  const generateQRCode = async (text) => {
    try {
      const canvas = await QRCode.toCanvas(text);
      return canvas.toDataURL();
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleCheckboxChange = (ids) => {
    const selectedIDs = new Set(ids);
    const data = herbariumItem?.map((e, index) => {
      return {
        ...e,
        id: e._id,
        index: index + 1,
        collector: e.collector || "-",
        photograph: e.photograph || "-",
        family: e.family || "-",
        genus: e.genus || "-",
        species: e.species || "-",
        author1: e.author1 || "-",
        author2: e.author2 || "-",
        intraspecificStatus: e.intraspecificStatus || "-",
        intraspecificName: e.intraspecificName || "-",
        location: e.location || "-",
        // collectorData:e?.collectorData || [],
        province: e.province || "-",
        district: e.district || "-",
        longtitude: e.longtitude || "-",
        latitude: e.latitude || "-",
        altitude: e.altitude || "-",
        remark: e.remark || "-",
        determineBy: e.determineBy || "-",
        duplicate: e.duplicate || "-",
        collectorNumber: e.collectorNumber || "-",
        herbariumNumber: e.herbariumNumber || "-",
        commonName: e.commonName ||"-",
      }
    })
    const selectedRowsData = data?.filter((row) =>
      selectedIDs.has(row.id),
    );
    console.log("selectedRowsData", selectedRowsData)
    setSelectedIds(ids)
    setSelectedRows(selectedRowsData);
  }



  const handleExportClick = async () => {

    // exportPDF(selectedIds);
    await exportMultipleLabel({ selectData: selectedRows })
    setSelectedRows([]);
    setSelectedIds([])
  };

  const handleExportClickDry = async () => {

    // exportPDF(selectedIds);
    await exportDry({ selectData: selectedRows })
    setSelectedRows([]);
    setSelectedIds([])
  };









  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 80,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "photograph",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.photograph?.length > 0 ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({
                open: true,
                img: params.row.photograph[0],
              })
            }}
          >
            <Box
              component="img"
              sx={{ width: "40px", height: "40px" }}
              src={params.row.photograph[0]}
            />
          </ButtonBase>
        ) : (
          <Typography>no photo</Typography>
        )
      },
    },
    {
      field: "herbariumNumber",
      headerName: "Herbarium Number",
      headerClassName: "super-app-theme--header",
      width: 160,
    },
    {
      field: "family",
      headerName: "Family",
      headerClassName: "super-app-theme--header",
      // type: "number",
      width: 200,
    },
    {
      field: "genus",
      headerName: "Genus",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "species",
      headerName: "Species",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "author1",
      headerName: "Author 1",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "intraspecificStatus",
      headerName: "Infraspecific Status",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "intraspecificName",
      headerName: "Infraspecific Name",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "author2",
      headerName: "Author 2",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "commonName",
      headerName: "Common Name",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "province",
      headerName: "Province",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "district",
      headerName: "District",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "longtitude",
      headerName: "Longtitude",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "altitude",
      headerName: "Altitude",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "remark",
      headerName: "Remark",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "determineBy",
      headerName: "Determined By",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "dateDetermine",
      headerName: "Date Determine",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params?.row?.dateDetermine ? (
          <Typography>
            {dayjs(params?.row?.dateDetermine).format("MMMM/YYYY")}
          </Typography>
        ) : (
          <Typography>-</Typography>
        ),
    },
    {
      field: "duplicate",
      headerName: "Duplicate",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params?.row?.date ? (
          <Typography>
            {dayjs(params?.row?.date).format("DD/MMM/YYYY")}
          </Typography>
        ) : (
          <Typography>-</Typography>
        ),
    },
    {
      field: "collectorNumber",
      headerName: "Collector Number",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "collector",
      headerName: "Collector",
      headerClassName: "super-app-theme--header",
      width: 250,
    //   renderCell:(params)=>{
    //     return(  <Typography>
    //     {
    //       params?.row?.collectorData.length !==0 ? params?.row?.collectorData?.map((collectorRow)=>{
    //         return(  `${collectorRow?.shortname} `)
    //       }).join(',')
    //    :null
    //     }
    //   </Typography>
    //     )
    //   }
    },
    {
      field: "createdBy",
      headerName: "Created by",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell:(params)=>{
        return(  <Typography>
        {
          params?.row?.createdByData.length !== 0  ? params?.row?.createdByData?.[0]?.firstname + ' ' + params?.row?.createdByData?.[0]?.lastname : "-"
        }
      </Typography>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 150,

    },
  ]

  return (
    <div className="herbariumview-container">
      <Helmet>
        <title>Herbariumview - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Herbariumview - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name14" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Herbarium", path: `/Herbariummenu` },
          { title: "Herbarium Lists", path: `/herbariumview/${params?.category}` },
          { title: "Print Labels" },
        ]}
      />

      <div style={{ position: "absolute", top: "-9999px" }}>
        <div ref={cardRef}>
          <div className="herbarium-detail-research-content">
            <ScientificNameheader
              ScientificName={`${herbariumItem?.family ? herbariumItem?.family : ""
                } ${herbariumItem?.genus ? herbariumItem?.genus : ""} ${herbariumItem?.species ? herbariumItem?.species : ""
                } ${herbariumItem?.author1 ? herbariumItem?.author1 : ""} ${herbariumItem?.intraspecificStatus
                  ? herbariumItem?.intraspecificStatus
                  : ""
                } ${herbariumItem?.intraspecificName
                  ? herbariumItem?.intraspecificName
                  : ""
                } ${herbariumItem?.author2 ? herbariumItem?.author2 : ""}`}
            />
            <div className="herbarium-detail-container1">
              <div className="herbarium-detail-container4">
                <div>
                  <DetailsCard
                    rootClassName="rootClassName9"
                    title={"Province"}
                    description={herbariumItem?.province}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="rootClassName11"
                    title={"District"}
                    description={herbariumItem?.district}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="rootClassName13"
                    title={"Location"}
                    description={herbariumItem?.location}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name16"
                    title={"Longtitude"}
                    description={herbariumItem?.longtitude}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name17"
                    title={"Latitude"}
                    description={herbariumItem?.latitude}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name18"
                    title={"Altitude"}
                    description={herbariumItem?.altitude}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name19"
                    title={"Determined By"}
                    description={herbariumItem?.determineBy}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name19"
                    title={"Date Determine"}
                    description={dayjs(herbariumItem?.dateDetermine).format(
                      "DD/MM/YYYY",
                    )}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name20"
                    title={"Duplicate"}
                    description={herbariumItem?.duplicate}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Plant Id"}
                    description={herbariumItem?.plantId}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Herbarium Number"}
                    description={herbariumItem?.herbariumNumber}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Collector"}
                    description={herbariumItem?.collector}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Date"}
                    description={dayjs(herbariumItem?.date).format(
                      "DD/MM/YYYY",
                    )}
                  ></DetailsCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="herbariumview-herbarium-list">
        <h1>
          <span>Print Labels</span>
          <br></br>
        </h1>

        <Box
          sx={{
            height: 400,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <Grid container>
            <Grid container justifyContent={"flex-end"} item xs={12} md={12}>
              
              {
                
                selectedRows?.length === 0 ?
                  <Typography sx={{ color: 'tomato', mr:2 , mt:1 }} variant="caption">
                    Please select data
                  </Typography> : null
              }
                <Button
                  onClick={() => handleExportClick()}
                  disabled={selectedRows?.length === 0}
                  variant="contained"
                  sx={{ alignContent: "flex-end", mb: 2, mr: 2 }}
                >
                  spirit template
                </Button>

                <Button
                onClick={() => handleExportClickDry()}
                  disabled={selectedRows?.length === 0}
                  variant="contained"
                  sx={{ alignContent: "flex-end", mb: 2, mr: 2 }}
                >
                  dry template
                </Button>

            </Grid>
          </Grid>
          <DataGrid
            rows={
              herbariumItem
                ? herbariumItem?.map((e, index) => {
                  const matchingCollector = allCollectors?.find((collector) => collector?._id === e.collector)
                  const collectorName = matchingCollector?.title + "\u00A0" + matchingCollector?.collectorName + "\u00A0" + matchingCollector?.collectorSurname || ""
                  return {
                    ...e,
                    id: e._id,
                    index: index + 1,
                    collector: e.collector || "-",
                    photograph: e.photograph || "-",
                    family: e.family || "-",
                    genus: e.genus || "-",
                    species: e.species || "-",
                    author1: e.author1 || "-",
                    author2: e.author2 || "-",
                    intraspecificStatus: e.intraspecificStatus || "-",
                    intraspecificName: e.intraspecificName || "-",
                    location: e.location || "-",
                    // collectorData:e?.collectorData || [],
                    province: e.province || "-",
                    district: e.district || "-",
                    longtitude: e.longtitude || "-",
                    latitude: e.latitude || "-",
                    altitude: e.altitude || "-",
                    remark: e.remark || "-",
                    determineBy: e.determineBy || "-",
                    duplicate: e.duplicate || "-",
                    collectorNumber: e.collectorNumber || "-",
                    herbariumNumber: e.herbariumNumber || "-",
                    commonName: e.commonName ||"-",
                  }
                })
                : []
            }
            columns={columns}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            initialState={{
              // ...data.initialState,
              pagination: { paginationModel: { pageSize: 50 } },
            }}
            pageSizeOptions={[50, 100]}
            rowSelection
            checkboxSelection
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={handleCheckboxChange}
            loading={loadingData}
            sx={{
              '.MuiDataGrid-columnHeaderCheckbox': {
                backgroundColor: 'rgb(67, 104, 142)',
                'svg': {
                  color: 'white'
                }
              },
              height: 2750

            }}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          <Box component="img" src={openFullImage?.img} />
        </Backdrop>
      </div>
      <Footer rootClassName="footer-root-class-name13"></Footer>
    </div>
  )
}

export default HerbariumPrint
