import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import CardCategories from '../components/card-categories'
import ResearcherCard from '../components/researcher-card'
import Footer from '../layouts/footer-layout'
import './homepage.css'
import "./plant.css"
import { request } from '../axios-config'
import { Autocomplete, TextField } from '@mui/material'

import BreadcrumbLayout from "../components/breadcrumb-layout"

const Researcher = (props) => {
  const [lecturerDatas, setLecturerDatas] = useState([])
  const [searchDatas, setSearchDatas] = useState([])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getItem = async () => {
      try {
        const response = await request.get('/lecturer', {
          signal: controller.signal
        })
        isMounted && setLecturerDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  // console.log(lecturerDatas);
  return (
    <div className="homepage-container">
      <Helmet>
        <title>Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="homepage-hero">
        <div className="homepage-container01">
          <h1 className="homepage-text">Science Natural Archive Platform</h1>
          <span className="homepage-text01">
            The Faculty of Science at Prince of Songkla University has launched the Science Natural Archive Platform, with the primary aim of gathering and sharing research samples, articles, and data related to the natural world. This initiative seeks to create a comprehensive repository of information from researchers within the Faculty, ensuring that it is accessible to the public for future reference. Through this platform, we hope to increase public access to valuable scientific information and promote collaboration among researchers to further scientific knowledge.
          </span>
        </div>
      </div>
    
      
      <div className="plant-search-tab">
        <Autocomplete
        disablePortal
        options={lecturerDatas.length!==0?lecturerDatas.map((row)=>({...row,label:`${row?.name}`})):[]}
        getOptionLabel={(option) => option.title+" "+option.name+" "+option.surname || ""}
        sx={{ width: 500 }}
        renderInput={(params => <TextField {...params} label="Search here" />)}
        onChange={(e, value) => {
          // console.log('value', value)
          if (value !== null) {  
            setSearchDatas([value])
          } else (
            setSearchDatas([])
          )
        }}
        // onChange={(event, value) => console.log(value)} 
      />
      </div>

      <div className="homepage-researcher-categories">
        <div className="homepage-container08">
          <h1 className="homepage-text08">
            <span>Researchers / Contributors</span>
            <br></br>
          </h1>
          <span className="homepage-text11">
            <span>นักวิจัยและผู้ร่วมให้ข้อมูล</span>
            <br></br>
          </span>
        </div>
      </div>
      {/* <div className="homepage-researcher"> */}
        <div className="plant-features ">
          <div className="plant-container2">
            {searchDatas.length !==0? searchDatas?.map((row, index) => (
            <ResearcherCard
              key={index}
              id={row?._id}
              index={index}
              photo={row?.pictureUrl}
              name={row?.title+" "+row?.name+" "+row?.surname}
              division={row?.major}
              email={row?.email}
              rootClassName="researcher-card-root-class-name10"
            />
            )):lecturerDatas?.map((row, index) => (
            <ResearcherCard
              key={index}
              id={row?._id}
              index={index}
              photo={row?.pictureUrl}
              name={row?.title+" "+row?.name+" "+row?.surname}
              division={row?.major}
              email={row?.email}
              rootClassName="researcher-card-root-class-name10"
            />
            ))}
          </div>
        </div>
      {/* </div> */}
      <Footer></Footer>
    </div>
  )
}

export default Researcher
