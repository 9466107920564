import React, { useEffect, useState } from "react"
import {
  ImageList,
  ImageListItem,
  Box,
  Backdrop,
  ButtonBase,
  Typography,
} from "@mui/material"
import PropTypes from "prop-types"
import "./photoDetailHerbarium.css"
import VisibilityIcon from "@mui/icons-material/Visibility"

const PhotoDetailHerbarium = (props) => {
  const [photos, setPhotos] = useState([])
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })
  const item = props.itemphoto

  useEffect(() => {
    let col = 4
    let row = 1
    const photoSet = item?.map((photo, index) => {
      switch (item.length) {
        case 4:
          col = 2
          row = 1.5
          break
        case 3:
          col = index === 0 ? 4 : 2
          row = index === 0 ? 2 : 1.3
          break
        case 2:
          col = 4
          row = 2
          break
        case 1:
          col = 4
          row = 3
          break
      }

      return { img: photo, rows: row, cols: col }
    })
    setPhotos(photoSet)
  }, [props?.itemphoto])

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    }
  }

  return (
    <>
      {item?.length > 0 ? (
        <ImageList
          sx={{
            width: 250,
            height: "auto",
            // marginTop: 5,
            marginBottom: 2,
            borderRadius: "10px",
            // boxShadow: "2px 2px  4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            "@media (max-width: 367px)": {
              width: 200,
            },
          }}
          variant="quilted"
          cols={4}
          rows={2}
          rowHeight={100}
        >
          {photos?.map((item, index) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
              component={ButtonBase}
              onClick={() => {
                setOpenFullImage({
                  open: true,
                  img: item.img,
                })
              }}
              className="image-container"
            >
              <img
                {...srcset(item.img, 0, item.rows, item.cols)}
                alt={`Photo ${index}`}
                loading="lazy"
                className="img-bg"
              />
              <div className="hover-icon">
                <VisibilityIcon />
                <Typography sx={{ fontSize: 10 }}>Show image</Typography>
              </div>
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
          }
          alt="no photo"
          loading="lazy"
          style={{
            width: "250px",
            height: "250px",
          }}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openFullImage?.open}
        onClick={() => {
          setOpenFullImage({ open: false, img: "" })
        }}
      >
        <Box
          component="img"
          src={openFullImage?.img}
          sx={{ maxWidth: "80%", maxHeight: "80%" }}
        />
      </Backdrop>
    </>
  )
}

PhotoDetailHerbarium.defaultProps = {
  itemphoto: [],
}

PhotoDetailHerbarium.propTypes = {
  itemphoto: PropTypes.array,
}

export default PhotoDetailHerbarium
