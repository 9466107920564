import React from 'react'

import PropTypes from 'prop-types'

import './footer-content.css'

const FooterContent = (props) => {
  return (
    <div className={`footer-content-container ${props.rootClassName} `}>
      <div className="footer-content-container1">
        <span className="footer-content-text">
        Research Support Unit, Faculty of Science, Prince of Songkla University
        </span>
        <span className="footer-content-text1">{props.ContactSci}</span>
        <span className="footer-content-text2">{props.callSci}</span>
        <span className="footer-content-text2">{props.callSci2}</span>
        <span className="footer-content-text3">{props.emailSci}</span>
      </div>
      <div className="footer-content-links-container">
        <a 
        href="https://www.sci.psu.ac.th/en/" 
        target="_blank" 
        onMouseOver={(e) => (e.currentTarget.style.opacity = 0.6)}
        onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}
        >
          <img
            alt={props.image_alt}
            src="/playground_assets/logosci2047-dqen-1500w.png"
            className="footer-content-image"
          />
        </a>
      </div>
    </div>
  )
}

FooterContent.defaultProps = {
  image_alt: 'logo',
  image_src: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  rootClassName: '',
  emailSci: 'Email : sci-res@psu.ac.th',
  ContactSci:
    'Faculty of Science, Prince of Songkla University, 15 Kanchanavanit Rd. Hat Yai Songkhla 90110',
  image_src1:
    'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  callSci: 'Tel : 074 28 8075 / 074 28 8079',
  callSci2: 'Internal number: 8075 / 8079 ',
}

FooterContent.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
  emailSci: PropTypes.string,
  ContactSci: PropTypes.string,
  image_src1: PropTypes.string,
  callSci: PropTypes.string,
  callSci2: PropTypes.string,
}

export default FooterContent
