import * as React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import PropTypes from "prop-types"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { Grid, Typography } from "@mui/material"

const BreadcrumbLayout = ({ pages }) => {
  // const location = useLocation()
  // console.log(location)
  return (
    <Grid container spacing={2} py={2} px={5}>
      <Grid item md={12} xs={12}>
        <Breadcrumbs>
          <Link key={"home"} to={"/"} component={RouterLink}>
            Home
          </Link>
          {pages?.map((page) =>
            page?.path ? (
              <Link key={page?.title} to={page?.path} component={RouterLink}>
                {page?.title}
              </Link>
            ) : (
              <Typography key={page?.title}>{page?.title}</Typography>
            ),
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  )
}
BreadcrumbLayout.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
}
BreadcrumbLayout.defaultProps = {
  pages: [],
}
export default BreadcrumbLayout
