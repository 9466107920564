import React from 'react'

import PropTypes from 'prop-types'


import './footer.css'
import FooterHerbariumContent from '../components/footer-herbarium-content'

const HerbariumFooter = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <FooterHerbariumContent
        rootClassName="footer-content-root-class-name"
        className=""
      ></FooterHerbariumContent>
      <div className="footer-separator"></div>
      <span className="footer-text">{props.text}</span>
    </footer>
  )
}

HerbariumFooter.defaultProps = {
  text: '© 2023 faculty of Science, All Rights Reserved.',
  rootClassName: '',
}

HerbariumFooter.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default HerbariumFooter
