import React, { Fragment, useEffect, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-lecturer.css"
import { request } from "../axios-config"
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment
} from "@mui/material"
import {
    Create as CreateIcon,
} from "@mui/icons-material"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"
import { useHistory } from "react-router-dom"


const AddStaffCollector = (props) => {

    const requestPrivate = useAxiosPrivate()
    const [item, setItem] = useState({})
    const history = useHistory()
    const [filePhoto, setFilePhoto] = useState({ data: null, base64: "" })


    const ClearData = () => {
        setItem({
            ...item,
            collectorName: "",
            collectorSurname: "",
            title: "",
            phoneNumber: "",
            email: "",
            afflication: "",
            designation: "",
        })
    }

    const AddItem = async () => {
        try {
            if (!item?.collectorName) {
                alert("Collector Name is required")
            } else {
                const responseUrlPhoto = await request.post("/uploadstaff", {
                    photo: { dataphoto: filePhoto?.base64, type: "base64" },
                })
                const response = await requestPrivate.post("/collector", {
                    ...item,
                    photo: responseUrlPhoto?.data?.url,
                })
                if (response) {
                    alert("success")
                    history.push("/staff-collector-view")
                }
            }
        } catch (e) {
            console.error(e)
        }

        // console.log(response);
    }

    const selectPhotoFile = (e) =>
        new Promise((resolve, reject) => {
            e.preventDefault()
            const file = e.currentTarget.files[0]
            if (file.size > 2000000) {
                alert("กรุณาใส่รูปที่มีขนาดไม่เกิน 2 mb");
                e.target.value = null; // clear the input field
                return;
            } else {
                setFilePhoto({ data: file })
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    resolve(reader.result)
                    setFilePhoto({ base64: reader.result })
                }
                reader.onerror = (error) => reject(error)
            }

        })
    // console.log("references", references)

    return (
        <Fragment>
            <Helmet>
                <title>Add Staff - Sci PSU Archive</title>
                <meta
                    name="description"
                    content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                />
                <meta property="og:title" content="AddLecturer - Sci PSU Archive" />
                <meta
                    property="og:description"
                    content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                />
            </Helmet>
            <Header rootClassName="header-root-class-name3" auth={true}></Header>
            <BreadcrumbLayout
                pages={[
                    { title: "Admin Menu", path: `/adminmenu` },
                    { title: "Staff List", path: `/staff-collector-view` },
                    { title: "Add Staff" },
                ]}
            />
            <Container sx={{ mt: 5 }} maxWidth="xl">
                <Grid sx={{ mb: 2 }}>
                    <Typography variant="h4">Staff</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        alignItems={"center"}
                        direction={"column"}
                        spacing={1}
                        sx={{ mb: "10px", mt: "5px", mb: "20px" }}
                    >
                        <label
                            style={{ position: "relative" }}
                            htmlFor="photo-project-file"
                        >
                            <Box
                                component={"img"}
                                zIndex={1}
                                variant="square"
                                size="lg"
                                src={filePhoto?.base64}
                                alt="กรุณาเลือกรูปภาพ"
                                sx={{
                                    objectFit: "cover",
                                    width: "200px",
                                    height: "200px",
                                    border: "1px dashed grey",
                                    "&:hover": {
                                        backgroundColor: "#14A9FF",
                                        opacity: [0.9, 0.8, 0.7],
                                        cursor: "pointer",
                                    },
                                }}
                            />
                            <IconButton
                                sx={{
                                    backgroundColor: "white",
                                    position: "absolute",
                                    right: "4px",
                                    bottom: "5px",
                                    zIndex: -1,
                                }}
                                color={"primary"}
                            >
                                <CreateIcon />
                            </IconButton>
                            <input
                                onChange={selectPhotoFile}
                                accept="image/*"
                                id="photo-project-file"
                                type="file"
                                style={{ display: "none" }}

                            />
                        </label>
                        <p
                            style={{ color: "#999999", marginTop: "10px" }}>
                            รูปภาพขนาด 600x800 px. (3:4) ขนาดไม่เกิน 2 mb.
                        </p>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Title <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="title"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.title : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        title: e.target.value,
                                    })
                                }}
                                error={ item && item?.title === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.title === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Name <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="collectorName"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.collectorName : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        collectorName: e.target.value,
                                    })
                                }} 
                                error={ item && item?.collectorName === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.collectorName === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Surname <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="collectorSurname"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.collectorSurname : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        collectorSurname: e.target.value,
                                    })
                                }}
                                error={ item && item?.collectorSurname === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.collectorSurname === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Email <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="email"
                                type="email"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.email : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        email: e.target.value,
                                    })
                                }}
                                error={ item && item?.email === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.email === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Mobile Number</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="phoneNumber"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.phoneNumber : ""}
                                onChange={(e) => {
                                    const input = e.target.value.replace(/[^0-9]/g, "");
                                    setItem({
                                        ...item,
                                        phoneNumber: input,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Affiliation</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="title"
                                multiline
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.afflication : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        afflication: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Designation</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="title"
                                multiline
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.designation : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        designation: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid
                            container
                            direction={"row"}
                            display={"flex"}
                            alignItems={"flex-end"}
                            justifyContent={"flex-end"}
                        // spacing={2}
                        >
                            <Button
                                disabled={
                                    item?.collectorName === undefined || item?.collectorName === "" ||
                                    item?.collectorSurname === undefined || item?.collectorSurname === "" ||
                                    item?.title === undefined || item?.title === "" ||
                                    item?.email === undefined || item?.email === ""
                                }
                                variant="contained"
                                onClick={AddItem}
                            >
                                Submit
                            </Button>
                            <Button variant="text" onClick={ClearData}>
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <Footer rootClassName="footer-root-class-name2"></Footer>
        </Fragment>
    )
}

export default AddStaffCollector
