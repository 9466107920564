import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./archiveview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { Box } from "@mui/system"
import {
    Backdrop,
    Button,
    ButtonBase,
    Grid,
    IconButton,
    Typography,
    TextField,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Delete , Search} from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"
import * as XLSX from "xlsx"

const Plantview = (props) => {
    // const [me, setMe] = useState({})
    const requestPrivate = useAxiosPrivate()
    const [loadingData, setLoadingData] = useState(false);

    // useEffect(() => {
    //     let isMounted = true
    //     const controller = new AbortController()

    //     const getMe = async () => {
    //         try {
    //             const response = await requestPrivate.get("/me", {
    //                 signal: controller.signal,
    //             })
    //             isMounted && setMe(response?.data)
    //         } catch (error) {
    //             history.push("./login")
    //             console.error(error)
    //         }
    //     }
    //     getMe()
    //     return () => {
    //         isMounted = false
    //         controller.abort()
    //     }
    // }, [])
    // console.log(me)
    const history = useHistory()

    const [searchDatas, setSearchDatas] = useState([])
    const [allDatas, setAllDatas] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })

    const exportExcel = async () => {
        const PlantData = searchDatas
            .filter((options) =>
                options?.category === "PLANT_ANGIOSPERM" ||
                options?.category === "PLANT_GYMNOSPERM" ||
                options?.category === "PLANT_LYCOPHYTEANDFERNS" ||
                options?.category === "PLANT_BRYOPHYTES" ||
                options?.category === "PLANT_ALGAEANDPHYTOPLANKTON"
            )
            .map(async (row) => {
                const lecturerData = await fetchLecturerData(row?.lecturer);

                return {
                    family: row?.family || "-",
                    genericName: row?.genericName || "-",
                    specificEpithet: row?.specificEpithet || "-",
                    authorName1: row?.authorName1 || "-",
                    infraspecificLevel: row?.infraspecificLevel || "-",
                    infraspecificName: row?.infraspecificName || "-",
                    commonName: row?.commonName || "-",
                    vercularName: row?.vercularName || "-",
                    tradingName: row?.tradingName || "-",
                    locality: row?.locality || "-",
                    ecologyAndHabitat: row?.ecologyAndHabitat || "-",
                    utilization: row?.utilization || "-",
                    category: row?.category || "-",
                    lecturer: lecturerData?.map((lecturer) => lecturer.title + " " + lecturer.name + " " + lecturer.surname).join(", ") || "-",
                    link: row?.references?.[0].link || "-",
                    year: row?.references?.[0].year || "-",
                    type: row?.references?.[0].type || "-",
                    title: row?.references?.[0].title || "-",
                }
            });

        const resolvedPlantData = await Promise.all(PlantData);

        const PlantSheet = XLSX.utils.json_to_sheet(resolvedPlantData);

        const PlantHeader = [
            "Family",
            "Generic Name",
            "Specific Epithet",
            "Author Name",
            "Infraspecific Level",
            "Infraspecific Name",
            "Common Name",
            "Vernacular Name",
            "Trading Name",
            "Locality",
            "Ecology & Habitat",
            "Utilization",
            "Category",
            "Researcher",
            "Link",
            "Year",
            "Type",
            "Title",
        ];
        const aoa = [
            PlantHeader.map((val) => ({ v: val, s: { font: { bold: true } } })),
        ];
        XLSX.utils.sheet_add_aoa(PlantSheet, aoa);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, PlantSheet, "Plant");
        XLSX.writeFile(workbook, `Plant.xlsx`);
    };

    const fetchLecturerData = async (lecturerIds) => {
        const lecturerPromises = lecturerIds.map(async (lecturerId) => {
            try {
                const response = await request.get(`/lecturer/${lecturerId}`);
                return response?.data || {};
            } catch (error) {
                console.error(error);
                return {};
            }
        });

        return Promise.all(lecturerPromises);
    };

    const columns = [
        {
            field: "index",
            headerName: "#",
            headerClassName: "super-app-theme--header",
            width: 50,
            flex: 0,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "photo",
            headerName: "Photo",
            headerClassName: "super-app-theme--header",
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return params?.row?.photo.length > 0 ? (
                <ButtonBase
                  onClick={() => {
                    setOpenFullImage({
                      open: true,
                      img: params.row.photo[0],
                    })
                  }}
                >
                  <Box
                    component="img"
                    sx={{ width: "40px", height: "40px" }}
                    src={params.row.photo[0]}
                  />
                </ButtonBase>
              ) : (
                <Typography>no photo</Typography>
              )
        },
        },
        {
            field: "family",
            headerName: "Family",
            headerClassName: "super-app-theme--header",
            width: 150,
        },
        {
            field: "genericName",
            headerName: "Generic Name",
            headerClassName: "super-app-theme--header",
            width: 150,
        },
        {
            field: "specificEpithet",
            headerName: "Specific Epithet",
            headerClassName: "super-app-theme--header",
            width: 150,
        },
        {
            field: "authorName1",
            headerName: "Author Name",
            headerClassName: "super-app-theme--header",
            width: 150,
        },

        {
            field: "infraspecificLevel",
            headerName: "Infraspecific Level",
            headerClassName: "super-app-theme--header",
            width: 150,
        },
        {
            field: "infraspecificName",
            headerName: "Infraspecific Name",
            headerClassName: "super-app-theme--header",
            width: 150,
        },

        {
            field: "commonName",
            headerName: "Common Name",
            headerClassName: "super-app-theme--header",
            width: 150,
        },

        {
            field: "vercularName",
            headerName: "Vernacular Name",
            headerClassName: "super-app-theme--header",
            width: 150,
        },

        {
            field: "tradingName",
            headerName: "Trading Name",
            headerClassName: "super-app-theme--header",
            width: 150,
        },

        {
            field: "locality",
            headerName: "Locality",
            headerClassName: "super-app-theme--header",
            width: 250,
        },

        {
            field: "ecologyAndHabitat",
            headerName: "Ecology & Habitat",
            headerClassName: "super-app-theme--header",
            width: 250,
        },

        {
            field: "utilization",
            headerName: "Utilization",
            headerClassName: "super-app-theme--header",
            width: 150,
        },

        {
            field: "category",
            headerName: "Category",
            headerClassName: "super-app-theme--header",
            width: 250,
        },

        // {
        //   field: "dateDetermine",
        //   headerName: "dateDetermine",
        //   headerClassName: "super-app-theme--header",
        //   width: 150,
        //   renderCell: (params) =>
        //     params?.row?.dateDetermine ? (
        //       <Typography>
        //         {dayjs(params?.row?.dateDetermine).format("DD/MM/YYYY")}
        //       </Typography>
        //     ) : (
        //       <Typography>{console.log(params?.row)}no Data</Typography>
        //     ),
        // },

        {
            field: "edit",
            headerName: "Edit",
            headerClassName: "super-app-theme--header",
            width: 100,
            flex: 0,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        history.push(`/edit-plant/${params?.row?._id}`)
                    }}
                >
                    Edit
                </Button>
            ),
        },
        {
            field: "delete",
            headerName: "จัดการ",
            headerClassName: "super-app-theme--header",
            width: 100,
            flex: 0,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                // <Button
                //   onClick={() => {
                //     RemoveArchive(params?.row?._id)
                //   }}
                // >
                //   Remove
                // </Button>
                <IconButton
                    onClick={() => {
                        RemoveArchive(params?.row?._id, params?.row?.photo)
                    }}
                >
                    <Delete />
                </IconButton>
            ),
        },
    ]

    useEffect(() => {
      let isMounted = true
      const controller = new AbortController()

      const search = async () => {
        try {
          setLoadingData(true)
          const response = await request.post(
            "/items",
            {
              filter: {
                category: "PLANT",
              },
            },
            {
              signal: controller.signal,
            },
          )
          isMounted && setSearchDatas(response?.data)
          if (response) {
            setAllDatas(response?.data)
            setRefetch(false)
          }
          setLoadingData(false)
        } catch (error) {
          console.error(error)
        }
      }
      search()
      return () => {
        isMounted = false
        controller.abort()
      }
    }, [refetch])
    // console.log(searchDatas)

    // what's API?

    const RemoveArchive = async (id, urls) => {
        try {
            const confirmed = confirm("Are you sure you want to delete this item?")
            if (confirmed) {
                const response = await requestPrivate.delete(`/itemremove/${id}`, {})
                if (response) {
                     if (urls.length > 0) {
                       const fileNames = urls.map((url) => url.split("/").pop())
                       const deleteOldPhoto = await request.delete(`/deletephoto/${fileNames}`, {})
                     }
                }
                setRefetch(true)
                alert("success")
            }
        } catch (error) {
            alert(`${error?.data?.message}`)
        }
    }

    const handleFormatTextSearch = async (e) => {

      const textInput = e.target.value
      const [genericName, specificEpithet] = textInput.split(" ")

      if (textInput === "") {
        setSearchDatas(allDatas)
      } else {
        const SearchItem = async () => {
          try {
            setLoadingData(true)
            const response = await requestPrivate.post(
              `/searchName`,
              {
                filter: {
                  category: "PLANT",
                },
                search: {
                  genericName: genericName || "",
                  specificEpithet: specificEpithet || "",
                },
              },
            )

            if (response.status === 200) {
              setSearchDatas(response.data)
            }
            setLoadingData(false)
          } catch (error) {
            console.error("An error occurred:", error)
            alert("An error occurred while searching. Please try again later.")
          }
        }
        SearchItem()
      }
    }

    return (
      <div className="archiveview-container">
        <Helmet>
          <title>Plantview - Sci PSU Archive</title>
          <meta
            name="description"
            content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
          />
          <meta property="og:title" content="Plantview - Sci PSU Archive" />
          <meta
            property="og:description"
            content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
          />
        </Helmet>
        <Header rootClassName="header-root-class-name6" auth={true}></Header>
        <BreadcrumbLayout
          pages={[
            { title: "Admin Menu", path: `/adminmenu` },
            { title: "Manage Archive", path: `/archiveview` },
            { title: "Plant Lists" },
          ]}
        />
        <div className="archiveview-archive-list">
          <h1>
            <span>Plant Lists</span>
            <br></br>
          </h1>
          <Box
            sx={{
              height: 400,
              width: 1,
              "& .super-app-theme--header": {
                backgroundColor: "primary.light",
                color: "white",
              },
              marginBottom: "100px",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "20px",
              }}
            >
              <TextField
                autoComplete="false"
                label={
                  <Grid container direction={"row"}>
                    <Grid item sx={{mr: 0.5}}> <Search /></Grid>
                   
                    <Grid item>Search</Grid>
                  </Grid>
                }
                id="Search"
                sx={{ width: "500px" }}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton onClick={()=>SearchItem()}>
                //         <Search />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
                onChange={handleFormatTextSearch}
              />
            </Grid>
            <Grid container>
              <Grid container justifyContent={"flex-end"} item xs={12} md={12} sx={{ mt:1 }}>
                <Button
                  onClick={() => exportExcel()}
                  variant="contained"
                  sx={{ alignContent: "flex-end", mb: 2, mr: 2 }}
                >
                  Excel
                </Button>
                <Button
                  component={Link}
                  to="/add-plant-items"
                  variant="contained"
                  sx={{ alignContent: "flex-end", mb: 2 }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={
                searchDatas
                  ? searchDatas
                      ?.filter(
                        (data) =>
                          data.category === "PLANT_ANGIOSPERM" ||
                          data.category === "PLANT_GYMNOSPERM" ||
                          data.category === "PLANT_LYCOPHYTEANDFERNS" ||
                          data.category === "PLANT_BRYOPHYTES" ||
                          data.category === "PLANT_ALGAEANDPHYTOPLANKTON",
                      )
                      .map((e, index) => ({
                        ...e,
                        id: e._id,
                        index: index + 1,
                        family: e?.family || "-",
                        genericName: e?.genericName || "-",
                        specificEpithet: e?.specificEpithet || "-",
                        authorName1: e?.authorName1 || "-",
                        infraspecificLevel: e?.infraspecificLevel || "-",
                        infraspecificName: e?.infraspecificName || "-",
                        commonName: e?.commonName || "-",
                        vercularName: e?.vercularName || "-",
                        tradingName: e?.tradingName || "-",
                        locality: e?.locality || "-",
                        ecologyAndHabitat: e?.ecologyAndHabitat || "-",
                        utilization: e?.utilization || "-",
                      }))
                  : []
              }
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              loading={loadingData}
            />
          </Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openFullImage?.open}
            onClick={() => {
              setOpenFullImage({ open: false, img: "" })
            }}
          >
            {" "}
            <Box
              component="img"
              // sx={{ width: "40px", height: "40px" }}
              src={openFullImage?.img}
            />
          </Backdrop>
          {/* <Link to="/add-archive-items" className="archiveview-navlink button">
          Add
        </Link>
        <div className="archiveview-container01">
          <div className="archiveview-lecturer-list">
            {searchDatas?.map((row, index) => (
              <div className="archiveview-container02">
                <img
                  alt="image"
                  src={row?.photo}
                  className="archiveview-image"
                />
                <div className="archiveview-container03">
                  <span className="archiveview-text03">
                    {row?.scientificName}
                  </span>
                </div>
                <div className="archiveview-container04">
                  <span className="archiveview-text04">{row?.commonName}</span>
                </div>
                <div className="archiveview-container05">
                  <span className="archiveview-text05">
                    {row?.vercularName}
                  </span>
                </div>
                <div className="archiveview-container05">
                  <span className="archiveview-text05">
                    {row?.category}
                  </span>
                </div>
                <div className="archiveview-container08">
                  <button
                    className="archiveview-button button"
                    onClick={() => {
                      history.push(`/edit-archive/${row?._id}`)
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="archiveview-button1 button"
                    onClick={() => {
                      RemoveArchive(row?._id)
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        </div>
        <Footer rootClassName="footer-root-class-name5"></Footer>
      </div>
    )
}

export default Plantview