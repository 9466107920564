import {
  Box,
  Typography,
  Stack,
  useMediaQuery,
  BottomNavigation,
  Divider,
  Grid,
} from "@mui/material"
// import PropTypes from "prop-types"

const FooterHerbarium = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  return (
    <BottomNavigation sx={{ backgroundColor: "#11AC8E", height: "100%" }}>
      <Grid container sx={{ p: { xs: 6 , sm: 8, md: 10 }, pb: { xs: 5 , sm: 5, md: 5 }, pt: { xs: 8 , sm: 8, md: 8 } }}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography
              color="#FFFFFF"
              sx={{
                fontSize: isMobile ? 22.5 : 32,
                fontWeight: "bold",
                fontFamily: "SUKHUMVIT SET",
                fontFamily: "Prompt",
              }}
            >
              Prince of Songkla University
            </Typography>
            <Typography
              color="#FFFFFF"
              sx={{
                fontSize: isMobile ? 15 : 16,
                fontFamily: "SUKHUMVIT SET",
                fontFamily: "Prompt",
              }}
            >
              PSU Herbarium, Department of Biology
            </Typography>
            <Typography
              color="#FFFFFF"
              sx={{
                fontSize: isMobile ? 15 : 16,
                fontFamily: "SUKHUMVIT SET",
                fontFamily: "Prompt",
              }}
            >
              Faculty of Science
            </Typography>
            <Typography
              color="#FFFFFF"
              sx={{
                fontSize: isMobile ? 15 : 16,
                fontFamily: "SUKHUMVIT SET",
                fontFamily: "Prompt",
              }}
            >
              Prince of Songkla University
            </Typography>
            <Typography
              color="#FFFFFF"
              sx={{
                fontSize: isMobile ? 15 : 16,
                fontFamily: "SUKHUMVIT SET",
                fontFamily: "Prompt",
              }}
            >
              15 Karnjanavanich Rd., Hat Yai. Songkhla 90110 THAILAND
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            paddingTop: 3,
            "@media (max-width: 900px)": {
              justifyContent: "center",
            },
          }}
        >
        <a 
          href="https://www.sci.psu.ac.th/en/" 
          target="_blank" 
          onMouseOver={(e) => (e.currentTarget.style.opacity = 0.6)}
          onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}
        >
          <img
            src="/playground_assets/herbarium/logo_PSU.png"
            style={{
              width: isMobile ? 200 : 291,
              height: isMobile ? 45 : 65,
            }}
            alt="Logo"
          />
        </a>
        </Grid>
        <Grid item xs={12} md={12} sx={{ marginTop: 3, marginBottom: 3 }}>
          <Divider sx={{ backgroundColor: "white" }} />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            sx={{
              fontFamily: "Prompt",
              color: "white",
              fontSize: isMobile ? 15 : 16,
              textAlign: "center",
            }}
          >
            © 2023 Faculty of Science, All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </BottomNavigation>
  )
}
// FooterHerbarium.defaultProps = {
//   image_src:
//     "https://www.sci.psu.ac.th/wp-content/uploads/2021/02/Logo_Subbrand_Faculty-of-Science-Full_EN_Artboard-b.svg",
// }

// FooterHerbarium.propTypes = {
//   image_src: PropTypes.string,
// }
export default FooterHerbarium
