import React from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import './desktop-nav.css'

const DesktopNav = (props) => {
  return (
    <nav className={`desktop-nav-nav ${props.rootClassName} `}>
      <NavLink to="/" className="desktop-nav-navlink" exact={true}>
        <i aria-hidden="true"></i>
        {props.text}
      </NavLink>
      <NavLink to="/plant" className="desktop-nav-navlink1">
        {props.text1}
      </NavLink>
      <NavLink to="/animal" className="desktop-nav-navlink2">
        {props.text2}
      </NavLink>
      <NavLink to="/microbe" className="desktop-nav-navlink3">
        {props.text3}
      </NavLink>
      <NavLink to="/geo" className="desktop-nav-navlink4">
        {props.text31}
      </NavLink>
      
      <NavLink to="/herbarium-home" target="_blank" className="desktop-nav-navlink4">
        {props.text5}
      </NavLink>
      <NavLink to="/contact-archive" className="desktop-nav-link">
        {props.text4}
      </NavLink>
      
    </nav>
  )
}

DesktopNav.defaultProps = {
  text3: 'Microbe',
  text1: 'Plant',
  rootClassName: '',
  text31: 'Geoscience',
  text2: 'Animal',
  text4: 'Contact',
  text: 'Home',
}

DesktopNav.propTypes = {
  text3: PropTypes.string,
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  text31: PropTypes.string,
  text2: PropTypes.string,
  text4: PropTypes.string,
  text: PropTypes.string,
}

export default DesktopNav
