import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import ScientificNameheader from '../components/scientific-nameheader'
import DetailsCard from '../components/details-card'
import ResearcherCard from '../components/researcher-card'
import Footer from '../layouts/footer-layout'
import './detail.css'
import { useParams } from 'react-router-dom'
import { request } from '../axios-config'

import BreadcrumbLayout from "../components/breadcrumb-layout"
import PhotoDetail from "../components/photoDetail"
import ShowCountViewsByItem from "../components/show-countviews"

const DetailGeo = (props) => {
  const params = useParams()
  const [itemDatas, setItemDatas] = useState({})
  const [namePath, setNamePath] = useState('')

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getItem = async () => {
      try {
        const response = await request.get(`/geoItem/${params?.id}`, {
          signal: controller.signal
        })
        isMounted && setItemDatas(response?.data)
        const path = `${response?.data?.category}`.substring(0, `${response?.data?.category}`.indexOf("_"))
        const pathtoLower = path.toLocaleLowerCase()
        setNamePath(pathtoLower)
      } catch (error) {
        console.error(error)
      }
    }
    getItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  // console.log("itemdatas", itemDatas);
  // console.log(namePath);

  const [lecturerDatas, setLecturerDatas] = useState([])


  return (
    <div className="detail-container">
      <Helmet>
        <title>Detail - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Detail - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name8"></Header>
      <BreadcrumbLayout
        pages={[
          { title: `Geo`, path: `/geo` },
          { title: `${itemDatas?.dataGroup + "\u00A0" + itemDatas?.type}` },
        ]}
      />
      <div className="detail-research-content">
        <h1 className="scientific-nameheader-text" style={{ marginBottom: 30 }}>
          {itemDatas?.dataGroup + "\u00A0" + itemDatas?.type}
        </h1>
        <div className="detail-separator"></div>
        <ShowCountViewsByItem itemId={params?.id} nameModel={"GeoModel"} />
        <div className="detail-container1">
          <div className="detail-container2">
            <PhotoDetail
              itemphoto={itemDatas?.photograph}
            />
            {/* <a
              href={itemDatas?.references?.[0].link}
              target="_blank"
              rel="noreferrer noopener"
              className="detail-link button"
            >
              Reference Article
            </a> */}
          </div>
          <div className="detail-container3">
            {/* <DetailsCard
              title={"Title"}
              //description={itemDatas?.scientificName}
              rootClassName="rootClassName1"
            ></DetailsCard> */}
            <DetailsCard
              title={"Acquistion Date"}
              description={new Date(itemDatas?.date).toLocaleString("en-US", {
                timeZone: "Asia/Bangkok",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              rootClassName="details-card-root-class-name5"
            ></DetailsCard>

            <DetailsCard
                title={"Location of sample/data"}
                description={"Location : "+itemDatas?.location + "\n" +"Province : " +itemDatas?.province + "\n" +"District : " +itemDatas?.district}
                rootClassName="details-card-root-class-name5"
            ></DetailsCard>

            <DetailsCard
              title={"Data Group"}
              description={itemDatas?.dataGroup}
              rootClassName="rootClassName2"
            ></DetailsCard>

            
            

            <DetailsCard
              title={"Description"}
              description={itemDatas?.description}
              rootClassName="details-card-root-class-name5"
            ></DetailsCard>
              
            <DetailsCard
              title={"Submission Date"}
              description={new Date(itemDatas?.createdAt).toLocaleString(
                "en-US",
                {
                  timeZone: "Asia/Bangkok",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                },
              )}
              rootClassName="rootClassName3"
            ></DetailsCard>
            
            {/* <DetailsCard
              title={"District"}
              description={itemDatas?.district}
              rootClassName="details-card-root-class-name5"
            ></DetailsCard> */}
            {/* <DetailsCard
              title={"Province"}
              description={itemDatas?.province}
              rootClassName="details-card-root-class-name5"
            ></DetailsCard> */}
            {/* <DetailsCard
              title={"Alittude"}
              description={itemDatas?.altitude}
              rootClassName="rootClassName4"
            ></DetailsCard> */}

            {/* <DetailsCard
              title={"Longtitude"}
              description={itemDatas?.longtitude}
              rootClassName="details-card-root-class-name5"
            ></DetailsCard> */}
            {/* <DetailsCard
              title={"Photograph"}
              description={itemDatas?.photograph}
              rootClassName="details-card-root-class-name5"></DetailsCard> */}

            {/* <DetailsCard
            title={"Propagation"}
            description={itemDatas?.scientificName?.full}
            rootClassName="details-card-root-class-name6"></DetailsCard> */}
            {/*<DetailsCard
            title={"Optimum temperature"}
            description={itemDatas?.optimumTemperature}
            rootClassName="details-card-root-class-name7"></DetailsCard>*/}
          </div>
        </div>
      </div>

      <div>
        <h2 class="mb-2 text-lg flex flex-wrap items-center justify-center font-semibold text-gray-900 ">More References</h2>
        <ul class="container mx-auto p-6 flex flex-1 items-stretch mb-6 text-blue-900 ">
          {itemDatas?.references?.map((row, index) => (
            <li>
              <div class="max-w-sm rounded overflow-hidden shadow-lg h-full">
                <div class="px-6 py-4">

                  <div class="font-bold text-xl mb-2">{row?.title}</div>

                  <p class="text-gray-700 text-base">
                    {row?.type}, {row?.year}
                  </p>
                </div>

                <div class="px-6 pt-4 pb-2">

                  {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span> */}


                  <a href="#" class="mr-4 hover:underline flex flex-wrap items-center justify-center md:mr-6 ">
                    <a
                      href={row?.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="detail-link2 button"
                    >
                      Reference Article

                    </a>
                  </a>

                </div>

              </div>

            </li>
          ))}


        </ul>

      </div>

      <div className="detail-banner">
        <h2 className="detail-text">
          <br></br>
          <span>Affiliated Researcher</span>
          <br></br>
          <span>Faculty of Science, PSU</span>
          <br className="detail-text3"></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h2>
      </div>
      <div className="detail-researcher">
        <div className="detail-container4">
          {itemDatas?.lecturerData?.map((row) => (
            <ResearcherCard
              id={row?._id}
              photo={row.pictureUrl}
              name={row?.title + " " + row?.name + " " + row?.surname}
              division={row?.major}
              email={row?.email}
              rootClassName="researcher-card-root-class-name"
            />
          ))}

        </div>

      </div>
      <Footer rootClassName="footer-root-class-name7"></Footer>
    </div>
  )
}

export default DetailGeo