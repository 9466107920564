import React from "react"
import "./contact-archive.css"
import Header from "../layouts/header-layout"
import { Helmet } from "react-helmet"
import Footer from "../layouts/footer-layout"
import { Box, Grid, Container, Button } from "@mui/material"
import { Email, LocationOn, Phone, Language } from "@mui/icons-material"

const ContactArchive = () => {
  const stylesCradContainer = {
    width: "100%",
    height: "100%",
    boxShadow: "1px 1px 1px  rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  const styleContainerBody = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
  }

  return (
    <div className="contact-archive-container">
      <Helmet>
        <title>Contact Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Contact Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>

      <Container
        sx={{
          maxWidth: "100%",
          margin: 10,
          marginBottom: "50px",
          marginRight: 0,
          marginLeft: 0,
          "@media(max-width: 767px)": {
            padding: 0,
          },
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          spacing={1}
          paddingLeft={{ xs: 2 }}
          paddingRight={{ xs: 2 }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                color: "#144372",
              }}
            >
              Contact Us
            </span>
          </Grid>

          <Grid item xs={12} md={12}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15840.046633859118!2d100.4972365!3d7.0079095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304d29a53bd618d3%3A0x9278c7039c122648!2z4LiE4LiT4Liw4Lin4Li04LiX4Lii4Liy4Lio4Liy4Liq4LiV4Lij4LmMIOC4oeC4q-C4suC4p-C4tOC4l-C4ouC4suC4peC4seC4ouC4quC4h-C4guC4peC4suC4meC4hOC4o-C4tOC4meC4l-C4o-C5jA!5e0!3m2!1sth!2sth!4v1693979464764!5m2!1sth!2sth"
              width="100%"
              height="350"
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            ></iframe>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ ...stylesCradContainer }}>
              <Box sx={{ ...styleContainerBody }}>
                <LocationOn sx={{ fontSize: "40px", color: "#144372" }} />
                <Box sx={{ color: "#144372", textAlign: "center" }}>
                  Faculty of Science, Prince of Songkla University, 15
                  Kanchanavanit Rd. Hat Yai Songkhla 90110
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ ...stylesCradContainer }}>
              <Box sx={{ ...styleContainerBody }}>
                <Box>
                  <Language sx={{ fontSize: "40px", color: "#144372" }} />
                </Box>

                <Button
                  onClick={() => {
                    window.open(`https://research.sci.psu.ac.th/index.php/th/`)
                  }}
                >
                  research.sci.psu.ac.th
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ ...stylesCradContainer }}>
              <Box sx={{ ...styleContainerBody }}>
                <Box>
                  <Email sx={{ fontSize: "40px", color: "#144372" }} />
                </Box>

                <Button
                  onClick={() => {
                    window.open(`mailto:sci-res@psu.ac.th`)
                  }}
                >
                  sci-res@psu.ac.th
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ ...stylesCradContainer }}>
              <Box sx={{ ...styleContainerBody }}>
                <Box>
                  <Phone sx={{ fontSize: "40px", color: "#144372" }} />
                </Box>
                <Box sx={{ color: "#144372" }}>
                  <Button
                    onClick={() => {
                      window.open(`tel:074 28 8075`)
                    }}
                  >
                    074 28 8075
                  </Button>
                  /
                  <Button
                    onClick={() => {
                      window.open(`tel:074 28 8079`)
                    }}
                  >
                    074 28 8079
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default ContactArchive
