import React, { useContext, useMemo } from "react"
import PropTypes from "prop-types"
import jsPDF from "jspdf"
import dayjs from "dayjs";
import { generateQRCode } from "./gen-qr";
import spiritHtmlForm from "./html-form-spirit";
import html2canvas from "html2canvas";
import { PrintPlugin } from "./print-html";
import dryHtmlForm from "./html-form-dry";

// const htmlStringToPdf = async (htmlString) => {
//   let iframe = document.createElement("iframe");
//   iframe.style.visibility = "hidden";
//   document.body.appendChild(iframe);
//   let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
//   iframedoc.body.innerHTML = htmlString;
  
//   let canvas = await html2canvas(iframedoc.body, {});
  
//   // Convert the iframe into a PNG image using canvas.
//   let imgData = canvas.toDataURL("image/png");

//   // Create a PDF document and add the image as a page.
//   const doc = new  jsPDF("p", "pt", "a4");
//   doc.addImage(imgData, "PNG", 0, 0, 210, 297);

//   // Get the file as blob output.
//   let blob = doc.output("blob");
//   doc.save(`tttttt.pdf`);

//   // Remove the iframe from the document when the file is generated.
//   document.body.removeChild(iframe);
// };

const exportDry = async({selectData}) => {
  try {

    if (!selectData) {
      console.log(`Item not found.`);
      return;
    }
    const htmlText = await dryHtmlForm({data:selectData})
    PrintPlugin.print(htmlText)
    // let pdfBlobOutput = await htmlStringToPdf(htmlText);
    // console.log('html',htmlText);
    // const pdf = new jsPDF("p", "px", "a4");;
    // const cardWidth = 180;
    // const cardHeight = 70;
    // // const columnWidth = cardWidth / 3;
    // const columnWidth = 70

    // // pdf.setFont('helvetica');
    // // pdf.setFontSize(9);
    // // pdf.html(htmlText)
    // pdf.html(htmlText, 10, 10, { width: 190 },
    //   {
    //   callback: async (doc) => {
    //     // window.open(pdf.output('bloburl'));
    //     doc.save(`tttttt.pdf`);
    //     console.log('Success');
    //   },
    //   x: 0, y: 0,
    //   autoPaging: 'text'
    // }
    // );
  //   selectData?.map(async (item) => {
  //     const CollectorsData =  item?.collectorData?.map((collectorRow)=>{
  //       return(  `${collectorRow?.title} ${collectorRow?.collectorName} ${collectorRow?.collectorSurname}`)
  //     }).join(', ')
  //   // First Column
  //   pdf.rect(10, 10, columnWidth, cardHeight); // Draw a rectangle for the first column
  //   pdf.text(`Province: ${item?.province}`, 15, 20);
  //   pdf.text(`Longitude: ${item?.longtitude}`, 15, 30);
  //   pdf.text(`Altitude: ${item?.altitude}`, 15, 40);
  //   pdf.text(`Date Determine: ${dayjs(item?.dateDetermine).format("MMMM/YYYY")}`, 15, 50)
  //   pdf.text(`Plant ID: ${item?.plantId}`, 15, 60);
  //   pdf.text(`Collector: ${CollectorsData}`, 15, 70);

  //   // Second Column
  //   pdf.rect(10 + columnWidth, 10, columnWidth, cardHeight); // Draw a rectangle for the second column
  //   pdf.text(`District: ${item?.district}`, 15 + columnWidth, 20);
  //   pdf.text(`Latitude: ${item?.latitude}`, 15 + columnWidth, 30);
  //   pdf.text(`Determined by: ${item?.determineBy}`, 15 + columnWidth, 40);
  //   pdf.text(`Duplicate: ${item?.duplicate}`, 15 + columnWidth, 50);
  //   pdf.text(`Herbarium Number: ${item?.herbariumNumber}`, 15 + columnWidth, 60);
  //   pdf.text(`Date: ${dayjs(item?.date).format("DD/MM/YYYY")}`, 15 + columnWidth, 70);

  //   // Third Column (QR Code)
  //   pdf.rect(10 + 2 * columnWidth, 10, 50, cardHeight); // Draw a rectangle for the third column
  //   const qrCodeText = `${process.env.REACT_APP_URL_PUBLIC}/herbarium-detail/${item?.id}`; // replace with your desired content
  //   const qrCodeDataUrl = await generateQRCode(qrCodeText);
  //   if (qrCodeDataUrl) {
  //     const qrCodeWidth = 40;
  //     const qrCodeHeight = 40;
  //     const qrCodeX = 10 + 2 * 65 + (columnWidth - qrCodeWidth) / 2;
  //     const qrCodeY = 25;
  //     pdf.addImage(qrCodeDataUrl, 'PNG', qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);
  //   }

  // })
  // pdf.save(`Herbarium-Label-${dayjs(Date.now()).format('DDMMYYYY-HHmmss')}.pdf`);
} catch (error) {
  console.log(`Error retrieving collector details: ${error}`);
}
}
exportDry.propTypes = {
  selectData: PropTypes.arrayOf(),
}
exportDry.defaultProps = {
  selectData: [],
}

export default exportDry
