import {
    Typography, Grid, Box, Paper,
    useMediaQuery
} from '@mui/material'
import dayjs from "dayjs"
import PropTypes from "prop-types"

const HerbariumDetailCard = (props) => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                p: isMobile ? 1 : 5,
                pb: 5,
                pt: isMobile ? 2 : 0,
            }}
        >
            <Paper sx={{ width: isMobile ? "85%" : "100%" }} elevation={1}>
                    <Grid container sx={{ p: { xs: 3 , sm: 5, md: 5}, }} spacing={4} columnSpacing={30} >
                        <Grid item xs={12} sm={6} md={4} >
                                <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                    Common&nbsp;name
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                    {props.commonName}
                                </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Box>
                                <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                    Province
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                    {props.province}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                District
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.district}
                            </Typography>
                        </Grid>
                    
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Location
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.location}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Longtitude
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.longtitude}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Latitude
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.latitude}
                            </Typography>
                        </Grid>
                   
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Altitude
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.altitude}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Remark
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.remark}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Determined by
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.determineBy}
                            </Typography>
                        </Grid>
                   
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Date Determined
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                            {dayjs(props.dateDetermine).format("MMMM/YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Duplicate
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                {props.duplicate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                            Date
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                            {dayjs(props.date).format("DD/MMM/YYYY")}
                            </Typography>
                        </Grid>
                    </Grid>
            </Paper>
        </Box>
    )
}

HerbariumDetailCard.defaultProps = {
    infraspecificName: "-",
    intraspecificStatus: "-",
    province: "-",
    author1: "-",
    author2: "-",
    intraspecificName: "-",
    location: "-",
    district: "-",
    longtitude: "-",
    latitude: "-",
    altitude: "-",
    remark: "-",
    determineBy: "-",
    dateDetermine: "-",
    duplicate: "-",
    category: "-",
    commonName: "-"
}

HerbariumDetailCard.propTypes = {
    infraspecificName: PropTypes.string,
    intraspecificStatus: PropTypes.string,
    province: PropTypes.string,
    author1: PropTypes.string,
    author2: PropTypes.string,
    intraspecificName: PropTypes.string,
    location: PropTypes.string,
    district: PropTypes.string,
    longtitude: PropTypes.string,
    latitude: PropTypes.string,
    altitude: PropTypes.string,
    remark: PropTypes.string,
    determineBy: PropTypes.string,
    dateDetermine: PropTypes.string,
    duplicate: PropTypes.string,
    category: PropTypes.string,
    commonName: PropTypes.string,
}

export default HerbariumDetailCard