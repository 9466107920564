import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './mobile-nav.css'

const MobileNav = (props) => {
  return (
    <nav className={`mobile-nav-nav ${props.rootClassName} `}>
      <Link to="/" className="mobile-nav-navlink">
        {props.text}
      </Link>
      <Link to="/plant" className="mobile-nav-navlink1">
        {props.text1}
      </Link>
      <Link to="/animal" className="mobile-nav-navlink2">
        {props.text2}
      </Link>
      <Link to="/microbe" className="mobile-nav-navlink3">
        {props.text3}
      </Link>
      <Link to="/geo" className="mobile-nav-navlink4">
        {props.text31}
      </Link>
      <a
        href="https://www.sci.psu.ac.th/en/contact-en/"
        target="_blank"
        rel="noreferrer noopener"
        className="mobile-nav-link"
      >
        {props.text4}
      </a>
    </nav>
  )
}

MobileNav.defaultProps = {
  text3: 'Microbe',
  rootClassName: '',
  text4: 'Contact',
  text2: 'Animal',
  text31: 'Geoscience',
  text1: 'Plant',
  text: 'Home',
}

MobileNav.propTypes = {
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
  text4: PropTypes.string,
  text2: PropTypes.string,
  text31: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
}

export default MobileNav
