import React, { useEffect, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import ScientificNameheader from "../components/scientific-nameheader"
import StatusItem from "../components/status-item"
import Borrowform from "../components/borrowform"
import Footer from "../layouts/footer-layout"
import "./herbarium-borrowform.css"
import { request } from "../axios-config"
import { Grid, TextField } from "@mui/material"
import { useParams } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"

const HerbariumBorrowform = (props) => {
  const params = useParams()
  const [herbariumDetail, setHerbariumDetail] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getHerbariumDetail = async () => {
      try {
        const response = await request.get(`/herbariumItem/${params?.id}`, {
          signal: controller.signal,
        })
        isMounted && setHerbariumDetail(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getHerbariumDetail()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params?.id])
  return (
    <div className="herbarium-borrowform-container">
      <Helmet>
        <title>HerbariumBorrowform - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="HerbariumBorrowform - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name11"></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Herbarium", path: `/herbarium-main` },
          {
            title: `${herbariumDetail?.family ? herbariumDetail?.family : ""} ${
              herbariumDetail?.genus ? herbariumDetail?.genus : ""
            } ${herbariumDetail?.species ? herbariumDetail?.species : ""} ${
              herbariumDetail?.author1 ? herbariumDetail?.author1 : ""
            } ${
              herbariumDetail?.intraspecificStatus
                ? herbariumDetail?.intraspecificStatus
                : ""
            } ${
              herbariumDetail?.intraspecificName
                ? herbariumDetail?.intraspecificName
                : ""
            } ${herbariumDetail?.author2 ? herbariumDetail?.author2 : ""}`,
            path: `/herbarium-detail/${params.id}`,
          },
          { title: "Borrow Form" },
        ]}
      />
      <div className="herbarium-borrowform-research-content">
        <ScientificNameheader
          ScientificName={`${
            herbariumDetail?.family ? herbariumDetail?.family : ""
          } ${herbariumDetail?.genus ? herbariumDetail?.genus : ""} ${
            herbariumDetail?.species ? herbariumDetail?.species : ""
          } ${herbariumDetail?.author1 ? herbariumDetail?.author1 : ""} ${
            herbariumDetail?.intraspecificStatus
              ? herbariumDetail?.intraspecificStatus
              : ""
          } ${
            herbariumDetail?.intraspecificName
              ? herbariumDetail?.intraspecificName
              : ""
          } ${herbariumDetail?.author2 ? herbariumDetail?.author2 : ""}`}
        />
        <div className="herbarium-borrowform-separator"></div>
        <div className="herbarium-borrowform-container1">
          <div className="herbarium-borrowform-container2">
            <StatusItem
              rootClassName="status-item-root-class-name"
              StatusItem={herbariumDetail?.status}
            />
            <div className="herbarium-borrowform-container3">
              <img
                alt="image"
                src={
                  herbariumDetail?.photograph
                    ? herbariumDetail?.photograph
                    : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
                }
                className="herbarium-borrowform-image"
              />
            </div>
          </div>
          <Borrowform
            rootClassName="borrowform-root-class-name"
            plantId={params?.id}
          ></Borrowform>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name10"></Footer>
    </div>
  )
}

export default HerbariumBorrowform
