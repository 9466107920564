import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import ItemArchiveCard from '../components/item-archive-card'
import Footer from '../layouts/footer-layout'
import './animal.css'
import { request } from '../axios-config'
import { Autocomplete, TextField , Chip, Divider, Popper, styled, Box} from '@mui/material'
import ItemArchiveCardAnimal from '../components/item-archive-card-animal'

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Opacity } from '@mui/icons-material'

const itemsPerPage = 4;

const Animal = (props) => {
  const [invertebratesPage, setInvertebratesPage] = useState(1);
  const [vertebratesPage, setVertebratesPage] = useState(1);

  const [animalDatas, setAnimalDatas] = useState([])
  const [searchDatas, setSearchDatas] = useState([])

  const filterDataByCategory = (data, category) => {
    return data.filter((item) => item.category === category);
  };

  const invertebratesData = filterDataByCategory(animalDatas, "ANIMAL_INVERTEBRATE");
  const vertebratesData = filterDataByCategory(animalDatas, "ANIMAL_VERTEBRATE");

  const invertebratesStartIndex = (invertebratesPage - 1) * itemsPerPage;
  const invertebratesEndIndex = invertebratesStartIndex + itemsPerPage;
  const invertebratesPageData = invertebratesData.slice(invertebratesStartIndex, invertebratesEndIndex);

  const vertebratesStartIndex = (vertebratesPage - 1) * itemsPerPage;
  const vertebratesEndIndex = vertebratesStartIndex + itemsPerPage;
  const vertebratesPageData = vertebratesData.slice(vertebratesStartIndex, vertebratesEndIndex);

   const handleInvertebratesPageChange = (event, value) => {
    setInvertebratesPage(value);
  };

  const handleVertebratesPageChange = (event, value) => {
    setVertebratesPage(value);
  };

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.post(
          "/items",
          {
            filter: {
              category: "ANIMAL",
            },
          },
          {
            signal: controller.signal,
          },
        )
        isMounted && setAnimalDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiAutocomplete-groupLabel': {
      color: theme.palette.primary.main,
    },
  }));

  const styleBox = {
    padding: "20px" , 
    marginBottom: "30px",
    borderRadius: "8px", 
    width: "100%",
    backgroundColor: "#144372", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.95
  }

  const styleBoxLabel = {
    textAlign: "center",
    wordBreak: "break-all",
    fontSize: "20px",
    color: "#fff"
  }

  const stylePagination = {
    alignItems: "center",
    justifyContent: "center" ,
    marginTop: "30px",
    marginBottom: "40px",
  }

  return (
    <div className="animal-container">
      <Helmet>
        <title>Animal - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Animal - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name5"></Header>
      <div className="animal-hero">
        <div className="animal-container1">
          <h1 className="animal-text">
            <span className="animal-text01">
              Animal Archive of Faculty of Science,
            </span>
            <br></br>
            <span>Prince of Songkla University</span>
            <br></br>
          </h1>
          <span className="animal-text05">
            <span className="animal-text06">
              The Faculty of Science at Prince of Songkla University has
              compiled a comprehensive collection of animal samples, research
              data, and related information on this website. Our team of
              dedicated lecturers has gathered this information with the aim of
              making it readily available for academic and educational purposes.
              All of the data on this site is freely accessible, and we
              encourage researchers and educators to utilize it in their work.
              We strive to keep the contents of this website up to date with the
              latest findings from our faculty members, making this an
              invaluable resource for those seeking to advance scientific
              knowledge related to the animal kingdom.
            </span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="animal-search-tab">
        <Autocomplete
          // disablePortal
          options={
            animalDatas.length !== 0
              ? animalDatas
                  .map((row) => ({
                    ...row,
                    label: `${
                      row?.genericName +
                      " " +
                      row?.specificEpithet +
                      " " +
                      row?.authorName1
                    }`,
                    groupLabel:
                      row.category === "ANIMAL_VERTEBRATE"
                        ? "VERTEBRATE"
                        : "INVERTEBRATE",
                  }))
                  .sort((a, b) => -b.category.localeCompare(a.category))
              : []
          }
          groupBy={(option) => option.groupLabel}
          getOptionLabel={(option) =>
            (option.genericName || "") +
            " " +
            (option.specificEpithet || "") +
            " " +
            (option.authorName1 || "")
          }
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Search here" />
          )}
          onChange={(e, value) => {
            console.log("value", value)
            if (value !== null) {
              setSearchDatas([value])
            } else setSearchDatas([])
          }}
          PopperComponent={StyledPopper}
          // onChange={(event, value) => console.log(value)}
        />
      </div>
      <div className="animal-features">
        <div>
          {searchDatas.length !== 0 ? (
            searchDatas?.map((row, index) => (
              <ItemArchiveCardAnimal
                key={index}
                index={index}
                id={row?._id}
                photo={row?.photo}
                genericName={row?.genericName}
                specificEpithet={row?.specificEpithet}
                authorName1={row?.authorName1}
                commonName={row?.commonName}
                vercularName={row?.vercularName}
                rootClassName="item-archive-card-root-class-name"
              />
            ))
          ) : (
            <div>
              {invertebratesPageData && invertebratesPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <sapn style={{ ...styleBoxLabel }}>INVERTEBRATE</sapn>
                  </Box>

                  <div className="plant-container2">
                    {invertebratesPageData?.map((row, index) => (
                      <ItemArchiveCardAnimal
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        authorName1={row?.authorName1}
                        commonName={row?.commonName}
                        vercularName={row?.vercularName}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(invertebratesData.length / itemsPerPage)}
                      shape="rounded"
                      page={invertebratesPage}
                      onChange={handleInvertebratesPageChange}
                    />
                  </Stack>
                </div>
              )}
              
              {vertebratesPageData && vertebratesPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <sapn style={{ ...styleBoxLabel }}>VERTEBRATE</sapn>
                  </Box>

                  <div className="plant-container2">
                    {vertebratesPageData?.map((row, index) => (
                      <ItemArchiveCardAnimal
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        authorName1={row?.authorName1}
                        commonName={row?.commonName}
                        vercularName={row?.vercularName}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(vertebratesData.length / itemsPerPage)}
                      shape="rounded"
                      page={vertebratesPage}
                      onChange={handleVertebratesPageChange}
                    />
                  </Stack>
                </div>
              )}

            </div>
          )}
        </div>
      </div>

      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default Animal
