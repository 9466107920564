import React, { useEffect, useState } from "react"
import useAxiosPrivate from "../hook/use-axios-private"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Box, Typography } from "@mui/material"
import axios from "axios"

// const formatViews = (count) => {
//   if (count >= 1000000) {
//     return (count / 1000000).toFixed(1) + "M"
//   } else if (count >= 1000) {
//     return (count / 1000).toFixed(1) + "K"
//   } else {
//     return count
//   }
// }

const formatViews = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const ShowCountViewsByItem = ({ itemId, nameModel }) => {
  const requestPrivate = useAxiosPrivate()
  const [refresh, setRefresh] = useState(false)
  const [views, setViews] = useState(0)
  const [ipv4Address, setIPv4Address] = useState("")

  useEffect(() => {
    const fetchIPv4Address = async () => {
      try {
        await axios
          .get("https://api.ipify.org/?format=json")
          .then((response) => setIPv4Address(response?.data?.ip))
      } catch (error) {
        console.error("Error retrieving IP address:", error)
        // setIPv4Address("IPv4 address not found")
      }
    }

    fetchIPv4Address()
  }, [])

  useEffect(() => {
    const fetchCount = async () => {
      if (ipv4Address !== "") {
        console.log("ipAddress", ipv4Address)
        try {
          const response = await requestPrivate.post(
            `/countviewsbyitem/${itemId}`,
            {
              ipAddress: ipv4Address,
              nameModel: nameModel,
            },
          )
          if (response) setRefresh(true)
        } catch (error) {
          console.error("Error fetching count:", error)
        }
      }
    }

    fetchCount()
  }, [ipv4Address])

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await requestPrivate.get(`/getviewsbyitem/${itemId}`)
        setViews(response?.data?.totalViews)
      } catch (error) {
        console.error("Error fetching count:", error)
      }
    }

    fetchCount()
  }, [refresh])

  return (
    <Box sx={{ mt: 2, opacity: 0.8 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <VisibilityIcon sx={{ color: "rgba(0,0,0,0.5)", marginRight: 1 ,fontSize: "20px" }} />
        <Typography sx={{fontSize: "14px" }}>{formatViews(views)} views</Typography>
      </Box>
    </Box>
  )
}

export default ShowCountViewsByItem
