//researcher-details.js
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import ResearcherNameHeader from '../components/researcher-name-header'
import DetailsCard from '../components/details-card'
import Footer from '../layouts/footer-layout'
import './researcher-details.css'
import { Link, useParams } from 'react-router-dom'
import { request } from '../axios-config'

import BreadcrumbLayout from "../components/breadcrumb-layout"

import { Button } from "@mui/material"

import * as XLSX from "xlsx"

const ResearcherDetails = (props) => {
  const params = useParams()
  const [researcherDatas, setResearcherDatas] = useState({})

  const dataForPlant = []
  const dataForMicrobe = []
  const dataForAnimal = []

  const exportToExcel = () => {
    const PlantWorksheet = XLSX.utils.json_to_sheet(dataForPlant)
    const MicrobeWorksheet = XLSX.utils.json_to_sheet(dataForMicrobe)
    const AnimalWorksheet = XLSX.utils.json_to_sheet(dataForAnimal)

    // Add header row with bold font
    const PlantHeader = ['Research Name', 'Researcher', 'Scientific Name', 'Generic Name', 'Specific Epithet', 'Category', 'Author', 'Vercular Name', 'habitat', 'Common Name', 'Location', 'Optimum temperature', 'Type', 'Year', 'Link']
    const aoa = [PlantHeader.map(val => ({ v: val, s: { font: { bold: true } } }))]
    XLSX.utils.sheet_add_aoa(PlantWorksheet, aoa)

    const MicrobeHeader = ['Research Name', 'Researcher', 'Scientific Name', 'Family', 'Category', 'Generic Name', 'Specific Epithet', 'Sp', 'Subspecies', 'Serovar', 'Serotype', 'Biotype', 'Strain Designation', 'Specific Applications', 'Type Of Isolate', 'IsolationSource', 'Geographical Isolation', 'OptimumTemperature', 'bsl', 'Type', 'Year', 'Link']
    const aoa2 = [MicrobeHeader.map(val => ({ v: val, s: { font: { bold: true } } }))]
    XLSX.utils.sheet_add_aoa(MicrobeWorksheet, aoa2)

    const AnimalHeader = ['Research Name', 'Researcher', 'Kingdom', 'Category', 'Phylum', 'Aclass', 'Order', 'Family', 'Generic Name', 'Specific Epithet', 'Author', 'Sp', 'Subspecies', 'Common Name', 'Vercular Name', 'Locality', 'Ecology And Habitat', 'Specimen Repository', 'Molecular Data', 'Utilization', 'Type', 'Year', 'Link']
    const aoa3 = [AnimalHeader.map(val => ({ v: val, s: { font: { bold: true } } }))]
    XLSX.utils.sheet_add_aoa(AnimalWorksheet, aoa3)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, PlantWorksheet, "Plant")
    XLSX.utils.book_append_sheet(workbook, MicrobeWorksheet, "Microbe")
    XLSX.utils.book_append_sheet(workbook, AnimalWorksheet, "Animal")
    XLSX.writeFile(
      workbook,
      `${researcherDatas?.title}${researcherDatas?.name} ${researcherDatas?.surname}.xlsx`,
    )
  }

  useEffect(() => {
    const dataPlantMap = () => {
      researcherDatas?.items?.filter((options) =>
        options?.category === "PLANT_ANGIOSPERM" ||
        options?.category === "PLANT_GYMNOSPERM" ||
        options?.category === "PLANT_LYCOPHYTEANDFERNS" ||
        options?.category === "PLANT_BRYOPHYTES" ||
        options?.category === "PLANT_ALGAEANDPHYTOPLANKTON"
      ).map((row, index) => {
        dataForPlant.push({
          title: row?.references?.[0].title || "-",
          lecturer: researcherDatas?.title + " " + researcherDatas?.name + " " + researcherDatas?.surname || "-",
          scientificName: row?.genericName + " " + row?.specificEpithet + " " + row?.authorName1 || "-",
          genericName: row?.genericName || "-",
          specificEpithet: row?.specificEpithet || "-",
          category: row?.category || "-",
          authorName1: row?.authorName1 || "-",
          vernacularName: row?.vercularName || "-",
          category: row?.category || "-",
          ecologyAndHabitat: row?.ecologyAndHabitat || "-",
          commonName: row?.commonName || "-",
          locality: row?.locality || "-",
          optimumTemperature: row?.optimumTemperature || "-",
          type: row?.references?.[0].type || "-",
          year: row?.references?.[0].year || "-",
          link: row?.references?.[0].link || "-",
        })
      })
    }

    const dataMicrobeMap = () => {
      researcherDatas?.items?.
        filter((options) =>
          options.category === "MICROBE_BACTERIA" ||
          options.category === "MICROBE_MOLD" ||
          options.category === "MICROBE_PROTOZOA" ||
          options.category === "MICROBE_VIRUS"
        ).map((row, index) => {
          dataForMicrobe.push({
            title: row?.references?.[0].title || "-",
            lecturer: researcherDatas?.title + " " + researcherDatas?.name + " " + researcherDatas?.surname || "-",
            scientificName: row?.genericName + " " + row?.specificEpithet + " " + row?.authorName1 || "-",
            family: row?.family || "-",
            category: row?.category || "-",
            genericName: row?.genericName || "-",
            specificEpithet: row?.specificEpithet || "-",
            sp: row?.sp || "-",
            subspecies: row?.subspecies || "-",
            serovar: row?.serovar || "-",
            serotype: row?.serotype || "-",
            biotype: row?.biotype || "-",
            strainDesignation: row?.strainDesignation || "-",
            specificApplications: row?.specificApplications || "-",
            typeOfIsolate: row?.typeOfIsolate || "-", 
            isolationSource: row?.isolationSource || "-",
            geographicalIsolation: row?.geographicalIsolation || "-",
            optimumTemperature: row?.optimumTemperature || "-",
            bsl: row?.bsl || "-",
            type: row?.references?.[0].type || "-",
            year: row?.references?.[0].year || "-",
            link: row?.references?.[0].link || "-",
          })
        })
    }

    const dataAnimalMap = () => {
      researcherDatas?.items?.
        filter((options) =>
          options?.category === "ANIMAL_VERTEBRATE" ||
          options?.category === "ANIMAL_INVERTEBRATE"
        ).map((row, index) => {
          dataForAnimal.push({
            title: row?.references?.[0].title || "-",
            lecturer: researcherDatas?.title + " " + researcherDatas?.name + " " + researcherDatas?.surname || "-",
            kingdom: row?.kingdom || "-",
            category: row?.category || "-",
            phylum: row?.phylum || "-",
            aclass: row?.aclass || "-",
            order: row?.order || "-",
            family: row?.family || "-",
            genericName: row?.genericName || "-",
            specificEpithet: row?.specificEpithet || "-",
            authorName1: row?.authorName1 || "-",
            sp: row?.sp || "-",
            subspecies: row?.subspecies || "-",
            commonName: row?.commonName || "-",
            vercularName: row?.vercularName || "-",
            locality: row?.locality || "-",
            ecologyAndHabitat: row?.ecologyAndHabitat || "-",
            specimenRepository: row?.specimenRepository || "-",
            molecularData: row?.molecularData || "-",
            utilization: row?.utilization || "-",
            type: row?.references?.[0].type || "-",
            year: row?.references?.[0].year || "-",
            link: row?.references?.[0].link || "-",
          })
        })
    }

    dataPlantMap()
    dataMicrobeMap()
    dataAnimalMap()
  }, [researcherDatas])


  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getItem = async () => {
      try {
        const response = await request.get(`/lecturer/${params?.id}`, {
          signal: controller.signal
        })
        isMounted && setResearcherDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  console.log(researcherDatas);

  return (
    <div className="researcher-details-container">
      <Helmet>
        <title>ResearcherDetails - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="ResearcherDetails - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name18"></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Researcher", path: "/researcher" },
          { title: `${researcherDatas?.title + " " + researcherDatas?.name + " " + researcherDatas?.surname}` },
        ]}
      />
      <div className="researcher-details-reseacrher-content">
        <ResearcherNameHeader
          ResearcherName={researcherDatas?.title + " " + researcherDatas?.name + " " + researcherDatas?.surname}
          rootClassName="researcher-name-header-root-class-name"></ResearcherNameHeader>
        <div className="researcher-details-separator"></div>
        <div className="researcher-details-container1">
          <div className="researcher-details-container2">
            <img
              alt="image"
              src={researcherDatas?.pictureUrl}
              className="researcher-details-image"
            />
            <a
              href={researcherDatas?.profileLink}
              target="_blank"
              rel="noreferrer noopener"
              className="researcher-details-link button"
            >
              <span>
                <span>More info</span>
                <br></br>
              </span>
            </a>
          </div>
          <div className="researcher-details-container3">

            <DetailsCard
              title="Division"
              description={researcherDatas?.major}
              rootClassName="rootClassName8" />

            <DetailsCard
              title="Email"
              description={researcherDatas?.email}
              rootClassName="rootClassName8" />

            <DetailsCard
              title="Expert"
              description={researcherDatas?.items?.[0]?.category}
              rootClassName="rootClassName8" />
            {/* <DetailsCard rootClassName="rootClassName10"></DetailsCard>
            <DetailsCard rootClassName="details-card-root-class-name12"></DetailsCard>
            <DetailsCard rootClassName="details-card-root-class-name14"></DetailsCard> */}
          </div>
        </div>
      </div>

      <h2 class="mb-2 text-lg flex flex-wrap items-center justify-center font-semibold text-gray-900 ">Research Work</h2>

      <ul class="container mx-auto p-6 flex flex-wrap items-center justify-center mb-6 text-blue-900 ">
        {researcherDatas?.items?.map((row, index) => (
          <li>
            <div class="max-w-sm rounded overflow-hidden shadow-lg h-full">
              <div class="px-6 py-4">

                <div class="font-bold text-xl mb-2">{row?.references?.[0].title}</div>

                <p class="text-gray-700 text-base">
                  {row?.scientificName} <br />
                  {row?.references?.[0].year} <br />
                  {row?.vercularName}
                </p>
              </div>

              <div class="px-6 pt-4 pb-2">

                <a href="#" class="mr-4 hover:underline flex flex-wrap items-center justify-center md:mr-6 ">
                  <a
                    href={row?.references?.[0].link}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="detail-link2 button"
                  >
                    Reference Article

                  </a>


                </a>

              </div>

              <div class="px-6 pt-4 pb-2">

                <a href="#" class="mr-4 hover:underline flex flex-wrap items-center justify-center md:mr-6 ">

                  <Link to={`/detail/${row?._id}`} className="">More Details in Archive</Link>

                </a>

              </div>

            </div>

          </li>
        ))}
      </ul>

      <Button onClick={() => exportToExcel()} variant="contained">
        Export All Research Work to Excel
      </Button>


      <Footer rootClassName="footer-root-class-name18"></Footer>
    </div>
  )
}

export default ResearcherDetails
