import React, { useState, ChangeEvent, Fragment, useEffect } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-archive-items.css"
import { request } from "../axios-config"
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material"

import {
  Create as CreateIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Delete,
} from "@mui/icons-material"

import BreadcrumbLayout from "../components/breadcrumb-layout"
import { useHistory, useParams } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"

const EditMicrobeItems = (props) => {
  const requestPrivate = useAxiosPrivate()

  const params = useParams()
  const history = useHistory()

  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const [references, setReferences] = useState([
    { link: "", year: "", type: "", title: "" },
  ])
  const [lecturers, setLecturers] = useState([])
  const [archive, setArchive] = useState([])
  const [selectedLecturers, setSelectedLecturers] = useState([])

  useEffect(() => {
    setSelectedLecturers(item?.lecturer?.map((row) => row || "") || [])
  }, [item?.lecturer])

  useEffect(() => {
    setReferences(item?.references?.map((row) => row || "") || [])
  }, [item?.references])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getArchive = async () => {
      try {
        const response = await request.get(`/item/${params.id}`, {
          signal: controller.signal,
        })
        isMounted && setArchive(response?.data)
        isMounted &&
          setItem({
            ...item,
            family: response?.data?.family,
            category: response?.data?.category,
            genericName: response?.data?.genericName,
            specificEpithet: response?.data?.specificEpithet,
            sp: response?.data?.sp,
            subspecies: response?.data?.subspecies,
            serovar: response?.data?.serovar,
            serotype: response?.data?.serotype,
            biotype: response?.data?.biotype,
            strainDesignation: response?.data?.strainDesignation,
            specificApplications: response?.data?.specificApplications,
            typeOfIsolate: response?.data?.typeOfIsolate,
            isolationSource: response?.data?.isolationSource,
            geographicalIsolation: response?.data?.geographicalIsolation,
            optimumTemperature: response?.data?.optimumTemperature,
            bsl: response?.data?.bsl,
            lecturer: response?.data?.lecturer,
            references: response?.data?.references,
            photo: response?.data?.photo,
          })
      } catch (error) {
        console.error(error)
      }
    }
    getArchive()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params.id])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getLecturers = async () => {
      try {
        const response = await request.get("/lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturers(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getLecturers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const SubmitEditArchive = async () => {
    try {
      // if (false) {
      //   alert("Scientific Name  is required")
      // }
      if (!references[0]?.title) {
        alert("References is required")
      }else if(!selectedLecturers[0]){
        alert("Researcher is required");
      } else {
        const responseUrlPhoto = await request.post("/updatePhoto", {
          newPhoto: filePhotos || [],
          defPhoto: item?.photo || [],
        })
        const response = await requestPrivate.put(`/updateItem/${params?.id}`, {
          ...item,
          photo: responseUrlPhoto?.data?.urls || [],
          references: references,
          lecturer: selectedLecturers,
        })
        if (response) {
          const urls = responseUrlPhoto?.data?.missingUrls
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            await request.delete(`/deletephoto/${fileNames}`, {})
          }
          alert("success")
          history.push("/microbeview")
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  // console.log("references", references)

  // const selectPhotoFile = (e) =>
  //   new Promise((resolve, reject) => {
  //     e.preventDefault()
  //     const file = e.currentTarget.files[0]
  //     if (file.size > 2000000) {
  //       alert("กรุณาใส่รูปที่มีขนาดไม่เกิน 2 mb");
  //       e.target.value = null; // clear the input field
  //       return;
  //     } else {
  //       setFilePhoto({ data: file })
  //       const reader = new FileReader()
  //       reader.readAsDataURL(file)
  //       reader.onload = () => {
  //         resolve(reader.result)
  //         setFilePhoto({ base64: reader.result })
  //       }
  //       reader.onerror = (error) => reject(error)
  //     }
  //   })

  useEffect(() => {
    setFilePhotos(item.photo?.map((row) => row || "") || [])
  }, [item])

  const selectPhotoFiles = (e) => {
    if (filePhotos.length >= 4) {
      alert(`Up to 4 photos can be uploaded.`)
      e.target.value = null
      return
    }

    new Promise((resolve, reject) => {
      e.preventDefault()
      const files = Array.from(e.currentTarget.files)
      if (files?.length + filePhotos?.length > 4) {
        alert(`Up to 4 photos can be uploaded.`)
        e.target.value = null
        return
      }
      console.log("files", files)
      const invalidFiles = files.filter((file) => file.size > 2000000)
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((file) => file.name).join(", ")
        alert(
          `Please select images with a size not exceeding 2 MB: ${fileNames}`,
        )
        e.target.value = null // clear the input field
        return
      }

      const filePromises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          }),
      )
      console.log(filePromises)
      Promise.all(filePromises)
        .then((results) => {
          const base64Files = files.map((file, index) => ({
            data: file,
            base64: results[index],
          }))
          setFilePhotos([...base64Files, ...filePhotos])
          resolve(results)
        })
        .catch((error) => reject(error))
    })
  }

  const handleDeletePhoto = (index) => {
    const newPhotos = [...filePhotos]
    newPhotos.splice(index, 1)
    setFilePhotos(newPhotos)
  }

  const handleLecturerChange = (e, index) => {
    const newValue = e.target.value
    setSelectedLecturers((prevSelectedLecturers) => {
      const newSelectedLecturers = [...prevSelectedLecturers]
      newSelectedLecturers[index] = newValue
      return newSelectedLecturers
    })
  }

  const handleAddLecturer = (index) => {
    const newSelectedLecturers = [...selectedLecturers]
    newSelectedLecturers.splice(index + 1, 0, "")
    setSelectedLecturers(newSelectedLecturers)
  }

  const handleDeleteLecturer = (index) => {
    const newSelectedLecturers = [...selectedLecturers]
    newSelectedLecturers.splice(index, 1)
    setSelectedLecturers(newSelectedLecturers)
  }

  const isLecturerSelected = (lecturerValue, lecturerId) => {
    if (lecturerValue === lecturerId) return false
    return selectedLecturers.some((selectedId) => selectedId === lecturerId)
  }

  const handleDeleteReference = (index) => {
    const newReferences = [...references]
    newReferences.splice(index, 1)
    setReferences(newReferences)
  }

  return (
    <>
      <Helmet>
        <title>EditArchiveItems - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="EditArchiveItems - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Archive", path: `/archiveview` },
          { title: "Microbe Lists", path: `/microbeview` },
          {
            title: `${archive?.genericName || ""} ${
              archive?.specificEpithet || ""
            } ${archive?.sp || ""} ${archive?.subspecies || ""} ${
              archive?.strainDesignation || ""
            }`,
          },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid
          container
          direction={"row"}
          sx={{ alignItems: "center", mb: 3, wordWrap: "break-word" }}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="h4">
              <i>{archive.genericName ? archive.genericName + "\u00A0" : ""}</i>
              <i>
                {archive.specificEpithet
                  ? archive.specificEpithet + "\u00A0"
                  : ""}
              </i>
              {archive.sp ? archive.sp + "\u00A0" : ""}
              {archive.subspecies ? archive.subspecies + "\u00A0" : ""}
              <i>
                {archive.strainDesignation
                  ? archive.strainDesignation + "\u00A0"
                  : ""}
              </i>
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h5">(Edit)</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"column"}
            spacing={1}
            sx={{ mb: "10px", mt: "5px" }}
          >
            <PhotoAction
              filePhotos={filePhotos}
              handleDeletePhoto={handleDeletePhoto}
              selectPhotoFiles={selectPhotoFiles}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Category <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="category"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        // height: "180px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
               
                value={ item?.category ? item.category : null}
                error={ item?.category === undefined || item?.category ? false : true}
                InputProps={{
                    endAdornment: (
                        item && item.category === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px", marginRight: "14px", zIndex: -4}}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    category: e.target.value,
                  })
                }}
              >
                {/* <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Microbe
                </ListSubheader> */}

                <MenuItem value={"MICROBE_BACTERIA"}>Bacteria</MenuItem>
                <MenuItem value={"MICROBE_MOLD"}>Mold</MenuItem>
                <MenuItem value={"MICROBE_PROTOZOA"}>Protozoa</MenuItem>
                <MenuItem value={"MICROBE_VIRUS"}>Virus</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Family <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="family"
                size="small"
                fullWidth
                value={item?.family ? item?.family : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    family: e.target.value,
                  })
                }}
                error={ item && item?.family === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.family === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Generic Name / Genus <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="genericName"
                size="small"
                fullWidth
                value={item?.genericName ? item?.genericName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    genericName: e.target.value,
                  })
                }}
                error={ item && item?.genericName === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.genericName === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">
                Specific Epithet / Species <span style={{color: 'red'}}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="specificEpithet"
                size="small"
                fullWidth
                value={item?.specificEpithet ? item?.specificEpithet : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specificEpithet: e.target.value,
                  })
                }}
                error={ item && item?.specificEpithet === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.specificEpithet === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">sp./spp.</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="sp"
                size="small"
                fullWidth
                value={item ? item?.sp : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    sp: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Subspecies</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="subspecies"
                size="small"
                fullWidth
                value={item ? item?.subspecies : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    subspecies: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Serovar</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="serovar"
                size="small"
                fullWidth
                value={item ? item?.serovar : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    serovar: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Serotype</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="serotype"
                size="small"
                fullWidth
                value={item ? item?.serotype : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    serotype: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Biotype</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="biotype"
                size="small"
                fullWidth
                value={item ? item?.biotype : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    biotype: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Strain designation</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="vercularName"
                size="small"
                fullWidth
                value={item ? item?.strainDesignation : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    strainDesignation: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Specific applications</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="specificApplications"
                size="small"
                fullWidth
                value={item ? item?.specificApplications : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specificApplications: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Type of isolate <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="typeOfIsolate"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        // height: "100px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item?.typeOfIsolate ? item?.typeOfIsolate : ""}
                error={ item?.typeOfIsolate === undefined || item?.typeOfIsolate ? false : true}
                InputProps={{
                    endAdornment: (
                        item && item.typeOfIsolate === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px", marginRight: "14px", zIndex: -4}}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    typeOfIsolate: e.target.value,
                  })
                }}
              >
                {/* <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Microbe
                </ListSubheader> */}
                <MenuItem value={"Human"}>Human</MenuItem>
                <MenuItem value={"Environmental"}>Environmental</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Isolation source</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="isolationSource"
                size="small"
                fullWidth
                value={item ? item?.isolationSource : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    isolationSource: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">
                Geographical isolation
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="geographicalIsolation"
                size="small"
                fullWidth
                value={item ? item?.geographicalIsolation : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    geographicalIsolation: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Medium/Temperature</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="optimumTemperature"
                size="small"
                fullWidth
                value={item ? item?.optimumTemperature : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    optimumTemperature: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">BSL</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="bsl"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        // height: "100px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item?.bsl ? item?.bsl : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    bsl: e.target.value,
                  })
                }}
              >
                {/* <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Microbe
                </ListSubheader> */}
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          ></Grid>

          <Grid item xs={6} md={4} mt={3} mb={-3}>
            <Typography variant="h5">Researcher</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              {selectedLecturers &&
                selectedLecturers.map((row, index) => {
                  const lecturerValue =
                    selectedLecturers[index] !== undefined &&
                    selectedLecturers[index] !== ""
                      ? selectedLecturers[index]
                      : row
                  const isLastIndex = index === selectedLecturers.length - 1
                  const isLastItem = selectedLecturers.length === 1
                  return (
                    <Grid container key={index} spacing={2}>
                      <Grid item xs={6} md={4} mt={2}>
                        <Typography variant="subtitle2">{`Researcher ${
                          index + 1
                        }`}</Typography>
                      </Grid>
                      <Grid item xs={6} md={4} mt={2}>
                        <TextField
                          id="lecturer"
                          required
                          size="small"
                          fullWidth
                          select
                          value={lecturerValue}
                          onChange={(e) => handleLecturerChange(e, index)}
                        >
                          {lecturers?.length !== 0 ? (
                            lecturers?.map((lecturer, index) => {
                              return (
                                <MenuItem
                                  disabled={isLecturerSelected(
                                    lecturerValue,
                                    lecturer?._id,
                                  )}
                                  key={index}
                                  value={`${lecturer?._id}`}
                                >{`${lecturer?.title} ${lecturer?.name} ${lecturer?.surname}`}</MenuItem>
                              )
                            })
                          ) : (
                            <MenuItem />
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={4} mt={2}>
                        {!isLastItem && (
                          <IconButton
                            onClick={() => handleDeleteLecturer(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}

                        {isLastIndex && ( // Only show "Add Lecturer" button in last index
                          <IconButton
                            color="primary"
                            onClick={() => handleAddLecturer(index)}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={5}>
          <Typography variant="h5">References</Typography>

          {references &&
            references.map((row, index) => {
              const isLastIndex = index === references.length - 1
              const isLastItem = references.length === 1
              return (
                <Fragment key={index}>
                  <Grid item xs={12} md={12} mt={2}>
                    <Typography variant="subtitle1">{`Reference ${
                      index + 1
                    }`}</Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2">title</Typography>
                      </Grid>
                      <Grid item xs={6} md={9}>
                        <TextField
                          multiline
                          id="title"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.title
                              : references[index]?.title
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              title: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2">link</Typography>
                      </Grid>
                      <Grid item xs={6} md={9}>
                        <TextField
                          multiline
                          id="link"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.link
                              : references[index]?.link
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              link: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">year</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="year"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.year
                              : references[index]?.year
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              year: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">type</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="type"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.type
                              : references[index]?.type
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              type: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      {!isLastItem && (
                        <IconButton
                          onClick={() => handleDeleteReference(index)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                      {isLastIndex && (
                        <IconButton
                          color={"primary"}
                          onClick={() => {
                            let res = references.slice()
                            res[index + 1] = {
                              title: "",
                              link: "",
                              year: "",
                              type: "",
                            }
                            setReferences(res)
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Fragment>
              )
            })}
        </Grid>
        <Grid
          mt={10}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <Button
            disabled={
              item?.category === undefined || item?.category === "" ||
              item?.family === undefined || item?.family === "" ||
              item?.genericName === undefined || item?.genericName === "" ||
              item?.specificEpithet === undefined || item?.specificEpithet === "" ||
              item?.typeOfIsolate === undefined || item?.typeOfIsolate === ""
            }
            variant="contained"
            onClick={() => SubmitEditArchive(item?.photo)}
          >
            submit
          </Button>
          <Button
            variant="text"
            onClick={() => {
              history.push("/microbeview")
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Container>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </>
  )
}

export default EditMicrobeItems
