import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import ItemArchiveCard from '../components/item-archive-card'
import Footer from '../layouts/footer-layout'
import './microbe.css'
import { request } from '../axios-config'
import { Autocomplete , Box, Popper, TextField, styled} from '@mui/material'
import ItemArchiveCardMicrobe from '../components/item-archive-card-microbe'

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const itemsPerPage = 4;

const Microbe = (props) => {

  const [bacteriaPage, setBacteriaPage] = useState(1);
  const [moldPage, setMoldPage] = useState(1);
  const [protozoaPage, setProtozoaPage] = useState(1);
  const [virusPage, setVirusPage] = useState(1);

  const [microbeDatas, setMicrobeDatas] = useState([]);
  const [searchDatas, setSearchDatas] = useState([]);

  const filterDataByCategory = (data, category) => {
    return data.filter((item) => item.category === category);
  };

  const bacteriaData = filterDataByCategory(microbeDatas, "MICROBE_BACTERIA");
  const moldData = filterDataByCategory(microbeDatas, "MICROBE_MOLD");
  const protozoaData = filterDataByCategory(microbeDatas, "MICROBE_PROTOZOA");
  const virusData = filterDataByCategory(microbeDatas, "MICROBE_VIRUS");

  const bacteriaStartIndex = (bacteriaPage - 1) * itemsPerPage;
  const bacteriaEndIndex = bacteriaStartIndex + itemsPerPage;
  const bacteriaPageData = bacteriaData.slice(bacteriaStartIndex, bacteriaEndIndex);

  const moldStartIndex = (moldPage - 1) * itemsPerPage;
  const moldEndIndex = moldStartIndex + itemsPerPage;
  const moldPageData = moldData.slice(moldStartIndex, moldEndIndex);

  const protozoaStartIndex = (protozoaPage - 1) * itemsPerPage;
  const protozoaEndIndex = protozoaStartIndex + itemsPerPage;
  const protozoaPageData = protozoaData.slice(protozoaStartIndex, protozoaEndIndex);

  const virusStartIndex = (virusPage - 1) * itemsPerPage;
  const virusEndIndex = virusStartIndex + itemsPerPage;
  const virusPageData = virusData.slice(virusStartIndex, virusEndIndex);

  const handleBacteriaPageChange = (event, value) => {
    setBacteriaPage(value);
  };

  const handleMoldPageChange = (event, value) => {
    setMoldPage(value);
  };

  const handleProtozoaPageChange = (event, value) => {
    setProtozoaPage(value);
  };

  const handleVirusPageChange = (event, value) => {
    setVirusPage(value);
  };

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.post(
          "/items",
          {
            filter: {
              category: "MICROBE",
            },
          },
          {
            signal: controller.signal,
          },
        )
        isMounted && setMicrobeDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  // console.log(plantDatas);

  const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiAutocomplete-groupLabel': {
      color: theme.palette.primary.main,
    },
  }));

  const styleBox = {
    padding: "20px" , 
    marginBottom: "30px",
    borderRadius: "8px", 
    width: "100%",
    backgroundColor: "#144372", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.95
  }

  const styleBoxLabel = {
    textAlign: "center",
    wordBreak: "break-all",
    fontSize: "20px",
    color: "#fff"
  }

  const stylePagination = {
    alignItems: "center",
    justifyContent: "center" ,
    marginTop: "30px",
    marginBottom: "40px",
  }

  return (
    <div className="microbe-container">
      <Helmet>
        <title>Microbe - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Microbe - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name17"></Header>
      <div className="microbe-hero">
        <div className="microbe-container1">
          <h1 className="microbe-text">
            <span className="microbe-text01">
              Microbe Archive of Faculty of Science,
            </span>
            <br></br>
            <span>Prince of Songkla University</span>
            <br></br>
          </h1>
          <span className="microbe-text05">
            <span className="microbe-text06">
              This website serves as a central repository for microbiology
              research conducted by the Faculty of Science at Prince of Songkla
              University. Our team of dedicated lecturers has gathered microbial
              samples, research data, and related information with the aim of
              making it readily available for academic and educational purposes.
              All of the data on this site is freely accessible, and we
              encourage researchers and educators to utilize it in their work.
              We strive to keep the contents of this website up to date with the
              latest findings from our faculty members, making this an
              invaluable resource for those seeking to advance scientific
              knowledge related to microbiology.
            </span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="microbe-search-tab">
        <Autocomplete
          disablePortal
          // options={plantDatas.length!==0?plantDatas.map((row)=>({...row,label:`${option?.family} ${option?.genericName} ${option?.specificEpithet} ${option?.sp || ""} ${option?.subspecies || ""}`})):[]}
          options={
            microbeDatas.length !== 0
              ? microbeDatas
                  .map((row) => ({
                    ...row,
                    groupLabel:
                      row.category === "MICROBE_BACTERIA"
                        ? "BACTERIA"
                        : row.category === "MICROBE_MOLD"
                        ? "MOLD"
                        : row.category === "MICROBE_PROTOZOA"
                        ? "PROTOZOA"
                        : row.category === "MICROBE_VIRUS"
                        ? "VIRUS"
                        : null,
                  }))
                  .sort((a, b) => {
                    // Define the desired sorting order
                    const order = [
                      "MICROBE_PROTOZOA",
                      "MICROBE_MOLD",
                      "MICROBE_BACTERIA",
                      "MICROBE_VIRUS",
                    ];
        
                    // Get the indices of the categories in the order array
                    const indexA = order.indexOf(a.category);
                    const indexB = order.indexOf(b.category);
        
                    // Compare the indices to determine the sorting order
                    return indexA - indexB;
                  })
              : []
          }
          groupBy={(option) => option.groupLabel}
          getOptionLabel={(option) =>
            `${option?.genericName} ${option?.specificEpithet || ""} ${
              option?.sp || ""
            } ${option?.subspecies || ""} ${option?.strainDesignation || ""}`
          }
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Search here" />
          )}
          onChange={(e, value) => {
            // console.log('value', value)
            if (value !== null) {
              setSearchDatas([value])
            } else setSearchDatas([])
          }}
          PopperComponent={StyledPopper}
        />
      </div>
      <div className="microbe-features">
        <div>
          {searchDatas.length !== 0 ? (
            searchDatas?.map((row, index) => (
              <ItemArchiveCardMicrobe
                key={index}
                index={index}
                id={row?._id}
                photo={row?.photo}
                genericName={row?.genericName}
                specificEpithet={row?.specificEpithet}
                sp={row?.sp}
                subspecies={row?.subspecies}
                typeOfIsolate={row?.typeOfIsolate}
                strainDesignation={row?.strainDesignation}
                rootClassName="item-archive-card-root-class-name"
              />
            ))
          ) : (
            <div>
              {protozoaPageData && protozoaPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>PROTOZOA</span>
                  </Box>

                  <div className="plant-container2">
                    {protozoaPageData?.map((row, index) => (
                      <ItemArchiveCardMicrobe
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        sp={row?.sp}
                        subspecies={row?.subspecies}
                        typeOfIsolate={row?.typeOfIsolate}
                        strainDesignation={row?.strainDesignation}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(protozoaData.length / itemsPerPage)}
                      shape="rounded"
                      page={protozoaPage}
                      onChange={handleProtozoaPageChange}
                    />
                  </Stack>
                </div>
              )}

              {moldPageData && moldPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>MOLD</span>
                  </Box>

                  <div className="plant-container2">
                    {moldPageData?.map((row, index) => (
                      <ItemArchiveCardMicrobe
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        sp={row?.sp}
                        subspecies={row?.subspecies}
                        typeOfIsolate={row?.typeOfIsolate}
                        strainDesignation={row?.strainDesignation}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(moldData.length / itemsPerPage)}
                      shape="rounded"
                      page={moldPage}
                      onChange={handleMoldPageChange}
                    />
                  </Stack>
                </div>
              )}

              {bacteriaPageData && bacteriaPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>BACTERIA</span>
                  </Box>

                  <div className="plant-container2">
                    {bacteriaPageData?.map((row, index) => (
                      <ItemArchiveCardMicrobe
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        sp={row?.sp}
                        subspecies={row?.subspecies}
                        typeOfIsolate={row?.typeOfIsolate}
                        strainDesignation={row?.strainDesignation}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(bacteriaData.length / itemsPerPage)}
                      shape="rounded"
                      page={bacteriaPage}
                      onChange={handleBacteriaPageChange}
                    />
                  </Stack>
                </div>
              )}

              {virusPageData && virusPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>VIRUS</span>
                  </Box>

                  <div className="plant-container2">
                    {virusPageData?.map((row, index) => (
                      <ItemArchiveCardMicrobe
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        sp={row?.sp}
                        subspecies={row?.subspecies}
                        typeOfIsolate={row?.typeOfIsolate}
                        strainDesignation={row?.strainDesignation}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(virusData.length / itemsPerPage)}
                      shape="rounded"
                      page={virusPage}
                      onChange={handleVirusPageChange}
                    />
                  </Stack>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer rootClassName="footer-root-class-name16"></Footer>
    </div>
  )
}

export default Microbe
