import React, { useEffect, useState, Fragment, ChangeEvent, useCallback } from "react"


import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import DetailFields from "../components/detail-fields"
import Footer from "../layouts/footer-layout"
import "./add-herbarium.css"
import axios from "axios"
import { request } from "../axios-config"
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  Create as CreateIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Delete,
} from "@mui/icons-material"
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useHistory } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"

const AddGeoArchiveItem = (props) => {

  const requestPrivate = useAxiosPrivate()

  const history = useHistory()
  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const [references, setReferences] = useState([
    { link: "", year: "", type: "", title: "" },
  ])
  const [lecturerForAdd, setlecturerForAdd] = useState([""])
  const [lecturers, setLecturers] = useState([])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getLecturers = async () => {
      try {
        const response = await request.get("lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturers(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getLecturers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const handleDeleteLecturer = (index) => {
    const newSelectedLecturers = [...lecturerForAdd]
    newSelectedLecturers.splice(index, 1)
    setlecturerForAdd(newSelectedLecturers)
  }

  const handleDeleteReference = (index) => {
    const newReferences = [...references]
    newReferences.splice(index, 1)
    setReferences(newReferences)
  }

  const handleLecturerChange = (e, index) => {
    const newValue = e.target.value
    setlecturerForAdd((prevSelectedLecturers) => {
      const newSelectedLecturers = [...prevSelectedLecturers]
      newSelectedLecturers[index] = newValue
      return newSelectedLecturers
    })
  }

  const isLecturerSelected = (lecturerValue, lecturerId) => {
    if (lecturerValue === lecturerId) return false
    return lecturerForAdd.some((selectedId) => selectedId === lecturerId)
  }

  const AddItem = async () => {
    try {
      if (!lecturerForAdd) {
        alert("Lectruer is required")
      }
      else {
        //console.log(lecturerForAdd,"77777777")

        const responseUrlPhoto = await request.post("/upload", {
          photo: filePhotos,
        })
        const response = await requestPrivate.post("/geoItem", {
          ...item,
          photograph: responseUrlPhoto?.data?.urls,
          references: references,
          lecturer: lecturerForAdd,
        })
        //console.log(response,"response55555");
        if (response) {
          alert("success")
          history.push("/geoview")
        }
      }
    } catch (e) {
      console.error(e)
      alert(`${e}`)
    }
  }

  const selectPhotoFiles = (e) => {
    if (filePhotos.length >= 4) {
      alert(`Up to 4 photos can be uploaded.`)
      e.target.value = null
      return
    }

    new Promise((resolve, reject) => {
      e.preventDefault()
      const files = Array.from(e.currentTarget.files)
      if (files?.length + filePhotos?.length > 4) {
        alert(`Up to 4 photos can be uploaded.`)
        e.target.value = null
        return
      }
   
      const invalidFiles = files.filter((file) => file.size > 2000000)
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((file) => file.name).join(", ")
        alert(
          `Please select images with a size not exceeding 2 MB: ${fileNames}`,
        )
        e.target.value = null // clear the input field
        return
      }

      const filePromises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          }),
      )

      Promise.all(filePromises)
        .then((results) => {
          const base64Files = files.map((file, index) => ({
            data: file,
            base64: results[index],
          }))
          setFilePhotos([...base64Files, ...filePhotos])
          resolve(results)
        })
        .catch((error) => reject(error))
    })
  }

  const handleDeletePhoto = (index) => {
    const newPhotos = [...filePhotos]
    newPhotos.splice(index, 1)
    setFilePhotos(newPhotos)
  }

  const ClearData = () => {
    setItem({
      ...item,
      photograph: "",
      province: "",
      district: "",
      location: "",
      longtitude: "",
      latitude: "",
      altitude: "",
      remark: "",
      date: "",
      lecturer: "",
      description: "",
      dataGroup: "",
      type: "",

      references: "",
    })
    setFilePhoto({ data: "", base64: "" })
    setReferences([{ link: "", year: "", type: "", title: "" }])
  }
  
  return (
    <div className="add-herbarium-container">
      <Helmet>
        <title>AddGeoArchive - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddHerbarium - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Geo Lists", path: `/geoview` },
          { title: `Add Geo Item` },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid>
          <Typography variant="h3">Geo Item</Typography>
        </Grid>

        <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              md={12}
              alignItems={"center"}
              direction={"column"}
              spacing={1}
              sx={{ mb: "40px", mt: "5px" }}
            >
              <PhotoAction
                filePhotos={filePhotos}
                handleDeletePhoto={handleDeletePhoto}
                selectPhotoFiles={selectPhotoFiles}
              />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Province</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="province"
                required
                size="small"
                fullWidth
                value={item ? item?.province : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    province: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">District</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="district"
                required
                size="small"
                fullWidth
                value={item ? item?.district : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    district: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Description</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="description"
                required
                size="small"
                fullWidth
                value={item ? item?.description : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    description: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Date</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={item.date}
                  onChange={(newValue) =>
                    setItem({
                      ...item,
                      date: newValue,
                    })
                  }
                  disableFuture
                  renderInput={(params) => (
                    <TextField required size="small" fullWidth {...params} />
                  )}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Location</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="location"
                required
                size="small"
                fullWidth
                value={item ? item?.location : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    location: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Longtitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="longtitude"
                required
                size="small"
                fullWidth
                value={item ? item?.longtitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    longtitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Latitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="latitude"
                required
                size="small"
                fullWidth
                value={item ? item?.latitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    latitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Altitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="altitude"
                required
                size="small"
                fullWidth
                value={item ? item?.altitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    altitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Type</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="type"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        height: "120px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item ? `${item?.type}` : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    type: e.target.value,
                  })
                }}
              >
                <MenuItem value={"Sample"}>Sample</MenuItem>
                <MenuItem value={"Measurement"}>Measurement</MenuItem>
                <MenuItem value={"Obverservation"}>Obverservation</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Datagroup</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="Datagroup"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        height: "90px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item ? `${item?.dataGroup}` : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    dataGroup: e.target.value,
                  })
                }}
              >
                <MenuItem value={"Geophysics"}>Geophysics</MenuItem>
                <MenuItem value={"Geology"}>Geology</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Remark</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="remark"
                required
                size="small"
                fullWidth
                value={item ? item?.remark : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    remark: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          ></Grid>
        </Grid>
        <Grid mt={5}>
          <Typography variant="h5">Researcher</Typography>
          {lecturerForAdd.map((row, index) => {
            const lecturerValue =
              lecturerForAdd[index] !== undefined &&
                lecturerForAdd[index] !== ""
                ? lecturerForAdd[index]
                : row
            const isLastIndex = index === lecturerForAdd.length - 1
            const isLastItem = lecturerForAdd.length === 1
            return (
              <Fragment key={index}>
                <Grid>
                  <Typography variant="subtitle1">{`Researcher ${index + 1
                    }`}</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={2}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">Researcher</Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        id="lecturer"
                        required
                        size="small"
                        fullWidth
                        select
                        value={lecturerValue}
                        onChange={(e) => handleLecturerChange(e, index)}
                      >
                        {lecturers?.length !== 0 ? (
                          lecturers?.map((row, index) => (
                            <MenuItem
                              disabled={isLecturerSelected(
                                lecturerValue,
                                row?._id,
                              )}
                              key={index}
                              value={`${row?._id}`}
                            >{`${row?.title} ${row?.name} ${row?.surname} `}</MenuItem>
                          ))
                        ) : (
                          <MenuItem />
                        )}
                      </TextField>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      {!isLastItem && (
                        <IconButton onClick={() => handleDeleteLecturer(index)}>
                          <Delete />
                        </IconButton>
                      )}

                      {isLastIndex && (
                        <IconButton
                          color={"primary"}
                          onClick={() => {
                            let lecfoad = lecturerForAdd.slice()
                            lecfoad[index + 1] = ""
                            setlecturerForAdd(lecfoad)
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )
          })}

          <Grid mt={5}>
            <Typography variant="h5">References</Typography>
            {references.map((row, index) => {
              const isLastIndex = index === references.length - 1
              const isLastItem = references.length === 1
              return (
                <Fragment key={index}>
                  <Grid>
                    <Typography variant="subtitle1">{`Reference ${index + 1
                      }`}</Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">title</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          multiline
                          id="title"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.title
                              : references[index]?.title
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              title: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">link</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          multiline
                          id="link"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.link
                              : references[index]?.link
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              link: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">year</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="year"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.year
                              : references[index]?.year
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              year: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">type</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="type"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.type
                              : references[index]?.type
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              type: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      {!isLastItem && (
                        <IconButton
                          // color={"primary"}
                          onClick={() => handleDeleteReference(index)}
                        >
                          <Delete />
                        </IconButton>
                      )}

                      {isLastIndex && (
                        <IconButton
                          color={"primary"}
                          onClick={() => {
                            let res = references.slice()
                            res[index + 1] = {
                              title: "",
                              link: "",
                              year: "",
                              type: "",
                            }
                            setReferences(res)
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Fragment>
              )
            })}
          </Grid>
          <Grid
            item
            xs={12}
            direction={"row"}
            display={"flex"}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {console.log()}
            <Button
              // disabled={
              //   item?.photograph === undefined //|| item?.lecturers === undefined
              // }
              variant="contained"
              onClick={AddItem}
            >
              submit
            </Button>
            <Button variant="text" onClick={ClearData}>
              Clear
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default AddGeoArchiveItem