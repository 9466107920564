import React, { useEffect, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import ScientificNameheaderMicrobe from "../components/scientific-nameheader-microbe"
import DetailsCard from "../components/details-card"
import ResearcherCard from "../components/researcher-card"
import Footer from "../layouts/footer-layout"
import "./detail.css"
import { useParams } from "react-router-dom"
import { request } from "../axios-config"

import BreadcrumbLayout from "../components/breadcrumb-layout"
import PhotoDetail from "../components/photoDetail"
import ShowCountViewsByItem from "../components/show-countviews"

const DetailMicrobe = (props) => {
  const params = useParams()
  const [itemDatas, setItemDatas] = useState({})
  const [namePath, setNamePath] = useState("")

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.get(`/item/${params?.id}`, {
          signal: controller.signal,
        })
        isMounted && setItemDatas(response?.data)
        const path = `${response?.data?.category}`.substring(
          0,
          `${response?.data?.category}`.indexOf("_"),
        )
        const pathtoLower = path.toLocaleLowerCase()
        setNamePath(pathtoLower)
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  // console.log(itemDatas)
  // console.log(namePath)

  const nameCategories = (name) => {
    switch (name) {
      case "PLANT_ANGIOSPERM":
        return "ANGIOSPERM"
      case "PLANT_GYMNOSPERM":
        return "GYMNOSPERM"
      case "PLANT_LYCOPHYTEANDFERNS":
        return "LYCOPHYTEANDFERNS"
      case "PLANT_BRYOPHYTES":
        return "BRYOPHYTE"
      case "PLANT_ALGAEANDPHYTOPLANKTON":
        return "ALGAEANDPHYTOPLANKTON"
      case "ANIMAL_VERTEBRATE":
        return "VERTEBRATE"
      case "ANIMAL_INVERTEBRATE":
        return "INVERTEBRATE"
      case "MICROBE_BACTERIA":
        return "BACTERIA"
      case "MICROBE_MOLD":
        return "MOLD"
      case "MICROBE_PROTOZOA":
        return "PROTOZOA"
      case "MICROBE_VIRUS":
        return "VIRUS"
      case "Geophysics":
        return "GEOPHYSICS"
      case "Geology":
        return "GEOLOGY"

      default:
        return null
    }
  }

  return (
    <div className="detail-container">
      <Helmet>
        <title>Detail - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Detail - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name8"></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Microbe", path: `/${namePath}` },
          {
            title: (
              <>
                {itemDatas?.genericName ? <em>{itemDatas.genericName}</em> : ""}{" "}
                {itemDatas?.specificEpithet ? <em>{itemDatas.specificEpithet}</em> : ""}{" "}
                {itemDatas?.sp || ""}{" "}
                {itemDatas?.subspecies || ""}{" "}
                {itemDatas?.strainDesignation || ""}
              </>
            ),
          },
        ]}
      />
      <div className="detail-research-content">
        <ScientificNameheaderMicrobe
          GenericName={itemDatas?.genericName}
          SpecificEpithet={itemDatas?.specificEpithet}
          Sp={itemDatas?.sp}
          Subspecies={itemDatas?.subspecies}
          StrainDesignation={itemDatas?.strainDesignation}
        ></ScientificNameheaderMicrobe>
        <div className="detail-separator"></div>
        <ShowCountViewsByItem itemId={params?.id} nameModel={"ArchiveModel"} />
        <div className="detail-container1">
          <div className="detail-container2">
            <PhotoDetail itemphoto={itemDatas?.photo} />
            {/* <a
              href={itemDatas?.references?.[0].link}
              target="_blank"
              rel="noreferrer noopener"
              className="detail-link button"
            >
              Reference Article
            </a> */}
          </div>
          <div className="detail-container3">
            <DetailsCard
              title={"Family"}
              description={itemDatas?.family || "-"}
              rootClassName="rootClassName1"
            ></DetailsCard>
            <DetailsCard
              title={"Generic Name"}
              description={<i>{itemDatas?.genericName || "-"}</i>}
              rootClassName="rootClassName1"
            ></DetailsCard>
            <DetailsCard
              title={"Specific Epithet"}
              description={<i>{itemDatas?.specificEpithet || "-"}</i>}
              rootClassName="rootClassName1"
            ></DetailsCard>
            {/* <DetailsCard
              title={"sp./spp."}
              description={itemDatas?.sp || "-"}
              rootClassName="rootClassName1"
            ></DetailsCard> */}
            <DetailsCard
              title={"Scientific name"}
              description={
                <span>
                  <p>
                    <em>{itemDatas?.genericName ? itemDatas?.genericName + "\u00A0" : ""}</em>
                    <em>{itemDatas?.specificEpithet ? itemDatas?.specificEpithet + "\u00A0" : ""}</em>
                    <span style={{ fontWeight: "normal" }}>
                      {itemDatas?.sp ? itemDatas?.sp + "\u00A0" : ""}
                    </span>
                    <span style={{ fontWeight: "normal" }}>
                      {itemDatas?.subspecies ? itemDatas?.subspecies + "\u00A0" : ""}
                    </span>
                    {itemDatas?.strainDesignation || ""}
                  </p>
                </span>
              }
              rootClassName="rootClassName1"
            ></DetailsCard>
            <DetailsCard
              title={"Subspecies"}
              description={<i>{itemDatas?.subspecies || "-"}</i>}
              rootClassName="rootClassName4"
            ></DetailsCard>
            <DetailsCard
              title={"Serovar"}
              description={itemDatas?.serovar || "-"}
              rootClassName="rootClassName3"
            ></DetailsCard>
            <DetailsCard
              title={"Serotype"}
              description={itemDatas?.serotype || "-"}
              rootClassName="rootClassName2"
            ></DetailsCard>
            <DetailsCard
              title={"Biotype"}
              description={itemDatas?.biotype || "-"}
              rootClassName="details-card-root-class-name5"
            ></DetailsCard>
            <DetailsCard
              title={"Strain designation"}
              description={itemDatas?.strainDesignation || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
            <DetailsCard
              title={"Specific applications"}
              description={itemDatas?.specificApplications || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
            <DetailsCard
              title={"Type of isolate"}
              description={itemDatas?.typeOfIsolate || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
            <DetailsCard
              title={"Isolation source"}
              description={itemDatas?.isolationSource || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
            <DetailsCard
              title={"Geographical isolation"}
              description={itemDatas?.geographicalIsolation || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
            <DetailsCard
              title={"Medium/Temperature"}
              description={itemDatas?.optimumTemperature || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
            <DetailsCard
              title={"BSL"}
              description={itemDatas?.bsl || "-"}
              rootClassName="details-card-root-class-name7"
            ></DetailsCard>
          </div>
        </div>
      </div>

      <div class="detail-research-content">
        <h2 class="mb-2 text-lg flex flex-wrap items-center justify-center font-semibold text-gray-900 ">
          More References
        </h2>
        <ul class="container mx-auto p-6 justify-center flex flex-wrap flex-1 items-stretch mb-6 text-blue-900 ">
          {itemDatas?.references?.map((row, index) => (
            <li>
              <div class="max-w-sm rounded overflow-hidden shadow-lg h-full">
                <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2">{row?.title}</div>

                  <p class="text-gray-700 text-base">
                    {row?.type}, {row?.year}
                  </p>
                </div>

                <div class="px-6 pt-4 pb-2">
                  {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span> */}

                  <a
                    href="#"
                    class="mr-4 hover:underline flex flex-wrap items-center justify-center md:mr-6 "
                  >
                    <a
                      href={row?.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="detail-link2 button"
                    >
                      Reference Article
                    </a>
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="detail-banner">
        <h2 className="detail-text">
          <br></br>
          <span>Affiliated Researcher</span>
          <br></br>
          <span>Faculty of Science, PSU</span>
          <span></span>
          <br className="detail-text3"></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </h2>
      </div>
      <div className="detail-researcher">
        <div className="detail-container4">
          {itemDatas?.lecturerData?.map((row) => (
            <ResearcherCard
              id={row?._id}
              photo={row.pictureUrl}
              name={row?.title + " " + row?.name + " " + row?.surname}
              division={row?.major}
              email={row?.email}
              rootClassName="researcher-card-root-class-name"
            />
          ))}
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name7"></Footer>
    </div>
  )
}

export default DetailMicrobe
