import React, { useState } from "react"
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './item-archive-card.css'

const ItemArchiveCardPlant = (props) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const containerStyle = {
    backgroundImage:
      props.photo.length > 0
        ? `url("${props.photo[0]}")`
        : "url(/playground_assets/rectangle493235-fxf-400h.png)",
    backgroundSize: isHovered ? "170%" : "cover",
    backgroundPosition: isHovered ? "center left" : "center",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    transition:
      "transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, background-size 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, background-position 0.5s",
  }
  return (
    <div
      key={props?.index}
      className={`item-archive-card-testimonial-card ${props.rootClassName} `}
    >
      <Link
        to={`/detailplant/${props?.id}`}
        className="item-archive-card-navlink"
      >
        {/* <div className="item-archive-card-container" style={{backgroundImage:`url("${props.photo}")`}}> */}
        <div
          className="item-archive-card-container"
          style={containerStyle}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <div className="item-archive-card-container1" style={{ padding: 20 }}>
            <span className="item-archive-card-text">
              <i>{props.genericName}</i>&nbsp;
              <i>{props.specificEpithet}</i>&nbsp;
              {props.authorName1}&nbsp;
              {props.authorName2 != "" ? `& ${props.authorName2}` : ""}
            </span>
            <span className="item-archive-card-text1">{props.commonName}</span>
            <span className="item-archive-card-text2">
              {props.vercularName}
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

ItemArchiveCardPlant.defaultProps = {
  index: 0,
  genericName: "",
  specificEpithet: "",
  authorName1: "",
  authorName2: "",
  commonName: "",
  vercularName: "",
  rootClassName: "",
  photo: ["/playground_assets/rectangle493235-fxf-400h.png"],
}

ItemArchiveCardPlant.propTypes = {
  index: PropTypes.number,
  photo: PropTypes.array,
  genericName: PropTypes.string,
  specificEpithet: PropTypes.string,
  authorName1: PropTypes.string,
  authorName2: PropTypes.string,
  commonName: PropTypes.string,
  vercularName: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default ItemArchiveCardPlant
