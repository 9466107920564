import React, { useState, useEffect } from 'react'
import { Grid, TextField, Typography, Container, Button, Stack } from '@mui/material'
import { Helmet } from 'react-helmet'
import { Autocomplete } from '@mui/material'
import { request } from '../axios-config'

import Header from '../layouts/header-layout'
import AdvancesearchCard from '../components/advanceresearchCard'
import Footer from '../layouts/footer-layout'
import HerbariumFooter from '../layouts/footer-herbarium'
import './herbarium-advancesearch.css'

import BreadcrumbLayout from "../components/breadcrumb-layout"

const HerbariumAdvancesearch = (props) => {
  const [herbariumData, setHerbariumData] = useState([])
  const [searchDatas, setSearchDatas] = useState([])
  const [searchDetail, setSearchDetail] = useState([])
  const [showData, setShowData] = useState(false)
  const [dataFound, setDataFound] = useState(true);

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getItem = async () => {
      try {
        const response = await request.get('/herbariumItem', {
          signal: controller.signal
        })
        isMounted && setHerbariumData(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  const [searchParams, setSearchParams] = useState({
    family: "",
    genus: "",
    species: "",
    author1: "",
    intraspecificStatus: "",
    intraspecificName: "",
    author2: "",
  });

  const handleSearch = async (event) => {
    event.preventDefault();
    const filteredData = herbariumData.filter((data) =>
      Object.entries(searchParams).every(
        ([key, value]) => !value || data[key]?.includes(value)
      )
    );
    setShowData(true);
    setSearchDatas(filteredData);
    setDataFound(filteredData.length > 0);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    if (name === "family" || name === "genus") {
      updatedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    setSearchParams({
      ...searchParams,
      [event.target.name]: updatedValue,
    });
  };

  return (
    <div className="herbarium-advancesearch-container">
      <Helmet>
        <title>HerbariumAdvancesearch - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="HerbariumAdvancesearch - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header />

      <div className="herbarium-advancesearch-hero">
        <div className="herbarium-advancesearch-container1">
          <h1 className="herbarium-advancesearch-text">
            <span>What is a herbarium PSU</span>
            <br />
          </h1>
          <span className="herbarium-advancesearch-text3">
            A herbarium is a critical resource for biodiversity, ecological, and
            evolutionary research studies. It is a primary data source of dried
            and labeled plant specimens that is arranged to allow for easy
            retrieval access and archival storage. A herbarium is like a
            library, but differs in that the information is stored in a
            biological form – as pressed, dried, and annotated plant specimens
            (in the case of most vascular plants; lichens, fungi, bryophytes and
            some vascular plants are preserved slightly differently, although
            the main points are the same).
          </span>
        </div>
      </div>

      <BreadcrumbLayout
        pages={[
          { title: "Herbarium", path: `/herbarium-main` },
          { title: "Advanced Search" },
        ]}
      />

      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid>
          <Typography variant="h4">Scientific Name</Typography>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Family</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                label="Search Family"
                name="family"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Generic Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                label="Search Genus"
                name="genus"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Specific Epithet</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                label="Search Species"
                name="species"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author 1</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                label="Search Author"
                name="author1"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Intraspecific Status</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                label="Search Intraspecific Status"
                name="intraspecificStatus"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Intraspecific Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                name="intraspecificName"
                label="Search Intraspecific Name"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author 2</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                variant='outlined'
                label="Search Author 2"
                name="author2"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

        </Grid>
      </Container>

      <Stack sx={{ mt: 10, width: "20%" }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSearch}
        >
          SEARCH
        </Button>
      </Stack>

      {showData && (
        <div className="homepage-researcher">
          <div className="homepage-container09">
            <div className="homepage-container10">
              {
                searchDatas.length > 0 ? (
                  searchDatas.map((row, index) => (
                    <AdvancesearchCard
                      key={index}
                      id={row?._id}
                      index={index}
                      photograph={row?.photograph}
                      family={row?.family}
                      genus={row?.genus}
                      species={row?.species}
                      author1={row?.author1}
                      intraspecificStatus={row?.intraspecificStatus}
                      intraspecificName={row?.intraspecificName}
                      author2={row?.author2}
                      rootClassName="researcher-card-root-class-name10"
                    />
                  ))
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <Typography variant='h4'>
                      Not Found
                    </Typography>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}

      <HerbariumFooter rootClassName="footer-root-class-name9"></HerbariumFooter>
    </div>
  )
}

export default HerbariumAdvancesearch;