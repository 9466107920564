import React, { useState, ChangeEvent, Fragment, useEffect } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-archive-items.css"
import { request } from "../axios-config"
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography, Stack
} from "@mui/material"

import {
    Create as CreateIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    Delete,
} from "@mui/icons-material"

import BreadcrumbLayout from "../components/breadcrumb-layout"
import { useHistory, useParams } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"

const EditStaffCollector = (props) => {
    const requestPrivate = useAxiosPrivate()

    const params = useParams()
    const history = useHistory()

    const [item, setItem] = useState({})
    const [filePhoto, setFilePhoto] = useState({ data: null, base64: "" })
    const [archive, setArchive] = useState([])

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        const getArchive = async () => {
            try {
                const response = await request.get(`/collector/${params.id}`, {
                    signal: controller.signal,
                })
                isMounted && setArchive(response?.data)
                isMounted &&
                    setItem({
                        ...item,
                        collectorName: response?.data?.collectorName,
                        collectorSurname: response?.data?.collectorSurname,
                        email: response?.data?.email,
                        phoneNumber: response?.data?.phoneNumber,
                        title: response?.data?.title,
                        afflication: response?.data?.afflication,
                        designation: response?.data?.designation,
                        photo: response?.data?.photo || "-",

                    })
                // setFilePhoto(response?.data?.photo || "")
            } catch (error) {
                console.error(error)
            }
        }
        getArchive()
        return () => {
            isMounted = false
            controller.abort()
        }
    }, [params.id])

    const SubmitEditArchive = async (url) => {
        try {
 
                const responseUrlPhoto = await request.post("/uploadstaff", {
                    photo: filePhoto?.base64 ? { dataphoto: filePhoto?.base64, type: "base64" } : {
                        dataphoto: item?.photo,
                        type: "url",
                    },
                })
                const response = await requestPrivate.put(`/updatecollector/${params?.id}`, {
                    ...item,
                    photo: responseUrlPhoto?.data?.url,
                })
                if (
                    response &&
                    !(filePhoto?.base64 === "")
                ) {
                    if (url) {
                        const parts = url.split("/")
                        const filename = parts[parts.length - 1]
                        await request.delete(
                            `/deleteonephoto/${filename}`,
                            {},
                        )
                    }
                }
                alert("success")
                history.push("/staff-collector-view")
            
        } catch (e) {
            console.error(e)
        }
    }
    // console.log("references", references)

    const selectPhotoFile = (e) => {
        new Promise((resolve, reject) => {
            e.preventDefault()
            const selectedFile = e.currentTarget.files[0]
            if (selectedFile.size > 2000000) {
                alert("กรุณาใส่รูปที่มีขนาดไม่เกิน 2 mb");
                e.target.value = null; // clear the input field
                return;
            } else {
                setFilePhoto({ data: selectedFile })
                const reader = new FileReader()
                reader.readAsDataURL(selectedFile)
                reader.onload = () => {
                    resolve(reader.result)
                    setFilePhoto((prevFilePhoto) => ({ ...prevFilePhoto, base64: reader.result }))
                }
                reader.onerror = (error) => reject(error)
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>Edit Staff - Sci PSU Archive</title>
                <meta
                    name="description"
                    content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                />
                <meta
                    property="og:title"
                    content="EditArchiveItems - Sci PSU Archive"
                />
                <meta
                    property="og:description"
                    content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
                />
            </Helmet>
            <Header rootClassName="header-root-class-name1" auth={true}></Header>
            <BreadcrumbLayout
                pages={[
                    { title: "Admin Menu", path: `/adminmenu` },
                    { title: "Staff List", path: `/staff-collector-view` },
                    {
                        title:
                            `
                        ${archive?.title || ""} 
                        ${archive?.collectorName || ""} 
                        ${archive?.collectorSurname || ""} 
                        `,
                    },
                ]}
            />
            <Container sx={{ mt: 5 }} maxWidth="xl">
                <Grid container direction={"row"} sx={{ alignItems: "center", mb: 3, wordWrap: 'break-word' }}>
                    {/* <Grid sx={{ ml: 1 }}>
                        <Typography variant="h5">(Edit)</Typography>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5">
                            {archive.title ? archive.title + "\u00A0" : ""}
                            {archive.collectorName ? archive.collectorName + "\u00A0" : ""}
                            {archive.collectorSurname ? archive.collectorSurname + "\u00A0" : ""}
                            / (Edit)
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        alignItems={"center"}
                        direction={"column"}
                        spacing={1}
                        sx={{ mb: "10px", mt: "5px" }}
                    >

                        <label style={{ position: "relative" }} htmlFor="photo-project-file">
                            <Box
                                component={"img"}
                                zIndex={1}
                                variant="square"
                                size="lg"
                                src={filePhoto.base64 ? filePhoto?.base64 : item?.photo}
                                alt="กรุณาเลือกรูปภาพ"
                                sx={{
                                    objectFit: "cover",
                                    width: "200px",
                                    height: "200px",
                                    border: "1px dashed grey",
                                    "&:hover": {
                                        backgroundColor: "#14A9FF",
                                        opacity: [0.9, 0.8, 0.7],
                                        cursor: "pointer",
                                    },
                                }}
                            />

                            <IconButton
                                sx={{
                                    backgroundColor: "white",
                                    position: "absolute",
                                    right: "4px",
                                    bottom: "5px",
                                    zIndex: -1,
                                }}
                                color={"primary"}
                            >
                                <CreateIcon />
                            </IconButton>

                            <input
                                onChange={selectPhotoFile}
                                accept="image/*"
                                id="photo-project-file"
                                type="file"
                                style={{ display: "none" }}
                            />
                        </label>
                        <Grid mt={1}>
                            <p style={{ color: "#999999" }}>
                                รูปภาพขนาด 600x800 px. (3:4) ขนาดไม่เกิน 2 mb.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Title <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="title"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.title : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        title: e.target.value,
                                    })
                                }}
                                error={ item && item?.title === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.title === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Name <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="collectorName"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.collectorName : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        collectorName: e.target.value,
                                    })
                                }} 
                                error={ item && item?.collectorName === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.collectorName === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Surname <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="collectorSurname"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.collectorSurname : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        collectorSurname: e.target.value,
                                    })
                                }}
                                error={ item && item?.collectorSurname === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.collectorSurname === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Email <span style={{color: 'red'}}>*</span></Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="email"
                                type="email"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.email : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        email: e.target.value,
                                    })
                                }}
                                error={ item && item?.email === ""  ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        item && item.email === "" ? (
                                            <InputAdornment position="end">
                                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                                            </InputAdornment>
                                        ) : null
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Mobile Number</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                multiline
                                id="phoneNumber"
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.phoneNumber : ""}
                                onChange={(e) => {
                                    const input = e.target.value.replace(/[^0-9]/g, "");
                                    setItem({
                                        ...item,
                                        phoneNumber: input,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Affiliation</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="title"
                                multiline
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.afflication : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        afflication: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2">Designation</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                id="title"
                                multiline
                                required
                                size="small"
                                fullWidth
                                value={item ? item?.designation : ""}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        designation: e.target.value,
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>



                <Grid
                    mt={10}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                >
                    <Button
                        disabled={
                            item?.collectorName === undefined || item?.collectorName === "" ||
                            item?.collectorSurname === undefined || item?.collectorSurname === "" ||
                            item?.title === undefined || item?.title === "" ||
                            item?.email === undefined || item?.email === "" 
                        }
                        variant="contained"
                        onClick={() => SubmitEditArchive(item?.photo)}
                    >
                        submit
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => {
                            history.push("/staff-collector-view")
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Container>
            <Footer rootClassName="footer-root-class-name"></Footer>
        </>
    )
}

export default EditStaffCollector
