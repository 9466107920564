import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"

import "./borrowform.css"
import { Button, Grid, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { request } from "../axios-config"
import { useHistory } from "react-router"

const Borrowform = (props) => {
  const [dataForm, setDataForm] = useState({})
  const history = useHistory({})

  useEffect(() => {
    setDataForm({ ...dataForm, plant: props?.plantId })
  }, [props?.plantId])

  const CreateBorrowForm = async (event) => {
    event.preventDefault()

    try {
      const response = await request.post("/borrowform", {
        ...dataForm,
      })
      if (response) {
        alert("success")
        history.push(`/herbarium-detail/${props?.plantId}`)
      }
    } catch (e) {
      console.error(e)
      alert(`${e?.data?.message}`)
    }
  }
  return (
    <div className={`borrowform-container ${props.rootClassName} `}>
      {/* <div className="borrowform-container01">
        <div className="borrowform-container02">
          <h1 className="borrowform-text">Firstname</h1>
        </div>
        <div className="borrowform-container03">
          <input
            type="text"
            required
            placeholder={props.firstname}
            className="input"
          />
        </div>
      </div>
      <div className="borrowform-container04">
        <div className="borrowform-container05">
          <h1 className="borrowform-text1">Lastname</h1>
        </div>
        <div className="borrowform-container06">
          <input
            type="text"
            required
            placeholder={props.Lastname}
            className="input"
          />
        </div>
      </div>
      <div className="borrowform-container07">
        <div className="borrowform-container08">
          <h1 className="borrowform-text2">E-mail</h1>
        </div>
        <div className="borrowform-container09">
          <input
            type="text"
            required
            placeholder={props.Email}
            className="input"
          />
        </div>
      </div>
      <button type="button" className="borrowform-button button">
        {props.button}
      </button> */}

      <Box
        component="form"
        onSubmit={CreateBorrowForm}
        sx={{ width: { md: "100%", xs: "max-content" } }}
      >
        <Grid container direction={"column"}>
          <Grid item>
            <Grid container direction={"row"} alignContent="center">
              <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
                <Typography variant="h5">Firstname</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  size="small"
                  fullWidth
                  id="name"
                  required
                  value={dataForm ? dataForm?.name : ""}
                  onChange={(e) => {
                    setDataForm({
                      ...dataForm,
                      name: e.target.value,
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ my: 2 }}>
            <Grid container direction={"row"} alignContent="center">
              <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
                <Typography variant="h5">Lastname</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  size="small"
                  fullWidth
                  id="surname"
                  required
                  value={dataForm ? dataForm?.surname : ""}
                  onChange={(e) => {
                    setDataForm({
                      ...dataForm,
                      surname: e.target.value,
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction={"row"} alignContent="center">
              <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
                <Typography variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  size="small"
                  fullWidth
                  id="email"
                  required
                  type={"email"}
                  value={dataForm ? dataForm?.email : ""}
                  onChange={(e) => {
                    setDataForm({
                      ...dataForm,
                      email: e.target.value,
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ mt: 3 }}
          >
            <Grid item xs={12} md={9}>
              <Button
                disabled={
                  dataForm?.name === undefined || dataForm?.surname === undefined || dataForm?.email === undefined
                }
                fullWidth variant="contained" type="submit">
                CONFIRM
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

Borrowform.defaultProps = {
  rootClassName: "",
  button: "CONFIRM",
  Email: "e-mail",
  Lastname: "lastname",
  firstname: "firstname",
  plantId: "",
}

Borrowform.propTypes = {
  rootClassName: PropTypes.string,
  button: PropTypes.string,
  Email: PropTypes.string,
  Lastname: PropTypes.string,
  firstname: PropTypes.string,
  plantId: PropTypes.string,
}

export default Borrowform
