import React, { useState, ChangeEvent, Fragment, useEffect } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import DetailFields from "../components/detail-fields"
import Footer from "../layouts/footer-layout"
import "./add-archive-items.css"
import axios from "axios"
import { request } from "../axios-config"
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  Create as CreateIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from "@mui/icons-material"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { useHistory, useParams } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"

const EditArchiveItems = (props) => {

  const requestPrivate = useAxiosPrivate()

  const params = useParams()
  const history = useHistory()

  const [item, setItem] = useState({})
  const [filePhoto, setFilePhoto] = useState({ data: null, base64: "" })
  const [references, setReferences] = useState([
    { link: "", year: "", type: "", title: "" },
  ])
  const [lecturers, setLecturers] = useState([])
  const [archive, setArchive] = useState([])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getArchive = async () => {
      try {
        const response = await request.get(`/item/${params.id}`, {
          signal: controller.signal,
        })
        isMounted && setArchive(response?.data)
        isMounted &&
          setItem({
            ...item,
            family: response?.data?.family,
            category: response?.data?.category,
            genericName: response?.data?.genericName,
            specificEpithet: response?.data?.specificEpithet,
            authorName1: response?.data?.authorName1,
            infraspecificLevel: response?.data?.infraspecificLevel,
            infraspecificName: response?.data?.infraspecificName,
            authorName2: response?.data?.authorName2,
            commonName: response?.data?.commonName,
            vercularName: response?.data?.vercularName,
            tradingName: response?.data?.tradingName,
            locality: response?.data?.locality,
            utilization: response?.data?.utilization,
            optimumTemperature: response?.data?.optimumTemperature,
            lecturer: response?.data?.lecturer,
          })
      } catch (error) {
        console.error(error)
      }
    }
    getArchive()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params.id])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getLecturers = async () => {
      try {
        const response = await request.get("/lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturers(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getLecturers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const SubmitEditArchive = async () => {
    try {
      if (!item?.scientificName) {
        alert("Scientific Name  is required")
      } else if (!references[0]?.title) {
        alert("References is required")
      } else {
        const responseUrlPhoto = await request.post("/upload", {
          photo: filePhoto?.base64,
        })
        const response = await requestPrivate.put(`/updateItem/${params?.id}`, {
          ...item,
          photo: responseUrlPhoto?.data?.url,
          references: references,
        })
        if (response) {

          alert("success")
          history.push("/archiveview")
        }
      }
    } catch (e) {
      console.error(e)
    }

    // console.log(response);
  }

  const selectPhotoFile = (e) =>
    new Promise((resolve, reject) => {
      e.preventDefault()
      const file = e.currentTarget.files[0]
      setFilePhoto({ data: file })
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
        setFilePhoto({ base64: reader.result })
      }
      reader.onerror = (error) => reject(error)
    })
  // console.log("references", references)
  return (
    <>
      <Helmet>
        <title>EditArchiveItems - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="EditArchiveItems - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Archive Lists", path: `/archiveview` },
          { title: `${archive?.scientificName}` },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid container direction={"row"} sx={{ alignItems: "center", mb: 3 }}>
          <Grid>
            <Typography variant="h4" fontStyle="italic">
              {archive?.scientificName}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h5">(Edit)</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"column"}
            spacing={1}
            sx={{ mb: "10px", mt: "5px" }}
          >
            <label
              style={{ position: "relative" }}
              htmlFor="photo-project-file"
            >
              <Box
                component={"img"}
                zIndex={1}
                variant="square"
                size="lg"
                src={filePhoto?.base64}
                alt="กรุณาเลือกรูปภาพ"
                sx={{
                  objectFit: "cover",
                  width: "200px",
                  height: "200px",
                  border: "1px dashed grey",
                  "&:hover": {
                    backgroundColor: "#14A9FF",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              />
              <IconButton
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  right: "4px",
                  bottom: "5px",
                }}
                color={"primary"}
              >
                <CreateIcon />
              </IconButton>
              <input
                onChange={selectPhotoFile}
                accept="image/*"
                id="photo-project-file"
                type="file"
                style={{ display: "none" }}
              />
            </label>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Scientific Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="scientificName"
                required
                size="small"
                fullWidth
                value={item ? item?.scientificName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    scientificName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid> */}
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Family</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="family"
                size="small"
                fullWidth
                value={item ? item?.family : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    family: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Category</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="category"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        height: "300px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item ? item?.category : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    category: e.target.value,
                  })
                }}
              >
                <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Plant
                </ListSubheader>
                <MenuItem value={"PLANT_ANGIOSPERM"}>Angiospem</MenuItem>
                <MenuItem value={"PLANT_GYMNOSPERM"}>Gymnosperm</MenuItem>
                <MenuItem value={"PLANT_LYCOPHYTEANDFERNS"}>
                  Lycophyteandferns
                </MenuItem>
                <MenuItem value={"PLANT_BRYOPHYTES"}>Bryophytes</MenuItem>
                <MenuItem value={"PLANT_ALGAEANDPHYTOPLANKTON"}>
                  Algaeandphytoplankton
                </MenuItem>
                <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Animal
                </ListSubheader>
                <MenuItem value={"ANIMAL_VERTEBRATE"}>Vertebrate</MenuItem>
                <MenuItem value={"ANIMAL_INVERTEBRATE"}>
                  Invertebrate
                </MenuItem>
                <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Microbe
                </ListSubheader>
                <MenuItem value={"MICROBE_BACTERIA"}>Bacteria</MenuItem>
                <MenuItem value={"MICROBE_MOLD"}>Mold</MenuItem>
                <MenuItem value={"MICROBE_PROTOZOA"}>Protozoa</MenuItem>
                <MenuItem value={"MICROBE_VIRUS"}>Virus</MenuItem>
                <ListSubheader sx={{ bgcolor: "#dddd" }} color="primary">
                  Geo
                </ListSubheader>
                <MenuItem value={"GEO_ROCK"}>Rock</MenuItem>
                <MenuItem value={"GEO_MAP"}>Map</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Generic Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="genericName"
                size="small"
                fullWidth
                value={item ? item?.genericName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    genericName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Specific Epithet</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="specificEpithet"
                size="small"
                fullWidth
                value={item ? item?.specificEpithet : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specificEpithet: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author Name 1</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="authorName1"
                size="small"
                fullWidth
                value={item ? item?.authorName1 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    authorName1: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Infraspecific Level</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="infraspecificLevel"
                size="small"
                fullWidth
                value={item ? item?.infraspecificLevel : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    infraspecificLevel: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Infraspecific Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="infraspecificName"
                size="small"
                fullWidth
                value={item ? item?.infraspecificName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    infraspecificName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author Name 2</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="authorName2"
                size="small"
                fullWidth
                value={item ? item?.authorName2 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    authorName2: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Common Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="commonName"
                size="small"
                fullWidth
                value={item ? item?.commonName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    commonName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Vernacular Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="vercularName"
                size="small"
                fullWidth
                value={item ? item?.vercularName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    vercularName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">tradingName</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="tradingName"
                size="small"
                fullWidth
                value={item ? item?.tradingName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    tradingName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Locality</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="locality"
                size="small"
                fullWidth
                value={item ? item?.locality : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    locality: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Ecology And Habitat</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="ecologyAndHabitat"
                size="small"
                fullWidth
                value={item ? item?.ecologyAndHabitat : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    ecologyAndHabitat: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Utilization</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="utilization"
                size="small"
                fullWidth
                value={item ? item?.utilization : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    utilization: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Optimum Temperature</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="optimumTemperature"
                size="small"
                fullWidth
                value={item ? item?.optimumTemperature : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    optimumTemperature: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Researcher</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="lecturer"
                required
                size="small"
                fullWidth
                select
                value={item?.lecturer ? item?.lecturer : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    lecturer: e.target.value,
                  })
                }}
              >
                {lecturers?.length !== 0 ? (
                  lecturers?.map((row, index) => (
                    <MenuItem
                      key={index}
                      value={`${row?._id}`}
                    >{`${row?.title} ${row?.name} ${row?.surname} `}</MenuItem>
                  ))
                ) : (
                  <MenuItem />
                )}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={5}>
          <Typography variant="h5">References</Typography>
          {references.map((row, index) => {
            return (
              <Fragment key={index}>
                <Grid>
                  <Typography variant="subtitle1">{`Reference ${index + 1
                    }`}</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">title</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        id="title"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.title
                            : references[index]?.title
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            title: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">link</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        id="link"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.link
                            : references[index]?.link
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            link: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">year</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        id="year"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.year
                            : references[index]?.year
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            year: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <Grid item xs={6} md={4}>
                      <Typography variant="subtitle2">type</Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        id="type"
                        size="small"
                        fullWidth
                        required
                        value={
                          references[index]
                            ? references[index]?.type
                            : references[index]?.type
                        }
                        onChange={(e) => {
                          let res = references.slice()
                          let re = {
                            ...references[index],
                            type: e.target.value,
                          }
                          res[index] = re
                          setReferences(res)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    direction={"row"}
                    spacing={1}
                  >
                    <IconButton
                      color={"primary"}
                      onClick={() => {
                        let res = references.slice()
                        res[index + 1] = {
                          title: "",
                          link: "",
                          year: "",
                          type: "",
                        }
                        setReferences(res)
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
        <Grid
          mt={10}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          {/* {console.log()} */}
          <Button
            disabled={
              item?.scientificName === undefined || item?.category === undefined
            }
            variant="contained"
            onClick={SubmitEditArchive}
          >
            submit
          </Button>
          <Button
            variant="text"
            onClick={() => {
              history.push("/archiveview")
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Container>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </>
  )
}

export default EditArchiveItems
