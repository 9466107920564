import React, { Fragment, useEffect, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import DetailFields from "../components/detail-fields"
import Footer from "../layouts/footer-layout"
import "./add-lecturer.css"
import { request } from "../axios-config"
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useHistory, useParams } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"

const EditLecturer = (props) => {
  const requestPrivate = useAxiosPrivate()
  const [researcher, setResearcher] = useState({})
  const [researcherData, setResearcherData] = useState({})
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.get(`/lecturer/${params?.id}`, {
          signal: controller.signal,
        })
        isMounted && setResearcherData(response?.data)
        isMounted &&
          setResearcher({
            ...researcher,
            name: response?.data?.name,
            surname: response?.data?.surname,
            title: response?.data?.title,
            pictureUrl: response?.data?.pictureUrl,
            email: response?.data?.email,
            major: response?.data?.major,
            profileLink: response?.data?.profileLink,
          })
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const EditResearcher = async () => {
    try {
      if (!researcher?.name) {
        alert("Name is required")
      } else {
        const response = await requestPrivate.put(`/updateLecturer/${params?.id}`, {
          ...researcher,
        })
        if (response) {
          history.push("/lecturerview")
          alert("success")
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>AddLecturer - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddLecturer - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name3" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Researcher Lists", path: `/lecturerview` },
          {
            title: `${researcherData?.title ? researcherData?.title : ""} ${researcherData?.name ? researcherData?.name : ""
              } ${researcherData?.surname ? researcherData?.surname : ""} `,
          },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid container direction={"row"} sx={{ alignItems: "center", mb: 3 }}>
          <Grid>
            <Typography variant="h4" fontStyle="italic">
              {`${researcherData?.title ? researcherData?.title : ""}${researcherData?.name ? researcherData?.name : ""
                } ${researcherData?.surname ? researcherData?.surname : ""} `}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h5">(Edit)</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="name"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.name : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    name: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Surname</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="surname"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.surname : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    surname: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Title</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="title"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.title : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    title: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Picture Url</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="pictureUrl"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.pictureUrl : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    pictureUrl: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Email</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="email"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.email : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    email: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Division</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="major"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.major : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    major: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Profile Link</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="profileLink"
                required
                size="small"
                fullWidth
                value={researcher ? researcher?.profileLink : ""}
                onChange={(e) => {
                  setResearcher({
                    ...researcher,
                    profileLink: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            direction={"row"}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          // spacing={2}
          >
            <Button variant="contained" onClick={EditResearcher}>
              Submit
            </Button>
            <Button
              variant="text"
              onClick={() => {
                history.push("/lecturerview")
              }}
            >
              cancel
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer rootClassName="footer-root-class-name2"></Footer>
    </Fragment>
  )
}

export default EditLecturer
