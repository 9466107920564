import React, { useEffect, useState } from 'react'
import { Link } from '@mui/icons-material'

import Pagination from '@mui/material/Pagination';
import {
    Stack,
    Container,
    Grid,
    Box,
    CardMedia
} from '@mui/material'

import { Helmet } from 'react-helmet'
import HerbariumAppBar from '../../layouts/header-herbarium'
import FooterHerbarium from '../../layouts/new-footerherbarium'

import CardCategories from '../../components/card-categories'
import HerbariumCard from '../../components/herbarium-card'
import '../homepage.css'
import '../plant.css'
import '../../components/herbarium-staff-card'
import { request } from '../../axios-config'

const textPathImage = "/playground_assets/herbarium/"
const itemsPerPage = 6;

const HerbariumStaff = (props) => {

    const [collectorDatas, setCollectorDatas] = useState([])

    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = collectorDatas.slice(startIndex, endIndex);

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController();
        const getItem = async () => {
            try {
                const response = await request.get('/insidercollector', {
                    signal: controller.signal
                })
                isMounted && setCollectorDatas(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getItem();
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    return (
        <Stack>
            <Helmet>
                <title>Herbarium Staff - Sci PSU Archive</title>
                <meta
                    name="description"
                    content="Science Natural Archive Platform is initiated by the Faculty of Science, Prince of Songkla University."
                />
                <meta property="og:title" content="Sci PSU Archive" />
                <meta
                    property="og:description"
                    content="Science Natural Archive Platform is initiated by the Faculty of Science, Prince of Songkla University."
                />
            </Helmet>
            <HerbariumAppBar></HerbariumAppBar>
            <Box className="staff-page-herbarium-container-1">
                <CardMedia
                    image={`${textPathImage}herbarium-pumpkin-hero.png`}
                    alt="Pumpkin"
                    className="staff-img-background"
                />
                <Grid container>
                    <Grid item xs={12} md={8} className="hero-text-container">
                        <h1 className="staff-text-1">Herbarium Staff</h1>
                        <h1 className="staff-text-2">
                            PSU Herbarium was established in 1970 and has grown over the years through the efforts of department staff and graduate students.
                            Initially, inadequate facilities caused damage to some specimens. However, by 1987, the Herbarium gained recognition with over 7,000 specimens.
                            The Faculty of Science at PSU has since conducted diverse research, leading to the addition of more specimens,
                            including a notable collection of lichens from Peninsular Thailand. Additionally,
                            there are pending registrations for macro-algae and plant fossil specimens to be included in the Herbarium.
                        </h1>
                        <Box className="line-hero1"></Box>
                    </Grid>
                    <Grid item xs={12} md={4} className="hero-icon">
                        <img src={`${textPathImage}herbarium-plant.png`} alt="Pumpkin" />
                    </Grid>
                </Grid>
            </Box>


            <div className="herbarium-container">
                <div className="herbarium-features">
                    <div className="herbarium-container2">
                        {
                            pageData?.map((row, index) => (
                                <HerbariumCard
                                    key={index}
                                    id={row?._id}
                                    index={index}
                                    photo={row?.photo}
                                    title={row?.title}
                                    name={row?.collectorName + ' ' + row?.collectorSurname}
                                    email={row?.email}
                                    rootClassName="researcher-card-root-class-name10"
                                />
                            ))
                        }

                    </div>
                </div>

                <Stack spacing={2} marginBottom={4}>
                    <Pagination
                        count={Math.ceil(collectorDatas.length / itemsPerPage)}
                        shape="rounded"
                        onChange={handleChange}
                    />
                </Stack>
            </div>

            <FooterHerbarium />
        </Stack>


    )
}

export default HerbariumStaff
