import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import "./herbarium-item-list.css"
import dayjs from "dayjs"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { Button, CardActionArea, Stack, Grid } from "@mui/material"

const HerbariumItemList = (props) => {
  return (
    <Card
      sx={{
        // minWidth: 300,
        mt: 2,
        "&:hover": {
          color: props.color, // Change the text color on hover
          transform: "scale(1.005)"
        },
        transition: "color 0.1s ease-in-out, transform 0.1s ease-in-out"
      }}
    >
      <CardActionArea component={Link} to={`/${props?.path}/${props?.id}`}>
        <CardContent>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item xs={12} sm={10} md={11}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontFamily: "Prompt",
                  fontWeight: "bold",
                  fontSize: {xs: 16, sm: 16, md:20}
                }}
              >
                <em>
                  {props?.genus} {props?.species}
                </em>{" "}
                {props?.author1} {props?.intraspecificStatus}{" "}
                <em>{props?.intraspecificName}</em> {props?.author2}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {props.DateDetermine}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {props.Locality}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-end", sm: "flex-start" },
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to={`/${props?.path}/${props?.id}`}
                style={{ backgroundColor: props.color }}
              >
                Details
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

HerbariumItemList.defaultProps = {
  Family: "-",
  Locality: "-",
  DateDetermine: "-",
  button: "Details",
  ScientificName: "-",
  rootClassName: "herbarium-item-list-root-class-name",
  CollectorName: "-",
  id: "-",
}

HerbariumItemList.propTypes = {
  Family: PropTypes.string,
  Locality: PropTypes.string,
  DateDetermine: PropTypes.string,
  button: PropTypes.string,
  ScientificName: PropTypes.string,
  rootClassName: PropTypes.string,
  CollectorName: PropTypes.string,
  id: PropTypes.string,
}

export default HerbariumItemList
