import React, { useEffect, useState, ChangeEvent, Fragment, useCallback } from "react"

import { Helmet } from "react-helmet"
import dayjs from "dayjs"
import Header from "../layouts/header-layout"
import DetailFields from "../components/detail-fields"
import Footer from "../layouts/footer-layout"
import "./add-herbarium.css"
import { request } from "../axios-config"
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  Create as CreateIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Delete,
} from "@mui/icons-material"
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useHistory, useParams } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import ScientificNameheader from "../components/scientific-nameheader"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"

const EditGeoArchiveItem = (props) => {

  const history = useHistory()
  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const params = useParams()
  const [geoDetail, setGeoDetail] = useState()
  const requestPrivate = useAxiosPrivate()

  const [references, setReferences] = useState([
    { link: "", year: "", type: "", title: "" },
  ])
  const [lecturerForAdd, setlecturerForAdd] = useState([""])
  const [lecturers, setLecturers] = useState([])
  const [selectedLecturers, setSelectedLecturers] = useState([])

  useEffect(() => {
    setSelectedLecturers(item?.lecturer?.map((row) => row || "") || [])
  }, [item?.lecturer])

  useEffect(() => {
    setReferences(item?.references?.map((row) => row || "") || [])
  }, [item?.references])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getGeoDetail = async () => {
      try {
        const response = await request.get(`/geoItem/${params?.id}`, {
          signal: controller.signal,
        })
        isMounted && setGeoDetail(response?.data)
        isMounted &&
          setItem({
            ...item,
            photograph: response?.data?.photograph,
            province: response?.data?.province,
            district: response?.data?.district,
            description: response?.data?.description,
            date: response?.data?.date,
            location: response?.data?.location,
            longtitude: response?.data?.longtitude,
            latitude: response?.data?.latitude,
            altitude: response?.data?.altitude,
            type: response?.data?.type,
            dataGroup: response?.data?.dataGroup,
            remark: response?.data?.remark,
            lecturer: response?.data?.lecturer,
            references: response?.data?.references,
            photo: response?.data?.photo,

          })

      } catch (error) {
        console.error(error)
      }
    }

    getGeoDetail()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params?.id])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getLecturers = async () => {
      try {
        const response = await request.get("/lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturers(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getLecturers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  console.log(item)

  const SubmitEdit = async () => {
    try {
      // if (false) {
      //   alert("Scientific Name  is required")
      // }
      if (!lecturers) {
        alert("Lecturer is required")
      } else {
         const responseUrlPhoto = await request.post("/updatePhoto", {
           newPhoto: filePhotos || [],
           defPhoto: item.photograph
             ? typeof item?.photograph === "string"
               ? [item.photograph]
               : item.photograph
             : [],
         })
        const response = await requestPrivate.put(`/geoItem/${params?.id}`, {
          ...item,
          photograph: responseUrlPhoto?.data?.urls || [],
          references: references,
          lecturer: selectedLecturers,
        })
        if (response) {
          const urls = responseUrlPhoto?.data?.missingUrls
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            await request.delete(`/deletephoto/${fileNames}`, {})
          }
          alert("success")
          history.push("/geoview")
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

   useEffect(() => {
     setFilePhotos(
       typeof item?.photograph === "string"
         ? [item?.photograph]
         : item?.photograph?.map((row) => row || "") || [],
     )
   }, [item])

 const selectPhotoFiles = (e) => {
   if (filePhotos.length >= 4) {
     alert(`Up to 4 photos can be uploaded.`)
     e.target.value = null
     return
   }

   new Promise((resolve, reject) => {
     e.preventDefault()
     const files = Array.from(e.currentTarget.files)
     if (files?.length + filePhotos?.length > 4) {
       alert(`Up to 4 photos can be uploaded.`)
       e.target.value = null
       return
     }
     const invalidFiles = files.filter((file) => file.size > 2000000)
     if (invalidFiles.length > 0) {
       const fileNames = invalidFiles.map((file) => file.name).join(", ")
       alert(
         `Please select images with a size not exceeding 2 MB: ${fileNames}`,
       )
       e.target.value = null // clear the input field
       return
     }

     const filePromises = files.map(
       (file) =>
         new Promise((resolve) => {
           const reader = new FileReader()
           reader.readAsDataURL(file)
           reader.onload = () => {
             resolve(reader.result)
           }
         }),
     )
     console.log(filePromises)
     Promise.all(filePromises)
       .then((results) => {
         const base64Files = files.map((file, index) => ({
           data: file,
           base64: results[index],
         }))
         setFilePhotos([...base64Files, ...filePhotos])
         resolve(results)
       })
       .catch((error) => reject(error))
   })
 }

 const handleDeletePhoto = (index) => {
   const newPhotos = [...filePhotos]
   newPhotos.splice(index, 1)
   setFilePhotos(newPhotos)
 }

  // console.log(item, "5555")//log
  // console.log(lecturerForAdd, "6666")

  const handleLecturerChange = (e, index) => {
    const newValue = e.target.value
    setSelectedLecturers((prevSelectedLecturers) => {
      const newSelectedLecturers = [...prevSelectedLecturers]
      newSelectedLecturers[index] = newValue
      return newSelectedLecturers
    })
  }

  const handleAddLecturer = (index) => {
    const newSelectedLecturers = [...selectedLecturers]
    newSelectedLecturers.splice(index + 1, 0, "")
    setSelectedLecturers(newSelectedLecturers)
  }

  const handleDeleteLecturer = (index) => {
    const newSelectedLecturers = [...selectedLecturers]
    newSelectedLecturers.splice(index, 1)
    setSelectedLecturers(newSelectedLecturers)
  }

  const isLecturerSelected = (lecturerValue, lecturerId) => {
    if (lecturerValue === lecturerId) return false
    return selectedLecturers.some((selectedId) => selectedId === lecturerId)
  }

  const handleDeleteReference = (index) => {
    const newReferences = [...references]
    newReferences.splice(index, 1)
    setReferences(newReferences)
  }

  const [date, setDate] = useState(dayjs())
  
  useEffect(() => {
    setDate(dayjs(item?.date))
  }, [item])

  const handleDateChange = (newValue) => {
    setDate(newValue)
    setItem({
      ...item,
      date: newValue,
    })
  }

  return (
    <div className="add-herbarium-container">
      <Helmet>
        <title>EditGeoscience - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddGeoscience - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Geoscience Lists", path: `/geoview` },
          {
            title: `${geoDetail?.dataGroup ? geoDetail?.type : ""}  `,
          },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid container direction={"row"} sx={{ alignItems: "center", mb: 3 }}>
          <Grid>
            <Typography variant="h4" fontStyle="italic">
              {`
              ${geoDetail?.dataGroup ? geoDetail?.type : ""} `}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h5">(Edit)</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"column"}
            spacing={1}
            sx={{ mb: "40px", mt: "5px" }}
          >
            <PhotoAction
              filePhotos={filePhotos}
              handleDeletePhoto={handleDeletePhoto}
              selectPhotoFiles={selectPhotoFiles}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Province</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="province"
                required
                size="small"
                fullWidth
                value={item ? item?.province : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    province: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">District</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="district"
                required
                size="small"
                fullWidth
                value={item ? item?.district : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    district: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Photograph</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="photograph"
                required
                size="small"
                fullWidth
                value={item ? item?.photograph : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    photograph: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid> */}

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Description</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="description"
                required
                size="small"
                fullWidth
                value={item ? item?.description : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    description: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Date</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={date}
                  onChange={handleDateChange}
                  disableFuture
                  renderInput={(params) => (
                    <TextField required size="small" fullWidth {...params} />
                  )}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Location</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="location"
                required
                size="small"
                fullWidth
                value={item ? item?.location : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    location: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Longtitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="longtitude"
                required
                size="small"
                fullWidth
                value={item ? item?.longtitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    longtitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Latitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="latitude"
                required
                size="small"
                fullWidth
                value={item ? item?.latitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    latitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Altitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="altitude"
                required
                size="small"
                fullWidth
                value={item ? item?.altitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    altitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Type</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="type"
                defaultValue={item.type}
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        height: "120px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item ? `${item?.type}` : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    type: e.target.value,
                  })
                }}
              >
                <MenuItem value={"Sample"}>Sample</MenuItem>
                <MenuItem value={"Measurement"}>Measurement</MenuItem>
                <MenuItem value={"Obverservation"}>Obverservation</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Datagroup</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="dataGroup"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        height: "90px",
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                sx={{}}
                value={item ? `${item?.dataGroup}` : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    dataGroup: e.target.value,
                  })
                }}
              >
                <MenuItem value={"Geophysics"}>Geophysics</MenuItem>
                <MenuItem value={"Geology"}>Geology</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Remark</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="remark"
                required
                size="small"
                fullWidth
                value={item ? item?.remark : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    remark: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>


          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >

          </Grid>


          <Grid item xs={6} md={4} mt={3} mb={-3}>
            <Typography variant="h5">Researcher</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              {selectedLecturers &&
                selectedLecturers.map((row, index) => {
                  const lecturerValue =
                    selectedLecturers[index] !== undefined &&
                      selectedLecturers[index] !== ""
                      ? selectedLecturers[index]
                      : row
                  const isLastIndex = index === selectedLecturers.length - 1
                  const isLastItem = selectedLecturers.length === 1
                  return (
                    <Grid container key={index} spacing={2}>
                      <Grid item xs={6} md={4} mt={2}>
                        <Typography variant="subtitle2">{`Researcher ${index + 1
                          }`}</Typography>
                      </Grid>
                      <Grid item xs={6} md={4} mt={2}>
                        <TextField
                          id="lecturer"
                          required
                          size="small"
                          fullWidth
                          select
                          value={lecturerValue}
                          onChange={(e) => handleLecturerChange(e, index)}
                        >
                          {lecturers?.length !== 0 ? (
                            lecturers?.map((lecturer, index) => {
                              return (
                                <MenuItem
                                  disabled={isLecturerSelected(
                                    lecturerValue,
                                    lecturer?._id,
                                  )}
                                  key={index}
                                  value={`${lecturer?._id}`}
                                >{`${lecturer?.title} ${lecturer?.name} ${lecturer?.surname}`}</MenuItem>
                              )
                            })
                          ) : (
                            <MenuItem />
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={4} mt={2}>
                        {!isLastItem && (
                          <IconButton
                            onClick={() => handleDeleteLecturer(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}

                        {isLastIndex && ( // Only show "Add Lecturer" button in last index
                          <IconButton
                            color="primary"
                            onClick={() => handleAddLecturer(index)}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>


          <Grid mt={5}>
            <Typography variant="h5">References</Typography>
            {references.map((row, index) => {
              return (
                <Fragment key={index}>
                  <Grid>
                    <Typography variant="subtitle1">{`Reference ${index + 1
                      }`}</Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">title</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          multiline
                          id="title"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.title
                              : references[index]?.title
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              title: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">link</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          multiline
                          id="link"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.link
                              : references[index]?.link
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              link: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">year</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="year"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.year
                              : references[index]?.year
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              year: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">type</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="type"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.type
                              : references[index]?.type
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              type: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <IconButton
                        color={"primary"}
                        onClick={() => {
                          let res = references.slice()
                          res[index + 1] = {
                            title: "",
                            link: "",
                            year: "",
                            type: "",
                          }
                          setReferences(res)
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Fragment>
              )
            })}
          </Grid>

          <Grid
            item
            xs={12}
            direction={"row"}
            display={"flex"}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button variant="contained" onClick={() => SubmitEdit()}>
              submit
            </Button>
            <Button
              variant="text"
              onClick={() => {
                history.push("/geoview")
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default EditGeoArchiveItem