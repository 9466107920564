import React from 'react'

import PropTypes from 'prop-types'

import './researcher-name-header.css'

const ResearcherNameHeader = (props) => {
  return (
    <div className={`researcher-name-header-container ${props.rootClassName} `}>
      <h1 className="researcher-name-header-text">{props.ResearcherName}</h1>
    </div>
  )
}

ResearcherNameHeader.defaultProps = {
  ResearcherName: 'Clarias batrachus',
  rootClassName: '',
}

ResearcherNameHeader.propTypes = {
  ResearcherName: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default ResearcherNameHeader
