import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import "./style.css"
import Homepage from "./views/homepage"
import AddLecturer from "./views/add-lecturer"
import Herbariumview from "./views/herbariumview"
import Detail from "./views/detail"
import HerbariumBorrowform from "./views/herbarium-borrowform"
import Archiveview from "./views/archiveview"
import Borrowview from "./views/borrowview"
import AddArchiveItems from "./views/add-archive-items"
import HerbariumMain from "./views/herbarium-main"
import Animal from "./views/animal"
import Plant from "./views/plant"
import HerbariumAdvancesearch from "./views/herbarium-advancesearch"
import ResearcherDetails from "./views/researcher-details"
import Microbe from "./views/microbe"
import AddHerbarium from "./views/add-herbarium"
import Lecturerview from "./views/lecturerview"
import HerbariumDetail from "./views/herbarium-detail"
import Geoscience from "./views/geoscience"
import Login from "./views/login"
import Adminmenu from "./views/adminmenu"
import Researcher from "./views/researcher"
import EditHerbarium from "./views/edit-herbarium-items"
import EditLecturer from "./views/edit-lecturer"
import EditArchiveItems from "./views/edit-archive"
import AddGeoArchiveItem from "./views/add-geo-archive-items"
import DetailGeo from "./views/detailgeo"
import AdminUserManagement from "./views/admin-user-management"
import AddminAddUser from "./views/admin-add-user"
import EditUser from "./views/admin-edit-user"
import GeoView from "./views/geoview"
import EditGeoArchiveItem from "./views/edit-geo-items"
import DetailAnimal from "./views/detail-animal"
import DetailPlant from "./views/detail-plant"
import DetailMicrobe from "./views/detail-microbe"
import Archivemenu from "./views/archivemenu"
import Herbariummenu from "./views/herbariummenu"
import AddMicrobeItems from "./views/add-microbe-items"
import EditMicrobeItems from "./views/edit-microbe"
import Microbeview from "./views/microbeview"
import AddAnimal from "./views/add-animal-items"
import AnimalView from "./views/animalview"
import EditAnimal from "./views/edit-animal"
import AddPlantItems from "./views/add-plants-items"
import Plantview from "./views/plantview"
import EditPlantItems from "./views/edit-plant-items"
import StaffCollectorView from "./views/staff-collector-view"
import AddStaffCollector from "./views/add-staff-collector"
import EditStaffCollector from "./views/edit-staff-collector"

import HerbariumHomePage from "./views/herbarium/homepage-herbarium"
import NewHerbariumAdvancesearch from "./views/herbarium/herbarium-advancesearch-new"
import HerbariumSearch from "./views/herbarium/herbarium-search"
import ContactGrid from "./views/herbarium/herbarium-contact"
import HerbariumLogin from "./views/herbarium/herbarium-login"
import HerbariumReserve from "./views/herbarium/herbarium-reserve"
import NewHerbariumDetail from "./views/herbarium/herbarium-detail"
import HerbariumStaff from "./views/herbarium/herbarium-staff"
import HerbariumStaffDetail from "./views/herbarium/herbarium-staff-detail"
import GridContactvII from "./views/herbarium/contact-v2"
import HerbariumCollectorDetail from "./views/herbarium/herbarium-collector"
import CountViewsOverall from "./views/countviews-overall"
import HerbariumPrint from "./views/herbarium-print"
import ContactArchive from "./views/contact-archive"
import AdvancedSearchArchive from "./views/advanced-search-archive"

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Homepage} exact path="/" />
        <Route component={ContactArchive} exact path="/contact-archive" />
        <Route component={AdvancedSearchArchive} exact path="/advanced-search-archive" />
        <Route component={AddLecturer} exact path="/add-lecturer" />
        <Route component={Herbariumview} exact path="/herbariumview/:category" />
        <Route component={Detail} exact path="/detail/:id" />
        <Route
          component={HerbariumBorrowform}
          exact
          path="/herbarium-borrowform/:id"
        />
        <Route component={Archivemenu} exact path="/archiveview" />
        <Route component={Herbariummenu} exact path="/herbariummenu" />
        <Route component={Borrowview} exact path="/borrowview" />
        <Route component={AddArchiveItems} exact path="/add-archive-items" />
        <Route component={HerbariumMain} exact path="/herbarium-main" />
        <Route component={Animal} exact path="/animal" />
        <Route component={Plant} exact path="/plant" />
        <Route
          component={HerbariumAdvancesearch}
          exact
          path="/herbarium-advancesearch"
        />
        <Route
          component={ResearcherDetails}
          exact
          path="/researcher-detail/:id"
        />
        <Route component={Microbe} exact path="/microbe" />
        <Route component={AddHerbarium} exact path="/add-herbarium/:category" />
        <Route component={Lecturerview} exact path="/lecturerview" />
        <Route component={StaffCollectorView} exact path="/staff-collector-view" />
        {/* <Route component={HerbariumDetail} exact path="/herbarium-detail/:id" /> */}
        <Route component={Geoscience} exact path="/geo" />
        <Route component={Login} exact path="/login" />
        <Route component={Adminmenu} exact path="/adminmenu" />
        <Route component={Researcher} exact path="/researcher" />
        <Route component={EditHerbarium} exact path="/edit-herbarium/:category/:id" />
        <Route component={EditLecturer} exact path="/edit-lecturer/:id" />
        <Route component={EditArchiveItems} exact path="/edit-archive/:id" />

        <Route component={GeoView} exact path="/geoview"/>
        <Route component={AddGeoArchiveItem} exact path="/add-geo-archive-items" />
        <Route component={EditGeoArchiveItem} exact path="/edit-geo-items/:id"/>
        <Route component={DetailGeo} exact path="/detailgeo/:id" />
        <Route component={DetailAnimal} exact path="/detailanimal/:id" />
        <Route component={DetailPlant} exact path="/detailplant/:id" />
        <Route component={DetailMicrobe} exact path="/detailmicrobe/:id" />


        <Route
          component={AdminUserManagement}
          exact
          path="/admin-user-management"
        />
        <Route component={AddminAddUser} exact path="/admin-add-user" />
        <Route component={AddStaffCollector} exact path="/add-staff-collector" />
        <Route component={EditStaffCollector} exact path="/edit-staff-collector/:id" />
        <Route component={EditUser} exact path="/admin-edit-user/:id" />

        {/* //microbe  ##################################################*/}
        <Route component={AddMicrobeItems} exact path="/add-microbe" />
        <Route component={Microbeview} exact path="/microbeview" />
        <Route component={EditMicrobeItems} exact path="/edit-microbe/:id" />
        
        {/* //animal ##################################################*/}
        <Route component={AddAnimal} exact path="/add-animal" />
        <Route component={AnimalView} exact path="/animalview" />
        <Route component={EditAnimal} exact path="/edit-animal/:id" />
        
        {/* //plant ##################################################*/}
        <Route component={AddPlantItems} exact path="/add-plant-items" />
        <Route component={Plantview} exact path="/plantview" />
        <Route component={EditPlantItems} exact path="/edit-plant/:id" />

        {/* //herbarium ##################################################*/}
        <Route component={HerbariumHomePage} exact path="/herbarium-home" />
        {/* <Route component={HerbariumSearch} exact path="/herbarium-search" /> */}
        <Route
          component={NewHerbariumAdvancesearch}
          exact
          path="/herbarium-search"
        />
        <Route component={ContactGrid} exact path="/herbarium-contactv1" />
        <Route component={HerbariumLogin} exact path="/herbarium-login" />
        <Route component={HerbariumReserve} exact path="/herbarium-reserve/:id" />
        <Route component={NewHerbariumDetail} exact path="/herbarium-detail/:id" />
        <Route component={HerbariumStaff} exact path="/herbarium-staff" />
        <Route component={HerbariumStaffDetail} exact path="/herbarium-staff-detail/:id" />
        <Route component={HerbariumCollectorDetail} exact path="/herbarium-collector/:id" />
        <Route component={GridContactvII} exact path="/herbarium-contact" />
        <Route component={HerbariumPrint} exact path= "/herbarium-printview/:category" />

         <Route component={CountViewsOverall} exact path="/count-views" />
        
      </div>
    </Router>
  )
}
export default App
