import React, { useEffect, useState } from "react"
import { ImageList, ImageListItem, IconButton, Box, Grid } from "@mui/material"
import { Delete, AddPhotoAlternate } from "@mui/icons-material"
import PropTypes, { string } from "prop-types"

const PhotoAction = ({ filePhotos, handleDeletePhoto, selectPhotoFiles }) => {
  return (
    <>
      <label style={{ position: "relative" }} htmlFor="photo-project-file">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            height: "80px",
            "&:hover": {
              bgcolor: "rgba(0,0,0,0.3)",
              borderRadius: "50%",
            },
          }}
        >
          <AddPhotoAlternate
            sx={{
              width: "50px",
              height: "50px",
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
          />
        </Box>
      </label>
      <input
        onChange={selectPhotoFiles}
        accept="image/*"
        id="photo-project-file"
        type="file"
        multiple
        style={{ display: "none" }}
      />

      {filePhotos?.length > 0 ? (
        <ImageList sx={{ width: "auto" }} cols={2}>
          {filePhotos?.map((photo, index) => (
            <ImageListItem key={index} sx={{ border: "1px solid" }}>
              <img
                src={photo?.base64 ? photo?.base64 : photo}
                alt={`Photo ${index}`}
                loading="lazy"
                style={{ width: 100, height: 100 }}
              />
              <IconButton
                onClick={() => handleDeletePhoto(index)}
                sx={{
                  position: "absolute",
                  top: "60%",
                  left: "60%",
                  bgcolor: "white",
                  width: 35,
                  height: 35,
                  ":hover": {
                    bgcolor: "rgba(0,0,0,0.7)",
                  },
                  ":hover .delete-img": {
                    color: "red",
                  },
                }}
              >
                <Delete className="delete-img" />
              </IconButton>
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <></>
      )}
      <Grid mt={1}>
        <p style={{ color: "#999999", textAlign: "center" }}>
          รูปภาพขนาด 600x800 px. (3:4) ขนาดไม่เกิน 2 mb.
        </p>
      </Grid>
    </>
  )
}

PhotoAction.defaultProps = {
  filePhotos: [],
}

PhotoAction.propTypes = {
  filePhotos: PropTypes.arrayOf(
    PropTypes.shape({
      base64: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  selectPhotoFiles: PropTypes.func.isRequired,
}

export default PhotoAction
