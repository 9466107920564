import React, { useState, useRef, Fragment, useEffect } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import DetailFields from "../components/detail-fields"
import Footer from "../layouts/footer-layout"
import "./add-archive-items.css"
import axios from "axios"
import { request } from "../axios-config"
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Input, FormControl,
  InputAdornment
} from "@mui/material"
import {
  Create as CreateIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Delete, PhotoCamera
} from "@mui/icons-material"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import { useHistory, useParams } from "react-router-dom"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"
import { set } from "mobx"

const EditAnimal = (props) => {

  const requestPrivate = useAxiosPrivate()
  const fileInputRef = useRef(null);
  const params = useParams()
  const history = useHistory()

  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const [references, setReferences] = useState([
    { link: "", year: "", type: "", title: "" },
  ])
  const [lecturers, setLecturers] = useState([])
  const [archive, setArchive] = useState([])
  const [selectedLecturers, setSelectedLecturers] = useState([])
  const [fileTxt, setFileTxt] = useState({ data: "" })
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    setSelectedLecturers(item?.lecturer?.map((row) => row || "") || [])
  }, [item?.lecturer])

  useEffect(() => {
    setReferences(item?.references?.map((row) => row || "") || [])
  }, [item?.references])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getArchive = async () => {
      try {
        const response = await request.get(`/item/${params.id}`, {
          signal: controller.signal,
        })
        isMounted && setArchive(response?.data)
        isMounted &&
          setItem({
            ...item,
            kingdom: response?.data?.kingdom,
            category: response?.data?.category,
            phylum: response?.data?.phylum,
            aclass: response?.data?.aclass,
            order: response?.data?.order,
            family: response?.data?.family,
            genericName: response?.data?.genericName,
            specificEpithet: response?.data?.specificEpithet,
            authorName1: response?.data?.authorName1,
            sp: response?.data?.sp,
            subspecies: response?.data?.subspecies,
            commonName: response?.data?.commonName,
            vercularName: response?.data?.vercularName,
            locality: response?.data?.locality,
            ecologyAndHabitat: response?.data?.ecologyAndHabitat,
            specimenRepository: response?.data?.specimenRepository,
            molecularData: response?.data?.molecularData,
            utilization: response?.data?.utilization,
            lecturer: response?.data?.lecturer,
            references: response?.data?.references,
            photo: response?.data?.photo,
          })
      } catch (error) {
        console.error(error)
      }
    }
    getArchive()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params.id])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getLecturers = async () => {
      try {
        const response = await request.get("/lecturer", {
          signal: controller.signal,
        })
        isMounted && setLecturers(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getLecturers()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const [isFileUpdated, setIsFileUpdated] = useState(false)

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setIsFileUpdated(true);
      setFileTxt(selectedFile);
      setFileName(selectedFile.name);

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        setFileTxt(contents);
      };
      reader.readAsText(selectedFile);
    }
  };

  const SubmitEditArchive = async (urls, fileurl) => {
    try {
      if (!references[0]?.title) {
        alert("References is required");
      } else if(!selectedLecturers[0]){
        alert("Researcher is required");
      }else {
        const responseUrlPhoto = await request.post("/updatePhoto", {
          newPhoto: filePhotos || [],
          defPhoto: item?.photo || [],
        });

        let molecularDataUrl = null;
        if (isFileUpdated) {
          const responseFileTxt = await request.post("/uploadfiles", {
            text: fileTxt,
          });
          molecularDataUrl = responseFileTxt?.data?.url;
        } else {
          molecularDataUrl = item?.molecularData;
        }

        const response = await requestPrivate.put(`/updateItem/${params?.id}`, {
          ...item,
          photo: responseUrlPhoto?.data?.urls || [],
          references: references,
          lecturer: selectedLecturers,
          molecularData: molecularDataUrl,
        });

        if (response) {
          const urls = responseUrlPhoto?.data?.missingUrls;
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop());
            await request.delete(`/deletephoto/${fileNames}`, {});
          }

          if (fileurl?.length > 0 && isFileUpdated) {
            const parts = fileurl.split("/");
            const txtfilename = parts[parts.length - 1];
            const deleteOldFile = await request.delete(`/deletefile/${txtfilename}`);
            if (deleteOldFile) {
              console.log('success');
            }
          }

          alert("success");
          history.push("/animalview");
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setFilePhotos(item.photo?.map((row) => row || "") || [])
  }, [item])

  const selectPhotoFiles = (e) => {
    if (filePhotos.length >= 4) {
      alert(`Up to 4 photos can be uploaded.`)
      e.target.value = null
      return
    }

    new Promise((resolve, reject) => {
      e.preventDefault()
      const files = Array.from(e.currentTarget.files)
      if (files?.length + filePhotos?.length > 4) {
        alert(`Up to 4 photos can be uploaded.`)
        e.target.value = null
        return
      }
      console.log("files", files)
      const invalidFiles = files.filter((file) => file.size > 2000000)
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((file) => file.name).join(", ")
        alert(
          `Please select images with a size not exceeding 2 MB: ${fileNames}`,
        )
        e.target.value = null // clear the input field
        return
      }

      const filePromises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          }),
      )
      console.log(filePromises)
      Promise.all(filePromises)
        .then((results) => {
          const base64Files = files.map((file, index) => ({
            data: file,
            base64: results[index],
          }))
          setFilePhotos([...base64Files, ...filePhotos])
          resolve(results)
        })
        .catch((error) => reject(error))
    })
  }

  const handleDeletePhoto = (index) => {
    const newPhotos = [...filePhotos]
    newPhotos.splice(index, 1)
    setFilePhotos(newPhotos)
  }

  const handleLecturerChange = (e, index) => {
    const newValue = e.target.value
    setSelectedLecturers((prevSelectedLecturers) => {
      const newSelectedLecturers = [...prevSelectedLecturers]
      newSelectedLecturers[index] = newValue
      return newSelectedLecturers
    })
  }

  const handleAddLecturer = (index) => {
    const newSelectedLecturers = [...selectedLecturers]
    newSelectedLecturers.splice(index + 1, 0, "")
    setSelectedLecturers(newSelectedLecturers)
  }

  const handleDeleteLecturer = (index) => {
    const newSelectedLecturers = [...selectedLecturers]
    newSelectedLecturers.splice(index, 1)
    setSelectedLecturers(newSelectedLecturers)
  }

  const isLecturerSelected = (lecturerValue, lecturerId) => {
    if (lecturerValue === lecturerId) return false
    return selectedLecturers.some((selectedId) => selectedId === lecturerId)
  }

  const handleDeleteReference = (index) => {
    const newReferences = [...references]
    newReferences.splice(index, 1)
    setReferences(newReferences)
  }

  return (
    <>
      <Helmet>
        <title>EditArchiveItems - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta
          property="og:title"
          content="EditArchiveItems - Sci PSU Archive"
        />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Archive", path: `/archiveview` },
          { title: "Animal Lists", path: `/animalview` },
          {
            title: `${archive?.genericName || ""} 
                    ${archive?.specificEpithet || ""}
                    ${archive?.authorName1 || ""}  `,
          },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid
          container
          direction={"row"}
          sx={{ alignItems: "center", mb: 3, wordWrap: "break-word" }}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="h4">
              <i>{archive?.genericName || ""}&nbsp;</i>
              <i>{archive?.specimenRepository || ""}</i>
              &nbsp;{archive?.authorName1 || ""}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h5">(Edit)</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          alignItems={"center"}
          direction={"column"}
          spacing={1}
          sx={{ mb: "40px", mt: "5px" }}
        >
          <PhotoAction
            filePhotos={filePhotos}
            handleDeletePhoto={handleDeletePhoto}
            selectPhotoFiles={selectPhotoFiles}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Kingdom <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="kingdom"
                size="small"
                fullWidth
                value={item ? item?.kingdom : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    kingdom: e.target.value,
                  })
                }}
                error={ item && item?.kingdom === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.kingdom === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Category <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="category"
                required
                size="small"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      ".MuiList-root": {
                        overflowY: "auto",
                        bgColor: "green",
                      },
                    },
                  },
                }}
                
                value={ item?.category ? item.category : null}
                error={ item?.category === undefined || item?.category ? false : true}
                InputProps={{
                    endAdornment: (
                        item && item.category === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px", marginRight: "14px", zIndex: -4}}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    category: e.target.value,
                  })
                }}
              >
                <MenuItem value={"ANIMAL_VERTEBRATE"}>Vertebrate</MenuItem>
                <MenuItem value={"ANIMAL_INVERTEBRATE"}>
                  Invertebrate
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Phylum</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="phylum"
                size="small"
                fullWidth
                value={item ? item?.phylum : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    phylum: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Class</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="aclass"
                size="small"
                fullWidth
                value={item ? item?.aclass : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    aclass: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Order</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="order"
                size="small"
                fullWidth
                value={item ? item?.order : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    order: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Family <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="family"
                size="small"
                fullWidth
                value={item ? item?.family : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    family: e.target.value,
                  })
                }}
                error={ item && item?.family === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.family === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Genus <span style={{color: 'red'}}>*</span></Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="genericName"
                size="small"
                fullWidth
                value={item ? item?.genericName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    genericName: e.target.value,
                  })
                }}
                error={ item && item?.genericName === ""  ? true : false}
                InputProps={{
                    endAdornment: (
                        item && item.genericName === "" ? (
                            <InputAdornment position="end">
                                <span style={{ color: "red", fontSize: "10px" }}>Required!</span>
                            </InputAdornment>
                        ) : null
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Specific Epithet</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="specificEpithet"
                size="small"
                fullWidth
                value={item ? item?.specificEpithet : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specificEpithet: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author Name(s)</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="authorName1"
                size="small"
                fullWidth
                value={item ? item?.authorName1 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    authorName1: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">
                SP. (กรณีไม่ทราบสปีชีส์)
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="sp"
                size="small"
                fullWidth
                value={item ? item?.sp : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    sp: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Subspecies</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="subspecies"
                size="small"
                fullWidth
                value={item ? item?.subspecies : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    subspecies: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Common Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="commonName"
                size="small"
                fullWidth
                value={item ? item?.commonName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    commonName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Local Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="vercularName"
                size="small"
                fullWidth
                value={item ? item?.vercularName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    vercularName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Locality</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="locality"
                size="small"
                fullWidth
                value={item ? item?.locality : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    locality: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Ecology & Habitat</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="ecologyAndHabitat"
                size="small"
                fullWidth
                value={item ? item?.ecologyAndHabitat : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    ecologyAndHabitat: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Specimen Repository</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="specimenRepository"
                size="small"
                fullWidth
                value={item ? item?.specimenRepository : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    specimenRepository: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Utilization</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="utilization"
                size="small"
                fullWidth
                value={item ? item?.utilization : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    utilization: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Molecular Data</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <FormControl>
                <Input
                  id="upload-file"
                  type="file"
                  inputProps={{
                    accept: ".txt"
                  }}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="upload-file" >
                  <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Button variant="outlined" component="span">
                      {fileName ? fileName : 'เลือกไฟล์ใหม่'}
                    </Button>
                    {/* <Typography variant='body1'>
                      {fileName ? fileName : 'เลือกไฟล์'}
                    </Typography> */}
                  </Stack>
                </label>
              </FormControl>
              {/* {fileTxt &&
                <Stack justifyContent="center" alignItems="center">
                  <Typography>
                    {isFileUpdated ? fileName : item?.molecularData}
                  </Typography>
                </Stack>
              } */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} md={4} mt={3} mb={-3}>
          <Typography variant="h5">Researcher</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            {selectedLecturers &&
              selectedLecturers.map((row, index) => {
                const lecturerValue =
                  selectedLecturers[index] !== undefined &&
                    selectedLecturers[index] !== ""
                    ? selectedLecturers[index]
                    : row
                const isLastIndex = index === selectedLecturers.length - 1
                const isLastItem = selectedLecturers.length === 1
                return (
                  <Grid container key={index} spacing={2}>
                    <Grid item xs={6} md={4} mt={2}>
                      <Typography variant="subtitle2">{`Researcher ${index + 1
                        }`}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} mt={2}>
                      <TextField
                        id="lecturer"
                        required
                        size="small"
                        fullWidth
                        select
                        value={lecturerValue}
                        onChange={(e) => handleLecturerChange(e, index)}
                      >
                        {lecturers?.length !== 0 ? (
                          lecturers?.map((lecturer, index) => {
                            return (
                              <MenuItem
                                disabled={isLecturerSelected(
                                  lecturerValue,
                                  lecturer?._id,
                                )}
                                key={index}
                                value={`${lecturer?._id}`}
                              >{`${lecturer?.title} ${lecturer?.name} ${lecturer?.surname}`}</MenuItem>
                            )
                          })
                        ) : (
                          <MenuItem />
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} md={4} mt={2}>
                      {!isLastItem && (
                        <IconButton onClick={() => handleDeleteLecturer(index)}>
                          <Delete />
                        </IconButton>
                      )}

                      {isLastIndex && ( // Only show "Add Lecturer" button in last index
                        <IconButton
                          color="primary"
                          onClick={() => handleAddLecturer(index)}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>

        <Grid mt={5}>
          <Typography variant="h5">References</Typography>

          {references &&
            references.map((row, index) => {
              const isLastIndex = index === references.length - 1
              const isLastItem = references.length === 1
              return (
                <Fragment key={index}>
                  <Grid item xs={12} md={12} mt={2}>
                    <Typography variant="subtitle1">{`Reference ${index + 1
                      }`}</Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2">title</Typography>
                      </Grid>
                      <Grid item xs={6} md={9}>
                        <TextField
                          multiline
                          id="title"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.title
                              : references[index]?.title
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              title: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2">link</Typography>
                      </Grid>
                      <Grid item xs={6} md={9}>
                        <TextField
                          multiline
                          id="link"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.link
                              : references[index]?.link
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              link: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">year</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="year"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.year
                              : references[index]?.year
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              year: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={2}
                      alignItems={"center"}
                      direction={"row"}
                      spacing={1}
                    >
                      <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2">type</Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <TextField
                          id="type"
                          size="small"
                          fullWidth
                          required
                          value={
                            references[index]
                              ? references[index]?.type
                              : references[index]?.type
                          }
                          onChange={(e) => {
                            let res = references.slice()
                            let re = {
                              ...references[index],
                              type: e.target.value,
                            }
                            res[index] = re
                            setReferences(res)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      {!isLastItem && (
                        <IconButton
                          onClick={() => handleDeleteReference(index)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                      {isLastIndex && (
                        <IconButton
                          color={"primary"}
                          onClick={() => {
                            let res = references.slice()
                            res[index + 1] = {
                              title: "",
                              link: "",
                              year: "",
                              type: "",
                            }
                            setReferences(res)
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Fragment>
              )
            })}
        </Grid>

        <Grid
          mt={10}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          {/* {console.log()} */}
          <Button
            disabled={
              item?.category === undefined || item?.category === "" ||
              item?.family === undefined ||  item?.family === "" ||
              item?.kingdom === undefined || item?.kingdom === "" ||
              item?.genericName === undefined ||  item?.genericName === "" 
            }
            variant="contained"
            onClick={() => SubmitEditArchive(item?.photo, item?.molecularData)}
          >
            submit
          </Button>
          <Button
            variant="text"
            onClick={() => {
              history.push("/animalview")
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Container>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </>
  )
}

export default EditAnimal
