import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { axiosPrivate } from "../axios-config"
import { useAuth } from "../contexts/auth-context"
import useRefreshToken from "./use-refresh-token"

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { auth } = useAuth()
  const history = useHistory()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          const newAccessToken = await refresh()
          // console.log("newAccessToken", newAccessToken)
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`
          return axiosPrivate(prevRequest)
        }
        // else if (error?.response?.status === 401) {
        //   history.push("/login")
        // }
        return Promise.reject(error)
      },
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh])

  return axiosPrivate
}
export default useAxiosPrivate
