import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./adminmenu.css"
import CardMenuLayout from "../components/card-menu-layout"
import { GiDeer, GiMonsteraLeaf } from "react-icons/gi"
import { BsFillPersonLinesFill } from "react-icons/bs"
import { HiOutlineDocumentText } from "react-icons/hi"
import useAxiosPrivate from "../hook/use-axios-private"
import { Container, Grid, Typography } from "@mui/material"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import GroupIcon from '@mui/icons-material/Group';
import CategoryIcon from '@mui/icons-material/Category';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Adminmenu = (props) => {
  const [me, setMe] = useState()
  const requestPrivate = useAxiosPrivate()
  const history = useHistory()
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getMe = async () => {
      try {
        const response = await requestPrivate.get("/me", {
          signal: controller.signal,
        })
        isMounted && setMe(response?.data)
      } catch (error) {
        history.push("./login")
        console.error(error)
      }
    }
    getMe()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])
  // console.log(me)
  return (
    <div className="adminmenu-container">
      <Helmet>
        <title>Adminmenu - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Adminmenu - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name4" auth={true}></Header>
      <Container>
        <div className="adminmenu-manage-section">
          <h1 className="adminmenu-text">
            <span>Manage</span>
            <br></br>
          </h1>
          <Grid container display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} md={12}>
              <Typography align="center">
                Welcome {`${me?.firstname} ${me?.lastname}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={{ xs: 1, sm: 5, md: 15 }}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
            alignContent={"center"}
          >
            <Grid item xs={4}>
              <CardMenuLayout
                path={`/lecturerview`}
                title={"Researcher"}
                description="หน้าจัดการนักวิจัย"
                icon={
                  <BsFillPersonLinesFill
                    color="#80ED99"
                    style={{ fontSize: "82px" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <CardMenuLayout
                path={`/archiveview`}
                title={"Archive Items"}
                description="หน้าจัดการ Archive items"
                icon={
                <CategoryIcon 
                sx={{
                  color: '#FF75A0'
                }}
                style={{ fontSize: "82px" }} 
                />}
              />
            </Grid>

            <Grid item xs={4}>
              <CardMenuLayout
                path={`/geoview`}
                s
                title={"Geoscience View"}
                description="หน้าดูข้อมูล GEO"
                icon={
                  <TravelExploreIcon
                    sx={{
                      color: '#00b0ff'
                    }}
                    style={{ fontSize: "82px" }}
                  />
                }
              />
            </Grid>

            <Grid item xs={4}>
              <CardMenuLayout
                path={`/Herbariummenu`}
                s
                title={"Herbarium Items"}
                description="หน้าจัดการ Herbarium Items"
                icon={
                  <GiMonsteraLeaf
                    color="#C8DE79"
                    style={{ fontSize: "82px" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <CardMenuLayout
                path={`/borrowview`}
                s
                title={"Borrowed Items"}
                description="หน้าดูข้อมูลการยืม"
                icon={
                  <HiOutlineDocumentText
                    color="#ff6333"
                    style={{ fontSize: "82px" }}
                  />
                }
              />
            </Grid>



            <Grid item xs={4}>
              <CardMenuLayout
                path={`/admin-user-management`}
                s
                title={"User Management"}
                description="หน้าจัดการผู้ใช้"
                icon={
                  <ManageAccountsIcon
                    sx={{
                      color: '#ffea00'
                    }}
                    style={{ fontSize: "82px" }}
                  />
                }
              />
            </Grid>

            <Grid item xs={4}>
              <CardMenuLayout
                path={`/staff-collector-view`}
                title={"Staff"}
                description="หน้าจัดการ Staff"
                icon={
                  <GroupIcon
                    style={{ fontSize: "82px" }}
                    sx={{
                      color: '#d500f9'
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={4}>
              <CardMenuLayout
                path={`/count-views`}
                title={"Count Views"}
                description="หน้าดูจำนวนการเข้ารับชม"
                icon={<VisibilityIcon style={{ fontSize: "82px" }} />}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default Adminmenu
