import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"
import jwtDecode from "jwt-decode"
import axios from "axios"

const AuthContext = createContext()

export const checkTokenExpired = (token, data) => {
  if (token) {
    localStorage.setItem("token", token)
    try {
      const decoded = jwtDecode(token)
      if (decoded.exp > Math.round(new Date().getTime() / 1000)) {
        return { user: decoded, token }
      }
      localStorage.removeItem("token")
      return { user: null, token: null }
    } catch (err) {
      localStorage.removeItem("token")
      return { user: null, token: null }
    }
  } else {
    localStorage.removeItem("token")
    return { user: null, token: null }
  }
}

const useToken = () => {
  const [auth, setAuth] = useState("")
  // const [session, setSession] = useState(() =>
  //   checkTokenExpired(localStorage.getItem("token")),
  // )
  // const setToken = (t) => setSession(checkTokenExpired(t))
  // const checkToken = () => checkTokenExpired(localStorage.getItem("token"))
  // const removeToken = () => setSession(checkTokenExpired(null))
  // const { user, token } = session
  return {
    auth,
    setAuth,
  }
}

export const AuthProvider = (props) => {
  const { children } = props
  const { auth, setAuth } = useToken()

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
AuthProvider.propTypes = {
  children: PropTypes.node,
}
AuthProvider.defaultProps = {
  children: null,
}

export const AuthConsumer = AuthContext.Consumer
export const useAuth = () => useContext(AuthContext)

export default AuthContext
