import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import axios from 'axios'
import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./herbariumview.css"
import { request } from "../axios-config"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel, 
  LinearProgress,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import {Download, Close, Delete, CloudUpload, Info } from "@mui/icons-material"
import useAxiosPrivate from "../hook/use-axios-private"
import * as XLSX from "xlsx"
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import ScientificNameheader from "../components/scientific-nameheader"
import DetailsCard from "../components/details-card"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Herbariumview = () => {
  const history = useHistory()
  const requestPrivate = useAxiosPrivate()
  const params = useParams()
  const [status, setStatus] = useState()
  const [herbariumItem, setHerbariumItem] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [openFullImage, setOpenFullImage] = useState({ open: false, img: "" })
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const cardRef = useRef(null);
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setData([])
    setOpen(true);
  }
  const handleClose = () => {
    setData([])
    setOpen(false);
  } 

  const [me, setMe] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getMe = async () => {
      try {
        const response = await requestPrivate.get("/me", {
          signal: controller.signal,
        })
        isMounted && setMe(response?.data)
      } catch (error) {
        history.push("/login")
        console.error(error)
      }
    }
    getMe()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getHerbariumItem = async () => {
      try {
        setLoadingData(true)
        const response = await request.get(`/herbariumItemByCategory/${params?.category}`, {
          signal: controller.signal,
        })
        // const response = await request.get(`/herbariumItem/`, {
        //   signal: controller.signal,
        // })
        isMounted && setHerbariumItem(response?.data)
        if (response) {
          setRefetch(false)
        }
        setLoadingData(false)
      } catch (error) {
        console.error(error)
      }
    }
    getHerbariumItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refetch])

 
  const importHerbariumByExcel = async () => {
    try {
      setLoading(true)
      const response = await requestPrivate.post(`/importHerbariumByExcel`, {
        listItem: data,
        category: params?.category,
        createdBy: me?.id
      })
      if (response) {
        alert("Import Data From Excel Success.")
        setData([])
        setRefetch(!refetch)
        handleClose()
      }
      setLoading(false)
    } catch (error) {
      alert("Data format in Excel is incorrect.")
      console.error(error)
    }
  }
  
  const downloadTemplate = () => {
    // Replace 'your_template_endpoint' with the actual endpoint that serves the template file
    const apiServer = process.env?.REACT_APP_API_SERVER;

    // Create a URL object
    const apiUrl = new URL(apiServer);

    // Extract the base URL
    const baseUrl = apiUrl.origin;

    // console.log(baseUrl); 
    const templateEndpoint = `${baseUrl}/template/Herbarium_template.xlsx`;

    axios({
      url: templateEndpoint,
      method: 'GET',
      responseType: 'blob', // Important: responseType should be 'blob' for binary files
    })
      .then((response) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        link.href = url;
  
        // Replace 'your_template_filename.xlsx' with the actual name you want for the template file
        link.setAttribute('download', 'Herbarium_template.xlsx');
        document.body.appendChild(link);
  
        link.click();
  
        // Clean up the link element
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading template:', error);
      });
  };
  

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      // Extract headers by iterating through the range
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const headerCell = sheet[XLSX.utils.encode_cell({ r: range.s.r, c: col })];
        if (headerCell && headerCell.t === 's') {
          headers.push(headerCell.v.trim());
        }
      }
  
      // console.log("headers", headers)
      // Define the expected headers
      const expectedHeaders = [
        "herbariumNumber", "family", "genus", "species", 
        "author1", "intraspecificStatus", "intraspecificName", "author2", 
        "commonName", "province", "district", "location", 
        "longtitude", "latitude", "altitude", "remark", 
        "determineBy", "dateDetermine", "duplicate", "date", 
        "collectorNumber", "collector"
      ];
  
      // Check if all expected headers are present in the actual headers
      const hasAllHeaders = expectedHeaders.every(header => headers.includes(header));
  
      if (!hasAllHeaders) {
        setData([])
        alert("The Excel file is missing some essential headers. Please utilize the provided Excel template.");
        return;
      }
      // If all headers are present, proceed with parsing the data
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: headers });
      parsedData.shift();
      setData(parsedData);
    };
  };
  
  

  const RemoveHerbariumItem = async (id, urls) => {
    try {
      const confirmed = confirm("Are you sure you want to delete this item?")
      if (confirmed) {
        const response = await requestPrivate.delete(`/deleteHerbariumItem/${id}`, {})
        if (response) {
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            const deletePhoto = await request.delete(`/deletephoto/${fileNames}`, {})
          } 
          setRefetch(true)
          alert("success")
        }
      }
    } catch (error) {
      alert(`${error?.data?.message}`)
    }
  }

  const generateQRCode = async (text) => {
    try {
      const canvas = await QRCode.toCanvas(text);
      return canvas.toDataURL();
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const exportPDF = async (id) => {
    try {
      const item = herbariumItem?.find((item) => item._id === id);
      if (!item) {
        console.log(`Item with ID ${id} not found.`);
        return;
      }

      const response = await request.get(`/collector/${item?.collector}`);
      const matchingCollector = response?.data;
      const collectorName = `${matchingCollector?.title} ${matchingCollector?.collectorName} ${matchingCollector?.collectorSurname}` || "";

      const pdf = new jsPDF();
      const cardWidth = 180;
      const cardHeight = 70;
      // const columnWidth = cardWidth / 3;
      const columnWidth = 70

      pdf.setFont('helvetica');
      pdf.setFontSize(9);

      // First Column
      pdf.rect(10, 10, columnWidth, cardHeight); // Draw a rectangle for the first column
      pdf.text(`Province: ${item?.province}`, 15, 20);
      pdf.text(`Longitude: ${item?.longtitude}`, 15, 30);
      pdf.text(`Altitude: ${item?.altitude}`, 15, 40);
      pdf.text(`Date Determine: ${dayjs(item?.dateDetermine).format("MMMM/YYYY")}`, 15, 50)
      pdf.text(`Plant ID: ${item?.collectorNumber}`, 15, 60);
      pdf.text(`Collector: ${collectorName}`, 15, 70);

      // Second Column
      pdf.rect(10 + columnWidth, 10, columnWidth, cardHeight); // Draw a rectangle for the second column
      pdf.text(`District: ${item?.district}`, 15 + columnWidth, 20);
      pdf.text(`Latitude: ${item?.latitude}`, 15 + columnWidth, 30);
      pdf.text(`Determined by: ${item?.determineBy}`, 15 + columnWidth, 40);
      pdf.text(`Duplicate: ${item?.duplicate}`, 15 + columnWidth, 50);
      pdf.text(`Herbarium Number: ${item?.herbariumNumber}`, 15 + columnWidth, 60);
      pdf.text(`Date: ${dayjs(item?.date).format("DD/MM/YYYY")}`, 15 + columnWidth, 70);

      // Third Column (QR Code)
      pdf.rect(10 + 2 * columnWidth, 10, 50, cardHeight); // Draw a rectangle for the third column
      const qrCodeText = `${process.env.REACT_APP_URL_PUBLIC}/herbarium-detail/${id}`; // replace with your desired content
      const qrCodeDataUrl = await generateQRCode(qrCodeText);
      if (qrCodeDataUrl) {
        const qrCodeWidth = 40;
        const qrCodeHeight = 40;
        const qrCodeX = 10 + 2 * 65 + (columnWidth - qrCodeWidth) / 2;
        const qrCodeY = 25;
        pdf.addImage(qrCodeDataUrl, 'PNG', qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);
      }

      pdf.save(`${item?.family} ${item?.genus} ${item?.species} ${item?.family} ${item?.author1}.pdf`);
    } catch (error) {
      console.log(`Error retrieving collector details: ${error}`);
    }
  };



  const exportExcel = async () => {
    const herbariumData = await Promise.all(
      herbariumItem?.map(async (row) => {
        return {
          herbariumNumber: row?.herbariumNumber || "-",
          family: row?.family || "-",
          genus: row?.genus || "-",
          species: row?.species || "-",
          author1: row?.author1 || "-",
          intraspecificStatus: row?.intraspecificStatus || "-",
          intraspecificName: row?.intraspecificName || "-",
          author2: row?.author2 || "-",
          commonName: row?.commonName || "-",
          province: row?.province || "-",
          district: row?.district || "-",
          location: row?.location || "-",
          longtitude: row?.longtitude || "-",
          latitude: row?.latitude || "-",
          altitude: row?.altitude || "-",
          remark: row?.remark || "-",
          determineBy: row?.determineBy || "-",
          dateDetermine: row?.dateDetermine ? new Date(row?.dateDetermine).toLocaleString("en-US", {
            timeZone: "Asia/Bangkok",
            year: "numeric",
            month: "long",
          }) : "-",
          duplicate: row?.duplicate || "-",
          date: row?.date ? new Date(row?.date).toLocaleString("en-US", {
            timeZone: "Asia/Bangkok",
            year: "numeric",
            month: "short",
            day: "2-digit",
          }) : "-",
          collector: row?.collector || "-",
          collectorNumber: row?.collectorNumber || "-",
          createdBy: row?.createdByData.length !== 0  ? row?.createdByData?.[0]?.firstname + ' ' + row?.createdByData?.[0]?.lastname : "-",
        };
      })
    );

    const HerbariumSheet = XLSX.utils.json_to_sheet(herbariumData);

    const HerbariumHeader = [
      "Herbarium Number",
      "Family",
      "Genus",
      "Species",
      "Author1",
      "Infraspecific Status",
      "Infraspecific Name",
      "Author2",
      "Common Name",
      "Province",
      "District",
      "Location",
      "Longtitude",
      "Latitude",
      "Altitude",
      "Remark",
      "Determined By",
      "Date Determine",
      "Duplicate",
      "Date",
      "Collector",
      "Collector Number",
      "Created By",
    ];
    const aoa = [
      HerbariumHeader.map((val) => ({ v: val, s: { font: { bold: true } } })),
    ];
    XLSX.utils.sheet_add_aoa(HerbariumSheet, aoa);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, HerbariumSheet, "Herbarium");
    XLSX.writeFile(workbook, `Herbarium.xlsx`);
  };

  const editStatus = async (status, id) => {
    try {
      const response = await requestPrivate.put(`/updateherbariumstatus/${id}`, {
        status: status,
      })
      if (response) {
        setRefetch(true)
        alert("success")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleCheckboxChange = (ids) => {
    const IDs = new Set(ids);
    const data = herbariumItem?.map((e, index) => {
      return { 
        id: e?._id,
        photograph: e?.photograph,
      }
    })
    const selectedRowsData = data?.filter((row) =>
      IDs.has(row.id),
    );
    // console.log("selectedRowsData", selectedRowsData)
    setSelectedIds([...IDs])
    setSelectedRows(selectedRowsData)
  }

  const manyDelete = async () => {
    try {
      const count = selectedRows?.length
      const confirmed = confirm(`Are you sure you want to delete ${count} selected item?`)
      if (confirmed) {
        setLoadingData(true)
        const response = await requestPrivate.delete(`/deleteHerbariumManyItem/`, 
          {
            data: { HerbariumList: selectedRows } 
          }
        )
        if(response?.data){
          const urls = response.data?.deletedItems
          if (urls?.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            // console.log("fileNames", fileNames);
            await request.delete(`/deletephoto/${fileNames}`, {})
          } 
          setRefetch(true)
          alert("success") 
        }
        setLoadingData(false)
      }
    } catch (error) {
      alert(`${error}`)
    }
  }


  const columns = [
    {
      field: "index",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      width: 80,
      flex: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "photograph",
      headerName: "Photo",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.photograph?.length > 0 ? (
          <ButtonBase
            onClick={() => {
              setOpenFullImage({
                open: true,
                img: params.row.photograph[0],
              })
            }}
          >
            <Box
              component="img"
              sx={{ width: "40px", height: "40px" }}
              src={params.row.photograph[0]}
            />
          </ButtonBase>
        ) : (
          <Typography>no photo</Typography>
        )
      },
    },
    {
      field: "herbariumNumber",
      headerName: "Herbarium Number",
      headerClassName: "super-app-theme--header",
      width: 160,
      renderCell: (params) => (
        <button
          // onClick={(e) => {
          //   history.push(`/edit-herbarium/${params?.row.category}/${params?.row._id}`);
          // }}
          onClick={(e) => {
            const url = `/edit-herbarium/${params?.row.category}/${params?.row._id}`;
            window.open(url, '_blank');
          }}
          style={{ textDecoration: 'none', cursor: 'pointer', color: "var(--dl-color-primary-100)" }}
          onMouseEnter={(e) => { e.target.style.textDecoration = 'underline'; }}
          onMouseLeave={(e) => { e.target.style.textDecoration = 'none'; }} 
        >
          {params?.row?.herbariumNumber}
        </button>
      ),
    },
    {
      field: "family",
      headerName: "Family",
      headerClassName: "super-app-theme--header",
      // type: "number",
      width: 200,
    },
    {
      field: "genus",
      headerName: "Genus",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "species",
      headerName: "Species",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "author1",
      headerName: "Author 1",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "intraspecificStatus",
      headerName: "Infraspecific Status",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "intraspecificName",
      headerName: "Infraspecific Name",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "author2",
      headerName: "Author 2",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "commonName",
      headerName: "Common Name",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "province",
      headerName: "Province",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "district",
      headerName: "District",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "longtitude",
      headerName: "Longtitude",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "altitude",
      headerName: "Altitude",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "remark",
      headerName: "Remark",
      headerClassName: "super-app-theme--header",
      width: 500,
    },
    {
      field: "determineBy",
      headerName: "Determined By",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "dateDetermine",
      headerName: "Date Determine",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params?.row?.dateDetermine ? (
          <Typography>
            {dayjs(params?.row?.dateDetermine).format("MMMM/YYYY")}
          </Typography>
        ) : (
          <Typography>-</Typography>
        ),
    },
    {
      field: "duplicate",
      headerName: "Duplicate",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params?.row?.date ? (
          <Typography>
            {dayjs(params?.row?.date).format("DD/MMM/YYYY")}
          </Typography>
        ) : (
          <Typography>-</Typography>
        ),
    },
    {
      field: "collector",
      headerName: "Collector",
      headerClassName: "super-app-theme--header",
      width: 250,
    //   renderCell:(params)=>{
    //     return(  <Typography>
    //     {
    //       params?.row?.collectorData.length !==0 ? params?.row?.collectorData?.map((collectorRow)=>{
    //         return(  `${collectorRow?.shortname} `)
    //       }).join(',')
    //    :null
    //     }
    //   </Typography>
    //     )
    //   }
    },
    {
      field: "collectorNumber",
      headerName: "Collector Number",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell:(params)=>{
        return(  <Typography>
        {
          params?.row?.createdByData?.length !== 0  ? params?.row?.createdByData?.[0]?.firstname + ' ' + params?.row?.createdByData?.[0]?.lastname : "-"
        }
      </Typography>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) => {
        return (
          <FormControl>
            <InputLabel id="statusLabel" />
            <Select
              fullWidth
              value={params?.row?.status ? params?.row?.status : ""}
              onChange={(e) => {
                setStatus({
                  ...status,
                  status: e.target.value
                })
              }}
              style={{
                color: params?.row?.status === 'AVAILABLE' ? 'green' : 'red',
              }}
            >
              <MenuItem value="AVAILABLE" onClick={() => editStatus("AVAILABLE", params?.row?._id)} style={{ color: "green" }}>
                AVAILABLE
              </MenuItem>

              <MenuItem value="UNAVAILABLE" onClick={() => editStatus("UNAVAILABLE", params?.row?._id)} style={{ color: "red" }}>
                UNAVAILABLE
              </MenuItem>

            </Select>
          </FormControl>
        );
      },
    },
    // {
    //   field: "export",
    //   headerName: "Export",
    //   headerClassName: "super-app-theme--header",
    //   width: 100,
    //   flex: 0,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => (
    //     <>
    //       <Button variant="contained" onClick={() => exportPDF(params?.row?._id)}>
    //         PDF
    //       </Button>
    //     </>
    //   ),
    // },

    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            history.push(`/edit-herbarium/${params?.row.category}/${params?.row._id}`);
          }}
        >
          Edit
        </Button>
      ),
    },

    {
      field: "delete",
      headerName: "จัดการ",
      headerClassName: "super-app-theme--header",
      width: 100,
      flex: 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            RemoveHerbariumItem(params?.row?._id, params?.row?.photograph)
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ]

  return (
    <div className="herbariumview-container">
      <Helmet>
        <title>Herbariumview - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Herbariumview - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name14" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Herbarium", path: `/Herbariummenu` },
          { title: "Herbarium Lists" },
        ]}
      />

      <div style={{ position: "absolute", top: "-9999px" }}>
        <div ref={cardRef}>
          <div className="herbarium-detail-research-content">
            <ScientificNameheader
              ScientificName={`${herbariumItem?.family ? herbariumItem?.family : ""
                } ${herbariumItem?.genus ? herbariumItem?.genus : ""} ${herbariumItem?.species ? herbariumItem?.species : ""
                } ${herbariumItem?.author1 ? herbariumItem?.author1 : ""} ${herbariumItem?.intraspecificStatus
                  ? herbariumItem?.intraspecificStatus
                  : ""
                } ${herbariumItem?.intraspecificName
                  ? herbariumItem?.intraspecificName
                  : ""
                } ${herbariumItem?.author2 ? herbariumItem?.author2 : ""}`}
            />
            <div className="herbarium-detail-container1">
              <div className="herbarium-detail-container4">
                <div>
                  <DetailsCard
                    rootClassName="rootClassName9"
                    title={"Province"}
                    description={herbariumItem?.province}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="rootClassName11"
                    title={"District"}
                    description={herbariumItem?.district}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="rootClassName13"
                    title={"Location"}
                    description={herbariumItem?.location}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name16"
                    title={"Longtitude"}
                    description={herbariumItem?.longtitude}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name17"
                    title={"Latitude"}
                    description={herbariumItem?.latitude}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name18"
                    title={"Altitude"}
                    description={herbariumItem?.altitude}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name19"
                    title={"Determined By"}
                    description={herbariumItem?.determineBy}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name19"
                    title={"Date Determine"}
                    description={dayjs(herbariumItem?.dateDetermine).format(
                      "DD/MM/YYYY",
                    )}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name20"
                    title={"Duplicate"}
                    description={herbariumItem?.duplicate}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Plant Id"}
                    description={herbariumItem?.collectorNumber}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Herbarium Number"}
                    description={herbariumItem?.herbariumNumber}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Collector"}
                    description={herbariumItem?.collector}
                  ></DetailsCard>
                  <DetailsCard
                    rootClassName="details-card-root-class-name21"
                    title={"Date"}
                    description={dayjs(herbariumItem?.date).format(
                      "DD/MMM/YYYY",
                    )}
                  ></DetailsCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="herbariumview-herbarium-list">
        <h1>
          <span>Herbarium Lists</span>
          <br></br>
        </h1>
        
        <strong style={{fontSize: "20px", }}>
        ( {
            params?.category == "HERBAR_BRYOPHYTES" ? 
            "Bryophytes" : params?.category == "HERBAR_ALGAE" ? 
            "Algae" : params?.category == "HERBAR_VASCULAR_PLANTS" ?
            "Vascular plants" : "DON'T HAVE CATEGORY"
        } )
        </strong>
       
        <Box
          sx={{
            height: 400,
            mt: 2,
            width: 1,
            "& .super-app-theme--header": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <Grid container>
            <Grid container justifyContent={"flex-end"} item xs={12} md={12}>


            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
               { loading && <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                  </Box>
                }
              <DialogTitle id="alert-dialog-title">
                
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} id="modal-modal-title">
                  <Typography  variant="h6" >
                  Import Data From Excel
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{display: 'flex', flexDirection: 'column'}}>
                  <Box sx={{ mb: 2}}>
                      <samll 
                        style={{
                          display: 'flex', 
                          justifyContent: 'flex-start', 
                          alignItems: 'center'}}>
                            <Info sx={{mr:1}}/> Please utilize the provided Excel template.
                      </samll>
                      <Button
                        component="label" 
                        startIcon={<Download />}
                        onClick={downloadTemplate}
                        variant="text"
                        // sx={{ transform: 'scale(0.8)'}}
                      >
                        Download Template
                      </Button>
                  </Box>
                  <label for="fileInput">Select File For import</label>
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    // style={{ display: 'none' }}
                    id="fileInput"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                    disabled={data.length > 0 ? false : true}
                    component="label" 
                    startIcon={<CloudUpload />}
                    onClick={importHerbariumByExcel}
                    variant="contained"
                    sx={{ alignContent: 'flex-end', mb: 2, mr: 2, }}
                >
                    Import
                </Button>
              </DialogActions>
            </Dialog>

            <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 1, mb:2 }}>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  onClick={manyDelete}
                  disabled={selectedIds?.length > 0 && !loadingData  ? false : true}
                  variant="contained"
                  color="error"
                  sx={{width: "100%"}}
                >
                  Multi Delete
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button onClick={handleOpen} variant="contained" sx={{width: "100%"}}>
                  Import Excel
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button onClick={() => exportExcel()} variant="contained" sx={{width: "100%"}}>
                  Export Excel
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <Button
                  component={Link}
                  to={`/add-herbarium/${params?.category}`}
                  variant="contained"
                  sx={{width: "100%"}}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <Button
                  component={Link}
                  to={`/herbarium-printview/${params?.category}`}
                  variant="contained"
                  sx={{width: "100%"}}
                >
                  Print
                </Button>
              </Grid>
            </Grid>

            </Grid>
          </Grid>
          <DataGrid
            rows={
              herbariumItem
                ? herbariumItem?.map((e, index) => {

                  return {
                    ...e,
                    id: e._id,
                    index: index + 1,
                    collector: e.collector || "-",
                    photograph: e.photograph || "-",
                    family: e.family || "-",
                    genus: e.genus || "-",
                    species: e.species || "-",
                    author1: e.author1 || "-",
                    author2: e.author2 || "-",
                    intraspecificStatus: e.intraspecificStatus || "-",
                    intraspecificName: e.intraspecificName || "-",
                    location: e.location || "-",
                    // collectorData:e?.collectorData || [],
                    province: e.province || "-",
                    district: e.district || "-",
                    longtitude: e.longtitude || "-",
                    latitude: e.latitude || "-",
                    altitude: e.altitude || "-",
                    remark: e.remark || "-",
                    determineBy: e.determineBy || "-",
                    duplicate: e.duplicate || "-",
                    collectorNumber: e.collectorNumber || "-",
                    herbariumNumber: e.herbariumNumber || "-",
                    commonName: e.commonName ||"-",
                  }
                })
                : []
            }
            columns={columns}
            // pageSize={50}
            initialState={{
              // ...data.initialState,
              pagination: { paginationModel: { pageSize: 50 } },
            }}
            pageSizeOptions={[50, 100]}
            rowSelection
            checkboxSelection
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={handleCheckboxChange}
            getRowHeight={() => 'auto'}
            loading={loadingData}
            sx={{
              '.MuiDataGrid-columnHeaderCheckbox': {
                backgroundColor: 'rgb(67, 104, 142)',
                'svg': {
                  color: 'white'
                }
              },
              "& .MuiDataGrid-cellContent": {
                // Forced to use important since overriding inline styles
                wordBreak: "break-all",
                padding: '8px !important'
              },
              height: 2750
            }}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openFullImage?.open}
          onClick={() => {
            setOpenFullImage({ open: false, img: "" })
          }}
        >
          <Box component="img" src={openFullImage?.img} />
        </Backdrop>
      </div>
      <Box sx={{height: "120px"}}></Box>
      <Footer rootClassName="footer-root-class-name13"></Footer>
    </div>
  )
}

export default Herbariumview
