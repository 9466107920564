import React, { useEffect, useState } from "react"

import { Helmet } from "react-helmet"
import dayjs from 'dayjs';
import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-herbarium.css"
import { request } from "../axios-config"
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import {
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useHistory, useParams } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"
import PhotoAction from "../components/photoAction"
import { flowResult } from "mobx";

const EditHerbarium = (props) => {
  const params = useParams()
  const history = useHistory()
  const requestPrivate = useAxiosPrivate()
  const [item, setItem] = useState({})
  const [filePhotos, setFilePhotos] = useState([])
  const [herbariumDetail, setHerbariumDetail] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getHerbariumDetail = async () => {
      try {
        const response = await request.get(`/collectorByHerbarium/${params?.id}`, {
          signal: controller.signal,
        })
        // console.log(response?.data)
        isMounted && setHerbariumDetail(response?.data)
        isMounted &&
          setItem({
            ...item,
            photograph: response?.data?.photograph,
            genus: response?.data?.genus,
            family: response?.data?.family,
            species: response?.data?.species,
            author1: response?.data?.author1,
            intraspecificStatus: response?.data?.intraspecificStatus,
            intraspecificName: response?.data?.intraspecificName,
            author2: response?.data?.author2,
            province: response?.data?.province,
            district: response?.data?.district,
            location: response?.data?.location,
            longtitude: response?.data?.longtitude,
            latitude: response?.data?.latitude,
            altitude: response?.data?.altitude,
            remark: response?.data?.remark,
            determineBy: response?.data?.determineBy,
            dateDetermine: response?.data?.dateDetermine,
            duplicate: response?.data?.duplicate,
            collectorNumber: response?.data?.collectorNumber,
            herbariumNumber: response?.data?.herbariumNumber,
            collector: response?.data?.collector,
            date: response?.data?.date,
            photo: response?.data?.photo,
            commonName: response?.data?.commonName
          })
      } catch (error) {
        console.error(error)
      }
    }
    getHerbariumDetail()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params?.id])

  const SubmitEdit = async () => {
    try {
      if (!item?.family) {
        alert("Family is required")
      } else {
        const responseUrlPhoto = await request.post("/updatePhoto", {
          newPhoto: filePhotos || [],
          defPhoto: item?.photograph
            ? typeof item?.photograph === "string"
              ? [item.photograph]
              : item.photograph
            : [],
        })
        const response = await requestPrivate.put(
          `/updateHerbariumId/${params?.id}`,
          {
            ...item,
            photograph: responseUrlPhoto?.data?.urls || [],
            // collector: selectedLecturers,
          },
        )
        if (response) {
          const urls = responseUrlPhoto?.data?.missingUrls
          if (urls.length > 0) {
            const fileNames = urls.map((url) => url.split("/").pop())
            await request.delete(`/deletephoto/${fileNames}`, {})
          }
          alert("success")
          history.push(`/herbariumview/${params?.category}`)
        }
      }
    } catch (e) {
      console.error(e)
      alert(`${e?.data?.message}`)
    }
  }

  useEffect(() => {
    setFilePhotos(
      typeof item?.photograph === "string"
        ? [item?.photograph]
        : item?.photograph?.map((row) => row || "") || [],
    )
  }, [item])

  const selectPhotoFiles = (e) => {
    if (filePhotos.length >= 4) {
      alert(`Up to 4 photos can be uploaded.`)
      e.target.value = null
      return
    }

    new Promise((resolve, reject) => {
      e.preventDefault()
      const files = Array.from(e.currentTarget.files)
      if (files?.length + filePhotos?.length > 4) {
        alert(`Up to 4 photos can be uploaded.`)
        e.target.value = null
        return
      }
      const invalidFiles = files.filter((file) => file.size > 2000000)
      if (invalidFiles.length > 0) {
        const fileNames = invalidFiles.map((file) => file.name).join(", ")
        alert(
          `Please select images with a size not exceeding 2 MB: ${fileNames}`,
        )
        e.target.value = null // clear the input field
        return
      }

      const filePromises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          }),
      )
      console.log(filePromises)
      Promise.all(filePromises)
        .then((results) => {
          const base64Files = files.map((file, index) => ({
            data: file,
            base64: results[index],
          }))
          setFilePhotos([...base64Files, ...filePhotos])
          resolve(results)
        })
        .catch((error) => reject(error))
    })
  }

  const handleDeletePhoto = (index) => {
    const newPhotos = [...filePhotos]
    newPhotos.splice(index, 1)
    setFilePhotos(newPhotos)
  }
  const [dateDeter, setDateDeter] = useState(dayjs());
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    setDateDeter(dayjs(item?.dateDetermine || null));
    setDate(dayjs(item?.date || null));
  }, [item]);

  const handleDateDeterChange = (newValue) => {
    setDateDeter(newValue);
    setItem({
      ...item,
      dateDetermine: newValue,
    })
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);
    setItem({
      ...item,
      date: newValue,
    })
  };


  // const isLecturerSelected = (lecturerValue, lecturerId) => {
  //       if (lecturerValue === lecturerId) return false
  //       return lecturerForAdd.some((selectedId) => selectedId === lecturerId)
  // }
  // const handleDeleteLecturer = (index) => {
  //       const newSelectedLecturers = [...lecturerForAdd]
  //       newSelectedLecturers.splice(index, 1)
  //       setlecturerForAdd(newSelectedLecturers)
  // }

  // const handleLecturerChange = (e, index) => {
  //       const newValue = e.target.value
  //       setlecturerForAdd((prevSelectedLecturers) => {
  //           const newSelectedLecturers = [...prevSelectedLecturers]
  //           newSelectedLecturers[index] = newValue
  //           return newSelectedLecturers
  //       })
  // }direction={"row"}

  return (
    <div className="add-herbarium-container">
      <Helmet>
        <title>EditHerbarium - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddHerbarium - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "Manage Herbarium", path: `/Herbariummenu` },
          { title: "Herbarium Lists", path: `/herbariumview/${params?.category}` },
          {
            title: `${herbariumDetail?.family ? herbariumDetail?.family : ""} ${herbariumDetail?.genus ? herbariumDetail?.genus : ""
              } ${herbariumDetail?.species ? herbariumDetail?.species : ""} ${herbariumDetail?.author1 ? herbariumDetail?.author1 : ""
              } ${herbariumDetail?.intraspecificStatus
                ? herbariumDetail?.intraspecificStatus
                : ""
              } ${herbariumDetail?.intraspecificName
                ? herbariumDetail?.intraspecificName
                : ""
              } ${herbariumDetail?.author2 ? herbariumDetail?.author2 : ""}`,
          },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
      <Grid container direction={"row"} sx={{ alignItems: "center", mb: 3 }}>
          <Grid sx={{display: 'flex'}}>                  
              <Typography variant="h4" sx={{ mr: 1 }}>{`${herbariumDetail?.family ? herbariumDetail?.family : ""} `}</Typography>
              <Typography variant="h4" fontStyle="italic" sx={{ mr: 1 }}>{`${herbariumDetail?.genus ? herbariumDetail?.genus : ""}`}</Typography>
              <Typography variant="h4" fontStyle="italic" sx={{ mr: 1 }}>{`${herbariumDetail?.species ? herbariumDetail?.species : ""} `}</Typography>
              <Typography variant="h4"sx={{ mr: 1 }}>{`${herbariumDetail?.author1 ? herbariumDetail?.author1 : ""}  `}</Typography>
              <Typography variant="h4"sx={{ mr: 1 }}>{`
                  ${herbariumDetail?.intraspecificStatus
                  ? herbariumDetail?.intraspecificStatus
                  : ""
                }`}</Typography>
              <Typography variant="h4" fontStyle="italic" sx={{ mr: 1 }}>{`
                ${herbariumDetail?.intraspecificName
                  ? herbariumDetail?.intraspecificName
                  : ""
                } `}</Typography>
              <Typography variant="h4" sx={{ mr: 1 }}>{`${herbariumDetail?.author2 ? herbariumDetail?.author2 : ""}`}</Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h5">(Edit)</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"column"}
            spacing={1}
            sx={{ mb: "40px", mt: "5px" }}
          >
            <PhotoAction
              filePhotos={filePhotos}
              handleDeletePhoto={handleDeletePhoto}
              selectPhotoFiles={selectPhotoFiles}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6">Scientific Name</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={10}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2">Family</Typography>
            </Grid>
            <Grid item xs={6} md={5}>
              <TextField
                multiline
                id="family"
                required
                size="small"
                fullWidth
                value={item ? item?.family : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    family: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Genus</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="genus"
                required
                size="small"
                fullWidth
                value={item ? item?.genus : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    genus: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Species</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="species"
                required
                size="small"
                fullWidth
                value={item ? item?.species : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    species: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author1</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="author1"
                required
                size="small"
                fullWidth
                value={item ? item?.author1 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    author1: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Infraspecific Status</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="intraspecificStatus"
                required
                size="small"
                fullWidth
                value={item ? item?.intraspecificStatus : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    intraspecificStatus: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Infrapecific Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="intraspecificName"
                required
                size="small"
                fullWidth
                value={item ? item?.intraspecificName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    intraspecificName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Author2</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="author2"
                required
                size="small"
                fullWidth
                value={item ? item?.author2 : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    author2: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Common Name</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="commonName"
                required
                size="small"
                fullWidth
                value={item ? item?.commonName : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    commonName: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6">Locality</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Province</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="province"
                required
                size="small"
                fullWidth
                value={item ? item?.province : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    province: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">District</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="district"
                required
                size="small"
                fullWidth
                value={item ? item?.district : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    district: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Location</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="location"
                required
                size="small"
                fullWidth
                value={item ? item?.location : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    location: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Longtitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="longtitude"
                required
                size="small"
                fullWidth
                value={item ? item?.longtitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    longtitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Latitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="latitude"
                required
                size="small"
                fullWidth
                value={item ? item?.latitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    latitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Altitude</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="altitude"
                required
                size="small"
                fullWidth
                value={item ? item?.altitude : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    altitude: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6">Details</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={2}>
              <Typography variant="subtitle2">Remark</Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <TextField
                multiline
                id="remark"
                required
                size="small"
                fullWidth
                value={item ? item?.remark : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    remark: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Determined By</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="determineBy"
                required
                size="small"
                fullWidth
                value={item ? item?.determineBy : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    determineBy: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Date Determine</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MMMM/YYYY"
                  views={["year", "month"]}
                  value={dateDeter}
                  onChange={handleDateDeterChange}
                  disableFuture
                  renderInput={(params) => (
                    <TextField
                      required
                      size="small"
                      sx={{}}
                      fullWidth
                      {...params}
                    />
                  )}
                  sx={{
                    width: "100%",
                    ".MuiOutlinedInput-input": {
                      height: "100%",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Duplicate</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="duplicate"
                required
                size="small"
                fullWidth
                value={item ? item?.duplicate : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    duplicate: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Collector</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                multiline
                id="collector"
                required
                size="small"
                fullWidth
                value={item ? item?.collector : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    collector: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Collector Number</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="collectorNumber"
                required
                size="small"
                fullWidth
                value={item ? item?.collectorNumber : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    collectorNumber: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Collector</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="lecturer"
                required
                size="small"
                fullWidth
                select
                value={item ? `${item.collector}` : ""} // Default value is an empty string if item.collector is falsy
                onChange={(e) => {
                  setItem({
                    ...item,
                    collector: e.target.value,
                  });
                }}
              >
                {collectors?.length !== 0 ? (
                  collectors?.map((row, index) => (
                    <MenuItem key={index} value={`${row?._id}`}>
                      {`${row?.title} ${row?.collectorName} ${row?.collectorSurname}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem />
                )}
              </TextField>
            </Grid>

          </Grid> */}




          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Date</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={date}
                  onChange={handleDateChange}
                  disableFuture
                  renderInput={(params) => (
                    <TextField required size="small" fullWidth {...params} />
                  )}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>



          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Herbarium Number</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="herbariumNumber"
                required
                size="small"
                fullWidth
                value={item ? item?.herbariumNumber : ""}
                onChange={(e) => {
                  setItem({
                    ...item,
                    herbariumNumber: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Created By</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                id="createdBy"
                disabled
                size="small"
                fullWidth
                value={
                  herbariumDetail && herbariumDetail.createdByData?.length > 0
                    ? herbariumDetail.createdByData[0].firstname + " " + herbariumDetail.createdByData[0].lastname
                    : "-"
                }
              />
            </Grid>
          </Grid>

          {/* <Grid container mt={5}>
            <Typography variant="h5">Collector</Typography>
            {selectedLecturers &&
              selectedLecturers.map((row, index) => {
                        const lecturerValue =
                            selectedLecturers[index] !== undefined &&
                                selectedLecturers[index] !== ""
                                ? selectedLecturers[index]
                                : row
                        const isLastIndex = index === selectedLecturers.length - 1
                        const isLastItem = selectedLecturers.length === 1
                        return (
                            <Fragment key={index}>
                                <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="subtitle1">{`Collector ${index + 1
                                        }`}</Typography>
                                </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={12}
                                        alignItems={"center"}
                                        direction={"row"}
                                        spacing={2}
                                    >
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2">Collector</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <TextField
                                                id="lecturer"
                                                required
                                                size="small"
                                                fullWidth
                                                select
                                                value={lecturerValue}
                                                onChange={(e) => handleLecturerChange(e, index)}
                                            >
                                                {collectors?.length !== 0 ? (
                                                    collectors?.map((row, index) => (
                                                        <MenuItem
                                                            disabled={isLecturerSelected(
                                                                lecturerValue,
                                                                row?._id,
                                                            )}
                                                            key={index}
                                                            value={`${row?._id}`}
                                                        >{`${row?.shortname}`}</MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem />
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={2}
                                            alignItems={"center"}
                                            direction={"row"}
                                            spacing={1}
                                        >
                                            {!isLastItem && (
                                                <IconButton onClick={() => handleDeleteLecturer(index)}>
                                                    <Delete />
                                                </IconButton>
                                            )}

                                            {isLastIndex && (
                                                <IconButton
                                                    color={"primary"}
                                                    onClick={() =>handleAddLecturer(index)}
                                                >
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    })}
          </Grid> */}



          <Grid
            item
            xs={12}
            md={12}
            direction={"row"}
            display={"flex"}
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ mt: 2, mb: 2 }}
          >
            <Button
              variant="contained"
              disabled={item?.family === undefined || item?.family === ""}
              onClick={() => SubmitEdit(item?.photo)}
            >
              submit
            </Button>
            <Button
              variant="text"
              onClick={() => {
                history.push(`/herbariumview/${params?.category}`)
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default EditHerbarium
