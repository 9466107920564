import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import "./homepage-herbarium.css"
import HerbariumAppBar from "../../layouts/header-herbarium"
import { Box, Container, Grid, CardMedia } from "@mui/material"
import FooterHerbarium from "../../layouts/new-footerherbarium"

const textPathImage = "/playground_assets/herbarium/"

const HerbariumHomePage = (props) => {
  useEffect(() => {
    const handleScroll = () => {
      const classList = [
        ".text-herbarium-container",
        ".text-herbarium-container1",
        ".text-slide",
      ]

      classList.forEach((className) => {
        const elements = document.querySelectorAll(className)

        elements.forEach((element) => {
          const rect = element.getBoundingClientRect()
          const isVisible = rect.top < window.innerHeight && rect.bottom >= 0

          if (isVisible) {
            element.classList.add("fade-in")
          } else {
            element.classList.remove("fade-in")
          }
        })
      })
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <div className="home-page-herbarium-container">
        <Helmet>
          <title>Herbarium - Sci PSU Archive</title>
          <meta
            name="description"
            content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
          />
          <meta
            property="og:title"
            content="HerbariumDetail - Sci PSU Archive"
          />
          <meta
            property="og:description"
            content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
          />
        </Helmet>
        <HerbariumAppBar></HerbariumAppBar>
        <Box className="home-page-herbarium-container-1">
          <CardMedia
            image={`${textPathImage}herbarium-pumpkin-hero.png`}
            alt="Pumpkin"
            className="img-background"
          />
          <Grid container>
            <Grid item xs={12} md={8} className="hero-text-container">
              <Box className="hero-text-1">Welcome</Box>
              <Box className="hero-text-2">
                Prince of Songkla University Herbarium (PSU)
              </Box>
              <Box className="hero-line"></Box>
            </Grid>
            <Grid item xs={12} md={4} className="hero-icon">
              <img src={`${textPathImage}herbarium-plant.png`} alt="Pumpkin" />
            </Grid>
          </Grid>
        </Box>
        <Box className="box-on-hero">
          <h4 className="box-on-hero-text1">
            Prince of Songkla University Herbarium
          </h4>
          <div className="box-on-hero-text2">
            (international acronym PSU) was initially founded for teaching.
            However, it gradually became more important as a reference
            collection for various kinds of research related to plants in the
            region of Peninsular Thailand and Malaysia.
          </div>
        </Box>
        <Grid className="line-bottom-hero"></Grid>
        <Container></Container>
        <Grid container className="content-container">
          <Grid item xs={12} sm={12} md={6} className="img-herbarium-container">
            <Box className="img-herbarium-container-body">
              <img
                src={`${textPathImage}herbarium-1.jpg`}
                alt="herbarium-1"
                className="img-herbarium1"
              />
              <img
                src={`${textPathImage}herbarium-2.jpg`}
                alt="herbarium-2"
                className="img-herbarium2"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="text-herbarium-container"
          >
            <div className="text-content-header">
              <h4 className="text-content-header1">The </h4>
              <h4 className="text-content-header2">Herbarium</h4>
            </div>
            <div className="text-content-body">
              specimen collection at PSU Herbarium took place in 1970 in the
              Department of Biology, Faculty of Science, Prince of Songkla
              University. The collection has gradually increased in size over
              the years with the help of department staff as well as the
              students, especially the graduate students in Plant Taxonomy and
              Plant Ecology. Unfortunately, at the initial stage, mold and
              insects, due to inadequate facilities, destroyed many specimens.
              There were more than 7000 specimens by 1987 when the Herbarium
              became internationally known after appearing in the Index
              Herbariorum.
            </div>
          </Grid>
        </Grid>

        {/* <Container className="content-container1"> */}
        <Grid
          display="flex"
          container
          className="content-container1"
          flexDirection={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="text-herbarium-container"
          >
            <div className="text-content-header">
              <h4 className="text-content-header1">After</h4>
              <h4 className="text-content-header2"> the 1980s</h4>
            </div>
            <div className="text-content-body">
              due to the diversity of research in the Faculty of Science at PSU,
              which deal with plants and related groups such as algae and
              lichens, more collection has been added to the Herbarium as the
              voucher specimens of those research work. Among those collections
              is the excellent collection of lichens from Peninsular Thailand by
              Assoc. Prof. Feeya Amatavanich. Besides, there are many more
              herbarium specimens of macro-algae and some plant fossil specimens
              waiting for registration to collect the Herbarium.
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="img-herbarium-container">
            <Box className="img-herbarium-container-body">
              <img
                src={`${textPathImage}herbarium-3.jpg`}
                alt="herbarium-1"
                className="img-herbarium1"
              />
              <img
                src={`${textPathImage}herbarium-6.jpg`}
                alt="herbarium-1"
                className="img-herbarium20"
              />
            </Box>
          </Grid>
        </Grid>
        {/* </Container> */}
        <Grid className="content-container3">
          <Box className="content-container4">
            <img
              src={`${textPathImage}herbarium-plant-circle.png`}
              alt="plant"
            />
          </Box>
          <div className="text-slide">
            <Box className="content-container5">
              <div className="text-content-body2">
                PSU herbarium enjoys its superb duty in teaching and
                research, not only for the PSU staff, but it serves as a place
                for a reference collection of plants for various kinds of
                research in Peninsular Thailand as a whole.
              </div>
            </Box>
          </div>
        </Grid>
        <Box className="content-container6">
          <Box className="content-container7">
            <Box className="content-container-body01">
              <Grid container direction="column">
                <div className="text-slide">
                  <div className="text-content-header31">
                    <h4 className="text-content-header310">At{"\u00A0"}</h4>
                    <h4 className="text-content-header320">present</h4>
                  </div>
                </div>
                <CardMedia
                  image={`${textPathImage}herbarium-4.jpg`}
                  alt="herbarium-1"
                  className="img-herbarium31"
                />
              </Grid>
            </Box>
            <Box className="content-container-body02">
              <CardMedia
                image={`${textPathImage}herbarium-5.jpg`}
                alt="herbarium-1"
                className="img-herbarium32"
              />
            </Box>
            <Box className="content-container-body03">
              <Grid container direction="column">
                <div className="text-slide">
                  <div className="text-content-body33">
                    the Herbarium, situated at the Biology Building, Division of
                    Biological Science, Faculty of Science, has housed around
                    25,000 herbarium specimens, with the addition of some more
                    spirit and pollen specimens, including plant fossils. It is
                    one of the first oldest three international herbaria of the
                    Kingdom besides the Forest Herbarium (BKF) and the Bangkok
                    Herbarium (BK) in Bangkok. Its collection mainly represents
                    Peninsular Thailand's flora and the Northern Malay
                    Peninsula.
                  </div>
                  <Box className="text-content-body33" sx={{ marginTop: 3 }}>
                    Prof. Puangpen Sirirugsa had been appointed to be the
                    curator-in-charge of the Herbarium from the beginning. After
                    her retirement in 1999, Assoc. Prof. Kitichate Sridith has
                    succeeded in her position as a curator in charge.
                  </Box>
                </div>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Grid className="content-container10">
          <div className="text-slide">
            <Grid container className="content-container-body04">
              <div className="text-content-header32">
                <h6 className="text-content-header313">Despite{"\u00A0"}</h6>
                <h6 className="text-content-header323">
                  its budgetary problems
                </h6>
              </div>
              <div className="text-content-body34">
                PSU Herbarium always seeks collaboration with any other Herbaria
                and institutions in the world. It is open to the public for any
                research and provides general knowledge of Systematic Botany.
                Volunteers helping the staff running the herbarium activities
                are encouraged. Herbarium specimen exchanges from any other
                Herbaria are welcomed. The contact can be made direct to the
                curator.
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
      <FooterHerbarium />
    </>
  )
}

export default HerbariumHomePage
