import React from 'react'

import PropTypes from 'prop-types'

import './footer-content.css'

const FooterHerbariumContent = (props) => {
    const LecturerData = [
        { id: 1, name: "รศ.ดร.กิติเชษฐ์ ศรีดิษฐ", phoneNumber: "8525" },
        { id: 2, name: "รศ.ดร.จรัล ลีรติวงศ์", phoneNumber: "8485" },
        { id: 3, name: "รศ.ดร.สหัช จันทนาอรพินท์", phoneNumber: "8509" },
        { id: 4, name: "ดร.ธิดารัตน์ พ่วงไพโรจน์", phoneNumber: "8520" },
        { id: 5, name: "อ.เจริญศักดิ์ แซ่ไว่", phoneNumber: "8515" },
        { id: 6, name: "นางสาวภัสมน เมืองทอง", phoneNumber: "8482" }
    ]
    return (
        <div className={`footer-content-container ${props.rootClassName} `}>
            <div className="footer-content-container1">
                <span className="footer-content-text">
                    คณะวิทยาศาสตร์ มหาวิทยาลัยสงขลานครินทร์
                </span>
                {LecturerData.map((row, index) => (
                    <span>
                        <span className="footer-content-text1">{row.name}</span>
                        <span className="footer-content-text2">&nbsp;&nbsp;&nbsp;เบอร์โทร: 074-28{row.phoneNumber}</span>
                        {/* <span className="footer-content-text3">{props.emailSci}</span> */}
                    </span>
                ))}
            </div>
            <div className="footer-content-links-container">
                <img
                    alt={props.image_alt}
                    src="/playground_assets/logosci2047-dqen-1500w.png"
                    className="footer-content-image"
                />
            </div>
        </div>
    )
}

FooterHerbariumContent.defaultProps = {
    image_alt: 'logo',
    image_src: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
    rootClassName: '',
    emailSci: 'อีเมล : sci-pr@psu.ac.th',
    ContactSci:
        'คณะวิทยาศาสตร์ มหาวิทยาลัยสงขลานครินทร์ 15 ถ.กาญจนวณิชย์ อ.หาดใหญ่ จ.สงขลา 90110',
    image_src1:
        'https://presentation-website-assets.teleporthq.io/logos/logo.png',
    callSci: 'โทรศัพท์ : +66 (0) 74 288 022',
}

FooterHerbariumContent.propTypes = {
    image_alt: PropTypes.string,
    image_src: PropTypes.string,
    rootClassName: PropTypes.string,
    emailSci: PropTypes.string,
    ContactSci: PropTypes.string,
    image_src1: PropTypes.string,
    callSci: PropTypes.string,
}

export default FooterHerbariumContent
