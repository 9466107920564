import React from 'react'

import PropTypes from 'prop-types'

import './details-plant-card.css'

const PlantCard = (props) => {
  return (
    <div className={`details-plant-card-feature-card ${props.rootClassName} `}>
      <h2 className="details-plant-card-text">{props.title}</h2>
      <span className="details-plant-card-text1">{props.description}</span>
    </div>
  )
}

PlantCard.defaultProps = {
  rootClassName: '',
  title: 'Lorem ipsum',
  description: '-',
}

PlantCard.propTypes = {
  rootClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default PlantCard
