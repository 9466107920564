import React, { useCallback, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./login.css"
import { Grid, TextField, InputAdornment, IconButton } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"

import { request } from "../axios-config"
import { useAuth } from "../contexts/auth-context"

const Login = (props) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { setAuth } = useAuth()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(true)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = useCallback(async (event) => {
    try {
      event.preventDefault();
      const response = await request.post(
        "/login",
        {
          username,
          password,
        },
        {
          withCredentials: true,
        },
      )
      // console.log(response)
      if (response.status === 200) {
        setAuth((prev) => {
          // console.log(JSON.stringify(prev))
          // console.log(response.data.accessToken)
          return {
            ...prev,
            roles: response.data.roles,
            accessToken: response.data.accessToken,
          }
        })
        localStorage.setItem("Me", { roles: response.data.roles })
        history.push("/adminmenu")
      }
    } catch (err) {
      alert("Username or password incorrect")
    }
  }, [username, password])

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Login - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name16"></Header>
      <div className="flex min-h-full items-center justify-center py-10 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-32 w-auto"
              src="/playground_assets/rectangle493235-fxf-700w.png"
              alt="archive"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div className="-space-y-px rounded-md">
            <div>
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="username"
                      id="username-Archive-login"
                      size="small"
                      fullWidth
                      inputProps={{
                        autocomplete: "new-password",
                        // form: {
                        //   autocomplete: "off",
                        // },
                      }}
                      autoComplete="none"
                      value={username ? username : ""}
                      onChange={(e) => {
                        setUsername(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <TextField
                      label="password"
                      id="password-Archive-login"
                      size="small"
                      inputProps={{
                        autocomplete: "new-password",
                        // form: {
                        //   autocomplete: "off",
                        // },
                      }}
                      type={showPassword ? "password" : "text"}
                      fullWidth
                      value={password ? password : ""}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <button
                      type="submit"
                      style={{ backgroundColor: "#144372" }}
                      // onClick={handleSubmit}
                      className="group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign in
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name15"></Footer>
    </div>
  )
}

export default Login
