import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import CardCategories from '../components/card-categories'
import ResearcherCard from '../components/researcher-card'
import Footer from '../layouts/footer-layout'
import './homepage.css'
import { request } from '../axios-config'

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import { Search, ArrowBackIosNew, ArrowForwardIos, Scale } from "@mui/icons-material"
import ItemArchiveCardMicrobe from "../components/item-archive-card-microbe"
import ItemArchiveCardAnimal from "../components/item-archive-card-animal"
import ItemArchiveCardPlant from "../components/item-archive-card-plant"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgb(20, 67, 114)",
        borderRadius: 100,
        transform: "scale(1.5)"
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgb(20, 67, 114)",
        borderRadius: 100,
        transform: "scale(1.5)",
      }}
      onClick={onClick}
    />
  )
}

const dotStyles = {
  marginTop: "20px",
  fontSize: "50px",
}

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
      },
    },
    {
      breakpoint: 922,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
}

const Homepage = (props) => {
  const history = useHistory()
  const [lecturerDatas, setLecturerDatas] = useState([])
  const [countItems, setCountItems] = useState([])
  const [countGeoItems, setCountGeoItems] = useState([])
  const [namePath, setNamePath] = useState('')
  const [searchDatas, setSearchDatas] = useState([])
  const [allItem, setAllItem] = useState([])
  const [searchResearcher, setSearchResearcher] = useState([])

  const microbeRegex = new RegExp("MICROBE", "i")
  const plantRegex = new RegExp("PLANT", "i")
  const animalRegex = new RegExp("ANIMAL", "i")

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getItem = async () => {
      try {
        const response = await request.get('/lecturer', {
          signal: controller.signal
        })
        isMounted && setLecturerDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const getAllItem = async () => {
      try {
        const response = await request.get('/getallsitems', {
          signal: controller.signal
        })
        isMounted && setAllItem(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getAllItem();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  // console.log(allItem)


  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const countItems = async () => {
      try {
        const response = await request.get('/count', {
          signal: controller.signal
        })
        isMounted && setCountItems(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    countItems();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController();
    const countGeoItems = async () => {
      try {
        const response = await request.get('/countGeo', {
          signal: controller.signal
        })
        isMounted && setCountGeoItems(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    countGeoItems();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  //console.log(countGeoItems);

  const [searchInput, setSearchInput] = useState("");
  const handleChange = async (e) => {
    setSearchInput(e.target.value)
    const response = await request.post(
          "/search",
          {
            search: {
              scientificName: `${e.target.value}`,
            },
          },
          )
    e.preventDefault();
    
    setSearchDatas(response?.data);
  };
  //console.log(searchInput)

    useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    
    const search = async () => {
      try {
        const response = await request.post(
          "/search",
          {
            search: {
              scientificName: `${searchInput}`,
            },
          },
          {
            signal: controller.signal,
          },
          )
          isMounted && setSearchDatas(response?.data)
        } catch (error) {
          console.error(error)
        }
      }
      search()
      return () => {
        isMounted = false
        controller.abort()
      }
    }, [])
  // console.log(searchDatas);
  

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="homepage-hero">
        <div className="homepage-container01">
          <h1 className="homepage-text">Science Natural Archive Platform</h1>
          <span className="homepage-text01">
            The Faculty of Science at Prince of Songkla University has launched the Science Natural Archive Platform, with the primary aim of gathering and sharing research samples, articles, and data related to the natural world. This initiative seeks to create a comprehensive repository of information from researchers within the Faculty, ensuring that it is accessible to the public for future reference. Through this platform, we hope to increase public access to valuable scientific information and promote collaboration among researchers to further scientific knowledge.
          </span>
        </div>
      </div>
      <div className="homepage-research-categories">
        <div className="homepage-container02">
          <h1 className="homepage-text02">
            <span>Research Category</span>
            <br></br>
          </h1>
          <span className="homepage-text05">
            <span>ประเภทงานวิจัย</span>
            <br></br>
          </span>
        </div>
      </div>

      <Container
        sx={{
          maxWidth: "100%",
          margin: 10,
          marginRight: 0,
          marginLeft: 0,
          "@media(max-width: 767px)": {
            padding: 0,
          },
        }}
      >
        <style>
          {`
        .slick-dots li button:before { font-size: ${dotStyles.fontSize};
         margin-top: ${dotStyles.marginTop} }`}
        </style>
        <Slider {...settings}>
          {countItems?.map((row) => (
            // <div className="homepage-container04">
            <CardCategories
              key={row?._id}
              name={row?._id?.category}
              Samples={row?.count + " SAMPLES"}
              // rootClassName="card-categories-root-class-name6"
              className="homepage-component01"
            ></CardCategories>
            // </div>
          ))}

          {countGeoItems?.map((row) => (
            // <div className="homepage-container04">
            <CardCategories
              key={row?._id}
              name={row?._id?.category}
              Samples={row?.count + " SAMPLES"}
              // rootClassName="card-categories-root-class-name6"
              className="homepage-component01"
            ></CardCategories>
            // </div>
          ))}
        </Slider>
      </Container>

      <Container>
        <Autocomplete
          disablePortal
          options={
            allItem.length !== 0
              ? allItem.map((row) => ({
                  ...row,
                  // label: (()=>{
                  //   if (microbeRegex.test(option?.category))
                  //        return `${option?.family} ${option?.genericName} ${option?.specificEpithet} ${option?.sp || ""} ${option?.subspecies || ""}`
                  //   if (plantRegex.test(option?.category))
                  //        return `${option?.family} ${option?.genericName} ${option?.specificEpithet} ${option?.authorName1 || ""} ${option?.authorName2 || ""}`
                  //   if (animalRegex.test(option?.category))
                  //        return `${option?.family} ${option?.genericName} ${option?.specificEpithet} ${option?.authorName1 || ""}`
                  //  })
                }))
              : []
          }
          getOptionLabel={(option) => {
            if (microbeRegex.test(option?.category))
              return `${option?.genericName} ${
                option?.specificEpithet || ""
              } ${option?.sp || ""} ${option?.subspecies || ""} ${
                option?.strainDesignation || ""
              }`
            if (plantRegex.test(option?.category))
                 return `${option?.genericName} ${option?.specificEpithet || ""} ${option?.authorName1 || ""} ${option?.authorName2 || ""}`
            if (animalRegex.test(option?.category))
                 return `${option?.genericName} ${option?.specificEpithet || ""} ${option?.authorName1 || ""}`
           }}
        // sx={{ width: 500 }}
        renderInput={(params => <TextField {...params} label="Search here" />)}
        onChange={(e, value) => {
          // console.log('value', value)
          if (value !== null) {  
            setSearchDatas([value])
          } else (
            setSearchDatas([])
          )
        }}
        // onChange={(event, value) => console.log(value)} 
      />
      <Grid sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px'}}>
        <Button variant="contained" startIcon={<Search />} onClick={()=>history.push("/advanced-search-archive")}>
          advanced search
        </Button>
      </Grid>
      
      </Container>

      <Container
        sx={{
          maxWidth: "100%",
          marginTop: 6,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 15,
          "@media(max-width: 767px)": {
            padding: 0,
          },
        }}
      >
        <style>
          {`
        .slick-dots li button:before { font-size: ${dotStyles.fontSize};
         margin-top: ${dotStyles.marginTop} }`}
        </style>

        {searchDatas.length !== 0 ? (
          searchDatas?.slice(0, 12).map((row, index) => {
            const category = row?.category
            if (microbeRegex.test(category))
              return (
                <ItemArchiveCardMicrobe
                  key={index}
                  index={index}
                  id={row?._id}
                  photo={row?.photo}
                  genericName={row?.genericName}
                  specificEpithet={row?.specificEpithet}
                  sp={row?.sp}
                  subspecies={row?.subspecies}
                  typeOfIsolate={row?.typeOfIsolate}
                  strainDesignation={row?.strainDesignation}
                  rootClassName="item-archive-card-root-class-name"
                />
              )
            if (plantRegex.test(category))
              return (
                <ItemArchiveCardPlant
                  key={index}
                  index={index}
                  id={row?._id}
                  photo={row?.photo}
                  genericName={row?.genericName}
                  specificEpithet={row?.specificEpithet}
                  authorName1={row?.authorName1}
                  authorName2={row?.authorName2}
                  commonName={row?.commonName}
                  vercularName={row?.vercularName}
                  rootClassName="item-archive-card-root-class-name"
                />
              )
            if (animalRegex.test(category))
              return (
                <ItemArchiveCardAnimal
                  key={index}
                  index={index}
                  id={row?._id}
                  photo={row?.photo}
                  genericName={row?.genericName}
                  specificEpithet={row?.specificEpithet}
                  authorName1={row?.authorName1}
                  commonName={row?.commonName}
                  vercularName={row?.vercularName}
                  rootClassName="item-archive-card-root-class-name"
                />
              )
          })
        ) : (
          <Slider {...settings}>
            {allItem?.slice(0, 9).map((row, index) => {
              const category = row?.category
              if (microbeRegex.test(category))
                return (
                  <ItemArchiveCardMicrobe
                    key={index}
                    index={index}
                    id={row?._id}
                    photo={row?.photo}
                    genericName={row?.genericName}
                    specificEpithet={row?.specificEpithet}
                    sp={row?.sp}
                    subspecies={row?.subspecies}
                    typeOfIsolate={row?.typeOfIsolate}
                    strainDesignation={row?.strainDesignation}
                    rootClassName="item-archive-card-root-class-name"
                  />
                )
              if (plantRegex.test(category))
                return (
                  <ItemArchiveCardPlant
                    key={index}
                    index={index}
                    id={row?._id}
                    photo={row?.photo}
                    genericName={row?.genericName}
                    specificEpithet={row?.specificEpithet}
                    authorName1={row?.authorName1}
                    authorName2={row?.authorName2}
                    commonName={row?.commonName}
                    vercularName={row?.vercularName}
                    rootClassName="item-archive-card-root-class-name"
                  />
                )
              if (animalRegex.test(category))
                return (
                  <ItemArchiveCardAnimal
                    key={index}
                    index={index}
                    id={row?._id}
                    photo={row?.photo}
                    genericName={row?.genericName}
                    specificEpithet={row?.specificEpithet}
                    authorName1={row?.authorName1}
                    commonName={row?.commonName}
                    vercularName={row?.vercularName}
                    rootClassName="item-archive-card-root-class-name"
                  />
                )
            })}
          </Slider>
        )}
      </Container>
      <Box sx={{ height: 10 }}></Box>
      <div className="homepage-researcher-categories">
        <div className="homepage-container08">
          <h1 className="homepage-text08">
            <span>Researchers / Contributors</span>
            <br></br>
          </h1>
          <span className="homepage-text11">
            <span>นักวิจัยและผู้ร่วมให้ข้อมูล</span>
            <br></br>
          </span>
        </div>
      </div>
      <Container sx={{ mt: 2 }}>
        <Autocomplete
              disablePortal    
              options={lecturerDatas.length !== 0 ? lecturerDatas.map((row) => ({ ...row, label: `${row?.name}` })) : []}
              getOptionLabel={(option) => option.title + " " + option.name + " " + option.surname || ""}
              // sx={{ width: 500 }}
              renderInput={(params => <TextField {...params} label="Search researcher here" />)}
              onChange={(e, value) => {
                // console.log('value', value)
                if (value !== null) { 
                  setSearchResearcher([value])
                } else (
                  setSearchResearcher([])
                )
              }}
            />
      </Container>

      <Container
        sx={{
          maxWidth: "100%",
          marginTop: 10,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 15,
          "@media(max-width: 767px)": {
            padding: 0,
          },
        }}
      >
        <style>
          {`
        .slick-dots li button:before { font-size: ${dotStyles.fontSize};
         margin-top: ${dotStyles.marginTop} }`}
        </style>

        {searchResearcher.length !== 0 ? (
          searchResearcher?.map((row, index) => (
              <ResearcherCard
                key={index}
                id={row?._id}
                index={index}
                photo={row?.pictureUrl}
                name={row?.title + " " + row?.name + " " + row?.surname}
                division={row?.major}
                email={row?.email}
                rootClassName="researcher-card-root-class-name10"
              />
            ))
        ) : (
          <Slider {...settings}>
            {lecturerDatas?.slice(0, 9).map((row, index) => (
              <ResearcherCard
                key={index}
                id={row?._id}
                index={index}
                photo={row?.pictureUrl}
                name={row?.title + " " + row?.name + " " + row?.surname}
                division={row?.major}
                email={row?.email}
                rootClassName="researcher-card-root-class-name10"
              />
            ))}
          </Slider>
        )}
      </Container>

      <a
        href="#"
        class="hover:underline flex flex-wrap items-center justify-center "
      >
        <Link to={`/researcher`}>More Researcher in Archive</Link>
      </a>

      <Footer></Footer>
    </div>
  )
}

export default Homepage
