import React, { Fragment, useEffect, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import DetailFields from "../components/detail-fields"
import Footer from "../layouts/footer-layout"
import "./add-lecturer.css"
import { request } from "../axios-config"
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material"
import { useHistory, useParams } from "react-router-dom"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"

const EditUser = (props) => {
  const requestPrivate = useAxiosPrivate()
  const [user, setUser] = useState({})
  const [userData, setUserData] = useState({})
  const params = useParams()
  const history = useHistory()
  const [userNameError, setUserNameError] = useState(false)
  const [userName, setUserName] = useState("")
  const [emailError, setEmailError] = useState("")

  useEffect(() => {
    // console.log(params?.id)
    let isMounted = true
    const controller = new AbortController()
    const getItem = async () => {
      try {
        const response = await request.get(`/userinfo/${params?.id}`, {
          signal: controller.signal,
        })
        isMounted && setUserData(response?.data)
        isMounted &&
          setUser({
            ...user,
            firstname: response?.data?.firstname,
            lastname: response?.data?.lastname,
            username: response?.data?.username,
            password: response?.data?.password,
            email: response?.data?.email,
            mobile: response?.data?.mobile,
          })
        isMounted && setUserName(response?.data?.username)
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const Submit = async () => {
     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
     if (!emailRegex.test(user?.email)) {
       setEmailError("กรุณากรอกที่อยู่อีเมลที่ถูกต้อง")
       return
     }

    try {
      if (!user?.firstname) {
        alert("Name is required")
      } else {
        const response = await requestPrivate.put(`/updateUser/${params?.id}`, {
          ...user,
        })
        if (response) {
          history.push("/admin-user-management")
          alert("success")
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const CheckUserName = async (username) => {
    if (username.trim() !== "") {
      try {
        const response = await request.get(`/checkusername/${username.trim()}`)
        // console.log("result", response?.data[0]?.username, userName)
        if (response.data.length > 0 && response?.data[0]?.username !== userName) {
          setUserNameError(true)
        } else {
          setUserNameError(false)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const ChangeUserName = (e) => {
    setUser({
      ...user,
      username: e.target.value,
    })
    CheckUserName(e.target.value)
  }

  return (
    <Fragment>
      <Helmet>
        <title>EditUser - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddLecturer - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name3" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "User Lists", path: `/admin-user-management` },
          { title: "Edit User" },
          // {
          //   title: `${userData?.title ? userData?.title : ""} ${
          //     userData?.name ? userData?.name : ""
          //   } ${userData?.surname ? userData?.surname : ""} `,
          // },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid container direction={"row"} sx={{ alignItems: "center", mb: 3 }}>
          {/* <Grid>
            <Typography variant="h4" fontStyle="italic">
              {`${userData?.title ? userData?.title : ""}${
                userData?.name ? userData?.name : ""
              } ${userData?.surname ? userData?.surname : ""} `}
            </Typography>
          </Grid> */}
          <Grid sx={{ ml: 1 }}>
            <Typography variant="h3">Edit User</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Firstname</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="firstname"
                required
                size="small"
                fullWidth
                value={user ? user?.firstname : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    firstname: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Lastname</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="lastname"
                required
                size="small"
                fullWidth
                value={user ? user?.lastname : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    lastname: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Username</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="username"
                required
                size="small"
                fullWidth
                value={user ? user?.username : ""}
                onChange={(e) => ChangeUserName(e)}
                error={userNameError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {user.username && (
                        <div>
                          {userNameError ? (
                            <Typography sx={{ fontSize: 10, color: "red" }}>
                              {/* <ClearIcon
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    marginRight: 0.3,
                                  }}
                                /> */}
                              ชื่อผู้ใช้นี้ถูกใช้งานไปแล้ว
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: 10, color: "green" }}>
                              {/* <CheckIcon
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    marginRight: 0.3,
                                  }}
                                /> */}
                              ชื่อผู้ใช้สามารถใช้งานได้
                            </Typography>
                          )}
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Email</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="email"
                required
                size="small"
                fullWidth
                value={user ? user?.email : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  })
                }}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Mobile</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="major"
                required
                size="small"
                fullWidth
                value={user ? user?.mobile : ""}
                onChange={(e) => {
                  const input = e.target.value
                  const onlyNums = input.replace(/[^0-9]/g, "")
                  if (onlyNums.length <= 10) {
                    setUser({
                      ...user,
                      mobile: onlyNums,
                    })
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid
            pt={3}
            columnGap={1}
            xs={12}
            container
            direction={"row"}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            // spacing={2}
          >
            <Button
              variant="contained"
              onClick={Submit}
              disabled={userNameError}
            >
              Submit
            </Button>
            <Button
              variant="text"
              onClick={() => {
                history.push("/admin-user-management")
              }}
            >
              cancel
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </Fragment>
  )
}

export default EditUser
