import React, {useState } from "react"
import { Link } from "react-router-dom"

import PropTypes from 'prop-types'

import './item-archive-card.css'

const ItemArchiveCardMicrobe = (props) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const containerStyle = {
    backgroundImage:
      props.photo.length > 0
        ? `url("${props.photo[0]}")`
        : "url(/playground_assets/rectangle493235-fxf-400h.png)",
    backgroundSize: isHovered ? "170%" : "cover",
    backgroundPosition: isHovered ? "center left" : "center",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    transition:
      "transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, background-size 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s, background-position 0.5s",
  }
  return (
    <div
      key={props?.index}
      className={`item-archive-card-testimonial-card ${props.rootClassName} `}
    >
      <Link
        to={`/detailmicrobe/${props?.id}`}
        className="item-archive-card-navlink"
      >
        {/* <div className="item-archive-card-container" style={{backgroundImage:`url("${props.photo}")`}}> */}
        <div
          className="item-archive-card-container"
          style={containerStyle}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <div className="item-archive-card-container1" style={{ padding: 20 }}>
            <span className="item-archive-card-text">
              <i>{props.genericName ? props.genericName + "\u00A0" : ""}</i>
              <i>{props.specificEpithet ? props.specificEpithet + "\u00A0" : ""}</i>
              {props.sp ? props.sp + "\u00A0" : ""}
              {props.subspecies ? props.subspecies + "\u00A0" : ""}
              <i>{props.strainDesignation ? props.strainDesignation + "\u00A0" : ""}</i>
            </span>
            <span className="item-archive-card-text1">
              {props.typeOfIsolate}
            </span>
            {/* <span className="item-archive-card-text2">{props.thaicommonName}</span> */}
          </div>
        </div>
      </Link>
    </div>
  )
}

ItemArchiveCardMicrobe.defaultProps = {
  index: 0,
  genericName: "",
  specificEpithet: "",
  sp: "",
  subspecies: "",
  typeOfIsolate: "",
  strainDesignation: "",
  rootClassName: "",
  photo: ["/playground_assets/rectangle493235-fxf-400h.png"],
}

ItemArchiveCardMicrobe.propTypes = {
  index: PropTypes.number,
  photo: PropTypes.array,
  genericName: PropTypes.string,
  specificEpithet: PropTypes.string,
  sp: PropTypes.string,
  subspecies: PropTypes.string,
  typeOfIsolate: PropTypes.string,
  strainDesignation: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default ItemArchiveCardMicrobe
