import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../layouts/header-layout'
import Footer from '../layouts/footer-layout'
import './plant.css'
import { request } from '../axios-config'
import { Autocomplete, Box, Grid, Popper, TextField, styled } from '@mui/material'
import ItemArchiveCardPlant from '../components/item-archive-card-plant'

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ItemArchiveCardPlant2 from '../components/item-archive-card-plant2'
import ItemArchiveCardPlant1 from '../components/item-archive-card-plant1'

const itemsPerPage = 4;

const Plant = (props) => {
  const [angiospermPage, setAngiospermPage] = useState(1);
  const [gymnospermPage, setGymnospermPage] = useState(1);
  const [lycophyteAndFernsPage, setLycophyteAndFernsPage] = useState(1);
  const [bryophytesPage, setBryophytesPage] = useState(1);
  const [algaeAndPhytoplanktonPage, setAlgaeAndPhytoplanktonPage] = useState(1);

  const [plantDatas, setPlantDatas] = useState([]);
  const [searchDatas, setSearchDatas] = useState([]);

  const filterDataByCategory = (data, category) => {
    return data.filter((item) => item.category === category);
  };

  const algaeAndPhytoplanktonData = filterDataByCategory(plantDatas, "PLANT_ALGAEANDPHYTOPLANKTON");
  const angiospermData = filterDataByCategory(plantDatas, "PLANT_ANGIOSPERM");
  const bryophytesData = filterDataByCategory(plantDatas, "PLANT_BRYOPHYTES");
  const gymnospermData = filterDataByCategory(plantDatas, "PLANT_GYMNOSPERM");
  const lycophyteAndFernsData = filterDataByCategory(plantDatas, "PLANT_LYCOPHYTEANDFERNS");
  
  const algaeAndPhytoplanktonStartIndex = (algaeAndPhytoplanktonPage - 1) * itemsPerPage;
  const algaeAndPhytoplanktonEndIndex = algaeAndPhytoplanktonStartIndex + itemsPerPage;
  const algaeAndPhytoplanktonPageData = algaeAndPhytoplanktonData.slice(algaeAndPhytoplanktonStartIndex, algaeAndPhytoplanktonEndIndex);
  
  const angiospermStartIndex = (angiospermPage - 1) * itemsPerPage;
  const angiospermEndIndex = angiospermStartIndex + itemsPerPage;
  const angiospermPageData = angiospermData.slice(angiospermStartIndex, angiospermEndIndex);

  const bryophytesStartIndex = (bryophytesPage - 1) * itemsPerPage;
  const bryophytesEndIndex = bryophytesStartIndex + itemsPerPage;
  const bryophytesPageData = bryophytesData.slice(bryophytesStartIndex, bryophytesEndIndex);
  
  const gymnospermStartIndex = (gymnospermPage - 1) * itemsPerPage;
  const gymnospermEndIndex = gymnospermStartIndex + itemsPerPage;
  const gymnospermPageData = gymnospermData.slice(gymnospermStartIndex, gymnospermEndIndex);

  const lycophyteAndFernsStartIndex = (lycophyteAndFernsPage - 1) * itemsPerPage;
  const lycophyteAndFernsEndIndex = lycophyteAndFernsStartIndex + itemsPerPage;
  const lycophyteAndFernsPageData = lycophyteAndFernsData.slice(lycophyteAndFernsStartIndex, lycophyteAndFernsEndIndex);
  
  const handleAlgaeAndPhytoplanktonPageChange = (event, value) => {
    setAlgaeAndPhytoplanktonPage(value);
  };

  const handleAngiospermPageChange = (event, value) => {
    setAngiospermPage(value);
  };
  
  const handleBryophytesPageChange = (event, value) => {
    setBryophytesPage(value);
  };

  const handleGymnospermPageChange = (event, value) => {
    setGymnospermPage(value);
  };

  const handleLycophyteAndFernsPageChange = (event, value) => {
    setLycophyteAndFernsPage(value);
  };

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getItem = async () => {
      try {
        const response = await request.post(
          "/items",
          {
            filter: {
              category: "PLANT",
            },
          },
          {
            signal: controller.signal,
          },
        )
        isMounted && setPlantDatas(response?.data)
      } catch (error) {
        console.error(error)
      }
    }
    getItem()
    return () => {
      isMounted = false
      controller.abort()
    }
    }, [])
  // console.log(plantDatas);

  // const [searchInput, setSearchInput] = useState("");
  // const handleChange = async (e) => {
  //   setSearchInput(e.target.value)
  //   const response = await request.post(
  //         "/search",
  //         {
  //           search: {
  //             scientificName: `${e.target.value}`,
  //           },
  //         },
  //         )
  //   e.preventDefault();

  //   setSearchDatas(response?.data);
  // };
  // console.log(searchInput)
  // // if (searchInput.length > 0) {
  // //   countries.filter((country) => {
  // //     return country.name.match(searchInput);
  // //   });
  // // }

  // useEffect(() => {
  //   let isMounted = true
  //   const controller = new AbortController()

  //   const search = async () => {
  //     try {
  //       const response = await request.post(
  //         "/search",
  //         {
  //           search: {
  //             scientificName: `${searchInput}`,
  //           },
  //         },
  //         {
  //           signal: controller.signal,
  //         },
  //         )
  //         isMounted && setSearchDatas(response?.data)
  //       } catch (error) {
  //         console.error(error)
  //       }
  //     }
  //     search()
  //     return () => {
  //       isMounted = false
  //       controller.abort()
  //     }
  //   }, [])
  // console.log(searchDatas);
  const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiAutocomplete-groupLabel': {
      color: theme.palette.primary.main,
    },
  }));

  const styleBox = {
    padding: "20px" , 
    marginBottom: "30px",
    borderRadius: "8px", 
    width: "100%",
    backgroundColor: "#144372", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.95
  }

  const styleBoxLabel = {
    textAlign: "center",
    wordBreak: "break-all",
    fontSize: "20px",
    color: "#fff"
  }

  const stylePagination = {
    alignItems: "center",
    justifyContent: "center" ,
    marginTop: "30px",
    marginBottom: "40px",
  }

  return (
    <div className="plant-container">
      <Helmet>
        <title>Plant - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="Plant - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header></Header>
      <div className="plant-hero">
        <div className="plant-container1">
          <h1 className="plant-text">
            <span className="plant-text01">
              Plants Archive of Faculty of Science,
            </span>
            <br></br>
            <span>Prince of Songkla University</span>
            <br></br>
          </h1>
          <span className="plant-text05">
            <span className="plant-text06">
              The lecturers of the Faculty of Science at Prince of Songkla
              University have compiled a collection of plant samples, research
              data, and related information on this website. All information
              contained on this site is freely available for academic and
              educational use. We strive to keep the contents of this website up
              to date with the latest findings from our faculty members. Our
              goal is to make this a valuable resource for researchers,
              educators, and anyone interested in advancing scientific knowledge
              related to plant life.
            </span>
            <br></br>
          </span>
        </div>
      </div>

      <div className="plant-search-tab">
        <Autocomplete
          disablePortal
          options={
            plantDatas.length !== 0
              ? plantDatas
                  .map((row) => ({
                    ...row,
                    label: `${
                      row?.genericName +
                      " " +
                      row?.specificEpithet +
                      " " +
                      row?.authorName1
                    }`,
                    groupLabel:
                      row.category === "PLANT_ANGIOSPERM"
                        ? "ANGIOSPERM"
                        : row.category === "PLANT_GYMNOSPERM"
                        ? "GYMNOSPERM"
                        : row.category === "PLANT_LYCOPHYTEANDFERNS"
                        ? "LYCOPHYTEANDFERNS"
                        : row.category === "PLANT_BRYOPHYTES"
                        ? "BRYOPHYTES"
                        : row.category === "PLANT_ALGAEANDPHYTOPLANKTON"
                        ? "ALGAEANDPHYTOPLANKTON"
                        : null,
                  }))
                  .sort((a, b) => -b.category.localeCompare(a.category))
              : []
          }
          groupBy={(option) => option.groupLabel}
          getOptionLabel={(option) =>
            option.genericName +
              " " +
              option.specificEpithet +
              " " +
              option.authorName1 || ""
          }
          renderInput={(params) => (
            <TextField {...params} label="Search here" />
          )}
          onChange={(e, value) => {
            // console.log('value', value)
            if (value !== null) {
              setSearchDatas([value])
            } else setSearchDatas([])
          }}
          sx={{
            width: 500,
          }}
          PopperComponent={StyledPopper}
        />
      </div>

      <div className="plant-features">
        <div>
          {searchDatas.length !== 0 ? (
            searchDatas?.map((row, index) => (
              <ItemArchiveCardPlant
                key={index}
                index={index}
                id={row?._id}
                photo={row?.photo}
                genericName={row?.genericName}
                specificEpithet={row?.specificEpithet}
                authorName1={row?.authorName1}
                authorName2={row?.authorName2}
                commonName={row?.commonName}
                vercularName={row?.vercularName}
                rootClassName="item-archive-card-root-class-name"
              />
            ))
          ) : (
            <div>
              {algaeAndPhytoplanktonPageData &&
                algaeAndPhytoplanktonPageData?.length > 0 && (
                  <div>
                    <Box sx={{ ...styleBox }}>
                      <span style={{ ...styleBoxLabel }}>
                        ALGAE AND PHYTOPLANKTON
                      </span>
                    </Box>

                    <div className="plant-container2">
                      {algaeAndPhytoplanktonPageData?.map((row, index) => (
                        <ItemArchiveCardPlant
                          key={index}
                          index={index}
                          id={row?._id}
                          photo={row?.photo}
                          genericName={row?.genericName}
                          specificEpithet={row?.specificEpithet}
                          authorName1={row?.authorName1}
                          authorName2={row?.authorName2}
                          commonName={row?.commonName}
                          vernacularName={row?.vernacularName}
                          rootClassName="item-archive-card-root-class-name"
                        />
                      ))}
                    </div>
                    <Stack spacing={2} sx={{ ...stylePagination }}>
                      <Pagination
                        count={Math.ceil(
                          algaeAndPhytoplanktonData.length / itemsPerPage,
                        )}
                        shape="rounded"
                        page={algaeAndPhytoplanktonPage}
                        onChange={handleAlgaeAndPhytoplanktonPageChange}
                      />
                    </Stack>
                  </div>
                )}

              {angiospermPageData && angiospermPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>ANGIOSPERMS</span>
                  </Box>

                  <div className="plant-container2">
                    {angiospermPageData?.map((row, index) => (
                      <ItemArchiveCardPlant
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        authorName1={row?.authorName1}
                        authorName2={row?.authorName2}
                        commonName={row?.commonName}
                        vercularName={row?.vercularName}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(angiospermData.length / itemsPerPage)}
                      shape="rounded"
                      page={angiospermPage}
                      onChange={handleAngiospermPageChange}
                    />
                  </Stack>
                </div>
              )}
              {bryophytesPageData && bryophytesPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>BRYOPHYTES</span>
                  </Box>

                  <div className="plant-container2">
                    {bryophytesPageData?.map((row, index) => (
                      <ItemArchiveCardPlant
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        authorName1={row?.authorName1}
                        authorName2={row?.authorName2}
                        commonName={row?.commonName}
                        vernacularName={row?.vernacularName}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(bryophytesData.length / itemsPerPage)}
                      shape="rounded"
                      page={bryophytesPage}
                      onChange={handleBryophytesPageChange}
                    />
                  </Stack>
                </div>
              )}

              {gymnospermPageData && gymnospermPageData?.length > 0 && (
                <div>
                  <Box sx={{ ...styleBox }}>
                    <span style={{ ...styleBoxLabel }}>GYMNOSPERMS</span>
                  </Box>

                  <div className="plant-container2">
                    {gymnospermPageData?.map((row, index) => (
                      <ItemArchiveCardPlant
                        key={index}
                        index={index}
                        id={row?._id}
                        photo={row?.photo}
                        genericName={row?.genericName}
                        specificEpithet={row?.specificEpithet}
                        authorName1={row?.authorName1}
                        authorName2={row?.authorName2}
                        commonName={row?.commonName}
                        vercularName={row?.vercularName}
                        rootClassName="item-archive-card-root-class-name"
                      />
                    ))}
                  </div>
                  <Stack spacing={2} sx={{ ...stylePagination }}>
                    <Pagination
                      count={Math.ceil(gymnospermData.length / itemsPerPage)}
                      shape="rounded"
                      page={gymnospermPage}
                      onChange={handleGymnospermPageChange}
                    />
                  </Stack>
                </div>
              )}
              {lycophyteAndFernsPageData &&
                lycophyteAndFernsPageData?.length > 0 && (
                  <div>
                    <Box sx={{ ...styleBox }}>
                      <span style={{ ...styleBoxLabel }}>
                        LYCOPHYTES AND FERNS
                      </span>
                    </Box>

                    <div className="plant-container2">
                      {lycophyteAndFernsPageData?.map((row, index) => (
                        <ItemArchiveCardPlant
                          key={index}
                          index={index}
                          id={row?._id}
                          photo={row?.photo}
                          genericName={row?.genericName}
                          specificEpithet={row?.specificEpithet}
                          authorName1={row?.authorName1}
                          authorName2={row?.authorName2}
                          commonName={row?.commonName}
                          vernacularName={row?.vernacularName}
                          rootClassName="item-archive-card-root-class-name"
                        />
                      ))}
                    </div>
                    <Stack spacing={2} sx={{ ...stylePagination }}>
                      <Pagination
                        count={Math.ceil(
                          lycophyteAndFernsData.length / itemsPerPage,
                        )}
                        shape="rounded"
                        page={lycophyteAndFernsPage}
                        onChange={handleLycophyteAndFernsPageChange}
                      />
                    </Stack>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      <Footer rootClassName="footer-root-class-name17"></Footer>
    </div>
  )
}

export default Plant
