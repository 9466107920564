import React, { Fragment, useState } from "react"

import { Helmet } from "react-helmet"

import Header from "../layouts/header-layout"
import Footer from "../layouts/footer-layout"
import "./add-lecturer.css"
import { request } from "../axios-config"
import {
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  Select,
  InputAdornment,
} from "@mui/material"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import BreadcrumbLayout from "../components/breadcrumb-layout"
import useAxiosPrivate from "../hook/use-axios-private"
import { useHistory } from "react-router-dom"

const AddminAddUser = (props) => {
  const requestPrivate = useAxiosPrivate()
  const history = useHistory()
  const [user, setUser] = useState({})
  const [confirmpassword, setConfirmPassword] = useState("")
  const [showpassconfirm, setShowpassconfirm] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [userNameError, setUserNameError] = useState(false)

  const AddUser = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(user?.email)) {
      setEmailError("กรุณากรอกที่อยู่อีเมลที่ถูกต้อง")
      return
    }

    try {
      if (!user?.username) {
        alert("Username is required")
      } else {
        const response = await requestPrivate.post("/user", {
          ...user,
        })
        if (response) {
          alert("success")
          history.push("/admin-user-management")
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const ClearData = () => {
    setUser({
      ...user,
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      role: "",
      email: "",
      mobile: "",
    })
    setConfirmPassword("")
  }

  const CheckUserName = async (username) => {
    if (username.trim() !== "") {
      try {
        const response = await request.get(`/checkusername/${username.trim()}`)
        // console.log("result", response.data)
        if (response.data.length > 0) {
          setUserNameError(true)
        } else {
          setUserNameError(false)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const ChangeUserName = (e) => {
    setUser({
      ...user,
      username: e.target.value,
    })
    CheckUserName(e.target.value)
  }

  return (
    <Fragment>
      <Helmet>
        <title>AddUser - Sci PSU Archive</title>
        <meta
          name="description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
        <meta property="og:title" content="AddUser - Sci PSU Archive" />
        <meta
          property="og:description"
          content="Science Natural Archive Platform is initated by Faculty of Science, Prince of Songkla University."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name3" auth={true}></Header>
      <BreadcrumbLayout
        pages={[
          { title: "Admin Menu", path: `/adminmenu` },
          { title: "User Lists", path: `/admin-user-management` },
          { title: "Add User" },
        ]}
      />
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Grid sx={{ mb: 2 }}>
          <Typography variant="h3">Add User</Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Firstname</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                id="firstname"
                required
                size="small"
                fullWidth
                value={user ? user?.firstname : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    firstname: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Lastname</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="lastname"
                required
                size="small"
                fullWidth
                value={user ? user?.lastname : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    lastname: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          ></Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Role</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl fullWidth>
                <Select
                  defaultValue={""}
                  labelId="role"
                  id="role"
                  required
                  size="small"
                  onChange={(e) => {
                    setUser({
                      ...user,
                      role: e.target.value,
                    })
                  }}
                >
                  <MenuItem value="ADMIN">Admin</MenuItem>
                  <MenuItem value="RESEARCHER">Researcher</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Email</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                id="email"
                type="email"
                required
                size="small"
                fullWidth
                value={user ? user?.email : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  })
                }}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Mobile</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                id="mobile"
                type="tel"
                required
                size="small"
                fullWidth
                value={user ? user?.mobile : ""}
                onChange={(e) => {
                  const input = e.target.value
                  const onlyNums = input.replace(/[^0-9]/g, "")
                  if (onlyNums.length <= 10) {
                    setUser({
                      ...user,
                      mobile: onlyNums,
                    })
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Username</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                id="username"
                required
                size="small"
                fullWidth
                value={user ? user?.username : ""}
                onChange={(e) => ChangeUserName(e)}
                error={userNameError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {user.username && (
                        <div>
                          {userNameError ? (
                            <Typography sx={{ fontSize: 10, color: "red" }}>
                              {/* <ClearIcon
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    marginRight: 0.3,
                                  }}
                                /> */}
                              ชื่อผู้ใช้นี้ถูกใช้งานไปแล้ว
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: 10, color: "green" }}>
                              {/* <CheckIcon
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    marginRight: 0.3,
                                  }}
                                /> */}
                              ชื่อผู้ใช้สามารถใช้งานได้
                            </Typography>
                          )}
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Password</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                id="password"
                type="password"
                required
                size="small"
                fullWidth
                value={user ? user?.password : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    password: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Confirm Password</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                id="confirmpassword"
                type={showpassconfirm ? "text" : "password"}
                required
                size="small"
                fullWidth
                value={user ? confirmpassword : ""}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {user?.password && confirmpassword && (
                        <div>
                          {user?.password === confirmpassword ? (
                            <Typography sx={{ fontSize: 10, color: "green" }}>
                              <CheckIcon
                                sx={{ width: 10, height: 10, marginRight: 0.3 }}
                              />
                              รหัสผ่านตรงกัน
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: 10, color: "red" }}>
                              <ClearIcon
                                sx={{ width: 10, height: 10, marginRight: 0.3 }}
                              />
                              รหัสผ่านไม่ตรงกัน
                            </Typography>
                          )}
                        </div>
                      )}
                      <IconButton
                        onClick={() => {
                          setShowpassconfirm(!showpassconfirm)
                        }}
                      >
                        {showpassconfirm ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              pt={1}
              container
              direction={"row"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              spacing={2}
            >
              <Button variant="text" onClick={ClearData}>
                Clear All Data
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              columnGap={1}
              container
              direction={"row"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button
                disabled={
                  user?.username === "" ||
                  user?.password === "" ||
                  user?.firstname === "" ||
                  user?.lastname === "" ||
                  user?.role === "" ||
                  user?.email === "" ||
                  user?.mobile === "" ||
                  confirmpassword === "" ||
                  user?.password !== confirmpassword ||
                  userNameError === true
                }
                variant="contained"
                onClick={AddUser}
              >
                Submit
              </Button>

              <Button
                variant="text"
                onClick={() => {
                  history.push("/admin-user-management")
                }}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </Fragment>
  )
}

export default AddminAddUser
