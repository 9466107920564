import { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Grid,
    Card,
    useMediaQuery,
    Stack,
    Box
} from '@mui/material'
import { useParams, Link as RouteLink } from 'react-router-dom';
import { request } from "../../axios-config"
import HerbariumAppBar from '../../layouts/header-herbarium';
import FooterHerbarium from '../../layouts/new-footerherbarium';
import HerbariumDetailCard from '../../components/herbarium-detail-card';
import BreadcrumbHerbarium from '../../components/breadcrumb-herbarium-layout';
import PhotoDetailHerbarium from '../../components/photoDetailHerbarium';
import ShowCountViewsByItem from '../../components/show-countviews';

const NewHerbariumDetail = () => {

    const params = useParams()
    const [herbariumDetail, setHerbariumDetail] = useState()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        const getHerbariumDetail = async () => {
            try {
                const response = await request.get(`/collectorByHerbarium/${params?.id}`, {
                    signal: controller.signal,
                })
                isMounted && setHerbariumDetail(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getHerbariumDetail()
        return () => {
            isMounted = false
            controller.abort()
        }
    }, [params?.id])


    return (
        <div>
            <HerbariumAppBar />
            <BreadcrumbHerbarium
                pages={[
                    { title: "Search", path: `/herbarium-search` },
                    { title: `Detail Herbarium` },
                ]}
            />
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ p: isMobile ? 1 : 5 }}
            >
                <Card sx={{ width: isMobile ? "85%" : "100%" }}>
                    <Grid container  justifyContent="space-between" alignItems="center"sx={{ p: { xs: 3 , sm: 5, md: 5}, }} spacing={4}>
                        <Grid item xs={12} sm={12} md={5}>
                            <Grid container direction="column" sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <Box sx={{ mr: { xs: -3 , sm: 0, md: 0},  ml: { xs: -3 , sm: 0, md: 0},}}>
                                    <PhotoDetailHerbarium
                                        itemphoto={herbariumDetail?.photograph}
                                    />
                                </Box>
                                <Grid container direction="column" sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ justifyContent: "center", mb: 2, mt: -2 }}>
                                        <ShowCountViewsByItem itemId={params?.id} nameModel={"HerbariumModel"} />
                                    </Grid>
                                    <Button variant='contained' component={RouteLink} to={`/herbarium-reserve/${params?.id}`} style={{ backgroundColor: "#10AC8E", fontFamily: 'Prompt' }}
                                        disabled={
                                            herbariumDetail?.status === "UNAVAILABLE"
                                        }
                                    >
                                        Reserve
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={7}>
                            <Stack sx={{ mt: 1 }}>
                                <Grid container spacing={isMobile ? 3 : 8} direction="row" justifyContent={"space-around"}>
                 
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Prompt', fontSize: 20, fontWeight: 'bold' }}>
                                                Status
                                            </Typography>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ color: herbariumDetail?.status === "AVAILABLE" ? "green" : "red", mt: isMobile ? 1 : 3, fontFamily: 'Prompt', fontSize: 20, fontWeight: 'bold' }}>
                                                &nbsp;{herbariumDetail?.status}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                                Family
                                            </Typography>
                                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                                {herbariumDetail?.family}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={isMobile ? 3 : 8} direction="row" sx={{ mt: 1, mb: 2 }} justifyContent={"space-between"}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                                Scientific&nbsp;Name
                                            </Typography>
                                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                                <em>{herbariumDetail?.genus} {herbariumDetail?.species}</em> {herbariumDetail?.author1} {herbariumDetail?.intraspecificStatus} <em>{herbariumDetail?.intraspecificName}</em> {herbariumDetail?.author2}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        {/* <Link to={`/herbarium-collector/${herbariumDetail?.collector}?herbarium=${params?.id}`} target="_blank"> */}
                                        <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                                            <Stack direction={'row'}>
                                                <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                                    Collector&nbsp;Name
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'row'}>
                                                {herbariumDetail?.collector}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ t: 2 }}>
                                        <Stack spacing={1} direction={{ xs: "column", sm: "column" }}>
                                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", fontFamily: 'Prompt', fontSize: 20 }}>
                                                Collector&nbsp;Number
                                            </Typography>
                                            <Typography variant="body1" component="div" sx={{ fontFamily: 'Prompt' }}>
                                                {herbariumDetail?.collectorNumber}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>

                        </Grid>
                    </Grid>
                </Card>
            </Stack>

            <HerbariumDetailCard
                family={herbariumDetail?.infraspecificName}
                species={herbariumDetail?.intraspecificStatus}
                author1={herbariumDetail?.author1}
                author2={herbariumDetail?.author2}
                intraspecificStatus={herbariumDetail?.intraspecificStatus}
                intraspecificName={herbariumDetail?.intraspecificName}
                location={herbariumDetail?.location}
                province={herbariumDetail?.province}
                district={herbariumDetail?.district}
                longtitude={herbariumDetail?.longtitude}
                latitude={herbariumDetail?.latitude}
                altitude={herbariumDetail?.altitude}
                remark={herbariumDetail?.remark}
                determineBy={herbariumDetail?.determineBy}
                dateDetermine={herbariumDetail?.dateDetermine}
                photograph={herbariumDetail?.photograph}
                duplicate={herbariumDetail?.duplicate}
                category={herbariumDetail?.category}
                commonName={herbariumDetail?.commonName}
            />

            <FooterHerbarium />
        </div>
    )
}

export default NewHerbariumDetail

